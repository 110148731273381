import React, { useState, useMemo } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ApolloError, useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import NoteAdd from "@mui/icons-material/NoteAddOutlined"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import CallSplit from "@mui/icons-material/CallSplitOutlined"

import JobNoteDialog from "./JobNoteDialog"
import CloneJobDialog from "./CloneJobDialog"
import ConfirmationDialog from "~/components/ConfirmationDialog"
import SnackbarMessage from "~/components/SnackbarMessage"
import { ARCHIVE_JOB } from "~/queries/archiveJob"
import { CLONE_JOB } from "~/queries/cloneJob"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import type { Job, Snack } from "~/types"
import { DefaultPermission, FeatureFlag } from "~/types"
import { useAuth } from "~/context/AuthContext"
import EmailDialog, { EmailDialogMode } from "~/components/EmailDialog"
import { isFeatureFlagEnabled } from "~/util/featureFlag"
import useStore from "~/store"

interface ExtraMenuOption {
  id: string
  icon: React.ReactElement
  labelKey: string
}

interface JobFormTabBarProps {
  readonly activeTabIndex: number
  readonly job: Job
  readonly mode?: string
  readonly onTabChange: (index: number) => void
}

function JobFormTabBar({
  activeTabIndex = 0,
  job,
  mode = "create",
  onTabChange,
}: JobFormTabBarProps) {
  const { hasPermissions } = useAuth()
  const { featureFlags } = useStore()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState<boolean>(false)
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false)
  const [snack, setSnack] = useState<Snack>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [isArchiveJobDialogOpen, setIsArchiveJobDialogOpen] = useState<boolean>(false)
  const [isCloneJobDialogOpen, setIsCloneJobDialogOpen] = useState<boolean>(false)

  const [sendJobEmail, { loading: sendJobEmailLoading }] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setIsEmailDialogOpen(false)
      setSnack({ messageKey: "messages.messageSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({ messageKey: "messages.messageFailed", variant: "error" })
    },
  })

  const [cloneJob, { loading: cloneJobLoading }] = useMutation(CLONE_JOB, {
    onCompleted: (data) => {
      const jobClone = data.cloneJob.job
      setRedirectTo({
        to: `/app/jobs/edit/${jobClone.id}`,
        replace: false,
        state: {
          snack: {
            messageKey: "messages.cloneJob.success",
            messageOptions: { cloneJobNumber: jobClone.number, originalJobNumber: job.number },
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({ messageKey: "messages.messageFailed", variant: "error" })
    },
  })

  const [archiveJob, { loading: archiveJobLoading }] = useMutation(ARCHIVE_JOB, {
    onCompleted: () => {
      setIsArchiveJobDialogOpen(false)
      setRedirectTo({
        to: `/app/jobs`,
        replace: false,
        state: {
          snack: {
            messageKey: "messages.archiveJob.success",
            messageOptions: { jobNumber: job.number },
            variant: "success",
          },
        },
      })
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      setSnack({ messageKey: "messages.archiveJob.error", variant: "error" })
    },
  })

  function handleMenuClick(event: any) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose() {
    setAnchorEl(null)
  }

  function handleCloneJob() {
    cloneJob({ variables: { id: job.id } })
  }

  function handleArchiveJob() {
    archiveJob({ variables: { id: job.id } })
  }

  /**
   * Handles the event triggered when a user clicks on a "more options" menu item.
   * menuOption is an entry in the  `moreMenuOptions` array below.
   */
  function handleMenuOptionSelection(menuOption: ExtraMenuOption): void {
    handleMenuClose()

    switch (menuOption.id) {
      case "NEW_NOTE":
        setIsNoteDialogOpen(true)
        return
      case "SEND_EMAIL":
        setIsEmailDialogOpen(true)
        return
      case "CLONE_JOB":
        setIsCloneJobDialogOpen(true)
        return
      case "ARCHIVE_JOB":
        setIsArchiveJobDialogOpen(true)
        return
      default:
        return
    }
  }

  const moreMenuOptions: ExtraMenuOption[] = useMemo(() => {
    const result = []

    if (hasPermissions?.([DefaultPermission.UpdateJob])) {
      result.push({
        id: "NEW_NOTE",
        icon: <NoteAdd />,
        labelKey: "newNote",
      })
    }
    if (hasPermissions?.([DefaultPermission.SendJobEmail])) {
      result.push({
        id: "SEND_EMAIL",
        icon: <EmailIcon />,
        labelKey: "sendEmail",
      })
    }
    if (hasPermissions?.([DefaultPermission.CreateJob])) {
      result.push({
        id: "CLONE_JOB",
        icon: <CallSplit />,
        labelKey: "cloneJob",
      })
    }
    if (hasPermissions?.([DefaultPermission.UpdateJob])) {
      result.push({
        id: "ARCHIVE_JOB",
        icon: <DeleteIcon />,
        labelKey: "archiveJob",
      })
    }
    return result
  }, [hasPermissions])

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "1.25rem",
      }}
    >
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      {isNoteDialogOpen ? (
        <JobNoteDialog
          jobId={job.id}
          onCancel={() => setIsNoteDialogOpen(false)}
          onSave={() => {
            setIsNoteDialogOpen(false)
            setSnack({ messageKey: "messages.changesSaved", variant: "success" })
          }}
        />
      ) : null}
      {isEmailDialogOpen ? (
        <EmailDialog
          isJobEditable={false}
          job={job}
          mode={EmailDialogMode.COMPOSE_NEW}
          onCancel={() => setIsEmailDialogOpen(false)}
          onSend={(payload) => {
            sendJobEmail({ variables: payload })
          }}
          sending={sendJobEmailLoading}
        />
      ) : null}
      {cloneJobLoading ? <CloneJobDialog /> : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          allowScrollButtonsMobile
          indicatorColor="primary"
          onChange={(e, newValue) => onTabChange(newValue)}
          scrollButtons="auto"
          textColor="inherit"
          value={activeTabIndex}
          variant="scrollable"
        >
          <Tab disableRipple label={t("basicInfo")} />
          {mode == "edit" && <Tab data-testid="filesTab" disableRipple label={t("files")} />}
          {mode == "edit" && hasPermissions?.([DefaultPermission.ReadEstimate]) ? (
            <Tab data-testid="estimatesTab" disableRipple label={t("estimates")} />
          ) : null}
          {mode == "edit" && (
            <Tab data-testid="assignmentsTab" disableRipple label={t("assignments")} />
          )}
          {mode == "edit" && (
            <Tab data-testid="workOrdersTab" disableRipple label={t("workOrders")} />
          )}
          {mode == "edit" && <Tab data-testid="tasksTab" disableRipple label={t("tasks")} />}
          {mode == "edit" &&
          isFeatureFlagEnabled(FeatureFlag.HelicalPileInstallationWorksheet, featureFlags) &&
          hasPermissions?.([DefaultPermission.ReadHelicalPileInstallationWorksheet]) ? (
            <Tab
              data-testid="helicalPileInstallationWorksheetTab"
              disableRipple
              label={t("helicalPileInstallationWorksheet.title")}
            />
          ) : null}
          {mode == "edit" && hasPermissions?.([DefaultPermission.ReadInvoice]) ? (
            <Tab data-testid="invoicesTab" disableRipple label={t("invoices")} />
          ) : null}
          {mode == "edit" && <Tab data-testid="jobLogTab" disableRipple label={t("jobLog")} />}
        </Tabs>
        {mode == "edit" && moreMenuOptions.length > 0 && (
          <Box
            onClick={handleMenuClick}
            sx={{
              marginLeft: "0.625rem",
              border: "1px solid #cdcdcd",
              borderRadius: "4px",
              width: "auto",
              height: "2.5rem",
              padding: "0 0.5rem",
              position: "relative",
              top: "0.25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              cursor: "pointer",
            }}
          >
            <Box
              aria-controls="long-menu"
              aria-haspopup="true"
              aria-label={t("more") as string}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                whiteSpace: "nowrap",
              }}
            >
              <MoreHoriz />
            </Box>
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          position: "relative",
          top: "-1px",
          zIndex: 0,
        }}
      />
      <Menu
        anchorEl={anchorEl}
        id="more-options-menu"
        keepMounted
        onClose={handleMenuClose}
        open={isMenuOpen}
      >
        {moreMenuOptions.map((option) => (
          <MenuItem key={option.labelKey} onClick={() => handleMenuOptionSelection(option)}>
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{t(option.labelKey)}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      {job ? (
        <>
          <ConfirmationDialog
            description={t("component.archiveJobDialog.confirmationPrompt", {
              jobNumber: job.number,
            })}
            id="archive-job-dialog"
            isLoading={archiveJobLoading}
            onCancel={() => setIsArchiveJobDialogOpen(false)}
            onConfirm={handleArchiveJob}
            open={isArchiveJobDialogOpen}
            title={t("component.archiveJobDialog.title", {
              jobNumber: job.number,
            })}
          />
          <ConfirmationDialog
            description={t("component.cloneJobDialog.confirmationPrompt", {
              jobNumber: job.number,
            })}
            id="clone-job-dialog"
            onCancel={() => setIsCloneJobDialogOpen(false)}
            onConfirm={() => {
              setIsCloneJobDialogOpen(false)
              handleCloneJob()
            }}
            open={isCloneJobDialogOpen}
            title={t("component.cloneJobDialog.title", {
              jobNumber: job.number,
            })}
          />
        </>
      ) : null}
    </Box>
  )
}

export default JobFormTabBar
