/* eslint-disable no-control-regex */
import React from "react"
import numeral from "numeral"
import {
  Attachment,
  Discount,
  Invoice,
  Estimate,
  Contact,
  User,
  AttachmentType,
  WorkOrder,
  DiscountType,
  Address,
  WorkOrderFormInput,
} from "../types"

function sortCompare(a: string, b: string): number {
  const nameA = a.toUpperCase() // ignore upper and lowercase
  const nameB = b.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  // names must be equal
  return 0
}

function capitalize(str: string): string {
  str = str || ""
  str = typeof str === "string" ? str : `${str}`
  return str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (l) => l.toUpperCase())
}

function asFloat(val: string | number): number {
  const valAsString = String(val).replaceAll(",", "")
  if (!isNumeric(valAsString)) {
    return 0
  } else {
    return Number.parseFloat(valAsString)
  }
}

function asInt(val: string | number): number {
  const valAsString = String(val).replaceAll(",", "")
  if (!isNumeric(valAsString)) {
    return 0
  } else {
    return Number.parseInt(valAsString, 10)
  }
}

const numberRegex = /^-?(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/
function isNumeric(val: any): boolean {
  return numberRegex.test(val)
}

/**
 * Takes an address as a single-line, comma-separated string
 * and returns a JSX representation for display
 */
function formatAddress(addressString: string | undefined | null): JSX.Element {
  if (!addressString) {
    return <div>{NOT_SPECIFIED}</div>
  }

  const parts = addressString.split(",").map((p) => p.trim())
  const streetAddr = parts.shift()
  const cityStateZip = parts.join(", ")
  return (
    <>
      <div>{streetAddr}</div>
      <div>{cityStateZip}</div>
    </>
  )
}

/**
 * Takes an Address object with the streetNumber, route, and locality
 * and combines those properties into a single-line string, if possible.
 * @param address - an Address with streetNumber, route, and locality
 */
function getAddressSummary(address: Address): string {
  let result = ""
  if (address.streetNumber) {
    result = address.streetNumber
  }
  if (address.route) {
    result = `${result} ${address.route}`
  }
  if (address.locality) {
    result = `${result}, ${address.locality}`
  }
  return result
}

function getFileExtension(filename: string): string {
  return filename.split(".").pop() || ""
}

function getTruncatedFilename(file: Partial<Attachment>, t: any, maxLength: number): string {
  const filename = getFilename(file, t)
  const extension = getFileExtension(file?.name || "")
  const truncatedFilename = truncate(filename, maxLength - extension.length - 1)
  if (truncatedFilename.length < filename.length) {
    return `${truncatedFilename}${extension}`
  } else {
    return filename
  }
}

function getFilename(file: Partial<Attachment>, t: any): string {
  if (!file?.name) {
    return ""
  }

  const filename = file?.name?.toLowerCase()
  if (file.contentType === "application/pdf") {
    if (filename.startsWith("helicalPileInstallationWorksheet")) {
      return `${t("helicalPileInstallationWorksheet.title")} PDF`
    } else if (
      file.type === AttachmentType.ESTIMATE ||
      filename.startsWith("quote") ||
      filename.startsWith("estimate")
    ) {
      const estimateNumber = filename.split("_")?.[1]
      return estimateNumber ? `${t("estimate")} ${estimateNumber}` : file.name
    } else if (file.type === AttachmentType.INVOICE || filename.startsWith("invoice")) {
      const invoiceNumber = filename.split("_")?.[1]
      return invoiceNumber ? `${t("invoice")} ${invoiceNumber}` : file.name
    } else {
      return file.name
    }
  }

  return file.name
}

function formatPersonName(person: Partial<User | Contact> | null | undefined): string {
  return `${(person?.firstName || "").trim()} ${(person?.lastName || "").trim()}`.trim()
}

/**
 * Format a discount value. The 'transaction' arg is an Estimate, Invoice, or
 * anything else with a similar "shape".
 */
function formatDiscount(transaction: Estimate | Invoice): string {
  if (!transaction) {
    return ""
  }

  if (transaction.discount?.type === DiscountType.PERCENTAGE) {
    return numeral(transaction.discount.value).format("0.[000]%")
  } else {
    return formatMoney(transaction.currencyCode, transaction.discount?.value ?? "")
  }
}

/**
 * Format the discount value for input, transforming a fraction percentage
 * to a whole number for PERCENTAGE
 */
function formatDiscountValueForInput(discountObj: Discount): string {
  return discountObj?.type === DiscountType.PERCENTAGE
    ? numeral((discountObj.value ?? 0) * 100).format("0.[000]")
    : `${discountObj.value ?? 0}`
}

function getCurrencySymbol(currencyCode: string): string {
  switch (currencyCode) {
    case "USD":
    case "CAD":
    case "MXN":
    case "AUD":
      return "$"
    case "BRL":
      return "R$"
    case "EUR":
      return "€"
    case "GBP":
      return "£"
    case "INR":
      return "₹"
    case "JPY":
      return "¥"
    default:
      return "$"
  }
}

function formatMoney(
  currencyCode = "USD",
  value: string | number,
  formatString = "0,0.00"
): string {
  return `${getCurrencySymbol(currencyCode)}${numeral(value).format(formatString)}`
}

function formatTaxRate(value: string | number): string {
  return numeral(value).format("0.[0000]%")
}

const emailRegex =
  /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
function isValidEmail(emailAddress: any): boolean {
  return emailRegex.test(emailAddress)
}

function isBlank(str: any): boolean {
  if ("undefined" === typeof str || str === null || "undefined" === typeof str.trim) {
    return true
  }
  return !(str.trim().length > 0)
}

function truncate(str: string, maxLength = 100, stopAtNewline = false): string {
  if (stopAtNewline) {
    const chunk = str?.split("\n")[0] || ""
    if (chunk.length > maxLength) {
      return `${chunk.substring(0, maxLength - 3)}...`
    } else {
      return chunk
    }
  } else if (str?.length > maxLength) {
    return `${str.substring(0, maxLength - 3)}...`
  } else {
    return str
  }
}

function getJobDocumentDisplayNumber(
  document: Estimate | WorkOrder | Invoice | WorkOrderFormInput
): string {
  return `${document.job?.number}-${document.number}`
}

function formatFileSize(sizeInBytes: number): string {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(1)} KB`
  } else {
    return `${(sizeInBytes / 1024 / 1024).toFixed(1)} MB`
  }
}

const NOT_SPECIFIED = "--"

export {
  NOT_SPECIFIED,
  asFloat,
  asInt,
  capitalize,
  formatAddress,
  formatDiscount,
  formatDiscountValueForInput,
  formatFileSize,
  formatPersonName,
  formatMoney,
  formatTaxRate,
  getAddressSummary,
  getCurrencySymbol,
  getFilename,
  getTruncatedFilename,
  getFileExtension,
  getJobDocumentDisplayNumber,
  isBlank,
  isNumeric,
  isValidEmail,
  sortCompare,
  truncate,
}
