import dayjs, { Dayjs } from "dayjs"
import drop from "lodash/drop"
import pullAt from "lodash/pullAt"
import clone from "lodash/clone"

import {
  INTERVAL_DURATION,
  DAY_MODE_INTERVAL_WIDTH,
  DAY_MODE_ROW_HEIGHT,
  WEEK_MODE_ROW_HEIGHT,
  WEEK_MODE_CELL_WIDTH,
  WEEK_MODE_RESOURCE_COLUMN_WIDTH,
  DAY_MODE_RESOURCE_COLUMN_WIDTH,
  DAY_MODE_HEADER_HEIGHT,
  WEEK_MODE_HEADER_HEIGHT,
} from "./Constants"
import type { JobAssignment, User } from "~/types/apiTypes"
import type { DispatchResource, JobAssignmentFrame, JobAssignmentUserBlock } from "~/types/appTypes"
import { TimeFrameOption } from "~/types"
import { createDayJS } from "~/util/dateUtils"

// Calculate the size and position of the JobAssignment view
function getAssignmentFrame(
  date: Dayjs,
  assignment: JobAssignment,
  assignee: User,
  resources: DispatchResource[],
  timeZone: string
): JobAssignmentFrame {
  const start = createDayJS(assignment.startDate, timeZone) ?? dayjs()
  const end = createDayJS(assignment.endDate, timeZone) ?? dayjs()
  const startHour = start.isBefore(date, "day") ? 0 : start.hour()
  const startMinute = start.isBefore(date, "day") ? 0 : start.minute()
  const totalMinutes = end.diff(start, "minute")
  let totalMinutesInSelectedDate = totalMinutes
  if (start.isBefore(date, "day") && end.isSame(date, "day")) {
    if (end.isSame(date, "day")) {
      totalMinutesInSelectedDate = end.hour() * 60 + end.minute()
    } else if (end.isAfter(date, "day")) {
      totalMinutesInSelectedDate = 24 * 60 // all day; 24 hours * 60 minutes per hour
    }
  } else if (end.isAfter(date.endOf("day"))) {
    totalMinutesInSelectedDate = (24 * 60) - (startHour * 60 + startMinute)
  }

  const rowNum = resources.find(r => r.id === assignee.id)?.position ?? 0
  const yPos = rowNum * DAY_MODE_ROW_HEIGHT + DAY_MODE_HEADER_HEIGHT
  const xPos = ((startHour * 60 + startMinute) / INTERVAL_DURATION) * DAY_MODE_INTERVAL_WIDTH + DAY_MODE_RESOURCE_COLUMN_WIDTH

  let width = (totalMinutesInSelectedDate / INTERVAL_DURATION) * DAY_MODE_INTERVAL_WIDTH
  const maxXPos = (1440 / INTERVAL_DURATION) * DAY_MODE_INTERVAL_WIDTH + DAY_MODE_RESOURCE_COLUMN_WIDTH

  if (xPos + width > maxXPos) {
    // if the start position plus the total width would exceed the right edge, then truncate the width to butt up against the right edge
    width = width - (xPos + width - maxXPos)
  }

  return {
    left: xPos,
    top: yPos,
    width,
    minWidth: width,
    maxWidth: width,
    height: DAY_MODE_ROW_HEIGHT - 2,
    minHeight: DAY_MODE_ROW_HEIGHT,
    maxHeight: DAY_MODE_ROW_HEIGHT,
  }
}

/**
 * For each day of the week in which the given selectedDate falls, create a mapping
 * of the day-of-week, using a 0-based integer index for each day of a 7 day week,
 * to the list of assignments that take place in that day. 
 * Assignments may span multiple days. In that case, an assignment may be associated 
 * with multiple consecutive entries in the returned data structure.
 */
function groupAssignmentsByDayOfWeek(selectedDate: Dayjs, assignments: JobAssignment[], timeZone: string): Array<Array<JobAssignment>> {
  const result = [] as Array<Array<JobAssignment>>
  const startOfWeek = selectedDate.startOf("week")
  for (let i = 0; i < 7; i++) {
    const day = startOfWeek.add(i, "day")
    const dayAssignments = assignments.filter((a) => {
      const start = dayjs(a.startDate).tz(timeZone)
      const end = dayjs(a.endDate).tz(timeZone)
      return isDateInRange(day, start.startOf('day'), end.endOf('day'))
    }).map((a) => {
      let intraDayStart = dayjs(a.startDate).tz(timeZone)
      if (intraDayStart.isBefore(day)) {
        intraDayStart = day.startOf('day')
      }

      let intraDayEnd = dayjs(a.endDate).tz(timeZone)
      if (intraDayEnd.isAfter(day.endOf('day'))) {
        intraDayEnd = day.endOf('day')
      }

      return {
        ...a,
        clientKey: `${a.id}-${i}`,
        intraDayStart: intraDayStart.toISOString(),
        intraDayEnd: intraDayEnd.toISOString(),
      }
    })
    result[i] = dayAssignments
  }

  return result
}

function isDateInRange(date: Dayjs, start: Dayjs, end: Dayjs): boolean {
  return date.isBetween(start, end, "minute", "[]") // "[]" makes it inclusive of start & end
};

//Group assignments that have any overlapping time-of-day, for use in Week view.
function groupAssignments(selectedDate: Dayjs, assignments: JobAssignment[], timeZone: string): Array<Array<{ entries: JobAssignment[], start: Dayjs, end: Dayjs }>> {
  if (!assignments || assignments.length === 0) {
    return []
  }

  const assignmentsByDayOfWeek = groupAssignmentsByDayOfWeek(selectedDate, assignments, timeZone)

  const result = [] as Array<Array<{ entries: JobAssignment[], start: Dayjs, end: Dayjs }>>

  for (let i = 0; i < 7; i++) {
    const assignments = assignmentsByDayOfWeek[i]
    result[i] = []

    const assignmentGroups = []
    let remaining = clone(assignments)

    while (remaining.length > 0) {
      const group = {
        entries: [remaining[0]],
        start: createDayJS(remaining[0].intraDayStart, timeZone) ?? dayjs(),
        end: createDayJS(remaining[0].intraDayEnd, timeZone) ?? dayjs(),
      }
      assignmentGroups.push(group)
      remaining = drop(remaining) //remove the first element from the array

      const removedIndices = [] as number[]
      remaining.forEach((a, idx) => {
        const start = createDayJS(a.intraDayStart, timeZone) ?? dayjs()
        if (start.isBetween(group.start, group.end, null, "[)")) {
          group.entries.push(a)
          const end = createDayJS(a.intraDayEnd, timeZone) ?? dayjs()
          if (end.isAfter(group.end)) {
            group.end = createDayJS(a.intraDayEnd, timeZone) ?? dayjs()
          }
          removedIndices.push(idx)
        }
      })
      pullAt(remaining, removedIndices)
    }
    result[i] = assignmentGroups
  }

  return result
}

export function calculateAssignmentFrames(
  date: Dayjs,
  assignments: JobAssignment[],
  resources: DispatchResource[],
  timeFrame: TimeFrameOption,
  timeZone: string
): JobAssignmentUserBlock[] {
  if (timeFrame === TimeFrameOption.WEEK) {
    const blocks = [] as JobAssignmentUserBlock[]

    //Group assignments that have any overlapping time-of-day.
    const assignmentGroups = groupAssignments(date, assignments, timeZone)

    // Then figure out the xPos & height of each assignment in each group. The width should be 1/Nth the width.
    for (let i = 0; i < assignmentGroups.length; i++) {
      const dailyGroupings = assignmentGroups[i]
      dailyGroupings.forEach((g) => {
        g.entries.forEach((assignment, index) => {
          const start = createDayJS(assignment.intraDayStart, timeZone) ?? dayjs()
          const startHour = parseInt(start.format("H"), 10)
          const startMinute = parseInt(start.format("mm"), 10)
          const end = createDayJS(assignment.intraDayEnd, timeZone) ?? dayjs()
          const isMultiDay = start.isBefore(end, "day")
          const endHour = isMultiDay ? 24 : parseInt(end.format("H"), 10)
          const endMinute = isMultiDay ? 0 : parseInt(end.format("mm"), 10)
          const totalMinutes = (endHour - startHour) * 60 + (endMinute - startMinute)
          const pixelHeightPerMinute = WEEK_MODE_ROW_HEIGHT / 60
          const rowNum = startHour
          const yPos =
            rowNum * WEEK_MODE_ROW_HEIGHT +
            startMinute * pixelHeightPerMinute +
            WEEK_MODE_HEADER_HEIGHT
          const xPos = start.day() * WEEK_MODE_CELL_WIDTH + WEEK_MODE_RESOURCE_COLUMN_WIDTH
          const itemWidth = WEEK_MODE_CELL_WIDTH / g.entries.length
          const height = totalMinutes * pixelHeightPerMinute
          const left = xPos + index * itemWidth
          blocks.push({
            key: `assignment:${assignment.clientKey ?? assignment.id}`,
            assignment,
            assignee: assignment.assignees[0],
            frame: {
              left,
              originalLeft: left,
              top: yPos,
              width: itemWidth,
              minWidth: itemWidth,
              maxWidth: itemWidth,
              height,
              minHeight: WEEK_MODE_ROW_HEIGHT,
              maxHeight: WEEK_MODE_ROW_HEIGHT,
            },
          })
        })
      })
    }
    return blocks
  } else if (timeFrame === TimeFrameOption.DAY) {
    const assignmentsPerAssignee = assignments.flatMap((assignment) => {
      return assignment.assignees.map((assignee) => ({
        ...assignment,
        assignee,
      }))
    })

    const blocks = assignmentsPerAssignee.map((userAssignment) => ({
      assignment: userAssignment,
      assignee: userAssignment.assignee,
      frame: getAssignmentFrame(
        date,
        userAssignment,
        userAssignment.assignee,
        resources,
        timeZone
      ),
      key: `assignment:${userAssignment.id}-user:${userAssignment.assignee.id}`,
    }))

    return blocks
  } else {
    return []
  }
}
