import { useEffect, RefObject } from "react"

export const usePreventBackNavigationOnScroll = (ref: RefObject<HTMLElement>) => {
    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            const container = ref.current
            if (!container) return

            const atLeftEdge = container.scrollLeft === 0
            if (atLeftEdge && event.deltaX < 0) {
                event.preventDefault()
            }
        }

        document.addEventListener("wheel", handleWheel, { passive: false })

        return () => {
            document.removeEventListener("wheel", handleWheel)
        }
    }, [ref])
}
