import { gql } from "@apollo/client"

export const ALL_TEMPLATE_FIELDS = gql`
    query AllTemplateFields {
        allTemplateFields {
            id
            key
            format
        }
    }
`