/* eslint-disable react/jsx-no-literals */
import React from "react"
import { useTranslation } from "react-i18next"

function MobileHomeReferenceTable() {
  const { t } = useTranslation()

  return (
    <table css={classes.refTable}>
      <thead>
        <tr>
          <th colSpan="1" rowSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.homeWidth")}
          </th>
          <th colSpan="1" rowSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.distanceBetweenPiles")}
          </th>
          <th colSpan="6" width="30%">
            {t("helicalPileInstallationWorksheet.allowableCompressionLoadInPounds")}
          </th>
          <th colSpan="12" width="60%">
            {t("helicalPileInstallationWorksheet.torquesFootPounds")}
          </th>
        </tr>
        <tr>
          <th colSpan="6">{t("helicalPileInstallationWorksheet.snowLoad")}</th>
          <th colSpan="6">{t("helicalPileInstallationWorksheet.pileDiameter238")}</th>
          <th colSpan="6">{t("helicalPileInstallationWorksheet.pileDiameter278")}</th>
        </tr>
        <tr>
          <th>ft</th>
          <th>ft</th>
          <th>0 psf</th>
          <th>20 psf</th>
          <th>30 psf</th>
          <th>40 psf</th>
          <th>50 psf</th>
          <th>60 psf</th>
          <th>0 psf</th>
          <th>20 psf</th>
          <th>30 psf</th>
          <th>40 psf</th>
          <th>50 psf</th>
          <th>60 psf</th>
          <th>0 psf</th>
          <th>20 psf</th>
          <th>30 psf</th>
          <th>40 psf</th>
          <th>50 psf</th>
          <th>60 psf</th>
        </tr>
      </thead>
      <tbody>
        <tr css="first-in-section">
          <td rowSpan="7">10</td>
          <td>4</td>
          <td>1700</td>
          <td>1910</td>
          <td>2015</td>
          <td>2120</td>
          <td>2225</td>
          <td>2330</td>
          <td>350</td>
          <td>400</td>
          <td>450</td>
          <td>450</td>
          <td>450</td>
          <td>500</td>
          <td>400</td>
          <td>450</td>
          <td>450</td>
          <td>450</td>
          <td>500</td>
          <td>550</td>
        </tr>
        <tr>
          <td>5</td>
          <td>2125</td>
          <td>2388</td>
          <td>2519</td>
          <td>2650</td>
          <td>2781</td>
          <td>2913</td>
          <td>450</td>
          <td>500</td>
          <td>550</td>
          <td>550</td>
          <td>600</td>
          <td>600</td>
          <td>500</td>
          <td>550</td>
          <td>600</td>
          <td>600</td>
          <td>650</td>
          <td>650</td>
        </tr>
        <tr>
          <td>6</td>
          <td>2550</td>
          <td>2865</td>
          <td>3023</td>
          <td>3180</td>
          <td>3338</td>
          <td>3495</td>
          <td>550</td>
          <td>600</td>
          <td>650</td>
          <td>650</td>
          <td>700</td>
          <td>700</td>
          <td>600</td>
          <td>650</td>
          <td>700</td>
          <td>750</td>
          <td>750</td>
          <td>800</td>
        </tr>
        <tr>
          <td>7</td>
          <td>2975</td>
          <td>3343</td>
          <td>3526</td>
          <td>3710</td>
          <td>3894</td>
          <td>4078</td>
          <td>600</td>
          <td>700</td>
          <td>750</td>
          <td>750</td>
          <td>800</td>
          <td>850</td>
          <td>700</td>
          <td>750</td>
          <td>800</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
        </tr>
        <tr>
          <td>8</td>
          <td>3400</td>
          <td>3820</td>
          <td>4030</td>
          <td>4240</td>
          <td>4450</td>
          <td>4660</td>
          <td>700</td>
          <td>800</td>
          <td>850</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
          <td>800</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
        </tr>
        <tr>
          <td>9</td>
          <td>3825</td>
          <td>4298</td>
          <td>4534</td>
          <td>4770</td>
          <td>5006</td>
          <td>5243</td>
          <td>800</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
          <td>1050</td>
          <td>850</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
        </tr>
        <tr>
          <td>10</td>
          <td>4250</td>
          <td>4775</td>
          <td>5038</td>
          <td>5300</td>
          <td>5563</td>
          <td>5825</td>
          <td>850</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
          <td>950</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
          <td>1250</td>
          <td>1300</td>
        </tr>
        <tr css="first-in-section">
          <td rowSpan="7">12</td>
          <td>4</td>
          <td>2040</td>
          <td>2292</td>
          <td>2418</td>
          <td>2544</td>
          <td>2670</td>
          <td>2796</td>
          <td>450</td>
          <td>500</td>
          <td>500</td>
          <td>550</td>
          <td>550</td>
          <td>600</td>
          <td>500</td>
          <td>550</td>
          <td>550</td>
          <td>600</td>
          <td>600</td>
          <td>650</td>
        </tr>
        <tr>
          <td>5</td>
          <td>2550</td>
          <td>2865</td>
          <td>3023</td>
          <td>3180</td>
          <td>3338</td>
          <td>3495</td>
          <td>550</td>
          <td>600</td>
          <td>650</td>
          <td>650</td>
          <td>700</td>
          <td>700</td>
          <td>600</td>
          <td>650</td>
          <td>700</td>
          <td>750</td>
          <td>750</td>
          <td>800</td>
        </tr>
        <tr>
          <td>6</td>
          <td>3060</td>
          <td>3438</td>
          <td>3627</td>
          <td>3816</td>
          <td>4005</td>
          <td>4194</td>
          <td>650</td>
          <td>700</td>
          <td>750</td>
          <td>800</td>
          <td>850</td>
          <td>850</td>
          <td>700</td>
          <td>800</td>
          <td>850</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
        </tr>
        <tr>
          <td>7</td>
          <td>3570</td>
          <td>4011</td>
          <td>4232</td>
          <td>4452</td>
          <td>4673</td>
          <td>4893</td>
          <td>750</td>
          <td>850</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>800</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
        </tr>
        <tr>
          <td>8</td>
          <td>4080</td>
          <td>4584</td>
          <td>4836</td>
          <td>5088</td>
          <td>5340</td>
          <td>5592</td>
          <td>850</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>950</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
          <td>1250</td>
        </tr>
        <tr>
          <td>9</td>
          <td>4590</td>
          <td>5157</td>
          <td>5441</td>
          <td>5724</td>
          <td>6008</td>
          <td>6291</td>
          <td>950</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>1250</td>
          <td>1300</td>
          <td>1050</td>
          <td>1150</td>
          <td>1250</td>
          <td>1300</td>
          <td>1350</td>
          <td>1400</td>
        </tr>
        <tr>
          <td>10</td>
          <td>5100</td>
          <td>5730</td>
          <td>6045</td>
          <td>6360</td>
          <td>6675</td>
          <td>6990</td>
          <td>1050</td>
          <td>1150</td>
          <td>1250</td>
          <td>1300</td>
          <td>1350</td>
          <td>1400</td>
          <td>1150</td>
          <td>1300</td>
          <td>1350</td>
          <td>1450</td>
          <td>1500</td>
          <td>1600</td>
        </tr>
        <tr css="first-in-section">
          <td rowSpan="7">14</td>
          <td>4</td>
          <td>2380</td>
          <td>2674</td>
          <td>2821</td>
          <td>2968</td>
          <td>3115</td>
          <td>3262</td>
          <td>500</td>
          <td>550</td>
          <td>600</td>
          <td>600</td>
          <td>650</td>
          <td>700</td>
          <td>550</td>
          <td>600</td>
          <td>650</td>
          <td>700</td>
          <td>700</td>
          <td>750</td>
        </tr>
        <tr>
          <td>5</td>
          <td>2975</td>
          <td>3343</td>
          <td>3526</td>
          <td>3710</td>
          <td>3894</td>
          <td>4078</td>
          <td>600</td>
          <td>700</td>
          <td>750</td>
          <td>750</td>
          <td>800</td>
          <td>850</td>
          <td>700</td>
          <td>750</td>
          <td>800</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
        </tr>
        <tr>
          <td>6</td>
          <td>3570</td>
          <td>4011</td>
          <td>4232</td>
          <td>4452</td>
          <td>4673</td>
          <td>4893</td>
          <td>750</td>
          <td>850</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>800</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
        </tr>
        <tr>
          <td>7</td>
          <td>4165</td>
          <td>4680</td>
          <td>4937</td>
          <td>5194</td>
          <td>5451</td>
          <td>5709</td>
          <td>850</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>950</td>
          <td>1050</td>
          <td>1100</td>
          <td>1200</td>
          <td>1250</td>
          <td>1300</td>
        </tr>
        <tr>
          <td>8</td>
          <td>4760</td>
          <td>5348</td>
          <td>5642</td>
          <td>5936</td>
          <td>6230</td>
          <td>6524</td>
          <td>1000</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
          <td>1250</td>
          <td>1350</td>
          <td>1100</td>
          <td>1200</td>
          <td>1300</td>
          <td>1350</td>
          <td>1400</td>
          <td>1450</td>
        </tr>
        <tr>
          <td>9</td>
          <td>5355</td>
          <td>6017</td>
          <td>6347</td>
          <td>6678</td>
          <td>7009</td>
          <td>7340</td>
          <td>1100</td>
          <td>1250</td>
          <td>1300</td>
          <td>1350</td>
          <td>1450</td>
          <td>1500</td>
          <td>1200</td>
          <td>1350</td>
          <td>1450</td>
          <td>1500</td>
          <td>1600</td>
          <td>1650</td>
        </tr>
        <tr>
          <td>10</td>
          <td>5950</td>
          <td>6685</td>
          <td>7053</td>
          <td>7420</td>
          <td>7788</td>
          <td>8155</td>
          <td>1200</td>
          <td>1350</td>
          <td>1450</td>
          <td>1500</td>
          <td>1600</td>
          <td>1650</td>
          <td>1350</td>
          <td>1500</td>
          <td>1600</td>
          <td>1650</td>
          <td>1750</td>
          <td>1850</td>
        </tr>
        <tr css="first-in-section">
          <td rowSpan="7">16</td>
          <td>4</td>
          <td>2720</td>
          <td>3056</td>
          <td>3224</td>
          <td>3392</td>
          <td>3560</td>
          <td>3728</td>
          <td>550</td>
          <td>650</td>
          <td>650</td>
          <td>700</td>
          <td>750</td>
          <td>750</td>
          <td>650</td>
          <td>700</td>
          <td>750</td>
          <td>800</td>
          <td>800</td>
          <td>850</td>
        </tr>
        <tr>
          <td>5</td>
          <td>3400</td>
          <td>3820</td>
          <td>4030</td>
          <td>4240</td>
          <td>4450</td>
          <td>4660</td>
          <td>700</td>
          <td>800</td>
          <td>850</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
          <td>800</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
        </tr>
        <tr>
          <td>6</td>
          <td>4080</td>
          <td>4584</td>
          <td>4836</td>
          <td>5088</td>
          <td>5340</td>
          <td>5592</td>
          <td>850</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>950</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
          <td>1250</td>
        </tr>
        <tr>
          <td>7</td>
          <td>4760</td>
          <td>5348</td>
          <td>5642</td>
          <td>5936</td>
          <td>6230</td>
          <td>6524</td>
          <td>1000</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
          <td>1250</td>
          <td>1350</td>
          <td>1100</td>
          <td>1200</td>
          <td>1300</td>
          <td>1350</td>
          <td>1400</td>
          <td>1450</td>
        </tr>
        <tr>
          <td>8</td>
          <td>5440</td>
          <td>6112</td>
          <td>6448</td>
          <td>6784</td>
          <td>7120</td>
          <td>7456</td>
          <td>1100</td>
          <td>1250</td>
          <td>1300</td>
          <td>1400</td>
          <td>1450</td>
          <td>1500</td>
          <td>1250</td>
          <td>1400</td>
          <td>1450</td>
          <td>1550</td>
          <td>1600</td>
          <td>1700</td>
        </tr>
        <tr>
          <td>9</td>
          <td>6120</td>
          <td>6876</td>
          <td>7254</td>
          <td>7632</td>
          <td>8010</td>
          <td>8388</td>
          <td>1250</td>
          <td>1400</td>
          <td>1500</td>
          <td>1550</td>
          <td>1650</td>
          <td>1700</td>
          <td>1400</td>
          <td>1550</td>
          <td>1650</td>
          <td>1700</td>
          <td>1800</td>
          <td>1900</td>
        </tr>
        <tr>
          <td>10</td>
          <td>6800</td>
          <td>7640</td>
          <td>8060</td>
          <td>8480</td>
          <td>8900</td>
          <td>9320</td>
          <td>1400</td>
          <td>1550</td>
          <td>1650</td>
          <td>1700</td>
          <td>1800</td>
          <td>1900</td>
          <td>1550</td>
          <td>1700</td>
          <td>1800</td>
          <td>1900</td>
          <td>2000</td>
          <td>2100</td>
        </tr>
        <tr css="first-in-section">
          <td rowSpan="7">18</td>
          <td>4</td>
          <td>3060</td>
          <td>3438</td>
          <td>3627</td>
          <td>3816</td>
          <td>4005</td>
          <td>4194</td>
          <td>650</td>
          <td>700</td>
          <td>750</td>
          <td>800</td>
          <td>850</td>
          <td>850</td>
          <td>700</td>
          <td>800</td>
          <td>850</td>
          <td>850</td>
          <td>900</td>
          <td>950</td>
        </tr>
        <tr>
          <td>5</td>
          <td>3825</td>
          <td>4298</td>
          <td>4534</td>
          <td>4770</td>
          <td>5006</td>
          <td>5243</td>
          <td>800</td>
          <td>900</td>
          <td>950</td>
          <td>1000</td>
          <td>1050</td>
          <td>1050</td>
          <td>850</td>
          <td>1000</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>1200</td>
        </tr>
        <tr>
          <td>6</td>
          <td>4590</td>
          <td>5157</td>
          <td>5441</td>
          <td>5724</td>
          <td>6008</td>
          <td>6291</td>
          <td>950</td>
          <td>1050</td>
          <td>1100</td>
          <td>1150</td>
          <td>1250</td>
          <td>1300</td>
          <td>1050</td>
          <td>1150</td>
          <td>1250</td>
          <td>1300</td>
          <td>1350</td>
          <td>1400</td>
        </tr>
        <tr>
          <td>7</td>
          <td>5355</td>
          <td>6017</td>
          <td>6347</td>
          <td>6678</td>
          <td>7009</td>
          <td>7340</td>
          <td>1100</td>
          <td>1250</td>
          <td>1300</td>
          <td>1350</td>
          <td>1450</td>
          <td>1500</td>
          <td>1200</td>
          <td>1350</td>
          <td>1450</td>
          <td>1500</td>
          <td>1600</td>
          <td>1650</td>
        </tr>
        <tr>
          <td>8</td>
          <td>6120</td>
          <td>6876</td>
          <td>7254</td>
          <td>7632</td>
          <td>8010</td>
          <td>8388</td>
          <td>1250</td>
          <td>1400</td>
          <td>1500</td>
          <td>1550</td>
          <td>1650</td>
          <td>1700</td>
          <td>1400</td>
          <td>1550</td>
          <td>1650</td>
          <td>1700</td>
          <td>1800</td>
          <td>1900</td>
        </tr>
        <tr>
          <td>9</td>
          <td>6885</td>
          <td>7736</td>
          <td>8161</td>
          <td>8586</td>
          <td>9011</td>
          <td>9437</td>
          <td>1400</td>
          <td>1550</td>
          <td>1650</td>
          <td>1750</td>
          <td>1850</td>
          <td>1900</td>
          <td>1550</td>
          <td>1750</td>
          <td>1850</td>
          <td>1950</td>
          <td>2050</td>
          <td>2100</td>
        </tr>
        <tr>
          <td>10</td>
          <td>7650</td>
          <td>8595</td>
          <td>9068</td>
          <td>9540</td>
          <td>10013</td>
          <td>10485</td>
          <td>1550</td>
          <td>1750</td>
          <td>1850</td>
          <td>1950</td>
          <td>2050</td>
          <td>2100</td>
          <td>1700</td>
          <td>1950</td>
          <td>2050</td>
          <td>2150</td>
          <td>2250</td>
          <td>2350</td>
        </tr>
      </tbody>
    </table>
  )
}

const classes = {
  refTable: {
    backgroundColor: "rgb(255, 255, 255)",
    fontSize: "0.75rem",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      padding: "4px",
      border: "1px solid rgb(0, 0, 0)",
      textAlign: "center",
      verticalAlign: "text-top",
      fontWeight: 600,
    },

    "& td": {
      padding: "0",
      fontSize: "0.8125rem",
      border: "1px solid rgb(0, 0, 0)",
      textAlign: "center",
    },
    "& thead > tr:last-of-type > th:nth-of-type(2n+4), & tbody > tr.first-in-section > td:nth-of-type(2n+4), & tbody > :not(tr.first-in-section) > td:nth-of-type(2n+3)":
      {
        backgroundColor: "rgb(255, 242, 204)",
      },
  },
}

export default React.memo(MobileHomeReferenceTable)
