import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"

import StaticField from "~/components/StaticField"
import { formatDate, formatMoney, NOT_SPECIFIED } from "~/util"
import { OrganizationItem } from "~/types"
import HelperTextContainer from "./HelperTextContainer"

interface Props {
  readonly currencyCode: string
  readonly item: OrganizationItem
  readonly showBottomDivider?: boolean
}

function TopLevelProductDetailsStatic({ currencyCode, item, showBottomDivider }: Props) {
  const { t } = useTranslation()
  return (
    <>
      {item.isTopLevel ? (
        <>
          <StaticField
            label={t("isIntendedForResale")}
            showBottomDivider={showBottomDivider}
            value={item.isIntendedForResale ? t("yes") : t("no")}
          />
          <StaticField
            label={t("stockType")}
            showBottomDivider={showBottomDivider}
            value={t(`stockTypeOptions.${item.stockType}`)}
          />
          <StaticField
            label={t("leadTimeDays")}
            showBottomDivider={showBottomDivider}
            value={item.leadTimeDays ?? NOT_SPECIFIED}
          />
        </>
      ) : null}
      <StaticField
        label={t("palletQty")}
        showBottomDivider={showBottomDivider}
        value={item.palletQty ?? NOT_SPECIFIED}
      />
      <StaticField
        label={t("packSize")}
        showBottomDivider={showBottomDivider}
        value={
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "baseline" }}
          >
            <Box>{item.packSize ?? NOT_SPECIFIED}</Box>
            {Number(item.packSize) > 1 && !item.isPackBreakable && item.isTopLevel ? (
              <Box sx={{ fontSize: "0.75rem", color: (theme) => theme.fielderColors.mutedText }}>
                ({t("notBreakable")})
              </Box>
            ) : null}
          </Box>
        }
      />
      <StaticField
        label={t("msrp")}
        showBottomDivider={showBottomDivider}
        value={item.msrp ? formatMoney(currencyCode, item.msrp) : NOT_SPECIFIED}
      />
      <StaticField
        label={t("purchaseCost")}
        renderHelpText={() => {
          return item.wholesalePriceExpirationDate ? (
            <HelperTextContainer>
              <>
                <Box>{t("component.itemBasicInfoForm.helperText.purchaseCost")}</Box>
                <Box sx={{ fontStyle: "italic" }}>
                  {t("component.itemBasicInfoForm.purchaseCostExpiresOn", {
                    expirationDate: formatDate(
                      item.wholesalePriceExpirationDate,
                      t("format:dateFormat.short"),
                      "Etc/UTC"
                    ),
                  })}
                </Box>
              </>
            </HelperTextContainer>
          ) : null
        }}
        showBottomDivider={showBottomDivider}
        value={item.wholesalePrice ? formatMoney(currencyCode, item.wholesalePrice) : NOT_SPECIFIED}
      />
      {item.isTopLevel ? (
        <StaticField
          label={t("averagePurchaseCost")}
          renderHelpText={() => {
            return (
              <HelperTextContainer>
                <>{t(`component.itemBasicInfoForm.helperText.averagePurchaseCost`)}</>
              </HelperTextContainer>
            )
          }}
          showBottomDivider={showBottomDivider}
          value={
            item.averagePurchaseCost
              ? formatMoney(currencyCode, item.averagePurchaseCost)
              : NOT_SPECIFIED
          }
        />
      ) : null}
    </>
  )
}

export default TopLevelProductDetailsStatic
