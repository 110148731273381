import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Popover from "@mui/material/Popover"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import MainLayout from "../../../components/MainLayout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import SnackbarMessage from "../../../components/SnackbarMessage"
import { DELETE_ORGANIZATION_PLUGIN } from "../../../queries/deleteOrganizationPlugin"
import { EDIT_ORGANIZATION_PLUGIN } from "../../../queries/editOrganizationPlugin"
import { parseGraphQLErrorCode, PLUGINS, SETTINGS } from "../../../util"
import { useAuth } from "../../../context/AuthContext"
import SaveButton from "../../../components/SaveButton"
import { CoaAccount, CoaAccountType, OrganizationPlugin } from "../../../types"
import { ConfirmationDialog, SelectorField } from "../../../components"
import MenuItem from "@mui/material/MenuItem"
import Spinner from "../../../components/Spinner"

const GET_PLUGIN_CONFIG_INFO = gql`
  query GetPluginConfigInfo($id: ID!) {
    getOrganizationById(id: $id) {
      id
      name
      plugins {
        id
        supportsInventoryTracking
        pluginProvider {
          id
          name
          category
        }
        defaultRevenueAccount {
          id
          name
          code
          type
        }
        defaultAssetAccount {
          id
          name
          code
          type
        }
        defaultExpenseAccount {
          id
          name
          code
          type
        }
        chartOfAccounts {
          id
          name
          code
          type
        }
      }
    }
  }
`

function AccountOption({ option }: { readonly option: CoaAccount }) {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>{option.name}</Box>
      <Box sx={{ fontSize: "0.75rem", fontStyle: "italic" }}>
        {t("page.configurePlugin.accountCode")}: {option.code}
      </Box>
    </Box>
  )
}

function AccountValue({ account }: { readonly account?: CoaAccount }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>{account?.name ?? ""}</Box>
    </Box>
  )
}

function ConfigurePlugin() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const [showDisconnectConfirmationDialog, setShowDisconnectConfirmationDialog] =
    useState<boolean>(false)
  const [pluginId, setPluginId] = useState(null)
  const { user } = useAuth()
  const [expenseAccountOptions, setExpenseAccountOptions] = useState<CoaAccount[]>([])
  const [revenueAccountOptions, setRevenueAccountOptions] = useState<CoaAccount[]>([])
  const [assetAccountOptions, setAssetAccountOptions] = useState<CoaAccount[]>([])
  const [defaultExpenseAccount, setDefaultExpenseAccount] = useState<CoaAccount>()
  const [defaultRevenueAccount, setDefaultRevenueAccount] = useState<CoaAccount>()
  const [defaultAssetAccount, setDefaultAssetAccount] = useState<CoaAccount>()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [snack, setSnack] = useState<Snack>()
  const userOrgId = user?.organization?.id

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const { loading: orgPluginConfigDataLoading, error: orgPluginConfigDataError } = useQuery(
    GET_PLUGIN_CONFIG_INFO,
    {
      variables: {
        id: userOrgId,
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const orgPluginData = data?.getOrganizationById?.plugins?.find(
          (plugin: OrganizationPlugin) =>
            plugin.pluginProvider.id.toLowerCase() === id?.toLowerCase()
        )

        if (orgPluginData) {
          setPluginId(orgPluginData.id)
          if (orgPluginData.defaultRevenueAccount) {
            setDefaultRevenueAccount(orgPluginData.defaultRevenueAccount)
          }
          if (orgPluginData.defaultAssetAccount) {
            setDefaultAssetAccount(orgPluginData.defaultAssetAccount)
          }
          if (orgPluginData.defaultExpenseAccount) {
            setDefaultExpenseAccount(orgPluginData.defaultExpenseAccount)
          }

          const assetAccounts = orgPluginData.chartOfAccounts
            ?.filter((a: CoaAccount) => a.type === CoaAccountType.ASSET)
            ?.sort((a, b) => a.name.localeCompare(b.name))
          setAssetAccountOptions(assetAccounts)

          const expenseAccounts = orgPluginData.chartOfAccounts
            ?.filter((a: CoaAccount) => a.type === CoaAccountType.EXPENSE)
            ?.sort((a, b) => a.name.localeCompare(b.name))
          setExpenseAccountOptions(expenseAccounts)

          const revenueAccounts = orgPluginData.chartOfAccounts
            ?.filter((a: CoaAccount) => a.type === CoaAccountType.REVENUE)
            ?.sort((a, b) => a.name.localeCompare(b.name))
          setRevenueAccountOptions(revenueAccounts)
        }
      },
    }
  )

  const pluginData = PLUGINS.find((p) => p.id.toLowerCase() === id?.toLowerCase())

  const [editOrganizationPlugin, { loading: editLoading }] = useMutation(EDIT_ORGANIZATION_PLUGIN, {
    onCompleted: () => {
      navigate("/app/settings/plugins", {
        state: {
          snack: {
            messageKey: `messages.changesSaved`,
            variant: "success",
          },
        },
      })
    },
    onError: (error) => {
      let errorCode = parseGraphQLErrorCode(error)

      if (errorCode.includes("organization-plugin.accounting-plugin")) {
        errorCode = `${errorCode}-configure-screen`
      } else {
        Sentry.captureException(error)
      }

      setSnack({
        messageKey: errorCode,
        variant: "error",
        messageOptions: { pluginProvider: pluginData?.name },
      })
    },
  })

  const [deleteOrganizationPlugin, { loading: deleteLoading }] = useMutation(
    DELETE_ORGANIZATION_PLUGIN,
    {
      onCompleted: () => {
        navigate("/app/settings/plugins", {
          state: {
            snack: {
              messageKey: `page.configurePlugin.disconnectSuccessMessage`,
              messageOptions: { pluginProvider: pluginData?.name },
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const loading = editLoading || deleteLoading

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <ConfirmationDialog
        description={t("page.configurePlugin.disconnectConfirmationPrompt", {
          pluginProvider: pluginData?.name,
        })}
        onCancel={() => setShowDisconnectConfirmationDialog(false)}
        onConfirm={() => {
          setShowDisconnectConfirmationDialog(false)
          const variables = {
            id: pluginId,
          }
          deleteOrganizationPlugin({ variables })
        }}
        open={showDisconnectConfirmationDialog}
      />
      <Seo title={t("sectionTitle.settings")} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/plugins", titleKey: t("plugins") },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={pluginData?.name}
          />
          <Paper
            data-testid="contentRoot"
            sx={{
              maxWidth: "600px",
              padding: "2.5rem",
              display: "flex",
              flexDirection: "column",
              color: "#747474",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "1.25rem",
              }}
            >
              <img
                aria-label={`Logo for ${pluginData?.name}`}
                css={{
                  width: "5rem",
                  height: "5rem",
                }}
                src={pluginData?.logoPath}
              />
              <Box
                sx={{
                  color: "#747474",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  marginLeft: "1.25rem",
                }}
              >
                {pluginData?.name}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  marginTop: "1.25rem",
                  marginBottom: "1.75rem",
                }}
              >
                <Box
                  sx={{
                    color: "#212121",
                    fontWeight: "bold",
                  }}
                >
                  {t("page.configurePlugin.title")}
                </Box>
                <Box
                  sx={{
                    color: "#212121",
                  }}
                >
                  {t("page.configurePlugin.instructions", { pluginProvider: pluginData?.name })}
                </Box>
              </Box>
              {orgPluginConfigDataLoading ? (
                <Spinner />
              ) : !orgPluginConfigDataError && id ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #cdcdcd",
                    borderRadius: "4px",
                    padding: "0.5rem 1.25rem",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "1.25rem",
                    }}
                  >
                    <Box sx={classes.textFieldContainer}>
                      <SelectorField
                        label={t("page.configurePlugin.defaultExpenseAccount.label") as string}
                        onChange={setDefaultExpenseAccount}
                        options={expenseAccountOptions}
                        renderOption={(option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              <AccountOption option={option as CoaAccount} />
                            </MenuItem>
                          )
                        }}
                        renderValue={(value) => {
                          const account = expenseAccountOptions.find((a) => a.id == value)
                          return <AccountValue account={account} />
                        }}
                        value={defaultExpenseAccount?.id ?? ""}
                      />
                      <Box sx={classes.optionHelpContainer}>
                        <HelpOutlineIcon
                          aria-haspopup="true"
                          aria-owns={
                            anchorEl?.id === "defaultExpenseAccount-HelpIcon"
                              ? "defaultExpenseAccount-popover"
                              : undefined
                          }
                          fontSize="small"
                          id="defaultExpenseAccount-HelpIcon"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        />
                        <Popover
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          disableRestoreFocus
                          id="defaultExpenseAccount-popover"
                          onClose={handlePopoverClose}
                          open={Boolean(anchorEl?.id === "defaultExpenseAccount-HelpIcon")}
                          sx={classes.popover}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box sx={classes.popoverContent}>
                            {t(
                              `page.configurePlugin.defaultExpenseAccount.tooltip${id.toUpperCase()}`
                            )}
                          </Box>
                        </Popover>
                      </Box>
                    </Box>
                    <Box sx={classes.textFieldContainer}>
                      <SelectorField
                        label={t("page.configurePlugin.defaultRevenueAccount.label") as string}
                        onChange={setDefaultRevenueAccount}
                        options={revenueAccountOptions}
                        renderOption={(option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              <AccountOption option={option as CoaAccount} />
                            </MenuItem>
                          )
                        }}
                        renderValue={(value) => {
                          const account = revenueAccountOptions.find((a) => a.id == value)
                          return <AccountValue account={account} />
                        }}
                        value={defaultRevenueAccount?.id ?? ""}
                      />
                      <Box sx={classes.optionHelpContainer}>
                        <HelpOutlineIcon
                          aria-haspopup="true"
                          aria-owns={
                            anchorEl?.id === "defaultRevenueAccount-HelpIcon"
                              ? "defaultRevenueAccount-popover"
                              : undefined
                          }
                          fontSize="small"
                          id="defaultRevenueAccount-HelpIcon"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        />
                        <Popover
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          disableRestoreFocus
                          id="defaultRevenueAccount-popover"
                          onClose={handlePopoverClose}
                          open={Boolean(anchorEl?.id === "defaultRevenueAccount-HelpIcon")}
                          sx={classes.popover}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box sx={classes.popoverContent}>
                            {t(
                              `page.configurePlugin.defaultRevenueAccount.tooltip${id.toUpperCase()}`
                            )}
                          </Box>
                        </Popover>
                      </Box>
                    </Box>
                    <Box sx={classes.textFieldContainer}>
                      <SelectorField
                        label={t("page.configurePlugin.defaultAssetAccount.label") as string}
                        onChange={setDefaultAssetAccount}
                        options={assetAccountOptions}
                        renderOption={(option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              <AccountOption option={option as CoaAccount} />
                            </MenuItem>
                          )
                        }}
                        renderValue={(value) => {
                          const account = assetAccountOptions.find((a) => a.id == value)
                          return <AccountValue account={account} />
                        }}
                        value={defaultAssetAccount?.id ?? ""}
                      />
                      <Box sx={classes.optionHelpContainer}>
                        <HelpOutlineIcon
                          aria-haspopup="true"
                          aria-owns={
                            anchorEl?.id === "defaultAssetAccount-HelpIcon"
                              ? "defaultAssetAccount-popover"
                              : undefined
                          }
                          fontSize="small"
                          id="defaultAssetAccount-HelpIcon"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        />
                        <Popover
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          disableRestoreFocus
                          id="defaultAssetAccount-popover"
                          onClose={handlePopoverClose}
                          open={Boolean(anchorEl?.id === "defaultAssetAccount-HelpIcon")}
                          sx={classes.popover}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box sx={classes.popoverContent}>
                            {t(
                              `page.configurePlugin.defaultAssetAccount.tooltip${id.toUpperCase()}`
                            )}
                          </Box>
                        </Popover>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2.5rem",
              }}
            >
              <Button
                color="secondary"
                data-testid="deleteButton"
                disabled={loading}
                onClick={() => {
                  setShowDisconnectConfirmationDialog(true)
                }}
                variant="outlined"
              >
                <Box>
                  {t("page.configurePlugin.disconnectButton", { pluginProvider: pluginData?.name })}
                </Box>
              </Button>
              <SaveButton
                loading={loading}
                onClick={() => {
                  const variables = {
                    id: pluginId,
                    defaultExpenseAccountId: defaultExpenseAccount?.id,
                    defaultRevenueAccountId: defaultRevenueAccount?.id,
                    defaultAssetAccountId: defaultAssetAccount?.id,
                  }

                  editOrganizationPlugin({
                    variables,
                  })
                }}
              />
            </Box>
          </Paper>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  textFieldContainer: {
    marginBottom: "1.25rem",
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
  },
  textField: {
    marginRight: "0.625rem",
  },
  optionHelpContainer: {
    display: "flex",
    alignItems: "center",
    color: "#747474",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    padding: "0.5rem",
    maxWidth: "400px",
  },
} as const

export default ConfigurePlugin
