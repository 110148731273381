import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

interface Props {
  readonly onClick: () => void
}

export function AddReminderSeriesEventButton({ onClick }: Props) {
  const { t } = useTranslation()

  return (
    <Button
      onClick={onClick}
      sx={{
        cursor: "pointer",
        padding: "1rem",
        px: "3rem",
        borderColor: (theme) => theme.fielderColors.inputBorder,
        borderStyle: "dashed",
        minWidth: "7rem",
        maxWidth: "12rem",
        minHeight: "10rem",
        "&:hover": {
          borderColor: (theme) => theme.fielderColors.inputBorderFocused,
          backgroundColor: "#fffdf3",
          zIndex: 3,
        },
        zIndex: 2,
        backgroundColor: "#fff",
      }}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.25rem",
          fontSize: "0.875rem",
          color: "#4B5563",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        <Box>
          {"+ "}
          {t("page.manageAutomatedMessaging.addMessage")}
        </Box>
      </Box>
    </Button>
  )
}
