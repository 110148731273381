import React, { useEffect } from "react"
import { navigate } from "gatsby"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Cookies from "js-cookie"
import { useAuth } from "~/context/AuthContext"
import { getLandingRoute } from "~/util/authorization"

function IndexPage() {
  const { user, logout } = useAuth()

  useEffect(() => {
    if (Cookies.get("authToken") && user) {
      const route = getLandingRoute(user)
      navigate(route)
    } else {
      logout?.()
      navigate("/app/login")
    }
  })

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  )
}

export default IndexPage
