import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Sentry from "@sentry/react"
import { Descendant } from "slate"
import { useMutation, useQuery, gql } from "@apollo/client"
import { useParams, Navigate, NavigateProps } from "react-router-dom"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"

import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import RichTextComposer from "~/components/RichTextEditor/RichTextComposer"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import { isBlank, parseGraphQLErrorCode, SETTINGS } from "~/util"
import {
  DEFAULT_INITIAL_RTE_VALUE,
  SAMPLE_DATA,
  toHtml,
  resolveTemplate,
} from "~/util/richTextTemplateUtils"
import { usePrompt } from "~/hooks/usePrompt"
import { AllTemplateFieldsResponse, Snack, TemplateFieldOption } from "~/types"
import { ALL_TEMPLATE_FIELDS } from "~/queries/allTemplateFields"

const GET_CONTRACT_TEMPLATE = gql`
  query GetContractTemplateById($id: ID!) {
    getContractTemplateById(id: $id) {
      id
      name
      body
      createdAt
      createdBy {
        id
      }
      updatedAt
      updatedBy {
        id
      }
    }
  }
`

const CREATE_CONTRACT_TEMPLATE = gql`
  mutation CreateContractTemplate($name: String!, $body: String!) {
    createContractTemplate(input: { name: $name, body: $body }) {
      contractTemplate {
        id
        name
        body
        createdAt
        createdBy {
          id
        }
        updatedAt
        updatedBy {
          id
        }
      }
    }
  }
`

const EDIT_CONTRACT_TEMPLATE = gql`
  mutation EditContractTemplate($id: ID!, $name: String, $body: String) {
    editContractTemplate(input: { id: $id, name: $name, body: $body }) {
      contractTemplate {
        id
        name
        body
        createdAt
        createdBy {
          id
        }
        updatedAt
        updatedBy {
          id
        }
      }
    }
  }
`

function EditContractTemplate() {
  const { t } = useTranslation()
  const { id: idParam } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [id, setId] = useState<string | null | undefined>(idParam)
  const [name, setName] = useState<string>("")
  const [body, setBody] = useState<Descendant[] | null>(null)
  const [initialBody, setInitialBody] = useState<Descendant[] | null>(
    idParam ? null : DEFAULT_INITIAL_RTE_VALUE
  )
  const [loadError, setLoadError] = useState<string | null>(null)
  const [allTemplateFieldOptions, setAllTemplateFieldOptions] = useState<TemplateFieldOption[]>([])
  const [snack, setSnack] = useState<Snack>()
  const [errors, setErrors] = useState<{
    name?: string | null
    body?: string | null
  }>(() => ({
    name: null,
    body: null,
  }))
  const [inPreviewMode, setInPreviewMode] = useState<boolean>(false)

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  useQuery<AllTemplateFieldsResponse>(ALL_TEMPLATE_FIELDS, {
    onCompleted: (data) => {
      const sortedOptions = data?.allTemplateFields
        ?.map((p) => ({
          id: p.id,
          key: p.key,
          format: p.format,
          displayName: t(`component.richTextEditor.templateFieldOptions.${p.key}`),
        }))
        ?.sort((a, body) => a.displayName.localeCompare(body.displayName))
      setAllTemplateFieldOptions(sortedOptions)
    },
  })

  const { loading: getContractTemplateLoading } = useQuery(GET_CONTRACT_TEMPLATE, {
    variables: { id: idParam },
    skip: !idParam,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const template = data?.getContractTemplateById
      if (template) {
        setId(template.id)
        setName(template.name)
        try {
          const bodyJson = JSON.parse(template.body)
          setBody(bodyJson)
          setInitialBody(bodyJson)
          setLoadError(null)
        } catch (error) {
          Sentry.captureException(error)
          setLoadError(t("page.settings.templates.contract.error.invalidJson"))
          setBody(DEFAULT_INITIAL_RTE_VALUE)
          setInitialBody(DEFAULT_INITIAL_RTE_VALUE)
        }
      }
    },
  })

  const [editContractTemplate, { loading: editContractTemplateLoading }] = useMutation(
    EDIT_CONTRACT_TEMPLATE,
    {
      onCompleted: () => {
        setIsDirty(false)
        setRedirectTo({
          to: "/app/settings/templates/contract/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.changesSaved",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [createContractTemplate, { loading: createContractTemplateLoading }] = useMutation(
    CREATE_CONTRACT_TEMPLATE,
    {
      onCompleted: () => {
        setIsDirty(false)
        setRedirectTo({
          to: "/app/settings/templates/contract/list",
          replace: false,
          state: {
            snack: {
              messageKey: "messages.changesSaved",
              variant: "success",
            },
          },
        })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  function handleSave() {
    setErrors({})

    const variables = id
      ? { id, name: name.trim().substring(0, 255), body: JSON.stringify(body) }
      : { name: name.trim().substring(0, 255), body: JSON.stringify(body) }

    if (idParam) {
      editContractTemplate({ variables })
    } else {
      createContractTemplate({ variables })
    }
  }

  function getTemplateFieldOptions(): TemplateFieldOption[] {
    return allTemplateFieldOptions.filter(
      (f) => !f.key.startsWith("estimate.") && !f.key.startsWith("invoice.")
    )
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const renderEditorContent = () => {
    if (idParam && (getContractTemplateLoading || !initialBody)) {
      return <CircularProgress color="secondary" />
    } else if (initialBody) {
      const mutableBody = JSON.parse(JSON.stringify(body))
      return (
        <>
          {inPreviewMode ? (
            <iframe
              sandbox=""
              srcDoc={toHtml(resolveTemplate(mutableBody, SAMPLE_DATA, t))}
              style={{
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "4px",
              }}
            />
          ) : null}
          <RichTextComposer
            hide={inPreviewMode}
            onChange={setBody}
            templateFieldOptions={templateFieldOptions}
            value={initialBody}
          />
        </>
      )
    }
  }

  const loading = getContractTemplateLoading
  const saving = editContractTemplateLoading || createContractTemplateLoading
  const templateFieldOptions = getTemplateFieldOptions()

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/templates", titleKey: "templates" },
              { to: "/app/settings/templates/contract", titleKey: "contractTemplates" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={id ? "edit" : "create"}
          />
          {loading ? (
            <Box sx={classes.spinnerContainer}>
              <CircularProgress color="secondary" />
              <p>{t("loading")} ...</p>
            </Box>
          ) : (
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                width: "1161px",
                maxWidth: "1161px",
                margin: "0 auto",
                marginBottom: "1rem",
                padding: "1.25rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "30rem",
                  }}
                >
                  <FielderTextField
                    error={!!errors.name}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={t("templateName")}
                    name="name"
                    onBlur={() => {
                      if (isBlank(name)) {
                        setErrors({
                          ...errors,
                          name: "page.settings.templates.contract.validation.templateName.required",
                        })
                      } else {
                        setErrors({
                          ...errors,
                          name: null,
                        })
                      }
                    }}
                    onChange={(e) => {
                      const val = e.target.value
                      if (isBlank(val)) {
                        setErrors({
                          ...errors,
                          name: "page.settings.templates.contract.validation.templateName.required",
                        })
                      } else {
                        setErrors({
                          ...errors,
                          name: null,
                        })
                      }
                      setName(val)
                    }}
                    onFocus={(e) => e.target.select()}
                    placeholder={
                      t("page.settings.templates.contract.templateNamePlaceholder") as string
                    }
                    required
                    style={{ marginTop: 0 }}
                    value={name}
                  />
                  {errors.name ? (
                    <FieldHelperText error message={t(errors.name)} />
                  ) : (
                    <FieldHelperText
                      message={t("page.settings.templates.contract.helperText.templateName")}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: "0.625rem",
                    marginTop: "0.625rem",
                  }}
                >
                  <Button
                    color="primary"
                    data-testid="saveContractTemplateButton"
                    disabled={Boolean(!name || saving || loadError)}
                    onClick={handleSave}
                    sx={{
                      fontWeight: "bold",
                      minWidth: "135px",
                    }}
                    variant="contained"
                  >
                    {saving ? (
                      <CircularProgress color="secondary" size={20} thickness={6.0} />
                    ) : (
                      <span>{t("saveTemplate")}</span>
                    )}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  minHeight: "600px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    marginBottom: "0.625rem",
                    marginTop: "0.625rem",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                  >
                    <Box
                      component="label"
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      {t("page.settings.templates.contract.body")}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.25rem",
                        alignItems: "center",
                        fontSize: "0.875rem",
                      }}
                    >
                      <a
                        href={t("page.settings.templates.contract.learnMoreUrl") as string}
                        rel="noreferrer"
                        style={{ color: "black" }}
                        target="_blank"
                      >
                        {t("learnMore")}
                      </a>
                      <OpenInNewIcon sx={{ fontSize: "0.875rem" }} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "1.25rem",
                      flexGrow: 1,
                      alignSelf: "stretch",
                      height: "14rem",
                      minHeight: "12rem !important",
                      overflow: "hidden",
                      overflowY: "scroll",
                      overflowX: "scroll",
                    }}
                  >
                    {loadError ? (
                      <Box sx={{ width: "100%", padding: "1rem" }}>
                        <Alert severity="error">{loadError}</Alert>
                      </Box>
                    ) : (
                      renderEditorContent()
                    )}
                  </Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={inPreviewMode}
                        disabled={!!loadError}
                        onChange={() => {
                          setInPreviewMode(!inPreviewMode)
                        }}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green",
                            "& + .MuiSwitch-track": {
                              backgroundColor: "green",
                            },
                          },
                        }}
                      />
                    }
                    label={t("page.settings.templates.contract.preview") as string}
                    labelPlacement="start"
                    style={{ alignSelf: "flex-end" }}
                  />
                </Box>
              </Box>
            </Paper>
          )}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  rowItem: {
    flex: 1,
  },
  editor: {
    height: "87%",
  },
}

export default EditContractTemplate
