import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

import BasicInfoForm from "./BasicInfoForm"
import BasicInfoStatic from "./BasicInfoStatic"
import TopLevelProductDetailsStatic from "./TopLevelProductDetailsStatic"
import ProductDetailsForm from "./ProductDetailsForm"
import Sidebar from "./Sidebar"
import { ItemFormContext } from "./ItemFormContext"
import { asFloat, asInt, isNumeric } from "~/util"
import { usePrompt } from "~/hooks/usePrompt"
import { OrganizationItem, ItemType, OrganizationPlugin, PricingStrategy } from "~/types"
import SaveButton from "~/components/SaveButton"

interface Props {
  readonly accountingPlugin?: OrganizationPlugin
  readonly currencyCode: string
  readonly loading?: boolean
  readonly onCancel?: () => void
  readonly onSave: (item: OrganizationItem) => void
  readonly onImageUpload?: () => void
}

function ProductForm({
  accountingPlugin,
  currencyCode,
  loading,
  onCancel,
  onSave,
  onImageUpload,
}: Props) {
  const { t } = useTranslation()
  const { state } = useContext(ItemFormContext)

  usePrompt(t("messages.unsavedChangesNavPrompt"), state.isDirty)

  function isValid() {
    return state.code.value && !state.code.error && state.name.value && !state.name.error
  }

  function handleSubmit() {
    const qtyOnHand = accountingPlugin?.supportsInventoryTracking
      ? undefined
      : asInt(state.qtyOnHand.value)
    const reorderQty = asInt(state.reorderQty.value)
    const reorderPoint = asInt(state.reorderPoint.value)
    const isActive = state.isActive
    const isTracked = accountingPlugin?.supportsInventoryTracking ? undefined : state.isTracked

    const payload = {
      id: state.id,
      code: state.code.value,
      name: state.name.value,
      description: state.description.value,
      category: state.category.value,
      isActive,
      isTracked,
      type: ItemType.PRODUCT,
      qtyOnHand: isNumeric(qtyOnHand) && (qtyOnHand as number) >= 0 ? qtyOnHand : undefined,
      pricingStrategy: state.pricingStrategy,
      unitSalePrice:
        state.pricingStrategy === PricingStrategy.DYNAMIC
          ? undefined
          : asFloat(state.unitSalePrice.value),
      unitSalePriceMarkup:
        state.pricingStrategy === PricingStrategy.DYNAMIC
          ? asFloat(state.unitSalePriceMarkup.value) / 100
          : undefined,
      reorderQty: isNumeric(reorderQty) && (reorderQty as number) >= 0 ? reorderQty : undefined,
      reorderPoint:
        isNumeric(reorderPoint) && (reorderPoint as number) >= 0 ? reorderPoint : undefined,
    }

    if (!state.organizationItem.isTopLevel) {
      payload.palletQty = asInt(state.palletQty.value)
      payload.packSize = asInt(state.packSize.value)
      payload.msrp = asFloat(state.msrp.value)
      payload.wholesalePrice = asFloat(state.wholesalePrice.value)
    }

    onSave(payload)
  }

  return (
    <>
      <Box sx={{ paddingBottom: 0 }}>
        <Box
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              flex: 1,
              [theme.breakpoints.up("md")]: {
                flexDirection: "row",
              },
            }
          }}
        >
          {state.id ? (
            <Sidebar item={state.organizationItem} onImageUpload={onImageUpload} />
          ) : null}
          <Box
            sx={(theme) => {
              return {
                flex: 1,
                paddingLeft: "0",
                [theme.breakpoints.up("md")]: {
                  padding: "0rem 1rem 1rem 2rem",
                },
              }
            }}
          >
            {state.organizationItem.isTopLevel ? (
              <>
                <BasicInfoStatic item={state.organizationItem} showBottomDivider />
                <TopLevelProductDetailsStatic
                  currencyCode={currencyCode}
                  item={state.organizationItem}
                  showBottomDivider
                />
              </>
            ) : (
              <Box
                sx={{
                  padding: 0,
                }}
              >
                <BasicInfoForm />
              </Box>
            )}
            <Box
              sx={(theme) => {
                return {
                  padding: 0,
                  marginTop: "0.5rem",
                  [theme.breakpoints.up("sm")]: {
                    marginTop: "1rem",
                  },
                }
              }}
            >
              <ProductDetailsForm accountingPlugin={accountingPlugin} currencyCode={currencyCode} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => {
          return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "1rem 0 0.25rem 0",
            [theme.breakpoints.up("md")]: {
              marginTop: "2rem",
              padding: 0,
            },
          }
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={!isValid()} loading={loading} onClick={handleSubmit} />
      </Box>
    </>
  )
}

export default ProductForm
