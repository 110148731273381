import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs, { type Dayjs } from "dayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import PhoneIcon from "@mui/icons-material/PhoneOutlined"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import Collapse from "@mui/material/Collapse"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

import { formatPhoneNumber, getCurrentValue, getUserHqMarker, NOT_SPECIFIED } from "~/util"
import Tooltip from "~/components/Tooltip"
import AddressAutocompleteField from "~/components/AddressAutocompleteField"
import CustomerTypeahead from "~/components/CustomerTypeahead"
import JobWorkflowSelect from "~/components/JobWorkflowSelect"
import JobWorkflowStepSelect from "~/components/JobWorkflowStepSelect"
import PhoneNumberInput from "~/components/CustomPhoneNumberInput"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import CustomerContactSelect from "./CustomerContactSelect"
import JobCategorySelect from "./JobCategorySelect"
import CreateContactDialog from "./CreateContactDialog"
import {
  Address,
  Contact,
  Customer,
  CustomerType,
  Job,
  JobCategory,
  JobWorkflow,
  JobWorkflowStep,
  User,
} from "~/types"
import useStore, { jobBoardWorkflowSelector } from "~/store"
import { usePrompt } from "~/hooks/usePrompt"
import SaveButton from "~/components/SaveButton"

const TODAY = dayjs().startOf("day")
const MAX_DESIRED_COMPLETION_DATE = TODAY.add(5, "year")

interface JobBasicInfoFormProps {
  readonly job?: Job | null
  readonly loading?: boolean
  readonly onCancel?: () => void
  readonly onChangeAddress?: (address: Address) => void
  readonly onSave?: (job: Job) => void
  readonly user: Partial<User>
}

function JobBasicInfoForm({
  job,
  loading,
  onCancel,
  onChangeAddress,
  onSave,
  user,
}: JobBasicInfoFormProps) {
  const { t } = useTranslation()
  const jobBoardWorkflow = useStore(jobBoardWorkflowSelector)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const userHqMarker = React.useMemo(() => getUserHqMarker(user), [user])
  const [customer, setCustomer] = useState<Partial<Customer> | null>(() =>
    getCurrentValue(job, "customer", null)
  )
  const [workflow, setWorkflow] = useState<Partial<JobWorkflow> | null>(() =>
    getCurrentValue(job, "workflow", null)
  )
  const [workflowStep, setWorkflowStep] = useState<Partial<JobWorkflowStep>>(() =>
    getCurrentValue(job, "workflowStep", null)
  )
  const [categories, setCategories] = useState<JobCategory[]>(() =>
    getCurrentValue(job, "categories", [])
  )
  const [address, setAddress] = useState<Partial<Address> | null>(() =>
    getCurrentValue(job, "address")
  )
  const [description, setDescription] = useState<string>(() => getCurrentValue(job, "description"))
  const [desiredCompletionDate, setDesiredCompletionDate] = useState<Dayjs | null>(() => {
    return job?.id && job?.desiredCompletionDate ? dayjs(job.desiredCompletionDate) : null
  })
  const [siteContact, setSiteContact] = useState<Contact | null>(() =>
    getCurrentValue(job, "siteContact", null)
  )
  const [siteContactEmail, setSiteContactEmail] = useState<string>(() =>
    getCurrentValue(job, "siteContact.email", "")
  )
  const [siteContactPhone, setSiteContactPhone] = useState<string>(() =>
    getCurrentValue(job, "siteContact.phoneNumber", "")
  )
  const [createContactDialogOpen, setCreateContactDialogOpen] = useState<boolean>(false)
  const [touched, setTouched] = useState<{
    customer?: boolean
    workflow?: boolean
    workflowStep?: boolean
    categories?: boolean
    address?: boolean
    description?: boolean
    desiredCompletionDate?: boolean
    siteContactEmail?: boolean
    siteContactPhone?: boolean
  }>(() => ({
    customer: false,
    workflow: false,
    workflowStep: false,
    categories: false,
    address: false,
    description: false,
    desiredCompletionDate: false,
    siteContactEmail: false,
    siteContactPhone: false,
  }))
  const [errors, setErrors] = useState<{
    customer?: string | null
    workflow?: string | null
    workflowStep?: string | null
    categories?: string | null
    address?: string | null
    description?: string | null
    desiredCompletionDate?: string | null
    siteContactEmail?: string | null
    siteContactPhone?: string | null
  }>(() => ({
    customer: null,
    workflow: null,
    workflowStep: null,
    categories: null,
    address: null,
    description: null,
    desiredCompletionDate: null,
    siteContactEmail: null,
    siteContactPhone: null,
  }))

  useEffect(() => {
    if (!job?.workflow) {
      setWorkflow(jobBoardWorkflow)
    }
  }, [job?.workflow, jobBoardWorkflow])

  const handleCustomerSelect = useCallback(
    (cust) => {
      if (cust) {
        const selected = { id: "", ...cust }
        if (customer?.id !== selected.id) {
          // if the user changed the customer, then reset the Site Contact selection
          setSiteContact(null)

          if (selected.type === CustomerType.INDIVIDUAL) {
            setSiteContactEmail(selected.email ?? "")
            setSiteContactPhone(selected.phoneNumber ?? "")
          }
        }
        setCustomer(selected)
        setErrors((prev) => ({ ...prev, customer: null, workflowStep: null }))
      } else {
        setCustomer(null)
        setSiteContactEmail("")
        setSiteContactPhone("")
        if (!workflowStep?.isInitialStep) {
          setErrors((prev) => ({
            ...prev,
            customer: t("page.jobBasicInfo.validation.customer.required"),
          }))
        } else {
          setErrors((prev) => ({ ...prev, customer: null }))
        }
      }
      setIsDirty(true)
      setTouched((prev) => ({ ...prev, customer: true }))
    },
    [customer?.id, t, workflowStep?.isInitialStep]
  )

  const handleCustomerSelectBlur = useCallback(() => {
    setTouched((prev) => ({ ...prev, customer: true }))
    if (!customer) {
      setErrors((prev) => ({
        ...prev,
        customer: t("page.jobBasicInfo.validation.customer.required"),
      }))
    }
  }, [customer, t])

  const handleJobCategoryBlur = useCallback(() => {
    setTouched((prev) => ({ ...prev, categories: true }))
  }, [])

  const handleJobCategoryChange = useCallback((val) => {
    setCategories(val)
    setIsDirty(true)
  }, [])

  const handleWorkflowBlur = useCallback(() => {
    setTouched((prev) => ({ ...prev, workflow: true }))
    if (!workflow) {
      setErrors((prev) => ({
        ...prev,
        workflow: t("page.jobBasicInfo.validation.workflow.required"),
      }))
    }
  }, [t, workflow])

  const handleWorkflowChange = useCallback((newWorkflow) => {
    setErrors((prev) => ({
      ...prev,
      workflow: null,
    }))
    setTouched((prev) => ({ ...prev, workflow: true }))
    setWorkflow(newWorkflow)
    setIsDirty(true)
  }, [])

  const handleWorkflowStepBlur = useCallback(() => {
    setTouched((prev) => ({ ...prev, workflowStep: true }))
  }, [])

  const handleWorkflowStepChange = useCallback(
    (step) => {
      if (step.isInitialStep && !customer) {
        setErrors((prev) => ({
          ...prev,
          workflowStep: t("page.jobBasicInfo.validation.status.requiresCustomer"),
        }))
      } else {
        setErrors((prev) => ({
          ...prev,
          workflowStep: null,
          customer: null,
        }))
      }
      setTouched((prev) => ({ ...prev, workflowStep: true }))
      setWorkflowStep(step)
      setIsDirty(true)
    },
    [customer, t]
  )

  const handleCustomerContactSelect = useCallback((val) => {
    if (val === "CREATE_NEW_CONTACT") {
      setCreateContactDialogOpen(true)
    } else {
      setSiteContact(val)
    }
    setIsDirty(true)
  }, [])

  const handleCustomerPhoneChange = useCallback((event) => {
    setIsDirty(true)
    setSiteContactPhone(event.target.value)
  }, [])

  const handleAddressBlur = useCallback(() => {
    setTouched((prev) => ({ ...prev, address: true }))
  }, [])

  const handleAddressChange = useCallback(
    (addressObj) => {
      // addressObj is expected to be an object with properties:
      // addressString, latitude, longitude, streetNumber, route, locality, etc...
      setAddress(addressObj)
      setIsDirty(true)
      onChangeAddress?.(addressObj)

      if (addressObj?.addressString?.trim().length > 0) {
        setErrors((oldErrors) => {
          return { ...oldErrors, address: null }
        })
      } else {
        setErrors((oldErrors) => {
          return { ...oldErrors, address: t("page.jobBasicInfo.validation.address.required") }
        })
      }
    },
    [onChangeAddress, t]
  )

  function showWorkflowStepSelector(): boolean {
    return !!job?.id && !!job?.workflow && !!workflow && job.workflow.id === workflow.id
  }

  function handleSave() {
    if (!customer || !address?.addressString) {
      setErrors({
        ...errors,
        customer: !customer ? t("page.jobBasicInfo.validation.customer.required") : null,
        address: !address?.addressString
          ? t("page.jobBasicInfo.validation.address.required")
          : null,
      })

      return
    }

    if (Object.values(errors).some(Boolean)) {
      // Note that this check won't catch the customer and/or address errors
      // that may have been set above, because that call to setErrors basically
      // just *schedules* an update to the errors object.
      return
    }

    const cust = {} as any
    if (customer.id) {
      cust.existingCustomerId = customer.id
    } else {
      cust.newCustomerName = customer.name
      cust.newCustomerType = customer.type
      if (customer.type === CustomerType.INDIVIDUAL) {
        cust.siteContact = {
          email: siteContactEmail,
          phoneNumber: siteContactPhone,
        }
      }
    }

    const updatedJob = {
      ...cust,
      workflowId: workflow.id ?? undefined,
      workflowStepId: workflowStep ? workflowStep.id : undefined,
      description: description ? description : undefined,
      desiredCompletionDate:
        Boolean(desiredCompletionDate) && dayjs(desiredCompletionDate).isValid()
          ? dayjs(desiredCompletionDate).startOf("day").toISOString()
          : undefined,
      categoryIDs: categories.map((c) => c.id),
      address: address
        ? {
            addressString: address?.addressString,
            latitude: address?.latitude,
            longitude: address?.longitude,
            streetNumber: address?.streetNumber,
            route: address?.route,
            locality: address?.locality,
            administrativeAreaLevel1: address?.administrativeAreaLevel1,
            administrativeAreaLevel2: address?.administrativeAreaLevel2,
            postalCode: address?.postalCode,
            country: address?.country,
          }
        : undefined,
    }

    // set the siteContact variable for new jobs, but while editing existing jobs only set it if it changed .
    if ((siteContact && !job) || (siteContact && job && job.siteContact?.id !== siteContact.id)) {
      let jobSiteContact = siteContact
        ? {
            customerId: siteContact.customerId,
            firstName: siteContact.firstName,
            lastName: siteContact.lastName,
            jobTitle: siteContact.jobTitle,
            email: siteContact.email,
            phoneNumber: siteContact.phoneNumber,
            canBeJobSiteContact: siteContact.canBeJobSiteContact,
            canBeBillingContact: siteContact.canBeBillingContact,
            address: {
              addressString: siteContact.address?.addressString,
              latitude: siteContact.address?.latitude,
              longitude: siteContact.address?.longitude,
              streetNumber: siteContact.address?.streetNumber,
              route: siteContact.address?.route,
              locality: siteContact.address?.locality,
              administrativeAreaLevel1: siteContact.address?.administrativeAreaLevel1,
              administrativeAreaLevel2: siteContact.address?.administrativeAreaLevel2,
              postalCode: siteContact.address?.postalCode,
              country: siteContact.address?.country,
            },
          }
        : null

      if (siteContact.id !== "NEW") {
        jobSiteContact.id = siteContact.id
      }

      if (customer.type === CustomerType.INDIVIDUAL) {
        if (!jobSiteContact) {
          jobSiteContact = {}
        }
        jobSiteContact.email = siteContactEmail
        jobSiteContact.phoneNumber = siteContactPhone
      }

      updatedJob.siteContact = jobSiteContact
    }

    if (job?.id) {
      updatedJob.id = job.id
    }

    const payload = { variables: updatedJob }
    onSave?.(payload)
    setIsDirty(false)
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <>
      {createContactDialogOpen && customer ? (
        <CreateContactDialog
          canBeBillingContact
          canBeJobSiteContact
          customer={customer}
          onCancel={() => setCreateContactDialogOpen(false)}
          onSave={(contact: Contact) => {
            setIsDirty(true)
            setSiteContact(contact)
            setCreateContactDialogOpen(false)
          }}
          showCanBeBillingContactToggle
          showCanBeJobSiteContactToggle={false}
          user={user}
        />
      ) : null}
      {job?.estimates?.length || job?.invoices?.length ? (
        <Box
          sx={{
            padding: "0.625rem",
            paddingTop: "0.3125rem",
            paddingBottom: "0.3125rem",
            marginBottom: "1.5625rem",
            "& label": {
              fontWeight: 700,
              marginBottom: "0.25rem",
            },
            "& hr": {
              marginTop: "0.625rem",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <label>{t("customer")}</label>
              <Box>
                <Box>{job.customer?.name || NOT_SPECIFIED}</Box>
                {job.customer?.type === "INDIVIDUAL" && (
                  <>
                    {job.siteContact?.phoneNumber ? (
                      <Box>
                        <PhoneIcon /> {formatPhoneNumber(job.siteContact.phoneNumber)}
                      </Box>
                    ) : null}
                    {job.siteContact?.email ? (
                      <Box>
                        <EmailIcon /> {job.siteContact.email}
                      </Box>
                    ) : null}
                  </>
                )}
              </Box>
            </Box>
            <Box style={{ fontSize: "0.625rem" }}>
              <Tooltip
                arrow
                enterDelay={300}
                placement="bottom"
                title={t("page.jobBasicInfo.customerLockedTooltip") as string}
              >
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={classes.fieldContainer}>
          <CustomerTypeahead
            aria-label={t("customer") as string}
            currentValue={customer}
            hasError={!!errors.customer}
            label={t("customer")}
            name="customer"
            onBlur={handleCustomerSelectBlur}
            onSelect={handleCustomerSelect}
            required
            size="medium"
            testID="customerTypeahead"
          />
          {errors.customer && touched.customer ? (
            <FieldHelperText error message={errors.customer} />
          ) : (
            <FieldHelperText message={t("page.jobBasicInfo.helperText.customer")} />
          )}
        </Box>
      )}
      {customer?.id ? (
        <Box sx={classes.fieldContainer}>
          <CustomerContactSelect
            customerId={customer.id}
            id="jobSiteContact"
            label={t("siteContact") as string}
            name="jobSiteContact"
            onChange={handleCustomerContactSelect}
            onlyShowSiteContacts
            selectedOption={siteContact}
          />
          {errors.siteContact && touched.siteContact ? (
            <FieldHelperText error message={errors.siteContact} />
          ) : (
            <FieldHelperText message={t("page.jobBasicInfo.helperText.siteContact")} />
          )}
        </Box>
      ) : customer?.type === CustomerType.INDIVIDUAL ? (
        <>
          <Box sx={classes.fieldContainer}>
            <PhoneNumberInput
              label={t("customerPhone") as string}
              margin="none"
              onChange={handleCustomerPhoneChange}
              value={siteContactPhone}
            />
            {errors.siteContactPhone && touched.siteContactPhone ? (
              <FieldHelperText error message={errors.siteContactPhone} />
            ) : (
              <FieldHelperText message={t("page.jobBasicInfo.helperText.siteContactPhone")} />
            )}
          </Box>
          <Box sx={classes.fieldContainer}>
            <FielderTextField
              fullWidth
              id="siteContactEmail"
              inputProps={{
                maxLength: 255,
              }}
              label={t("customerEmail")}
              name="email"
              onBlur={() => setTouched({ ...touched, siteContactEmail: true })}
              onChange={(e) => {
                setIsDirty(true)
                setSiteContactEmail(e.target.value)
              }}
              onFocus={(e) => e.target.select()}
              value={siteContactEmail}
            />
            {errors.siteContactEmail && touched.siteContactEmail ? (
              <FieldHelperText error message={errors.siteContactEmail} />
            ) : (
              <FieldHelperText message={t("page.jobBasicInfo.helperText.siteContactEmail")} />
            )}
          </Box>
        </>
      ) : null}
      <Box sx={classes.fieldContainer}>
        <JobWorkflowSelect
          aria-label="Workflow"
          error={!!errors.workflow}
          label={t("workflow") as string}
          name="workflow"
          onBlur={handleWorkflowBlur}
          onChange={handleWorkflowChange}
          required
          selectedOption={workflow}
          variant="outlined"
        />
        {errors.workflow && touched.workflow ? (
          <FieldHelperText error message={errors.workflow} />
        ) : (
          <FieldHelperText
            message={
              job?.id
                ? t("page.jobBasicInfo.helperText.workflow.existingJob")
                : t("page.jobBasicInfo.helperText.workflow.newJob")
            }
          />
        )}
      </Box>
      <Collapse in={showWorkflowStepSelector()} timeout="auto" unmountOnExit>
        <Box sx={classes.fieldContainer}>
          <JobWorkflowStepSelect
            aria-label="Workflow Step"
            label={t("status") as string}
            name="workflowStep"
            onBlur={handleWorkflowStepBlur}
            onChange={handleWorkflowStepChange}
            selectedOption={workflowStep}
            variant="outlined"
            workflowId={workflow?.id}
            workflowStepId={job?.workflowStep?.id}
          />
          {errors.workflowStep && touched.workflowStep ? (
            <FieldHelperText error message={errors.workflowStep} />
          ) : (
            <FieldHelperText message={t("page.jobBasicInfo.helperText.workflowStep")} />
          )}
        </Box>
      </Collapse>
      <Box sx={classes.fieldContainer}>
        <AddressAutocompleteField
          centerCoordinate={userHqMarker}
          defaultValue={address?.addressString}
          hasError={!!errors.address}
          id="address"
          label={t("jobAddress") as string}
          name="address"
          onBlur={handleAddressBlur}
          onChange={handleAddressChange}
          required
        />
        {errors.address && touched.address ? (
          <FieldHelperText error message={errors.address} />
        ) : (
          <FieldHelperText message={t("page.jobBasicInfo.helperText.address")} />
        )}
      </Box>
      <Box sx={classes.fieldContainer}>
        <JobCategorySelect
          aria-label="Job Categories"
          id="categories"
          label={t("jobCategories")}
          name="categories"
          onBlur={handleJobCategoryBlur}
          onChange={handleJobCategoryChange}
          selectedValues={categories}
        />
        {errors.categories && touched.categories ? (
          <FieldHelperText error message={errors.categories} />
        ) : (
          <FieldHelperText message={t("page.jobBasicInfo.helperText.categories")} />
        )}
      </Box>
      <Box sx={classes.fieldContainer}>
        <FielderTextField
          fullWidth
          id="description"
          inputProps={{
            maxLength: 1000,
          }}
          label={t("description")}
          maxRows="4"
          minRows="4"
          multiline
          name="description"
          onBlur={() => setTouched({ ...touched, description: true })}
          onChange={(e) => {
            setDescription(e.target.value)
            setIsDirty(true)
          }}
          value={description}
        />
        {errors.description && touched.description ? (
          <FieldHelperText error message={errors.description} />
        ) : (
          <FieldHelperText message={t("page.jobBasicInfo.helperText.description")} />
        )}
      </Box>
      <Box sx={classes.fieldContainer}>
        <DatePicker
          aria-label={t("desiredCompletionDate")}
          disablePast={typeof job?.id === "undefined"}
          format={t("format:dateFormat.short") as string}
          label={t("desiredCompletionDate")}
          maxDate={MAX_DESIRED_COMPLETION_DATE}
          minDate={job?.id ? undefined : TODAY}
          onChange={(val) => {
            setIsDirty(true)
            setTouched({ ...touched, desiredCompletionDate: true })
            if (!val || val?.isValid()) {
              if (
                val?.isBetween(TODAY, MAX_DESIRED_COMPLETION_DATE, "day", "[]") ||
                (Boolean(job?.id) && val?.isBefore(MAX_DESIRED_COMPLETION_DATE, "day"))
              ) {
                setErrors({
                  ...errors,
                  desiredCompletionDate: null,
                })
              }
            }
            setDesiredCompletionDate(val)
          }}
          onError={(reason, value) => {
            if (reason === "minDate" || reason === "disablePast") {
              setErrors({
                ...errors,
                desiredCompletionDate: t(
                  "page.jobBasicInfo.validation.desiredCompletionDate.minDate"
                ),
              })
            } else if (reason === "maxDate") {
              setErrors({
                ...errors,
                desiredCompletionDate: t(
                  "page.jobBasicInfo.validation.desiredCompletionDate.maxDate"
                ),
              })
            } else if (reason === "invalidDate") {
              setErrors({
                ...errors,
                desiredCompletionDate: t(
                  "page.jobBasicInfo.validation.desiredCompletionDate.invalid"
                ),
              })
            }
          }}
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              InputProps: {
                disableUnderline: true,
              },
              fullWidth: true,
              required: false,
              helperText: errors.desiredCompletionDate
                ? errors.desiredCompletionDate
                : t("page.jobBasicInfo.helperText.desiredCompletionDate"),
            },
          }}
          slots={{
            textField: FielderTextField,
          }}
          value={desiredCompletionDate}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1.5rem",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          <Box>{t("cancel")}</Box>
        </Button>
        <SaveButton loading={loading} onClick={handleSave} />
      </Box>
    </>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default JobBasicInfoForm
