import React from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import TextMessageIcon from "@mui/icons-material/TextsmsOutlined"
import { useTranslation } from "react-i18next"

import { MessageChannel, ReminderScheduleUnit, ReminderSeriesEvent } from "~/types/apiTypes"
import { formatTimeOfDay } from "~/util/timeUtils"

interface Props {
  readonly event: ReminderSeriesEvent
  readonly onClick: () => void
}

export function ReminderSeriesEventCard({ event, onClick }: Props) {
  const { t } = useTranslation()

  const scheduleDescriptionOptions = {
    scheduleOffset: Math.abs(event.scheduleOffset),
    scheduleUnit: t(
      `page.manageAutomatedMessaging.scheduleUnit.${event.scheduleUnit}.${Math.abs(event.scheduleOffset) === 1 ? "singular" : "plural"}`
    ),
    priorOrAfter:
      event.scheduleOffset < 0
        ? t("page.manageAutomatedMessaging.prior")
        : t("page.manageAutomatedMessaging.after"),
  }

  return (
    <Button
      onClick={onClick}
      sx={{
        cursor: "pointer",
        padding: "1rem",
        px: "3rem",
        borderColor: (theme) => theme.fielderColors.inputBorder,
        minWidth: "7rem",
        maxWidth: "12rem",
        backgroundColor: "#fff",
        "&:hover": {
          borderColor: (theme) => theme.fielderColors.inputBorderFocused,
          backgroundColor: "#fffdf3",
          zIndex: 3,
        },
        zIndex: 2,
      }}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.25rem",
          fontSize: "0.875rem",
          color: "#4B5563",
        }}
      >
        <Box>
          {event.channel === MessageChannel.EMAIL ? (
            <EmailIcon fontSize="large" />
          ) : (
            <TextMessageIcon fontSize="large" />
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0", lineHeight: "1.25" }}>
          <Box>
            {event.channel === MessageChannel.EMAIL
              ? t("page.manageAutomatedMessaging.email")
              : t("page.manageAutomatedMessaging.textMessage")}
          </Box>
          <Box>
            <span>
              {t(
                "page.manageAutomatedMessaging.eventScheduleDescription",
                scheduleDescriptionOptions
              )}
            </span>
          </Box>
          {event.timeOfDay && event.scheduleUnit === ReminderScheduleUnit.DAYS ? (
            <Box>
              {t("page.manageAutomatedMessaging.atTimeOfDay", {
                timeOfDay: formatTimeOfDay(event.timeOfDay),
              }).toLowerCase()}
            </Box>
          ) : (
            <span>&nbsp;</span>
          )}
        </Box>
        <Box sx={{ fontSize: "0.875rem", textDecoration: "underline", marginTop: "1rem" }}>
          {t("edit")}
        </Box>
      </Box>
    </Button>
  )
}
