import React from "react"
import { useTranslation } from "react-i18next"
import LoginIcon from "@mui/icons-material/Login"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"

import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import {
  formatAddress,
  formatDate,
  formatPersonName,
  formatPhoneNumber,
  NOT_SPECIFIED,
} from "~/util"
import { useAuth } from "~/context/AuthContext"
import { DefaultPermission, Organization, OrganizationStatus } from "~/types"
import StaticField from "~/components/StaticField"
import OrganizationStatusPill from "./OrganizationStatusPill"
import FranchiseeSubscriptionInfo from "./FranchiseeSubscriptionInfo"
import QuickTip from "./QuickTip"
import OwnerInfoStatic from "./OwnerInfoStatic"
import { isDraftOrPending } from "../utils/franchiseeUtils"

interface Props {
  readonly franchisee: Organization
  readonly onClickDelete: () => void
  readonly onClickEdit: () => void
  readonly onClickLoginAs?: () => void
  readonly onClickSendInvitation?: () => void
  readonly onClickSendPasswordResetCode?: () => void
  readonly sendingInvitation?: boolean
}

function displayStaticString(value: string | undefined | null): string {
  if (!value) {
    return NOT_SPECIFIED
  }

  const trimmed = value.trim()

  return trimmed.length > 0 ? trimmed : NOT_SPECIFIED
}

function FranchiseeStatic({
  franchisee,
  onClickDelete,
  onClickEdit,
  onClickLoginAs,
  onClickSendInvitation,
  onClickSendPasswordResetCode,
  sendingInvitation,
}: Props) {
  const { t } = useTranslation()
  const { hasPermissions, user } = useAuth()

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "1rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr 1fr",
        },
      })}
    >
      <Box sx={{ gridRow: "1 / span 3", display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Paper>
          <SectionHeader>
            <label>{t("basicInfo")}</label>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              {franchisee.status === OrganizationStatus.ACTIVE &&
              hasPermissions?.([DefaultPermission.ImpersonateOrganization]) ? (
                <Button
                  aria-label={t("impersonateFranchisee") as string}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onClickLoginAs?.()
                  }}
                  sx={{
                    marginLeft: "0.625rem",
                    "& span": { whiteSpace: "nowrap" },
                    display: "flex",
                    gap: "0.5rem",
                    // color: (theme) => theme.fielderColors.mutedText,
                  }}
                >
                  <LoginIcon sx={{ fontSize: "1.125rem" }} />
                  <Box sx={{ fontSize: "0.875rem" }}>{t("impersonate")}</Box>
                </Button>
              ) : null}
              {hasPermissions?.([DefaultPermission.ArchiveOrganization]) &&
              (franchisee.status === OrganizationStatus.DRAFT ||
                franchisee.status === OrganizationStatus.PENDING ||
                franchisee.status === OrganizationStatus.SUSPENDED) ? (
                <Button
                  onClick={onClickDelete}
                  size="small"
                  startIcon={<DeleteIcon />}
                  sx={(theme) => ({
                    color: "#b91c1c",
                    fontSize: "0.875rem",
                    [theme.breakpoints.down("xs")]: {
                      marginLeft: 0,
                    },
                  })}
                  variant="text"
                >
                  {franchisee.status === OrganizationStatus.SUSPENDED ? t("archive") : t("delete")}
                </Button>
              ) : null}
              {hasPermissions?.([DefaultPermission.UpdateOrganization]) ? (
                <Button
                  onClick={onClickEdit}
                  size="small"
                  startIcon={<EditIcon />}
                  sx={(theme) => ({
                    fontSize: "0.875rem",
                    [theme.breakpoints.down("xs")]: {
                      marginLeft: 0,
                    },
                  })}
                  variant="text"
                >
                  {t("edit")}
                </Button>
              ) : null}
            </Box>
          </SectionHeader>
          <Divider />
          <SectionContent
            sx={(theme) => {
              return {
                [theme.breakpoints.up("md")]: {
                  padding: "1.25rem 2rem",
                },
              }
            }}
          >
            <StaticField
              label={t("status")}
              showBottomDivider
              value={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <OrganizationStatusPill status={franchisee.status} />
                  </Box>
                </Box>
              }
            />
            <StaticField
              label={t("tradeName")}
              showBottomDivider
              value={
                <Box
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                >
                  <Box>{displayStaticString(franchisee.tradeName)}</Box>
                </Box>
              }
            />
            <StaticField
              label={t("legalName")}
              showBottomDivider
              value={
                <Box
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                >
                  <Box>{displayStaticString(franchisee.legalName)}</Box>
                </Box>
              }
            />
            <StaticField label={t("region")} showBottomDivider value={franchisee.region?.name} />
            <StaticField
              label={t("language")}
              showBottomDivider
              value={t(`languageCodeOptions.${franchisee.languageCode}`)}
            />
            <StaticField
              label={t("accountManager")}
              showBottomDivider
              value={
                franchisee.accountManager
                  ? formatPersonName(franchisee.accountManager)
                  : NOT_SPECIFIED
              }
            />
            <StaticField
              label={t("accountNumber")}
              showBottomDivider
              value={displayStaticString(franchisee.accountNumber)}
            />
            <StaticField
              label={t("address")}
              showBottomDivider
              value={formatAddress(franchisee.address?.addressString)}
            />
            <StaticField
              label={t("currencyCode")}
              showBottomDivider
              value={franchisee.currencyCode}
            />
            <StaticField
              label={t("phoneNumber")}
              showBottomDivider
              value={
                franchisee.phoneNumber ? formatPhoneNumber(franchisee.phoneNumber) : NOT_SPECIFIED
              }
            />
            <StaticField
              label={t("emailAddress")}
              showBottomDivider
              value={displayStaticString(franchisee.email)}
            />
            <StaticField
              label={t("websiteAddress")}
              showBottomDivider
              value={displayStaticString(franchisee.websiteURL)}
            />
            <StaticField
              label={t("dateCreated")}
              showBottomDivider
              value={formatDate(franchisee.createdAt, "lll", user?.organization?.timeZone)}
            />
            <StaticField
              label={t("createdBy")}
              showBottomDivider={false}
              value={formatPersonName(franchisee.createdBy)}
            />
          </SectionContent>
        </Paper>
        {isDraftOrPending(franchisee) ? (
          <OwnerInfoStatic
            franchisee={franchisee}
            onClickSendInvitation={onClickSendInvitation}
            onClickSendPasswordResetCode={onClickSendPasswordResetCode}
            sendingInvitation={sendingInvitation}
          />
        ) : null}
      </Box>
      {isDraftOrPending(franchisee) ? (
        <Box>
          <QuickTip
            messageKey={
              franchisee?.status === OrganizationStatus.DRAFT
                ? "component.franchiseeForm.quickTip.message.draft"
                : "component.franchiseeForm.quickTip.message.pending"
            }
          />
        </Box>
      ) : null}
      {franchisee?.id && !isDraftOrPending(franchisee) ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <OwnerInfoStatic franchisee={franchisee} />
          <FranchiseeSubscriptionInfo franchisee={franchisee} />
        </Box>
      ) : null}
    </Box>
  )
}

export default FranchiseeStatic
