import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Collapse from "@mui/material/Collapse"
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import PercentIcon from "@mui/icons-material/Percent"
import InputAdornment from "@mui/material/InputAdornment"
import Divider from "@mui/material/Divider"

import {
  Organization,
  OrganizationFormInput,
  LanguageCode,
  OrganizationDefaultEstimateSettings,
  OrganizationDefaultInvoiceSettings,
  PricingStrategy,
} from "~/types"
import FieldHelperText from "~/components/FieldHelperText"
import { usePrompt } from "~/hooks/usePrompt"
import SaveButton from "~/components/SaveButton"
import LanguageSelect from "~/components/LanguageSelect"
import DefaultEstimateSettingsForm from "./DefaultEstimateSettingsForm"
import DefaultInvoiceSettingsForm from "./DefaultInvoiceSettingsForm"
import RadioOption from "~/components/RadioOption"
import IntegerInput from "~/components/IntegerInput"
import { asFloat, isNumeric } from "~/util"
import ConfirmationDialog from "~/components/ConfirmationDialog"

interface Props {
  readonly organization: Organization
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSave: (organization: OrganizationFormInput) => void
}

function OrganizationInfoForm({ loading = false, organization, onCancel, onSave }: Props) {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [showApplyPricingStrategyConfirm, setShowApplyPricingStrategyConfirm] =
    useState<boolean>(false)
  const [languageCode, setLanguageCode] = useState<LanguageCode | undefined | null>(
    organization.languageCode
  )
  const [allowBundleModsOnTxns, setAllowBundleModsOnTxns] = useState<boolean>(
    organization.allowBundleModsOnTxns ?? false
  )
  const [allowJobAssignmentOverlap, setAllowJobAssignmentOverlap] = useState<boolean>(
    organization.allowJobAssignmentOverlap ?? false
  )
  const [bccJobEmailToOrganizationEmail, setBccJobEmailToOrganizationEmail] = useState<boolean>(
    organization.bccJobEmailToOrganizationEmail ?? false
  )
  const [defaultPricingStrategy, setDefaultPricingStrategy] = useState<PricingStrategy>(
    organization.defaultPricingStrategySettings?.defaultPricingStrategy ?? PricingStrategy.FIXED
  )
  const [defaultUnitSalePriceMarkup, setDefaultUnitSalePriceMarkup] = useState<
    string | null | undefined
  >(() => {
    if (organization.defaultPricingStrategySettings?.defaultUnitSalePriceMarkup) {
      return (
        organization.defaultPricingStrategySettings.defaultUnitSalePriceMarkup * 100
      ).toString()
    } else {
      return ""
    }
  })
  const [defaultEstimateSettings, setDefaultEstimateSettings] =
    useState<OrganizationDefaultEstimateSettings>(() => {
      const { __typename, ...settings } = organization.defaultEstimateSettings
      return settings
    })
  const [defaultInvoiceSettings, setDefaultInvoiceSettings] =
    useState<OrganizationDefaultInvoiceSettings>(() => {
      const { __typename, ...settings } = organization.defaultInvoiceSettings
      return settings
    })
  const [errors, setErrors] = useState<{
    languageCode?: string | null
    defaultUnitSalePriceMarkup?: string | null
  }>({
    languageCode: null, // required
    defaultUnitSalePriceMarkup: null,
  })

  const handleBlurLanguageCode = useCallback(() => {
    if (!languageCode) {
      setErrors({
        ...errors,
        languageCode: t("component.organizationDetails.validation.languageCode.required"),
      })
    }
  }, [errors, languageCode, t])

  const handleChangeLanguageCode = useCallback(
    (selection?: LanguageCode | null) => {
      setIsDirty(true)
      setLanguageCode(selection)
      setErrors({
        ...errors,
        languageCode: null,
      })
    },
    [errors]
  )

  const handleChangeDefaultUnitSalePriceMarkup = (event: {
    target: { name: string; value: string }
  }) => {
    const value = event.target.value
    setDefaultUnitSalePriceMarkup(value)
    setIsDirty(true)
    setErrors({
      ...errors,
      defaultUnitSalePriceMarkup:
        defaultPricingStrategy === PricingStrategy.DYNAMIC && !isNumeric(defaultUnitSalePriceMarkup)
          ? t(
              "page.organizationDetails.defaultPricingStrategy.validation.defaultUnitSalePriceMarkup.required"
            )
          : null,
    })
  }

  function validate(): boolean {
    const newErrors = {
      languageCode: !languageCode
        ? t("page.organizationDetails.defaultPricingStrategy.validation.languageCode.required")
        : null,
      defaultUnitSalePriceMarkup:
        defaultPricingStrategy === PricingStrategy.DYNAMIC && !isNumeric(defaultUnitSalePriceMarkup)
          ? t(
              "page.organizationDetails.defaultPricingStrategy.validation.defaultUnitSalePriceMarkup.required"
            )
          : null,
    }
    setErrors(newErrors)

    return !newErrors.languageCode && !newErrors.defaultUnitSalePriceMarkup
  }

  function hasPricingStrategyChanged(): boolean {
    return (
      defaultPricingStrategy !==
        organization.defaultPricingStrategySettings?.defaultPricingStrategy ||
      Number(defaultUnitSalePriceMarkup) !==
        organization.defaultPricingStrategySettings?.defaultUnitSalePriceMarkup * 100
    )
  }

  function onClickSave() {
    if (!validate()) {
      return
    }

    if (hasPricingStrategyChanged()) {
      setShowApplyPricingStrategyConfirm(true)
      return
    } else {
      handleSubmit(false)
    }
  }

  function handleSubmit(applyPricingStrategyNow = false) {
    onSave({
      id: organization.id,
      languageCode: languageCode ?? undefined,
      allowBundleModsOnTxns,
      allowJobAssignmentOverlap,
      bccJobEmailToOrganizationEmail,
      defaultEstimateSettings,
      defaultInvoiceSettings,
      defaultPricingStrategySettings: {
        defaultPricingStrategy,
        defaultUnitSalePriceMarkup:
          defaultPricingStrategy === PricingStrategy.DYNAMIC && defaultUnitSalePriceMarkup
            ? asFloat(defaultUnitSalePriceMarkup) / 100
            : undefined,
        applyImmediately: applyPricingStrategyNow,
      },
    })
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <Box>
      <Box sx={classes.fieldContainer}>
        <LanguageSelect
          error={Boolean(errors.languageCode)}
          label={t("language") as string}
          name="language"
          onBlur={handleBlurLanguageCode}
          onChange={handleChangeLanguageCode}
          required
          value={languageCode}
        />
        {errors.languageCode ? (
          <FieldHelperText error message={errors.languageCode} />
        ) : (
          <FieldHelperText message={t("page.organizationDetails.helperText.languageCode")} />
        )}
      </Box>
      {organization.level >= 2 ? (
        <>
          <Divider />
          <Box sx={classes.fieldContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowJobAssignmentOverlap}
                  name="allowJobAssignmentOverlap"
                  onChange={(e) => {
                    setAllowJobAssignmentOverlap(e.target.checked)
                    setIsDirty(true)
                  }}
                />
              }
              label={t("page.organizationDetails.allowJobAssignmentOverlap")}
            />
            <FieldHelperText
              message={t("page.organizationDetails.helperText.allowJobAssignmentOverlap")}
            />
          </Box>
          <Divider />
          <Box sx={classes.fieldContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowBundleModsOnTxns}
                  name="allowBundleModsOnTxns"
                  onChange={(e) => {
                    setAllowBundleModsOnTxns(e.target.checked)
                    setIsDirty(true)
                  }}
                />
              }
              label={t("page.organizationDetails.allowBundleModsOnTxns")}
            />
            <FieldHelperText
              message={t("page.organizationDetails.helperText.allowBundleModsOnTxns")}
            />
          </Box>
          <Divider />
          <Box sx={classes.fieldContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={bccJobEmailToOrganizationEmail}
                  name="bccJobEmailToOrganizationEmail"
                  onChange={(e) => {
                    setBccJobEmailToOrganizationEmail(e.target.checked)
                    setIsDirty(true)
                  }}
                />
              }
              label={t("page.organizationDetails.bccJobEmailToOrganizationEmail")}
            />
            <FieldHelperText
              message={t("page.organizationDetails.helperText.bccJobEmailToOrganizationEmail")}
            />
          </Box>
          <Divider />
          <Box sx={classes.fieldContainer}>
            <FormLabel
              id="default-pricing-strategy-label"
              sx={{
                fontSize: "0.875rem",
                fontWeight: 700,
                color: (theme) => theme.fielderColors.black,
              }}
            >
              {t("page.organizationDetails.defaultPricingStrategy.label")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="default-pricing-strategy-label"
              defaultValue="dynamic"
              name="default-pricing-strategy-group"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = (event.target as HTMLInputElement).value
                setDefaultPricingStrategy(value)
                setIsDirty(true)
              }}
              value={defaultPricingStrategy}
            >
              <FormControlLabel
                control={<Radio />}
                label={
                  <RadioOption
                    description={
                      t(
                        "page.organizationDetails.defaultPricingStrategy.options.DYNAMIC.description"
                      ) as string
                    }
                    label={t(
                      "page.organizationDetails.defaultPricingStrategy.options.DYNAMIC.label"
                    )}
                    style={{ maxWidth: "100%" }}
                  />
                }
                value={PricingStrategy.DYNAMIC}
              />
              <Collapse in={defaultPricingStrategy === PricingStrategy.DYNAMIC}>
                <NestedFieldContainer>
                  <IntegerInput
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PercentIcon sx={{ fontSize: "1rem" }} />
                        </InputAdornment>
                      ),
                    }}
                    data-testid="unitSalePriceMarkup-Field"
                    error={!!errors.defaultUnitSalePriceMarkup}
                    fullWidth
                    id="unitSalePriceMarkup"
                    inputProps={{
                      maxLength: 16,
                    }}
                    label={t("markup")}
                    name="unitSalePriceMarkup"
                    onChange={handleChangeDefaultUnitSalePriceMarkup}
                    required={defaultPricingStrategy === PricingStrategy.DYNAMIC}
                    style={{ maxWidth: "8rem" }}
                    value={defaultUnitSalePriceMarkup}
                  />
                  {!errors.defaultUnitSalePriceMarkup ? (
                    <FieldHelperText
                      message={t("component.itemBasicInfoForm.helperText.unitSalePriceMarkup")}
                    />
                  ) : (
                    <FieldHelperText error message={t(errors.defaultUnitSalePriceMarkup)} />
                  )}
                </NestedFieldContainer>
              </Collapse>
              <FormControlLabel
                control={<Radio />}
                label={
                  <RadioOption
                    description={
                      t(
                        "component.itemBasicInfoForm.unitSalePriceTypeOption.fixed.description"
                      ) as string
                    }
                    label={t("component.itemBasicInfoForm.unitSalePriceTypeOption.fixed.label")}
                    style={{ maxWidth: "100%" }}
                  />
                }
                value={PricingStrategy.FIXED}
              />
              <FormControlLabel
                control={<Radio />}
                label={
                  <RadioOption
                    description={
                      t(
                        "component.itemBasicInfoForm.unitSalePriceTypeOption.msrp.description"
                      ) as string
                    }
                    label={t("component.itemBasicInfoForm.unitSalePriceTypeOption.msrp.label")}
                    style={{ maxWidth: "100%" }}
                  />
                }
                value={PricingStrategy.MSRP}
              />
            </RadioGroup>
          </Box>
          <Divider />
          <Box sx={classes.fieldContainer}>
            <DefaultEstimateSettingsForm
              defaultEstimateSettings={defaultEstimateSettings}
              onChange={setDefaultEstimateSettings}
            />
          </Box>
          <Divider />
          <Box sx={classes.fieldContainer}>
            <DefaultInvoiceSettingsForm
              defaultInvoiceSettings={defaultInvoiceSettings}
              onChange={setDefaultInvoiceSettings}
            />
          </Box>
        </>
      ) : null}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3rem",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SaveButton loading={loading} onClick={onClickSave} />
      </Box>
      <ConfirmationDialog
        description={t(
          "page.organizationDetails.defaultPricingStrategy.confirmApplyPricingStrategy.message"
        )}
        id="confirm-apply-pricing-strategy"
        isLoading={false}
        negativeButtonTitle={t("no")}
        onCancel={() => {
          handleSubmit(false)
        }}
        onConfirm={() => {
          handleSubmit(true)
        }}
        open={showApplyPricingStrategyConfirm}
        positiveButtonTitle={t("yes")}
        title={t(
          "page.organizationDetails.defaultPricingStrategy.confirmApplyPricingStrategy.title"
        )}
        titleBackgroundColor="#ffffff"
      />
    </Box>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
    paddingTop: "0.5rem",
  },
} as const

function NestedFieldContainer({ children }) {
  return (
    <Box
      sx={{
        margin: "0.5rem 2.75rem",
      }}
    >
      {children}
    </Box>
  )
}

export default OrganizationInfoForm
