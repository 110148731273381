/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState } from "react"
import Cookies from "js-cookie"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import ImageIcon from "@mui/icons-material/Image"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"

import { ONE_MEGABYTE } from "~/util"
import { DefaultPermission, Item, OrganizationItem } from "~/types"
import { useAuth } from "~/context/AuthContext"

/**
 * This component can handle uploading an image for both franchisor and franchisee items.
 */

interface ItemImageManagerProps {
  readonly item?: OrganizationItem | Item
  readonly disabled?: boolean
  readonly onUploadComplete?: () => void
}

function ItemImageManager({ item, disabled, onUploadComplete }: ItemImageManagerProps) {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const [imageObject, setImageObject] = useState(() => {
    return item?.attachments
      ?.map((a) => ({
        id: a.id,
        contentType: a.contentType,
        objectName: a.objectName,
        name: a.name,
        signedUrl: a.signedUrl,
        createdAt: a.createdAt,
      }))
      ?.sort((a, b) => {
        return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
      })?.[0]
  })
  const isEditable =
    (item?.isTopLevel && hasPermissions?.([DefaultPermission.UpdateItem])) ||
    (!item?.isTopLevel && hasPermissions?.([DefaultPermission.UpdateOrganizationItem]))

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/webp": [".webp"],
    },
    disabled: Boolean(disabled),
    minSize: 0,
    maxSize: ONE_MEGABYTE * 25,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const newImage = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )?.[0]
      uploadFile(newImage)
    },
  })

  const uploadFile = async (file: File) => {
    if (!item?.id) {
      return
    }
    setIsFileUploading(true)
    const data = new FormData()
    data.append("file", file)
    return fetch(`${process.env.GATSBY_API_SERVER_URL}/attachment/item/${item.id}/attachments`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.attachment) {
          setImageObject(json.attachment)
        } else {
          setImageObject(undefined)
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err)
      })
      .finally(() => {
        setIsFileUploading(false)
        onUploadComplete?.()
      })
  }

  function renderImage() {
    if (!item?.id) {
      return (
        <Box sx={classes.imagePlaceholder}>
          <ImageIcon sx={classes.imagePlaceholderIcon} />
        </Box>
      )
    } else if (isDragActive) {
      return <span>{t("component.fileSelector.dropFilesHere")}</span>
    } else if (isFileUploading) {
      return (
        <Box
          sx={{
            width: "9.375rem",
            height: "9.375rem",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )
    } else if (imageObject?.signedUrl) {
      return (
        <img
          src={imageObject.signedUrl}
          style={{
            width: "auto",
            maxWidth: "9.375rem",
            maxHeight: "18.75rem",
          }}
        />
      )
    } else {
      if (isEditable) {
        return (
          <Box sx={classes.imagePlaceholder}>
            <AddAPhotoIcon sx={classes.imagePlaceholderIcon} />
          </Box>
        )
      } else {
        return (
          <Box sx={classes.imagePlaceholder}>
            <ImageIcon sx={classes.imagePlaceholderIcon} />
          </Box>
        )
      }
    }
  }

  return (
    <>
      {/* Can't upload an image without an item ID */}
      {item?.id ? (
        <Box
          sx={(theme) => ({
            marginTop: "0.3125rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            [theme.breakpoints.up("md")]: {
              maxWidth: "200px",
            },
          })}
        >
          <div
            {...getRootProps({
              css: [classes.dropzone, { cursor: item.isTopLevel ? "default" : "pointer" }],
            })}
            id="image-container"
          >
            <div
              style={{
                fontSize: "1rem",
                marginBottom: "1rem",
              }}
            >
              {renderImage()}
            </div>
            {isEditable ? (
              <>
                <div
                  css={{
                    marginTop: "0.625rem",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {imageObject ? t("changeImage") : t("addImage")}
                </div>
                <input {...getInputProps()} />
              </>
            ) : null}
          </div>
        </Box>
      ) : null}
    </>
  )
}

const classes = {
  dropzone: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  imagePlaceholder: {
    width: "9.375rem",
    height: "9.375rem",
    borderRadius: "50%",
    backgroundColor: "#efefef",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#333",
  },
  imagePlaceholderIcon: {
    fontSize: "5rem",
    color: "#747474",
  },
} as const

export default ItemImageManager
