import React, { useRef } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import { useTranslation } from "react-i18next"
import useGoogleMaps from "../util/useGoogleMaps"
import { Coordinate, MapMarker } from "../types/appTypes"

const defaultStyle = {
  padding: "1.25rem",
  height: "100%",
  color: "#fefefe",
  backgroundColor: "#3f3f3f",
  textAlign: "center",
  border: "1px solid #212121",
  borderRadius: "4px",
}

export const DEFAULT_COORDINATE: Coordinate = {
  lat: 47.116386,
  lng: -101.299591,
}

interface CustomButton {
  title: string
  onClick: () => void
}

interface GoogleMapViewProps {
  readonly animate?: boolean
  readonly center?: Coordinate
  readonly customButton?: CustomButton
  readonly markers?: MapMarker[]
  readonly style?: React.CSSProperties
  readonly zoom?: number
}

function GoogleMapView({
  animate = true,
  center = DEFAULT_COORDINATE,
  customButton,
  markers,
  style,
  zoom = 4,
}: GoogleMapViewProps) {
  const googleMapsApi = useGoogleMaps()
  const mapContainerRef = useRef(null)
  const mapRef = useRef<typeof googleMapsApi.maps.Map>(null)
  const markerListRef = useRef<(typeof googleMapsApi.maps.Marker)[]>([])
  const { t } = useTranslation()
  const styles = { ...style } || defaultStyle

  useDeepCompareEffect(() => {
    if (googleMapsApi) {
      if (!mapRef.current) {
        mapRef.current = new googleMapsApi.maps.Map(mapContainerRef.current, {
          center: center,
          zoom: zoom,
        })

        if (customButton) {
          const controlUI = document.createElement("button")
          controlUI.style.backgroundColor = "#fff"
          controlUI.style.border = "0px"
          controlUI.style.margin = "10px"
          controlUI.style.padding = "0px"
          controlUI.style.textTransform = "none"
          controlUI.style.appearance = "none"
          controlUI.style.cursor = "pointer"
          controlUI.style.userSelect = "none"
          controlUI.style.borderRadius = "2px"
          controlUI.style.height = "40px"
          controlUI.style.width = "40px"
          controlUI.style.boxShadow = "rgb(0 0 0 / 30%) 0px 1px 4px -1px"
          controlUI.style.overflow = "hidden"
          controlUI.title = customButton.title
          const refreshIcon = document.createElement("img")
          refreshIcon.src = "/icons/refresh-icon.png"
          refreshIcon.style.transitionDuration = "1s"
          refreshIcon.style.width = "20px"
          refreshIcon.style.height = "auto"
          controlUI.appendChild(refreshIcon)
          let numClicks = 0
          controlUI.addEventListener("click", () => {
            refreshIcon.style.transform = `rotate(${360 * ++numClicks}deg)`
            customButton.onClick?.()
          })
          mapRef.current?.controls[googleMapsApi.maps.ControlPosition.TOP_RIGHT].push(controlUI)
        }
      }

      mapRef.current?.setOptions({
        center: center,
        zoom: zoom,
      })

      const infoWindow = new googleMapsApi.maps.InfoWindow({
        content: "",
        maxWidth: 400,
      })

      // remove all the old markers before drawing new ones
      markerListRef.current.forEach((m) => m.setMap(null))
      const bounds = new googleMapsApi.maps.LatLngBounds()

      markers
        ?.filter(
          (m) => !!m && Boolean(m.lat) && Boolean(m.lng) && Boolean(m.markerType) && Boolean(m.icon)
        )
        .forEach((marker) => {
          const mapMarker = new googleMapsApi.maps.Marker({
            position: {
              lat: marker.lat,
              lng: marker.lng,
            },
            animation: animate ? googleMapsApi.maps.Animation.DROP : undefined,
            icon: marker.icon,
            title: marker.infoWindow?.title ?? "",
            map: mapRef.current,
          })

          if (marker.infoWindow) {
            mapMarker.addListener("click", () => {
              infoWindow.close()
              infoWindow.setContent(marker.infoWindow?.content ?? "")
              infoWindow.open({
                anchor: mapMarker,
                map: mapRef.current,
                shouldFocus: true,
              })
            })
          }
          markerListRef.current.push(mapMarker)
          bounds.extend(mapMarker.getPosition())
        })

      if (markers && markers.length > 1) {
        mapRef.current.fitBounds(bounds)
      }
    }
  }, [googleMapsApi, center, zoom, markers])

  return (
    <div id="map" ref={mapContainerRef} style={styles}>
      {t("loadingMap")}
    </div>
  )
}

export default GoogleMapView
