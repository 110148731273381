import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import FielderTextField from "../../../../components/FielderTextField"
import FieldHelperText from "../../../../components/FieldHelperText"
import { isBlank } from "../../../../util"
import { JobCategory } from "../../../../types"
import SaveButton from "../../../../components/SaveButton"

interface JobCategoryDialogProps {
  category?: JobCategory
  loading?: boolean
  onCancel: () => void
  onSave: (categoryName: string) => void
  open: boolean
}

function JobCategoryDialog({ category, loading, onCancel, onSave, open }: JobCategoryDialogProps) {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(() => category?.name || "")
  const [error, setError] = useState()

  return (
    <Dialog
      aria-labelledby="edit-category-dialog-title"
      data-testid="CategoryDialog"
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle
        id="edit-category-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {category?.id ? t("editCategory") : t("addCategory")}
      </DialogTitle>
      <DialogContent>
        <Box sx={classes.dialogContent}>
          <FielderTextField
            error={!!error}
            fullWidth
            id="name"
            inputProps={{ maxLength: 255 }}
            label={t("categoryName")}
            name="name"
            onBlur={() => {
              if (isBlank(name)) {
                setError(t("component.jobCategoryDialog.validation.name.required"))
              } else {
                setError(undefined)
              }
            }}
            onChange={(e) => {
              setName(e.target.value)
              if (isBlank(e.target.value)) {
                setError(t("component.jobCategoryDialog.validation.name.required"))
              } else {
                setError(undefined)
              }
            }}
            onFocus={(e: any) => e.target.select()}
            required
            value={name}
            variant="filled"
          />
          {!error ? <FieldHelperText /> : <FieldHelperText error message={error} />}
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          disabled={loading}
          onClick={() => {
            onCancel?.()
          }}
          style={{ border: "1px solid #ccc", minWidth: "80px" }}
        >
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={isBlank(name)}
          loading={loading}
          onClick={() => {
            onSave?.(name)
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  dialogContent: {
    backgroundColor: "#fff",
    paddingBottom: "1rem",
    paddingTop: "1rem",
  },
  dialogActions: {
    px: "1.5rem",
    py: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
} as const

export default JobCategoryDialog
