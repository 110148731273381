import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import PercentIcon from "@mui/icons-material/Percent"
import InputAdornment from "@mui/material/InputAdornment"
import Divider from "@mui/material/Divider"
import Checkbox from "@mui/material/Checkbox"

import FieldHelperText from "~/components/FieldHelperText"
import MoneyInput from "~/components/MoneyInput"
import PositiveIntegerInput from "~/components/PositiveIntegerInput"
import { ItemFormContext } from "./ItemFormContext"
import { IntegerInput } from "~/components"
import { NOT_SPECIFIED, asFloat, formatMoney, isNumeric } from "~/util"
import StaticField from "~/components/StaticField"
import RadioOption from "~/components/RadioOption"
import { OrganizationPlugin, PricingStrategy } from "~/types"

function NestedFieldContainer({ children }) {
  return (
    <Box
      sx={{
        margin: "0.5rem 2.75rem",
        maxWidth: "25rem",
      }}
    >
      {children}
    </Box>
  )
}

interface Props {
  readonly currencyCode: string
  readonly accountingPlugin?: OrganizationPlugin
}

function ProductDetailsForm({ currencyCode, accountingPlugin }: Props) {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(ItemFormContext)
  const [status, setStatus] = useState<string>(() => {
    return state.isActive ? "active" : "inactive"
  })
  const [pricingStrategy, setPricingStrategy] = useState<PricingStrategy>(() => {
    return state.pricingStrategy
  })

  const dynamicUnitSalePrice = useMemo(() => {
    if (pricingStrategy !== PricingStrategy.DYNAMIC) {
      return null
    }

    const wholesalePrice = isNumeric(state.wholesalePrice.value)
      ? asFloat(String(state.wholesalePrice.value))
      : null
    const averagePurchaseCost = isNumeric(state.organizationItem.averagePurchaseCost)
      ? asFloat(state.organizationItem.averagePurchaseCost)
      : null

    if (wholesalePrice === null && averagePurchaseCost === null) {
      return null
    }

    let costBasis = wholesalePrice
    if (costBasis === null || (averagePurchaseCost !== null && costBasis < averagePurchaseCost)) {
      costBasis = averagePurchaseCost
    }

    const markup = asFloat(String(state.unitSalePriceMarkup.value)) ?? 0

    if (costBasis === null) {
      return null
    } else {
      return costBasis + costBasis * (markup / 100)
    }
  }, [
    pricingStrategy,
    state.wholesalePrice.value,
    state.organizationItem.averagePurchaseCost,
    state.unitSalePriceMarkup.value,
  ])

  function renderIsTrackedToggler() {
    if (accountingPlugin?.supportsInventoryTracking) {
      if (!state.isTracked) {
        return (
          <Box sx={{ marginBottom: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Box sx={{ fontWeight: "600", fontSize: "0.875rem" }}>
                {t("component.itemBasicInfoForm.itemNotTracked.title")}
              </Box>
              <Box sx={{ fontWeight: "normal", fontSize: "0.75rem" }}>
                {t("component.itemBasicInfoForm.itemNotTracked.description", {
                  pluginProvider: accountingPlugin?.pluginProvider?.name,
                })}
              </Box>
            </Box>
            <Divider />
          </Box>
        )
      } else {
        // there is an accounting plugin that supports inventory tracking, and the item is tracked
        // There generally isn't anything to do or say in this situation.
        return null
      }
    } else {
      // There is no accounting plugin that supports inventory tracking. Show the toggle.
      // Note that there may be an accounting plugin, but it doesn't support inventory tracking. In that case,
      // we want Fielder users to be able to track inventory in Fielder if they want to.
      return (
        <Box
          sx={{
            marginTop: "1rem",
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: "1.5rem",
            "& .MuiFormControlLabel-label": {
              fontSize: "0.875rem",
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.isTracked}
                disabled={accountingPlugin?.supportsInventoryTracking}
                name="isTracked"
                onChange={(event, checked) => {
                  dispatch({
                    type: "SET_FIELD",
                    payload: { fieldName: "isTracked", value: checked },
                  })
                }}
              />
            }
            label={
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                <Box sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                  {t("component.itemBasicInfoForm.isTracked.label") as string}
                </Box>
                <FieldHelperText
                  message={t("component.itemBasicInfoForm.isTracked.helperText")}
                  style={{ marginLeft: 0 }}
                />
              </Box>
            }
          />
        </Box>
      )
    }
  }

  function renderQtyOnHand() {
    if (state.isTracked) {
      if (accountingPlugin?.supportsInventoryTracking) {
        return (
          <Box sx={{ padding: 0, marginBottom: "1.5rem" }}>
            <StaticField
              label={t("quantityOnHand")}
              showBottomDivider={false}
              style={{ padding: 0 }}
              value={state.qtyOnHand.value}
            />
            <Box
              sx={{
                fontStyle: "italic",
                fontSize: "0.7rem",
                color: (theme) => theme.fielderColors.mutedText,
              }}
            >
              {t(
                `component.itemBasicInfoForm.helperText.qtyOnHandManagedBy.${accountingPlugin?.pluginProvider?.id}`
              )}
            </Box>
          </Box>
        )
      } else {
        return (
          <Box sx={[classes.fieldContainer, { marginTop: "1.5rem" }]}>
            <PositiveIntegerInput
              data-testid="qtyOnHand-Field"
              error={!!state.qtyOnHand.error}
              fullWidth
              id="qtyOnHand"
              inputProps={{
                maxLength: 12,
              }}
              label={t("quantityOnHand")}
              name="qtyOnHand"
              onChange={(e) => {
                dispatch({
                  type: "SET_FIELD",
                  payload: { fieldName: "qtyOnHand", value: e.target.value },
                })
              }}
              value={state.qtyOnHand.value}
            />
            {!(state.qtyOnHand.error && state.qtyOnHand.touched) ? (
              <FieldHelperText message={t("component.itemBasicInfoForm.helperText.qtyOnHand")} />
            ) : (
              <FieldHelperText error message={t(state.qtyOnHand.error)} />
            )}
          </Box>
        )
      }
    } else {
      // This item is not tracked, so don't show the qtyOnHand field
      return null
    }
  }

  return (
    <>
      {!state.organizationItem.isTopLevel ? (
        <>
          <Box sx={classes.fieldContainer}>
            <PositiveIntegerInput
              data-testid="palletQty-Field"
              fullWidth
              label={t("palletQty") as string}
              max={9999999}
              name="palletQty"
              onChange={(e: any) => {
                dispatch({
                  type: "SET_FIELD",
                  payload: { fieldName: "palletQty", value: e.target.value },
                })
              }}
              value={state.palletQty.value}
            />
            <FieldHelperText message={t("component.itemBasicInfoForm.helperText.palletQty")} />
          </Box>
          <Box sx={classes.fieldContainer}>
            <PositiveIntegerInput
              data-testid="packSize-Field"
              fullWidth
              label={t("packSize") as string}
              max={9999999}
              name="packSize"
              onChange={(e: any) => {
                dispatch({
                  type: "SET_FIELD",
                  payload: { fieldName: "packSize", value: e.target.value },
                })
              }}
              value={state.packSize.value}
            />
            <FieldHelperText message={t("component.itemBasicInfoForm.helperText.packSize")} />
          </Box>
          <Box sx={classes.fieldContainer}>
            <MoneyInput
              currencyCode={state.region?.currencyCode}
              data-testid="msrp-Field"
              fullWidth
              label={t("msrp") as string}
              max={999999999}
              name="msrp"
              onChange={(e) => {
                dispatch({
                  type: "SET_FIELD",
                  payload: { fieldName: "msrp", value: e.target.value },
                })
              }}
              value={state.msrp.value}
            />
            <FieldHelperText
              message={t("component.itemBasicInfoForm.helperText.suggestedRetailPrice")}
            />
          </Box>
          <Box sx={classes.fieldContainer}>
            <MoneyInput
              currencyCode={state.region?.currencyCode}
              data-testid="purchaseCost-Field"
              fullWidth
              label={t("purchaseCost") as string}
              max={999999999}
              name="wholesalePrice"
              onChange={(e) => {
                dispatch({
                  type: "SET_FIELD",
                  payload: { fieldName: "wholesalePrice", value: e.target.value },
                })
              }}
              value={state.wholesalePrice.value}
            />
            <FieldHelperText message={t("component.itemBasicInfoForm.helperText.purchaseCost")} />
          </Box>
        </>
      ) : null}
      <Box sx={{ marginBottom: "1.25rem" }}>
        <FormLabel
          id="status-group-label"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 700,
            color: (theme) => theme.fielderColors.black,
          }}
        >
          {t("status")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="status-group-label"
          defaultValue="active"
          name="status-group"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value
            setStatus(value)
            dispatch({
              type: "SET_FIELD",
              payload: {
                fieldName: "isActive",
                value: Boolean(value === "active"),
              },
            })
          }}
          value={status}
        >
          <FormControlLabel
            control={<Radio />}
            label={
              <RadioOption
                description={t("component.itemBasicInfoForm.status.active.description") as string}
                label={t("component.itemBasicInfoForm.status.active.label")}
              />
            }
            value="active"
          />
          <FormControlLabel
            control={<Radio />}
            label={
              <RadioOption
                description={t("component.itemBasicInfoForm.status.inactive.description") as string}
                label={t("component.itemBasicInfoForm.status.inactive.label")}
              />
            }
            value="inactive"
          />
        </RadioGroup>
      </Box>
      <Divider />
      <Box sx={[classes.fieldContainer, { marginTop: "1.25rem" }]}>
        <FormLabel
          id="unit-sales-price-group-label"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 700,
            color: (theme) => theme.fielderColors.black,
          }}
        >
          {t("component.itemBasicInfoForm.unitSalePriceTypeLabel")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="unit-sales-price-group-label"
          defaultValue="dynamic"
          name="unit-sales-price-group"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value
            setPricingStrategy(value)
            dispatch({
              type: "SET_FIELD",
              payload: {
                fieldName: "pricingStrategy",
                value: value,
              },
            })
          }}
          value={pricingStrategy}
        >
          <FormControlLabel
            control={<Radio />}
            label={
              <RadioOption
                description={
                  t(
                    "component.itemBasicInfoForm.unitSalePriceTypeOption.dynamic.description"
                  ) as string
                }
                label={t("component.itemBasicInfoForm.unitSalePriceTypeOption.dynamic.label")}
              />
            }
            value={PricingStrategy.DYNAMIC}
          />
          <Collapse in={pricingStrategy === PricingStrategy.DYNAMIC}>
            <NestedFieldContainer>
              <IntegerInput
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon sx={{ fontSize: "1rem" }} />
                    </InputAdornment>
                  ),
                }}
                data-testid="unitSalePriceMarkup-Field"
                error={!!state.unitSalePriceMarkup.error}
                fullWidth
                id="unitSalePriceMarkup"
                inputProps={{
                  maxLength: 16,
                }}
                label={t("markup")}
                name="unitSalePriceMarkup"
                onChange={(e) => {
                  dispatch({
                    type: "SET_FIELD",
                    payload: { fieldName: "unitSalePriceMarkup", value: e.target.value },
                  })
                }}
                value={state.unitSalePriceMarkup.value}
              />
              {!(state.unitSalePriceMarkup.error && state.unitSalePriceMarkup.touched) ? (
                <FieldHelperText
                  message={t("component.itemBasicInfoForm.helperText.unitSalePriceMarkup")}
                />
              ) : (
                <FieldHelperText error message={t(state.unitSalePriceMarkup.error)} />
              )}
            </NestedFieldContainer>
            <Box sx={{ marginLeft: "3rem" }}>
              <StaticField
                label={t("unitSalePrice")}
                showBottomDivider={false}
                value={
                  dynamicUnitSalePrice
                    ? formatMoney(currencyCode, dynamicUnitSalePrice)
                    : NOT_SPECIFIED
                }
              />
            </Box>
          </Collapse>
          <FormControlLabel
            control={<Radio />}
            label={
              <RadioOption
                description={
                  t(
                    "component.itemBasicInfoForm.unitSalePriceTypeOption.fixed.description"
                  ) as string
                }
                label={t("component.itemBasicInfoForm.unitSalePriceTypeOption.fixed.label")}
              />
            }
            value={PricingStrategy.FIXED}
          />
          <Collapse in={pricingStrategy === PricingStrategy.FIXED}>
            <NestedFieldContainer>
              <MoneyInput
                currencyCode={currencyCode}
                data-testid="unitSalePrice-Field"
                error={!!state.unitSalePrice.error}
                fullWidth
                id="unitSalePrice"
                label={t("unitSalePrice")}
                max={99999999}
                name="unitSalePrice"
                onChange={(e) => {
                  dispatch({
                    type: "SET_FIELD",
                    payload: { fieldName: "unitSalePrice", value: e.target.value },
                  })
                }}
                value={state.unitSalePrice.value}
              />
              {!(state.unitSalePrice.error && state.unitSalePrice.touched) ? (
                <FieldHelperText
                  message={t("component.itemBasicInfoForm.helperText.unitSalePrice")}
                />
              ) : (
                <FieldHelperText error message={t(state.unitSalePrice.error)} />
              )}
            </NestedFieldContainer>
          </Collapse>
          {state.organizationItem?.msrp ? (
            <FormControlLabel
              control={<Radio />}
              label={
                <RadioOption
                  description={
                    t(
                      "component.itemBasicInfoForm.unitSalePriceTypeOption.msrp.description"
                    ) as string
                  }
                  label={t("component.itemBasicInfoForm.unitSalePriceTypeOption.msrp.label")}
                />
              }
              value={PricingStrategy.MSRP}
            />
          ) : null}
        </RadioGroup>
      </Box>
      <Divider />
      {renderIsTrackedToggler()}
      {renderQtyOnHand()}
      {state.isTracked ? (
        <Box sx={classes.fieldContainer}>
          <PositiveIntegerInput
            data-testid="reorderPoint-Field"
            error={!!state.reorderPoint.error}
            fullWidth
            id="reorderPoint"
            inputProps={{
              maxLength: 12,
            }}
            label={t("reorderPoint")}
            name="reorderPoint"
            onChange={(e) => {
              dispatch({
                type: "SET_FIELD",
                payload: { fieldName: "reorderPoint", value: e.target.value },
              })
            }}
            value={state.reorderPoint.value}
          />
          {!(state.reorderPoint.error && state.reorderPoint.touched) ? (
            <FieldHelperText message={t("component.itemBasicInfoForm.helperText.reorderPoint")} />
          ) : (
            <FieldHelperText error message={t(state.reorderPoint.error)} />
          )}
        </Box>
      ) : null}
      <Box sx={classes.fieldContainer}>
        <PositiveIntegerInput
          data-testid="reorderQty-Field"
          error={!!state.reorderQty.error}
          fullWidth
          id="reorderQty"
          inputProps={{
            maxLength: 12,
          }}
          label={t("reorderQty")}
          name="reorderQty"
          onBlur={() => {
            const qty = Number(state.reorderQty.value)
            const packSize = Number(state.organizationItem.packSize ?? 1)
            if (isNumeric(qty) && packSize > 1) {
              // round up qty to the nearest multiple of packSize
              dispatch({
                type: "SET_FIELD",
                payload: {
                  fieldName: "reorderQty",
                  value: String(Math.ceil(qty / packSize) * packSize),
                },
              })
            }
          }}
          onChange={(e) => {
            const qty = e.target.value ?? ""
            dispatch({
              type: "SET_FIELD",
              payload: { fieldName: "reorderQty", value: String(qty) },
            })
          }}
          value={state.reorderQty.value}
        />
        {!(state.reorderQty.error && state.reorderQty.touched) ? (
          <FieldHelperText message={t("component.itemBasicInfoForm.helperText.reorderQty")} />
        ) : (
          <FieldHelperText error message={t(state.reorderQty.error)} />
        )}
      </Box>
    </>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default ProductDetailsForm
