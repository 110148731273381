import React from "react"
import { t } from "i18next"
import { Paper, Box, Divider } from "@mui/material"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import PhoneIcon from "@mui/icons-material/PhoneOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import Button from "@mui/material/Button"

import { useAuth } from "~/context/AuthContext"
import UserStatusPill from "~/app/settings/users/components/UserStatusPill"
import { SectionHeader, SectionContent } from "~/components"
import StaticField from "~/components/StaticField"
import { OrganizationStatus, Organization, UserStatus } from "~/types"
import { NOT_SPECIFIED, formatPersonName, formatDate, formatPhoneNumber } from "~/util"
import ChangeOwnerNotice from "./ChangeOwnerNotice"
import { isDraft, isDraftOrPending, isPendingWithUnverifiedOwner } from "../utils/franchiseeUtils"

interface Props {
  readonly franchisee: Organization
  readonly onClickSendInvitation?: () => void
  readonly onClickSendPasswordResetCode?: () => void
  readonly sendingInvitation?: boolean
}

export default function OwnerInfoStatic({
  franchisee,
  onClickSendInvitation,
  onClickSendPasswordResetCode,
  sendingInvitation,
}: Props) {
  const { user } = useAuth()

  return (
    <Paper>
      <SectionHeader>
        <label>{t("ownerInfo")}</label>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Box>
            {franchisee.status !== OrganizationStatus.DRAFT && franchisee.owner?.status ? (
              <UserStatusPill status={franchisee.owner.status} />
            ) : null}
          </Box>
        </Box>
      </SectionHeader>
      <Divider />
      <SectionContent
        sx={(theme) => {
          return {
            [theme.breakpoints.up("md")]: {
              padding: "1.25rem 2rem",
            },
          }
        }}
      >
        <StaticField
          label={t("emailAddress")}
          showBottomDivider
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <MailOutlineIcon fontSize="inherit" style={{ marginRight: "0.625rem" }} />{" "}
                <Box
                  component="span"
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {franchisee.owner?.email ?? NOT_SPECIFIED}
                </Box>
              </Box>
              {isDraftOrPending(franchisee) &&
              (franchisee.owner?.status === UserStatus.UNVERIFIED ||
                franchisee.owner?.status === UserStatus.LOCKED_NEEDS_PASSWORD_CHANGE) ? (
                <Button
                  disabled={sendingInvitation}
                  onClick={() => {
                    if (franchisee.owner?.status === UserStatus.LOCKED_NEEDS_PASSWORD_CHANGE) {
                      onClickSendPasswordResetCode?.()
                    } else if (franchisee.owner?.status === UserStatus.UNVERIFIED) {
                      onClickSendInvitation?.()
                    }
                  }}
                  size="small"
                  variant="outlined"
                >
                  {sendingInvitation ? (
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  ) : (
                    <div>
                      {isDraft(franchisee)
                        ? t("component.franchiseeForm.sendInvitation")
                        : isPendingWithUnverifiedOwner(franchisee)
                          ? t("component.franchiseeForm.resendInvitation")
                          : t("component.franchiseeForm.sendPasswordResetCode")}
                    </div>
                  )}
                </Button>
              ) : null}
            </Box>
          }
        />
        <StaticField
          label={t("name")}
          showBottomDivider
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                {franchisee.owner?.firstName || franchisee.owner?.lastName
                  ? formatPersonName(franchisee.owner)
                  : NOT_SPECIFIED}
              </Box>
            </Box>
          }
        />
        <StaticField
          label={t("mobilePhone")}
          showBottomDivider={Boolean(franchisee.owner)}
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <PhoneIcon fontSize="inherit" style={{ marginRight: "0.625rem" }} />{" "}
              {franchisee?.owner?.mobilePhoneNumber
                ? formatPhoneNumber(franchisee.owner?.mobilePhoneNumber, false)
                : NOT_SPECIFIED}
            </Box>
          }
        />
        {franchisee.owner ? (
          <StaticField
            label={t("lastLogin")}
            showBottomDivider={false}
            value={
              franchisee.owner?.lastLogin
                ? formatDate(franchisee.owner.lastLogin, "lll", user?.organization?.timeZone)
                : NOT_SPECIFIED
            }
          />
        ) : null}
        {franchisee.status !== OrganizationStatus.DRAFT &&
        franchisee.status !== OrganizationStatus.PENDING ? (
          <ChangeOwnerNotice />
        ) : null}
      </SectionContent>
    </Paper>
  )
}
