import gql from "graphql-tag"
import HelicalPileInstallationWorksheetFragment from "./fragments/helicalPileInstallationWorksheet"

export const EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET_AND_LOAD_PDF = gql`
  mutation EditHelicalPileInstallationWorksheetAndLoadPDF(
    $jobId: ID!
    $type: HelicalPileInstallationWorksheetType
    $diagram: String
    $projectDescription: String
    $installerUserIds: [ID!]
    $installationEquipment: String
    $installationDate: LocalDateTime
    $lineItems: [HelicalPileInstallationWorksheetLineItemInput!]
  ) {
    editHelicalPileInstallationWorksheet(
      input: {
        jobId: $jobId
        type: $type
        projectDescription: $projectDescription
        installerUserIds: $installerUserIds
        installationEquipment: $installationEquipment
        installationDate: $installationDate
        diagram: $diagram
        lineItems: $lineItems
      }
    ) {
      worksheet {
        ...HelicalPileInstallationWorksheetFragment
        pdfFile
      }
    }
  }
  ${HelicalPileInstallationWorksheetFragment}
`

