import gql from "graphql-tag"
import ContactFragment from "./contact"

export default gql`
  fragment InvoiceFragment on Invoice {
    id
    number
    currencyCode
    billingContact {
      ...ContactFragment
    }
    documentUrl
    pdfFileName
    status
    description
    issuedDate
    netTerms
    dueDate
    customerNote
    subTotal
    totalDiscount
    discount {
      value
      type
    }
    taxSummary {
      total
    }
    total
    totalDiscount
    balanceDue
    dateSentToCustomer
  }
  ${ContactFragment}
`
