/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-literals */
import React, { useCallback, useEffect, useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, NavigateProps, useParams } from "react-router-dom"
import fetch from "isomorphic-fetch"
import { Helmet } from "react-helmet"
import dayjs, { type Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Typography from "@mui/material/Typography"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import FileSelector, { CSV, JPEG, PDF, PlainText, PNG } from "~/components/FileSelector"
import PhoneNumberInput from "~/components/CustomPhoneNumberInput"
import Seo from "~/components/Seo"
import { CREATE_ESTIMATE_REQUEST } from "~/queries/createEstimateRequest"
import AddressAutocompleteField from "~/components/AddressAutocompleteField"
import { Address, CustomerType } from "~/types"
import { Coordinate } from "~/components/GoogleMapView"
import { isBlank, isValidEmail } from "~/util"
import PublicAppBar from "~/components/PublicAppBar"

interface FormError {
  businessName: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null
  jobAddress: string | null
  description: string | null
  desiredCompletionDate: string | null
}

interface FormTouched {
  businessName: boolean
  firstName: boolean
  lastName: boolean
  email: boolean
  phoneNumber: boolean
  jobAddress: boolean
  description: boolean
  desiredCompletionDate: boolean
}

const uploadFile = async (estimateRequestId: string, file: File) => {
  const data = new FormData()
  data.append("file", file)
  return fetch(
    `${process.env.GATSBY_API_SERVER_URL}/attachment/estimate-request/${estimateRequestId}/attachments`,
    {
      method: "POST",
      body: data,
    }
  ).then((response) => response.json())
}

const uploadFiles = async (estimateRequestId: string, files: File[]) => {
  if (files.length > 0) {
    const promises = files.map((file) => uploadFile(estimateRequestId, file))
    return await Promise.all(promises)
  } else {
    return []
  }
}

function CreateEstimateRequest() {
  const { t } = useTranslation()
  const { organizationId } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [files, setFiles] = useState<File[]>([])
  const [showError, setShowError] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [geoPosition, setGeoPosition] = useState<Coordinate>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.INDIVIDUAL)
  const [businessName, setBusinessName] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [jobAddress, setJobAddress] = useState<Address>()
  const [description, setDescription] = useState<string>("")
  const [desiredCompletionDate, setDesiredCompletionDate] = useState<Dayjs | null>(null)
  const [touched, setTouched] = useState<FormTouched>(() => ({
    businessName: false,
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    jobAddress: false,
    description: false,
    desiredCompletionDate: false,
  }))
  const [errors, setErrors] = useState<FormError>(() => ({
    businessName: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    jobAddress: null,
    description: null,
    desiredCompletionDate: null,
  }))

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setGeoPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }, [])

  const [createEstimateRequest] = useMutation(CREATE_ESTIMATE_REQUEST, {
    onCompleted: (data) => {
      const {
        createEstimateRequest: {
          estimateRequest: { id },
        },
      } = data

      uploadFiles(id, files).then(() => {
        setRedirectTo({
          to: "/app/estimate-requests/confirmation",
          replace: true,
          state: {
            id,
          },
        })
      })
    },
    onError: (error) => {
      Sentry.captureException(error)
      setShowError(true)
      setIsSubmitting(false)
    },
  })

  const handleChangeCustomerType = useCallback((event, val) => {
    setIsDirty(true)
    setCustomerType(val)
  }, [])

  const handleBlurBusinessName = () => {
    setTouched((prev) => ({
      ...prev,
      businessName: true,
    }))

    if (customerType === CustomerType.BUSINESS && isBlank(businessName)) {
      setErrors((prev) => ({
        ...prev,
        businessName: t("page.estimateRequest.validation.businessName.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        businessName: null,
      }))
    }
  }

  const handleChangeBusinessName = useCallback((e) => {
    const value = e.target.value
    setBusinessName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      name: null,
    }))
  }, [])

  const handleBlurFirstName = () => {
    setTouched((prev) => ({
      ...prev,
      firstName: true,
    }))

    if (isBlank(firstName)) {
      setErrors((prev) => ({
        ...prev,
        firstName: t("page.estimateRequest.validation.firstName.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        firstName: null,
      }))
    }
  }

  const handleChangeFirstName = useCallback((e) => {
    const value = e.target.value
    setFirstName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      firstName: null,
    }))
  }, [])

  const handleBlurLastName = () => {
    setTouched((prev) => ({
      ...prev,
      lastName: true,
    }))

    if (isBlank(lastName)) {
      setErrors((prev) => ({
        ...prev,
        lastName: t("page.estimateRequest.validation.lastName.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        lastName: null,
      }))
    }
  }

  const handleChangeLastName = useCallback((e) => {
    const value = e.target.value
    setLastName(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      lastName: null,
    }))
  }, [])

  const handleBlurJobAddress = () => {
    setTouched((prev) => ({
      ...prev,
      jobAddress: true,
    }))

    if (!jobAddress) {
      setErrors((prev) => ({
        ...prev,
        jobAddress: t("page.estimateRequest.validation.jobAddress.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        jobAddress: null,
      }))
    }
  }

  const handleChangeJobAddress = useCallback((val) => {
    setIsDirty(true)
    setJobAddress(val)
  }, [])

  const handleBlurEmail = () => {
    setTouched((prev) => ({
      ...prev,
      email: true,
    }))
    if (isBlank(email) || !isValidEmail(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t("page.estimateRequest.validation.email.invalid"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        email: null,
      }))
    }
  }

  const handleChangeEmail = useCallback((e) => {
    const value = e.target.value
    setEmail(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      email: null,
    }))
  }, [])

  const handleBlurPhoneNumber = () => {
    setTouched((prev) => ({
      ...prev,
      phoneNumber: true,
    }))
  }

  const handleChangePhoneNumber = useCallback((event) => {
    const value = event.target.value
    setPhoneNumber(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      phoneNumber: null,
    }))
  }, [])

  const handleBlurDescription = () => {
    setTouched((prev) => ({
      ...prev,
      description: true,
    }))

    if (isBlank(description)) {
      setErrors((prev) => ({
        ...prev,
        description: t("page.estimateRequest.validation.description.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        description: null,
      }))
    }
  }

  const handleChangeDescription = useCallback((e) => {
    const value = e.target.value
    setDescription(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      description: null,
    }))
  }, [])

  const handleChangeDesiredCompletionDate = useCallback((value) => {
    setDesiredCompletionDate(value)
    setIsDirty(true)
    setErrors((prev) => ({
      ...prev,
      desiredCompletionDate: null,
    }))
  }, [])

  function validate() {
    const validationErrors = {
      ...errors,
      businessName:
        customerType === CustomerType.BUSINESS && isBlank(businessName)
          ? t("page.estimateRequest.validation.businessName.required")
          : null,
      firstName: isBlank(firstName)
        ? t("page.estimateRequest.validation.firstName.required")
        : null,
      lastName: isBlank(lastName) ? t("page.estimateRequest.validation.lastName.required") : null,
      email: isBlank(email) ? t("page.estimateRequest.validation.email.invalid") : null,
      jobAddress: !jobAddress ? t("page.estimateRequest.validation.jobAddress.required") : null,
      description: isBlank(description)
        ? t("page.estimateRequest.validation.description.required")
        : null,
    }

    const issues = Object.values(validationErrors).filter((e) => !!e)

    setErrors(validationErrors)
    setTouched({
      businessName: true,
      firstName: true,
      lastName: true,
      email: true,
      phoneNumber: true,
      jobAddress: true,
      description: true,
      desiredCompletionDate: true,
    })

    return issues.length === 0
  }

  const handleSubmitRequest = () => {
    if (!validate()) {
      return
    }

    if (typeof window !== "undefined" && window.grecaptcha) {
      setIsSubmitting(true)
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(`${process.env.GATSBY_GOOGLE_RECAPTCHA_V3_KEY}`, {
            action: "estimate_request_page",
          })
          .then((gRecaptchaResponse: any) => {
            const variables = {
              address: jobAddress
                ? {
                    addressString: jobAddress.addressString,
                    latitude: jobAddress.latitude,
                    longitude: jobAddress.longitude,
                    streetNumber: jobAddress.streetNumber,
                    route: jobAddress.route,
                    locality: jobAddress.locality,
                    administrativeAreaLevel1: jobAddress.administrativeAreaLevel1,
                    administrativeAreaLevel2: jobAddress.administrativeAreaLevel2,
                    postalCode: jobAddress.postalCode,
                    country: jobAddress.country,
                  }
                : { addressString: "" },
              businessName,
              email,
              description,
              organizationId,
              firstName,
              lastName,
              phoneNumber,
              customerType,
              gRecaptchaResponse,
              desiredCompletionDate: desiredCompletionDate
                ? dayjs(desiredCompletionDate).startOf("day").toISOString()
                : null,
            }
            createEstimateRequest({ variables })
          })
      })
    }
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo
        description="Submit a request for an estimate"
        lang="en"
        title={t("page.estimateRequest.title")}
      />
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_GOOGLE_RECAPTCHA_V3_KEY}`}
        />
      </Helmet>
      <PublicAppBar />
      <Box
        component="main"
        sx={{
          width: "auto",
          maxWidth: "648px",
          margin: "0 auto",
          paddingBottom: "12rem",
        }}
      >
        {showError ? (
          <Box
            sx={{
              marginTop: "6.25rem",
              padding: "1.25rem",
              paddingTop: "6.75rem",
              paddingBottom: "6.75rem",
              textAlign: "center",
              fontWeight: 600,
              fontSize: "1.0625rem",
              color: "red",
              border: "2px solid red",
              borderRadius: "4px",
              backgroundColor: "#FF999922",
            }}
          >
            <h1>{t("error.general.title")}</h1>
            <h2>{t("error.general.message")}</h2>
          </Box>
        ) : null}
        {!organizationId && (
          <h1 style={{ textAlign: "center", marginTop: "10%" }}>Error: Missing orgId parameter</h1>
        )}
        {!showError && organizationId ? (
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "transparent",
              color: "#333",
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
              padding: "0.5rem",
            }}
          >
            <form>
              <Box
                sx={{
                  marginBottom: "1.25rem",
                }}
              >
                <h1>{t("page.estimateRequest.title")}</h1>
                <Box sx={classes.subTitle}>{t("page.estimateRequest.subTitle")}</Box>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" sx={classes.formControl}>
                    <FormLabel component="legend">
                      <Typography gutterBottom>
                        <strong>{t("page.estimateRequest.fields.customerType.label")}</strong>
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      aria-label="customerType"
                      name="customerType"
                      onChange={handleChangeCustomerType}
                      row
                      value={customerType}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        data-testid="customerType-INDIVIDUAL"
                        label={t("page.estimateRequest.fields.customerType.INDIVIDUAL")}
                        value={CustomerType.INDIVIDUAL}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        data-testid="customerType-business"
                        label={t("page.estimateRequest.fields.customerType.BUSINESS")}
                        value={CustomerType.BUSINESS}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid hidden={customerType === CustomerType.INDIVIDUAL} item xs={12}>
                  <FielderTextField
                    autoComplete="organization"
                    error={!!errors.businessName}
                    fullWidth
                    id="businessName"
                    inputProps={{ maxLength: 50, "data-testid": "businessName" }}
                    label={t("page.estimateRequest.fields.businessName")}
                    name="businessName"
                    onBlur={handleBlurBusinessName}
                    onChange={handleChangeBusinessName}
                    required={customerType === CustomerType.BUSINESS}
                    value={businessName}
                  />
                  {errors.businessName && touched.businessName ? (
                    <FieldHelperText error message={errors.businessName} />
                  ) : null}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FielderTextField
                    autoComplete="given-name"
                    error={!!errors.firstName}
                    fullWidth
                    id="firstName"
                    inputProps={{ maxLength: 50, "data-testid": "firstName" }}
                    label={t("firstName")}
                    name="firstName"
                    onBlur={handleBlurFirstName}
                    onChange={handleChangeFirstName}
                    required
                    value={firstName}
                  />
                  {errors.firstName && touched.firstName ? (
                    <FieldHelperText error message={errors.firstName} />
                  ) : null}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FielderTextField
                    autoComplete="family-name"
                    error={!!errors.lastName}
                    fullWidth
                    id="lastName"
                    inputProps={{ maxLength: 50, "data-testid": "lastName" }}
                    label={t("lastName")}
                    name="lastName"
                    onBlur={handleBlurLastName}
                    onChange={handleChangeLastName}
                    required
                    value={lastName}
                  />
                  {errors.lastName && touched.lastName ? (
                    <FieldHelperText error message={errors.lastName} />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <FielderTextField
                    autoComplete="email"
                    error={!!errors.email}
                    fullWidth
                    id="email"
                    inputProps={{ maxLength: 50, "data-testid": "email" }}
                    label={t("email")}
                    name="email"
                    onBlur={handleBlurEmail}
                    onChange={handleChangeEmail}
                    required
                    value={email}
                    variant="filled"
                  />
                  {errors.email && touched.email ? (
                    <FieldHelperText error message={errors.email} />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <PhoneNumberInput
                    label={t("phoneNumber")}
                    onBlur={handleBlurPhoneNumber}
                    onChange={handleChangePhoneNumber}
                    value={phoneNumber}
                  />
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <FieldHelperText error message={errors.phoneNumber} />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <AddressAutocompleteField
                    centerCoordinate={geoPosition}
                    defaultValue={jobAddress?.addressString}
                    hasError={!!errors.jobAddress}
                    id="jobAddress"
                    label={t("jobAddress") as string}
                    onBlur={handleBlurJobAddress}
                    onChange={handleChangeJobAddress}
                    required
                  />
                  {errors.jobAddress && touched.jobAddress ? (
                    <FieldHelperText error message={errors.jobAddress} />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <FielderTextField
                    error={!!errors.description}
                    fullWidth
                    id="description"
                    inputProps={{ maxLength: 500, "data-testid": "description" }}
                    label={t("page.estimateRequest.fields.description.label")}
                    maxRows="2"
                    minRows="2"
                    multiline
                    name="description"
                    onBlur={handleBlurDescription}
                    onChange={handleChangeDescription}
                    required
                    value={description}
                  />
                  {errors.description && touched.description ? (
                    <FieldHelperText error message={errors.description} />
                  ) : (
                    <FieldHelperText
                      message={t("page.estimateRequest.fields.description.helperText")}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    aria-label={t("desiredCompletionDate")}
                    disablePast
                    format={t("format:dateFormat.short") as string}
                    label={t("desiredCompletionDate")}
                    onChange={handleChangeDesiredCompletionDate}
                    slotProps={{
                      textField: {
                        InputProps: {
                          disableUnderline: true,
                        },
                        fullWidth: true,
                        required: false,
                      },
                    }}
                    slots={{
                      textField: FielderTextField,
                    }}
                    value={desiredCompletionDate}
                  />
                  {errors.desiredCompletionDate && touched.desiredCompletionDate ? (
                    <FieldHelperText error message={errors.desiredCompletionDate} />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" sx={classes.formControl}>
                    <Typography gutterBottom>
                      <strong>{t("page.estimateRequest.fields.attachments.label")}</strong>
                    </Typography>
                    <Typography gutterBottom>
                      {t("page.estimateRequest.fields.attachments.helperText")}
                      <ul>
                        <li>{t("page.estimateRequest.fields.attachments.helperTextBullet1")}</li>
                        <li>{t("page.estimateRequest.fields.attachments.helperTextBullet2")}</li>
                        <li>{t("page.estimateRequest.fields.attachments.helperTextBullet3")}</li>
                        <li>{t("page.estimateRequest.fields.attachments.helperTextBullet4")}</li>
                      </ul>
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FileSelector
                    acceptedTypes={[PNG, JPEG, PlainText, CSV, PDF]}
                    maxSize={10}
                    onFilesAdded={(newFiles) => setFiles([...files, ...newFiles])}
                    showFileList
                    showThumbs={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    onClick={handleSubmitRequest}
                    sx={{ fontWeight: "600" }}
                    variant="contained"
                  >
                    {isSubmitting ? (
                      <CircularProgress color="secondary" size={20} thickness={6.0} />
                    ) : (
                      <span>{t("page.estimateRequest.submitButton")}</span>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        ) : null}
      </Box>
    </>
  )
}

const classes = {
  subTitle: {
    fontSize: "1.1rem",
  },
  formControl: {
    "& strong": {
      color: "#333",
    },
  },
} as const

export default CreateEstimateRequest
