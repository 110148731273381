import gql from "graphql-tag"
import FullAddress from "./fragments/fullAddress"

export const GET_ORGANIZATION_BY_ID_WITH_PLUGINS = gql`
  query GetOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      tradeName
      legalName
      level
      status
      email
      phoneNumber
      websiteURL
      timeZone
      languageCode
      currencyCode
      allowBundleModsOnTxns
      allowJobAssignmentOverlap
      bccJobEmailToOrganizationEmail
      isStandalone
      canSubmitProductOrders
      canCreateProductItems
      accountNumber
      accountManager {
        id
        email
        firstName
        lastName
      }
      region {
        id
        name
        code
        currencyCode
      }
      address {
        ...FullAddress
      }
      plugins {
        id
        pluginProvider {
          id
          name
          category
        }
        supportsInventoryTracking
        tenantId
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
      owner {
        id
        lastName
        firstName
        email
        mobilePhoneNumber
        status
        lastLogin
      }
      defaultEstimateSettings {
        expirationDays
        showExpirationDate
        showContract
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
      defaultInvoiceSettings {
        netTerms
        showDueDate
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
      featureFlags {
        id
        enabled
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
  ${FullAddress}
`

export const GET_ORGANIZATION_BY_ID_WITHOUT_PLUGINS = gql`
  query GetOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      tradeName
      legalName
      level
      status
      email
      phoneNumber
      websiteURL
      timeZone
      languageCode
      allowBundleModsOnTxns
      allowJobAssignmentOverlap
      bccJobEmailToOrganizationEmail
      defaultPricingStrategySettings {
        defaultPricingStrategy
        defaultUnitSalePriceMarkup
      }
      isStandalone
      canSubmitProductOrders
      canCreateProductItems
      currencyCode
      accountNumber
      accountManager {
        id
        email
        firstName
        lastName
      }
      region {
        id
        name
        code
        currencyCode
      }
      address {
        ...FullAddress
      }
      owner {
        id
        lastName
        firstName
        email
        mobilePhoneNumber
        status
        lastLogin
      }
      defaultEstimateSettings {
        expirationDays
        showExpirationDate
        showContract
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
      defaultInvoiceSettings {
        netTerms
        showDueDate
        showLineItems
        showLineItemQuantity
        showLineItemUnitPrice
        showLineItemSubtotal
        showLineItemCode
        showLineItemName
        showLineItemDescription
        showLineItemBundleComponents
        showLineItemBundleComponentUnitPrice
        showLineItemBundleComponentQuantity
        showFooter
        showFooterSubtotal
        showFooterDiscount
        showFooterTaxes
        showFooterTotal
      }
      featureFlags {
        id
        enabled
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
  ${FullAddress}
`

