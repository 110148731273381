/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Skeleton from "@mui/material/Skeleton"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import FielderIconButton from "../../components/FielderIconButton"
import MainLayout from "../../components/MainLayout"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/Seo"
import SnackbarMessage from "../../components/SnackbarMessage"
import EmptyState from "../../components/EmptyState"
import SortableTableHeader from "../../components/SortableTableHeader"
import TablePaginationActions from "../../components/TablePaginationActions"
import NoResultsRow from "../../components/NoResultsRow"
import SearchField from "../../components/SearchField"
import { GET_DRAFT_PRODUCT_ORDER } from "../../queries/getDraftProductOrder"
import { ALL_PRODUCT_ORDERS } from "../../queries/allProductOrders"
import { useAuth } from "../../context/AuthContext"
import useStore from "../../store"
import {
  formatMoney,
  isBlank,
  PRODUCT_ORDERS_HQ,
  PRODUCT_ORDERS_FRANCHISEE,
  NOT_SPECIFIED,
  formatDate,
  useDebounce,
} from "../../util"
import { Snack, DefaultPermission, SortDirection as SortDirectionType } from "../../types"
import ProductOrderStatusPill from "../../components/ProductOrderStatusPill"

const NUM_COLUMNS = 5
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

function FranchiseeProductOrderListPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const { user, hasPermissions } = useAuth()
  const franchiseeOrdersListSettings = useStore((state) => state.franchiseeOrdersListSettings)
  const setFranchiseeOrdersListSettings = useStore((state) => state.setFranchiseeOrdersListSettings)
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>(franchiseeOrdersListSettings.searchTerm)
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(franchiseeOrdersListSettings.rowsPerPage)
  const [last, setLast] = useState<number | null>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const debouncedSearchTerm = useDebounce(filter, 500)

  if (user?.organization?.level === 1) {
    setRedirectTo({ to: "/app/orders/hq", replace: true })
  }

  useEffect(() => {
    if (debouncedSearchTerm !== franchiseeOrdersListSettings.searchTerm) {
      setFranchiseeOrdersListSettings({
        ...franchiseeOrdersListSettings,
        searchTerm: debouncedSearchTerm,
      })
      setAfter(null)
      setBefore(null)
      setFirst(franchiseeOrdersListSettings.rowsPerPage)
      setLast(null)
    }
  }, [debouncedSearchTerm, franchiseeOrdersListSettings, setFranchiseeOrdersListSettings])

  const { data: cartData } = useQuery(GET_DRAFT_PRODUCT_ORDER)

  const { loading, error, data } = useQuery(ALL_PRODUCT_ORDERS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: franchiseeOrdersListSettings.sortBy,
      sortDir: franchiseeOrdersListSettings.sortDir,
      includeArchived: true, // currently, only level-1 users may archive orders. This is only relevant to level-1 users.
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_PRODUCT_ORDERS",
      debounceTimeout: 50,
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(franchiseeOrdersListSettings.rowsPerPage)
    setLast(null)
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(franchiseeOrdersListSettings.rowsPerPage)
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(franchiseeOrdersListSettings.rowsPerPage)
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(franchiseeOrdersListSettings.rowsPerPage)
    setLast(null)
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value

    setFranchiseeOrdersListSettings({
      ...franchiseeOrdersListSettings,
      rowsPerPage: newPageSize,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir =
      propertyName !== franchiseeOrdersListSettings.sortBy
        ? SortDirectionType.ASC
        : franchiseeOrdersListSettings.sortDir === SortDirectionType.ASC
          ? SortDirectionType.DESC
          : SortDirectionType.ASC

    setFranchiseeOrdersListSettings({
      ...franchiseeOrdersListSettings,
      sortBy: newSortBy,
      sortDir: newSortDir,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(franchiseeOrdersListSettings.rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setAfter(null)
    setBefore(null)
    setFirst(franchiseeOrdersListSettings.rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    setRedirectTo({ to: `/app/orders/edit/${id}`, replace: false })
  }

  const pageInfo = data?.allProductOrders?.pageInfo || {}
  const orders = data?.allProductOrders?.edges?.filter((e) => e.node.status !== "DRAFT")
  const cartItemCount =
    cartData?.getDraftProductOrder?.lineItems?.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0) ?? 0

  if (!user?.organization?.canSubmitProductOrders) {
    return <Navigate state={{ from: location }} to="/app/unauthorized" />
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(PRODUCT_ORDERS_HQ.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={PRODUCT_ORDERS_FRANCHISEE}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader
            icon={PRODUCT_ORDERS_FRANCHISEE.icon}
            leafTitleKey={PRODUCT_ORDERS_HQ.titleKey}
          />
          <Box sx={classes.tableHeaderControls}>
            <Box
              sx={(theme) => ({
                width: "25rem",
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                },
              })}
            >
              <SearchField
                onChange={handleSearchChange}
                placeholder={t("searchOrders")}
                term={filter}
                testID="SearchField"
              />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down(840)]: {
                  marginTop: "0.625rem",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                },
                [theme.breakpoints.down(400)]: {
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                },
              })}
            >
              {hasPermissions?.([DefaultPermission.ReadProductOrder]) ? (
                <Box>
                  <Button
                    aria-label={t("viewCart") as string}
                    color="primary"
                    data-testid="viewCartButton"
                    onClick={() => {
                      setRedirectTo({ to: `/app/orders/cart`, replace: false })
                    }}
                    sx={classes.primaryActionButton}
                    variant="contained"
                  >
                    <Box>
                      {t("viewCart")} (
                      {cartItemCount === 0
                        ? t("empty")
                        : cartItemCount > 1
                          ? `${cartItemCount} ${t("items")}`
                          : `${cartItemCount} ${t("item")}`}
                      )
                    </Box>
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Box>
          {!error && !loading && orders?.length === 0 && (
            <Box sx={{ paddingTop: "1.875rem", paddingBottom: "1.875rem" }}>
              {isBlank(filter) && (
                <EmptyState title={t("page.productOrderList.emptyState.franchisee.title")}>
                  <Box>{t("page.productOrderList.emptyState.franchisee.message")}</Box>
                </EmptyState>
              )}
              {!isBlank(filter) && (
                <EmptyState title={t("page.productOrderList.noMatchingResults.title")}>
                  <Box>{t("page.productOrderList.noMatchingResults.message")}</Box>
                </EmptyState>
              )}
            </Box>
          )}
          {!error && (loading || orders?.length > 0) ? (
            <Paper sx={classes.tableWrapper}>
              <TableContainer sx={classes.tableContainer}>
                <Table sx={[classes.table, orders?.length == 0 && { height: "100%" }]}>
                  <TableHead>
                    <TableRow>
                      <SortableTableHeader
                        isActiveSort={franchiseeOrdersListSettings.sortBy === "number"}
                        label={t("orderNumber")}
                        onClick={() => sort("number")}
                        sortDir={franchiseeOrdersListSettings.sortDir}
                        sx={classes.tableHead}
                        width="10%"
                      />
                      <SortableTableHeader
                        isActiveSort={franchiseeOrdersListSettings.sortBy === "status"}
                        label={t("status")}
                        onClick={() => sort("status")}
                        sortDir={franchiseeOrdersListSettings.sortDir}
                        sx={classes.tableHead}
                        width="10%"
                      />
                      <SortableTableHeader
                        isActiveSort={franchiseeOrdersListSettings.sortBy === "dateSubmitted"}
                        label={t("dateSubmitted")}
                        onClick={() => sort("dateSubmitted")}
                        sortDir={franchiseeOrdersListSettings.sortDir}
                        sx={classes.tableHead}
                        width="15%"
                      />
                      <SortableTableHeader
                        align="left"
                        isActiveSort={franchiseeOrdersListSettings.sortBy === "total"}
                        label={t("total")}
                        onClick={() => sort("total")}
                        sortDir={franchiseeOrdersListSettings.sortDir}
                        sx={classes.tableHead}
                        width="10%"
                      />
                      <TableCell sx={classes.tableHead}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={orders?.length == 0 ? { height: "100%" } : {}}>
                    {!error && !loading && orders?.length == 0 && (
                      <NoResultsRow colSpan={NUM_COLUMNS} />
                    )}
                    {!error &&
                      !loading &&
                      orders?.map((edge) => {
                        const { node } = edge
                        return (
                          <TableRow
                            key={node.id}
                            onClick={() => {
                              if (hasPermissions?.([DefaultPermission.UpdateProductOrder])) {
                                handleRowClick(node.id)
                              }
                            }}
                            sx={classes.dataRow}
                          >
                            <TableCell>{node.number}</TableCell>
                            <TableCell>
                              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <ProductOrderStatusPill status={node.status} />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box sx={{ whiteSpace: "nowrap" }}>
                                {node.dateSubmitted
                                  ? formatDate(
                                      node.dateSubmitted,
                                      t("format:dateFormat.shortWithTime"),
                                      user?.organization?.timeZone,
                                      user
                                    )
                                  : NOT_SPECIFIED}
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Box sx={{ whiteSpace: "nowrap" }}>
                                {formatMoney(node.currencyCode, node.total)}
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              {hasPermissions?.([DefaultPermission.ReadProductOrder]) ? (
                                <FielderIconButton
                                  aria-label={t("viewOrder") as string}
                                  title={t("viewOrder") as string}
                                >
                                  <VisibilityIcon />
                                </FielderIconButton>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {loading
                      ? [...Array(franchiseeOrdersListSettings.rowsPerPage).keys()].map((i) => (
                          <TableRow key={i} sx={classes.dataRow}>
                            {[...Array(NUM_COLUMNS).keys()].map((j) => (
                              <TableCell key={j}>
                                <Skeleton variant="text" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    {error ? (
                      <TableRow>
                        <TableCell align="center" colSpan={NUM_COLUMNS}>
                          <Box
                            style={{
                              color: "rgb(244, 67, 54)",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                              lineHeight: 1.6,
                            }}
                          >
                            {t("errorLabel")}
                            {": "}
                            {error.message}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={() => (
                  <TablePaginationActions
                    hasNextPage={pageInfo.hasNextPage ?? false}
                    hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                    onGoToFirstPage={onGoToFirstPage}
                    onGoToLastPage={onGoToLastPage}
                    onGoToNext={onGoToNext}
                    onGoToPrevious={onGoToPrevious}
                  />
                )}
                component="div"
                count={-1}
                labelDisplayedRows={() => ``}
                labelRowsPerPage={t("rowsPerPage") as string}
                onPageChange={() => {}}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={franchiseeOrdersListSettings.currentPage}
                rowsPerPage={franchiseeOrdersListSettings.rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </Paper>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  tableWrapper: {
    overflowX: "auto",
  },
  tableContainer: {
    maxHeight: "calc(75vh)",
    height: "calc(75vh - 50px)",
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
  },
  tableHeaderControls: {
    marginBottom: "1.25rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  tableHead: {
    backgroundColor: "#f9f9f9",
    zIndex: 1000,
    position: "sticky",
    top: 0,
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
} as const

export default FranchiseeProductOrderListPage
