import React from "react"
import BarChartOutlined from "@mui/icons-material/BarChartOutlined"
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import DashboardOutlined from "@mui/icons-material/DashboardOutlined"
import DeviceHubOutlined from "@mui/icons-material/DeviceHubOutlined"
import InboxIcon from "@mui/icons-material/InboxOutlined"
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined"
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined"
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined"
import ScheduleIcon from "@mui/icons-material/Schedule"
import SettingsOutlined from "@mui/icons-material/SettingsOutlined"
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined"
import InventoryIcon from "../components/icons/InventoryIcon"
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined"
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined"
import ReceiptOutlined from "@mui/icons-material/ReceiptOutlined"
import TaskIcon from "../components/icons/TaskIcon"
import { AppSection, BadgeData } from "../types"
import PERMISSION_REQUIREMENTS from "./permissionRequirements"

const DASHBOARD: AppSection = {
  name: "dashboard",
  position: 0,
  titleKey: "sectionTitle.dashboard",
  path: "/app/dashboard",
  icon: <DashboardOutlined />,
  requiredOrgLevel: 2,
  orgLevels: [1, 2],
  features: [
    {
      name: "any",
      requiredPermissions: PERMISSION_REQUIREMENTS.dashboard,
    },
  ],
}

const INBOX: AppSection = {
  name: "inbox",
  position: 5,
  titleKey: "sectionTitle.inbox",
  path: "/app/inbox",
  icon: <InboxIcon />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.inbox,
    },
  ],
  getBadgeContent: (data: BadgeData) => {
    return data.unreadInboxConversationCount > 0 ? data.unreadInboxConversationCount : 0
  },
}

const DISPATCH: AppSection = {
  name: "dispatch",
  position: 10,
  titleKey: "sectionTitle.dispatch",
  path: "/app/dispatch",
  icon: <ScheduleIcon />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "dispatch",
      requiredPermissions: PERMISSION_REQUIREMENTS.dispatch,
    },
  ],
}

const JOBS: AppSection = {
  name: "jobs",
  position: 20,
  titleKey: "sectionTitle.jobs",
  path: "/app/jobs",
  icon: <WorkOutlineOutlined />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.jobs.list,
    },
  ],
}

const INVENTORY: AppSection = {
  name: "inventory",
  position: 30,
  titleKey: "sectionTitle.inventory",
  path: "/app/inventory",
  icon: <InventoryIcon />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.inventory.list,
    },
  ],
}

const PRODUCT_ORDERS_FRANCHISEE: AppSection = {
  name: "productOrders",
  position: 33,
  titleKey: "sectionTitle.productOrders",
  path: "/app/orders",
  icon: <ShoppingCartOutlinedIcon />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.productOrders,
    },
  ],
}

const PRODUCT_ORDERS_HQ: AppSection = {
  name: "productOrders",
  position: 34,
  titleKey: "sectionTitle.productOrders",
  path: "/app/orders/hq/board",
  icon: <ShoppingCartOutlinedIcon />,
  requiredOrgLevel: 1,
  orgLevels: [1],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.productOrdersHQ.list,
    },
    {
      name: "board",
      requiredPermissions: PERMISSION_REQUIREMENTS.productOrdersHQ.list,
    },
  ],
}

const CATALOG: AppSection = {
  name: "catalog",
  position: 35,
  titleKey: "sectionTitle.catalog",
  path: "/app/catalog",
  icon: <MenuBookOutlinedIcon />,
  requiredOrgLevel: 1,
  orgLevels: [1],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.catalog.list,
    },
  ],
}

const ESTIMATE_REQUESTS: AppSection = {
  name: "estimateRequests",
  position: 40,
  titleKey: "sectionTitle.estimateRequests",
  path: "/app/estimate-requests",
  icon: <RequestQuoteOutlinedIcon />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.estimateRequests,
    },
  ],
  getBadgeContent: (data: BadgeData) => {
    return data.estimateRequestCount > 0 ? data.estimateRequestCount : 0
  },
}

const TASKS: AppSection = {
  name: "tasks",
  position: 50,
  titleKey: "sectionTitle.tasks",
  path: "/app/tasks",
  icon: <TaskIcon />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.tasks,
    },
  ],
}

const CALENDAR: AppSection = {
  name: "calendar",
  position: 60,
  titleKey: "sectionTitle.calendar",
  path: "/app/calendar",
  icon: <CalendarTodayOutlined />,
  requiredOrgLevel: 2,
  orgLevels: [1, 2],
  features: [
    {
      name: "viewCalendar",
      requiredPermissions: PERMISSION_REQUIREMENTS.calendar,
    },
  ],
}

const INVOICES: AppSection = {
  name: "invoices",
  position: 70,
  titleKey: "sectionTitle.invoices",
  path: "/app/invoices",
  icon: <ReceiptOutlined />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.invoices,
    },
  ],
}

const CUSTOMERS: AppSection = {
  name: "customers",
  position: 80,
  titleKey: "sectionTitle.customers",
  path: "/app/customers",
  icon: <HomeWorkOutlinedIcon />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "customerList",
      requiredPermissions: PERMISSION_REQUIREMENTS.customers.list,
    },
  ],
}

const REPORTS: AppSection = {
  name: "reports",
  position: 90,
  titleKey: "sectionTitle.reports",
  path: "/app/reports",
  icon: <BarChartOutlined />,
  requiredOrgLevel: 2,
  orgLevels: [2],
  features: [
    {
      name: "viewReports",
      requiredPermissions: PERMISSION_REQUIREMENTS.reports,
    },
  ],
}

const FRANCHISEES: AppSection = {
  name: "franchisees",
  position: 120,
  titleKey: "sectionTitle.franchisees",
  path: "/app/franchisees",
  icon: <DeviceHubOutlined />,
  requiredOrgLevel: 1,
  orgLevels: [1],
  features: [
    {
      name: "list",
      requiredPermissions: PERMISSION_REQUIREMENTS.franchisees.list,
    },
    {
      name: "create",
      requiredPermissions: PERMISSION_REQUIREMENTS.franchisees.create,
    },
    {
      name: "edit",
      requiredPermissions: PERMISSION_REQUIREMENTS.franchisees.edit,
    },
  ],
}

const REPORTS_HQ: AppSection = {
  name: "reportsHQ",
  position: 130,
  titleKey: "sectionTitle.reports",
  path: "/app/reports/hq",
  icon: <BarChartOutlined />,
  requiredOrgLevel: 1,
  orgLevels: [1],
  features: [
    {
      name: "viewReports",
      requiredPermissions: PERMISSION_REQUIREMENTS.reports,
    },
  ],
}

const SETTINGS: AppSection = {
  name: "settings",
  position: 140,
  titleKey: "sectionTitle.settings",
  path: "/app/settings",
  icon: <SettingsOutlined />,
  requiredOrgLevel: 2,
  orgLevels: [1, 2],
  features: [
    {
      name: "myProfile",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.myProfile,
    },
    {
      name: "editOrganization",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.organization,
    },
    {
      name: "manageUsers",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.users.list,
    },
    {
      name: "manageRoles",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.roles,
    },
    {
      name: "managePlugins",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.plugins,
    },
    {
      name: "manageHelicalPileInstallationWorksheetTemplates",
      requiredPermissions:
        PERMISSION_REQUIREMENTS.settings.helicalPileInstallationWorksheetTemplates,
    },
    {
      name: "manageEmailTemplates",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.emailTemplates,
    },
    {
      name: "manageEstimateTemplates",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.estimateTemplates,
    },
    {
      name: "manageWorkOrderTemplates",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.workOrderTemplates,
    },
    {
      name: "manageJobCategories",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.jobCategories,
    },
    {
      name: "manageJobStatuses",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.jobStatuses,
    },
    {
      name: "manageTaxRateGroups",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.taxRateGroups,
    },
    {
      name: "manageJobWorkflows",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.jobWorkflows,
    },
    {
      name: "manageRegions",
      requiredPermissions: PERMISSION_REQUIREMENTS.settings.regions,
    },
  ],
}

const HELP: AppSection = {
  name: "help",
  position: 150,
  titleKey: "sectionTitle.helpCenter",
  path: "/app/help",
  icon: <HelpOutlineOutlined />,
  requiredOrgLevel: 2,
  orgLevels: [1, 2],
  features: [{ name: "*", requiredPermissions: PERMISSION_REQUIREMENTS.helpCenter }],
}

/**
 * This section can be used in the rare cases where an AppSection is a required argument but isn't actually
 * relevant to the situation, such as a 404 page.
 */
const NULL_SECTION: AppSection = {
  name: "",
  position: -1,
  titleKey: "",
  path: "",
  icon: <div />,
  requiredOrgLevel: 2,
  orgLevels: [0, 1, 2],
  features: [
    {
      name: "",
      requiredPermissions: [],
    },
  ],
}

const SECTIONS: AppSection[] = [
  DASHBOARD,
  DISPATCH,
  INBOX,
  JOBS,
  INVENTORY,
  PRODUCT_ORDERS_FRANCHISEE,
  PRODUCT_ORDERS_HQ,
  CATALOG,
  ESTIMATE_REQUESTS,
  TASKS,
  CUSTOMERS,
  REPORTS,
  FRANCHISEES,
  REPORTS_HQ,
  SETTINGS,
]

export {
  SECTIONS,
  DASHBOARD,
  DISPATCH,
  JOBS,
  INBOX,
  INVENTORY,
  INVOICES,
  PRODUCT_ORDERS_FRANCHISEE,
  PRODUCT_ORDERS_HQ,
  CATALOG,
  ESTIMATE_REQUESTS,
  TASKS,
  CUSTOMERS,
  REPORTS,
  REPORTS_HQ,
  HELP,
  FRANCHISEES,
  SETTINGS,
  CALENDAR,
  NULL_SECTION,
}
