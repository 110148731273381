import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import difference from "lodash/difference"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid2"
import Alert from "@mui/material/Alert"
import isNil from "lodash/isNil"

import SectionHeader from "~/components/SectionHeader"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import { isBlank } from "~/util"
import { usePrompt } from "~/hooks/usePrompt"
import { DefaultPermission, Role } from "~/types"
import SaveButton from "~/components/SaveButton"
import SectionContent from "~/components/SectionContent"
import PermissionGroup from "./PermissionGroup"
import { useAuth } from "~/context/AuthContext"
import {
  getEnabledPermissionOptionIds,
  getEnabledPermissions,
  getPermissionOptions,
  findPermission,
  flattenPermissions,
} from "../utils/helpers"

interface RoleFormProps {
  readonly isSaving?: boolean
  readonly onCancel: () => void
  readonly onSave: (role: Role) => void
  readonly role?: Role
}

function RoleForm({ isSaving, onCancel, onSave, role }: RoleFormProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [name, setName] = useState<string>(() => role?.name ?? "")
  const [description, setDescription] = useState<string>(() => role?.description ?? "")
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [errors, setErrors] = useState({
    roleName: null,
  })
  const [enabledPermissionOptionIds, setEnabledPermissionOptionIds] = useState<string[]>(() => {
    return role?.permissions
      ? getEnabledPermissionOptionIds(user?.organization?.level ?? 1000, role.permissions)
      : []
  })

  const permissionOptions = useMemo(() => {
    return !isNil(user?.organization?.level)
      ? getPermissionOptions(user?.organization?.level ?? 1000)
      : []
  }, [user?.organization?.level])

  function isValid() {
    return !isBlank(name) && enabledPermissionOptionIds.length > 0
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  return (
    <Paper
      sx={{
        padding: "0 1rem",
        display: "flex",
        flexDirection: "column",
        marginBottom: "4rem",
      }}
    >
      <SectionHeader>
        <label>{t("basicInfo")}</label>
      </SectionHeader>
      <Divider />
      <SectionContent sx={{ paddingBottom: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "4px 8px",
            paddingBottom: 0,
          }}
        >
          {role?.isStandard ? (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              spacing={3}
              sx={{
                marginBottom: "1.875rem",
              }}
            >
              <Grid>
                <Box sx={classes.masterLabel}>{t("roleName")}</Box>
                <Box>{t(`standardRoles.${role.name}.name`)}</Box>
              </Grid>
              <Grid>
                <Box sx={classes.masterLabel}>{t("description")}</Box>
                <Box>{role.description}</Box>
              </Grid>
              <Grid>
                <Alert severity="warning">{t("messages.standardRolesNotEditable")}</Alert>
              </Grid>
            </Grid>
          ) : (
            <>
              <Box sx={classes.fieldContainer}>
                <FielderTextField
                  data-testid="roleName"
                  error={!!errors.roleName}
                  fullWidth
                  id="roleName"
                  inputProps={{ maxLength: 255 }}
                  label={t("roleName")}
                  name="roleName"
                  onBlur={() => {
                    if (!isBlank(name)) {
                      setErrors((prev) => ({
                        ...prev,
                        roleName: null,
                      }))
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        roleName: t("page.roleManagement.validation.roleName.required"),
                      }))
                    }
                  }}
                  onChange={(e) => {
                    setIsDirty(true)
                    setName(e.target.value)
                  }}
                  onFocus={(e) => e.target.select()}
                  required
                  value={name}
                />
                {errors.roleName ? (
                  <FieldHelperText error message={errors.roleName} />
                ) : (
                  <FieldHelperText message={t("page.roleManagement.helperText.roleName")} />
                )}
              </Box>
              <Box sx={classes.fieldContainer}>
                <FielderTextField
                  data-testid="description"
                  fullWidth
                  id="description"
                  inputProps={{ maxLength: 1000 }}
                  label={t("description")}
                  name="description"
                  onChange={(e) => {
                    setIsDirty(true)
                    setDescription(e.target.value)
                  }}
                  value={description}
                />
              </Box>
            </>
          )}
        </Box>
      </SectionContent>
      <SectionHeader>
        <label>{t("permissions")}</label>
      </SectionHeader>
      <Divider />
      <SectionContent>
        {permissionOptions.map((p) => {
          return (
            <PermissionGroup
              disabled={role?.isStandard}
              enabledPermissionOptionIds={enabledPermissionOptionIds}
              key={p.id}
              onChange={(id: string, checked: boolean) => {
                const entry = findPermission(id)
                const allDescendents = entry ? flattenPermissions(entry) : []

                if (checked) {
                  const enabled = new Set([...enabledPermissionOptionIds, ...allDescendents])
                  if (entry && !entry.permissions) {
                    enabled.add(entry.id)
                  }
                  setEnabledPermissionOptionIds([...enabled])
                } else {
                  setEnabledPermissionOptionIds((prev) => difference(prev, allDescendents))
                }
              }}
              permissionOptionGroupId={p.id}
              permissionOptions={p.permissions}
            />
          )
        })}
      </SectionContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "1.25rem",
        }}
      >
        <Button color="secondary" data-testid="cancelButton" onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <SaveButton
          disabled={isSaving || !isValid() || role?.isStandard}
          loading={isSaving}
          onClick={() => {
            if (role?.isStandard) {
              return
            }

            if (enabledPermissionOptionIds.length === 0) {
              window?.alert(t("error.role.permission.required"))
              return
            }

            const updatedRole: Partial<Role> & { permissionIds: DefaultPermission[] } = {
              name,
              description,
              permissionIds: getEnabledPermissions(
                user?.organization?.level ?? 10000,
                enabledPermissionOptionIds
              ),
            }

            if (role?.id) {
              updatedRole.id = role.id
            }

            onSave(updatedRole)
          }}
        />
      </Box>
    </Paper>
  )
}

const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
  masterLabel: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
} as const

export default RoleForm
