import React, { useEffect, useRef, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import CircularProgress from "@mui/material/CircularProgress"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import TextField from "@mui/material/TextField"

import { isBlank } from "~/util"
import { OrganizationItemList } from "~/types"

const CREATE_ORGANIZATION_ITEM_LIST = gql`
  mutation CreateOrganizationItemList($name: String!) {
    createOrganizationItemList(input: { name: $name }) {
      organizationItemList {
        id
        name
      }
    }
  }
`

interface Props {
  readonly onCancel: () => void
  readonly onClose: () => void
  readonly onListCreated?: (list: OrganizationItemList) => void
  readonly open: boolean
}

export default function CreateListDialog({ open, onCancel, onClose, onListCreated }: Props) {
  const { t } = useTranslation()
  const [listName, setListName] = useState<string>("")
  const [errors, setErrors] = useState<{ name: string | null }>({ name: null })
  const inputRef = useRef(null)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 10)

    return () => clearTimeout(timeout)
  }, [open])

  const [createOrganizationItemList, { loading }] = useMutation(CREATE_ORGANIZATION_ITEM_LIST, {
    onCompleted: (data) => {
      setListName("")
      onListCreated?.(data.createOrganizationItemList.organizationItemList)
    },
  })

  function handleSubmit() {
    if (isBlank(listName)) {
      setErrors({
        ...errors,
        name: "component.organizationItemList.createListDialog.validation.name.required",
      })
    } else {
      createOrganizationItemList({
        variables: {
          name: listName,
        },
      })
    }
  }

  return (
    <Dialog
      aria-labelledby="create-list-dialog-title"
      disableRestoreFocus
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        id="create-list-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {t("component.organizationItemList.createListDialog.title")}
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.875rem",
          padding: "1rem 2rem 4rem 2rem",
        }}
      >
        <Box
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "column",
              color: errors.name ? theme.fielderColors.error : theme.fielderColors.text,
            }
          }}
        >
          <label htmlFor="listName">{t("listName")}</label>
          <TextField
            autoFocus
            error={Boolean(errors.name)}
            id="listName"
            inputRef={inputRef}
            onChange={(e) => {
              if (!isBlank(e.target.value)) {
                setErrors({ ...errors, name: null })
              } else {
                setErrors({
                  ...errors,
                  name: "component.organizationItemList.createListDialog.validation.name.required",
                })
              }
              setListName(e.target.value)
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit()
              }
            }}
            type="text"
            value={listName}
          />
          <Box
            component="div"
            sx={(theme) => {
              return {
                color: theme.fielderColors.error,
                height: "1.5rem",
              }
            }}
          >
            {errors.name ? t(errors.name) : " "}
          </Box>
        </Box>
        <Box
          sx={(theme) => {
            return {
              color: theme.fielderColors.mutedText,
            }
          }}
        >
          {t("component.organizationItemList.createListDialog.explanation")}
        </Box>
      </Box>
      <DialogActions
        sx={{
          px: "2rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.25rem", alignItems: "center" }}>
          <a
            href={t("page.inventoryList.learnMoreUrl") as string}
            rel="noreferrer"
            style={{ color: "black" }}
            target="_blank"
          >
            {t("learnMore")}
          </a>
          <OpenInNewIcon sx={{ fontSize: "1rem" }} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Button
            color="secondary"
            data-testid="cancelButton"
            disabled={loading}
            onClick={onCancel}
            sx={(theme) => ({
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },
            })}
            variant="outlined"
          >
            {t("cancel")}
          </Button>
          <Button
            color="primary"
            data-testid="submitButton"
            disabled={loading}
            onClick={handleSubmit}
            sx={(theme) => ({
              fontWeight: "bold",
              [theme.breakpoints.down("xs")]: {
                marginTop: "0.625rem",
                width: "100%",
              },
            })}
            variant="contained"
          >
            {loading ? (
              <CircularProgress color="secondary" size={20} thickness={6.0} />
            ) : (
              <Box>{t("createList")}</Box>
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
