import difference from "lodash/difference"
import { User } from "../types"

/**
 * Determine if the given user has ALL of the given permissions.
 * @param user
 * @param requiredPermissions
 * @returns true if the user has ALL of the required permissions
 */
function hasPermissions(
  user: Partial<User> | null | undefined,
  requiredPermissions: Array<string>
): boolean {
  const userPermissions = user?.roles?.flatMap(r => r.permissions).flatMap(p => p && p.id) ?? []
  if (!(user?.id && userPermissions && userPermissions.length > 0)) {
    throw new Error(
      "User must be the first argument to hasPermissions?.() and the User must have an Id and some permissions!"
    )
  }

  return difference(requiredPermissions, userPermissions).length === 0
}

/**
 * Determine the first page a newly-authenticated user should be routed to
 * based on either the User status or their Organization's status (or anything
 * else relevant to this decision)
 * Return a string representing the next route.
 */
function getLandingRoute(user: Partial<User>): string {
  const org = user.organization
  if (org && org.level > 1 && org.status === "PENDING") {
    if (user.isOrganizationOwner) {
      // user is the org owner; route them to the subscription pages
      return "/app/pricing"
    } else {
      // user is not the org owner; route them to a page explaining that the owner needs to setup the subscription first.
      return "/app/org-account-pending"
    }
  } else if (org?.status === "ACTIVE") {
    return "/app/dashboard"
  } else if (org?.status === "SUSPENDED") {
    return "/app/org-account-locked"
  } else {
    return "/app/unauthorized"
  }
}

export { getLandingRoute, hasPermissions }
