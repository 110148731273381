/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import dayjs from "dayjs"
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom"
import jwt_decode, { JwtPayload } from "jwt-decode"

import { hasPermissions } from "~/util"
import { Organization, User, DefaultPermission } from "~/types"
import useStore, {
  STORAGE_KEY,
  userSelector,
  setUserSelector,
  resetStoreSelector,
  tempUserSelector,
  setTempUserSelector,
  removeTempUserSelector,
} from "~/store"
import useVersionChecker from "~/hooks/useVersionChecker"

interface AuthContextState {
  user?: Partial<User> | null | undefined
  isImpersonating: boolean
  isAuthenticated: () => boolean
  login?: (user: Partial<User>, token: string) => void
  loginTemp?: (user: Partial<User>, token: string) => void
  logout?: () => void
  enterImpersonateMode?: (organization: Organization, token: string) => void
  exitImpersonateMode?: () => void
  hasPermissions?: (permissions: DefaultPermission[]) => boolean
  setUser?: (user: Partial<User>) => void
}

const storedStateString =
  "undefined" !== typeof window && window?.localStorage?.getItem(STORAGE_KEY)
    ? window.localStorage.getItem(STORAGE_KEY)
    : ""
const storedStateJSON = storedStateString ? JSON.parse(storedStateString) : null

const defaultState: AuthContextState = {
  user: storedStateJSON?.state?.user,
  isAuthenticated: () => Boolean(Cookies.get("authToken")),
  isImpersonating: Boolean(storedStateJSON?.state?.tempUser),
  login: undefined,
  loginTemp: undefined,
  logout: undefined,
  enterImpersonateMode: undefined,
  exitImpersonateMode: undefined,
  hasPermissions: undefined,
  setUser: undefined,
}

type AuthContextAction =
  | { type: "LOG_IN"; payload: { user: Partial<User> } }
  | { type: "LOG_OUT" }
  | {
      type: "ENTER_IMPERSONATE_MODE"
      payload: { organization: Organization }
    }
  | { type: "EXIT_IMPERSONATE_MODE"; payload: { user: Partial<User> } }
  | { type: "SET_USER"; payload: { user: Partial<User> | null } }

export const AuthContext = createContext<AuthContextState>(defaultState)

export function AuthProvider({ children }) {
  const storedUser = useStore(userSelector)
  const resetStore = useStore(resetStoreSelector)
  const setStoreUser = useStore(setUserSelector)
  const tempUser = useStore(tempUserSelector)
  const setTempUser = useStore(setTempUserSelector)
  const removeTempUser = useStore(removeTempUserSelector)
  const navigate = useNavigate()
  useVersionChecker()

  const [state, dispatch] = useReducer(
    (prevState: AuthContextState, action: AuthContextAction): AuthContextState => {
      switch (action.type) {
        case "LOG_IN":
          return {
            ...prevState,
            isImpersonating: false,
            user: action.payload.user,
          }
        case "LOG_OUT":
          return {
            ...prevState,
            user: null,
          }
        case "ENTER_IMPERSONATE_MODE":
          return {
            ...prevState,
            isImpersonating: true,
            user: {
              ...prevState.user,
              organization: action.payload.organization,
            },
          }
        case "EXIT_IMPERSONATE_MODE":
          return {
            ...prevState,
            isImpersonating: false,
            user: action.payload.user,
          }
        case "SET_USER":
          return {
            ...prevState,
            user: action.payload.user,
          }
        default:
          return prevState
      }
    },
    defaultState
  )

  useEffect(() => {
    function handleNewLogin(event: StorageEvent) {
      if (event.key === STORAGE_KEY && event.newValue) {
        const newValue = JSON.parse(event.newValue)
        const newUser = newValue?.state?.user

        if (newUser?.id !== state.user?.id) {
          window.location.reload()
        }
      }
    }
    window.addEventListener("storage", handleNewLogin)
    return () => window.removeEventListener("storage", handleNewLogin)
  }, [state.user?.id])

  const value = useMemo(() => {
    return {
      setUser: (user: Partial<User>) => {
        setStoreUser(user)
        dispatch({ type: "SET_USER", payload: { user } })
      },
      login: (user: Partial<User>, authToken: string) => {
        const decodedToken = jwt_decode<JwtPayload>(authToken)
        const expirationDateInSeconds = decodedToken.exp ?? 0
        setStoreUser(user)
        Cookies.set("authToken", authToken, {
          expires: expirationDateInSeconds ? dayjs.unix(expirationDateInSeconds).toDate() : 1,
        })
        Cookies.remove("tempAuthToken")
        dispatch({ type: "LOG_IN", payload: { user } })
      },
      loginTemp: (user: Partial<User>, authToken: string) => {
        const decodedToken = jwt_decode<JwtPayload>(authToken)
        const expirationDateInSeconds = decodedToken.exp ?? 0
        setStoreUser(user)
        Cookies.remove("authToken")
        Cookies.set("tempAuthToken", authToken, {
          expires: expirationDateInSeconds ? dayjs.unix(expirationDateInSeconds).toDate() : 1,
        })
        dispatch({ type: "LOG_IN", payload: { user } })
      },
      logout: () => {
        Cookies.remove("authToken")
        resetStore()
        if (typeof window !== "undefined" && typeof window.Intercom !== "undefined") {
          window.Intercom("shutdown")
        }
        dispatch({ type: "LOG_OUT" })
      },
      enterImpersonateMode: (organization: Organization, token: string) => {
        // Replace the current User in the state with the impersonated user.
        // Make sure the impersonated user is associated with the correct Organization.
        const impersonatingUser = {
          ...state.user,
          organization,
        }

        Cookies.set("tempAuthToken", Cookies.get("authToken") ?? "")
        Cookies.set("authToken", token)

        setTempUser(storedUser ?? {})
        setStoreUser(impersonatingUser)
        dispatch({ type: "ENTER_IMPERSONATE_MODE", payload: { organization } })
        navigate("/app/dashboard")
      },
      exitImpersonateMode: () => {
        const originalUser = tempUser
        const originalAuthToken = Cookies.get("tempAuthToken")

        if (originalUser && originalAuthToken) {
          // clear the impersonated user & token, and reload the originals.
          Cookies.set("authToken", originalAuthToken)
          Cookies.remove("tempAuthToken")
          setStoreUser(originalUser)
          removeTempUser()
          dispatch({
            type: "EXIT_IMPERSONATE_MODE",
            payload: { user: originalUser },
          })
          navigate("/app/franchisees")
        } else {
          console.error("Could not find original user or token")
          resetStore()
          dispatch({ type: "LOG_OUT" })
          navigate("/app/login", { replace: true })
        }
      },
      hasPermissions: (requiredPermissions: DefaultPermission[]) => {
        return (
          state.isImpersonating ||
          (state.user?.id && hasPermissions(state.user, requiredPermissions))
        )
      },
      isAuthenticated: () => Boolean(Cookies.get("authToken")),
      isImpersonating: state.isImpersonating,
      user: state.user,
    }
  }, [
    state.isImpersonating,
    state.user,
    setStoreUser,
    resetStore,
    navigate,
    setTempUser,
    storedUser,
    tempUser,
    removeTempUser,
  ])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
