import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined"
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import TrafficIcon from "@mui/icons-material/Traffic"
import LanguageIcon from "@mui/icons-material/Language"
import CategoryIcon from "@mui/icons-material/Category"
import PowerIcon from "@mui/icons-material/Power"
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined"
import FlagIcon from "@mui/icons-material/Flag"
import QuickreplyIcon from "@mui/icons-material/QuickreplyOutlined"

import { MainLayout, MenuItemCard, PageHeader, Seo, WorkflowIcon, TemplateIcon } from "~/components"
import { SETTINGS } from "~/util"
import { useAuth } from "~/context/AuthContext"
import PERMISSION_REQUIREMENTS from "~/util/permissionRequirements"
import useStore from "~/store"
import { FeatureFlag } from "~/types/appTypes"
import { isFeatureFlagEnabled } from "~/util/featureFlag"

function SettingsHome() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, hasPermissions } = useAuth()
  const { featureFlags } = useStore()

  const controlGrid = useMemo(() => {
    return {
      showTemplates:
        user?.organization?.level === 2 &&
        ((hasPermissions?.(
          PERMISSION_REQUIREMENTS.settings.helicalPileInstallationWorksheetTemplates
        ) &&
          isFeatureFlagEnabled(FeatureFlag.HelicalPileInstallationWorksheet, featureFlags)) ||
          hasPermissions?.(PERMISSION_REQUIREMENTS.settings.estimateTemplates) ||
          hasPermissions?.(PERMISSION_REQUIREMENTS.settings.workOrderTemplates) ||
          hasPermissions?.(PERMISSION_REQUIREMENTS.settings.emailTemplates)),
      showJobCategories:
        user?.organization?.level === 2 &&
        hasPermissions?.(PERMISSION_REQUIREMENTS.settings.jobCategories),
      showTaxRateGroups:
        user?.organization?.level === 2 &&
        hasPermissions?.(PERMISSION_REQUIREMENTS.settings.taxRateGroups),
      showPlugins:
        user?.organization?.level === 2 &&
        hasPermissions?.(PERMISSION_REQUIREMENTS.settings.plugins),
      showRegions:
        user?.organization?.level == 1 &&
        hasPermissions?.(PERMISSION_REQUIREMENTS.settings.regions),
      showUsers: hasPermissions?.(PERMISSION_REQUIREMENTS.settings.users.list),
      showRoles: hasPermissions?.(PERMISSION_REQUIREMENTS.settings.roles),
      showJobStatuses: hasPermissions?.(PERMISSION_REQUIREMENTS.settings.jobStatuses),
      showCustomerFlags:
        user?.organization?.level === 2 &&
        hasPermissions?.(PERMISSION_REQUIREMENTS.settings.customerFlags),
      showOrganization: hasPermissions?.(PERMISSION_REQUIREMENTS.settings.organization),
      showJobWorkflows: hasPermissions?.(PERMISSION_REQUIREMENTS.settings.jobWorkflows),
      showAutomatedMessaging:
        hasPermissions?.(PERMISSION_REQUIREMENTS.settings.automatedMessaging) &&
        isFeatureFlagEnabled(FeatureFlag.AutomatedMessaging, featureFlags),
    }
  }, [hasPermissions, user, featureFlags])

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      <MainLayout activeSection={SETTINGS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader icon={SETTINGS.icon} leafTitleKey={SETTINGS.titleKey} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
              gap: "1rem",
            }}
          >
            <MenuItemCard
              icon={<AccountCircleOutlinedIcon style={iconStyle} />}
              onClick={() => navigate("/app/settings/profile")}
              testID="MenuItem-MyProfile"
              title={t("myProfile")}
            />

            {controlGrid.showOrganization ? (
              <MenuItemCard
                icon={<ApartmentOutlinedIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/organization/edit")}
                testID="MenuItem-OrganizationDetails"
                title={t("organizationDetails")}
              />
            ) : null}

            {controlGrid.showRegions ? (
              <MenuItemCard
                icon={<LanguageIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/regions")}
                testID="MenuItem-Regions"
                title={t("regions")}
              />
            ) : null}

            {controlGrid.showTemplates ? (
              <MenuItemCard
                icon={<TemplateIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/templates")}
                testID="MenuItem-Templates"
                title={t("templates")}
              />
            ) : null}

            {controlGrid.showUsers ? (
              <MenuItemCard
                icon={<PeopleAltOutlinedIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/users/list")}
                testID="MenuItem-Users"
                title={t("users")}
              />
            ) : null}

            {controlGrid.showRoles ? (
              <MenuItemCard
                icon={<SecurityOutlinedIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/roles/list")}
                testID="MenuItem-SecurityRoles"
                title={t("securityRoles")}
              />
            ) : null}

            {controlGrid.showCustomerFlags ? (
              <MenuItemCard
                icon={<FlagIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/customerflags")}
                testID="MenuItem-CustomerFlags"
                title={t("customerFlags")}
              />
            ) : null}

            {controlGrid.showJobCategories ? (
              <MenuItemCard
                icon={<CategoryIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/jobcategories")}
                testID="MenuItem-JobCategories"
                title={t("jobCategoryOptions")}
              />
            ) : null}

            {controlGrid.showJobStatuses ? (
              <MenuItemCard
                icon={<TrafficIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/jobstatuses")}
                testID="MenuItem-JobStatuses"
                title={t("jobStatusOptions")}
              />
            ) : null}

            {controlGrid.showJobWorkflows ? (
              <MenuItemCard
                icon={<WorkflowIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/jobworkflows")}
                testID="MenuItem-JobWorkflow"
                title={t("jobWorkflows")}
              />
            ) : null}

            {controlGrid.showTaxRateGroups ? (
              <MenuItemCard
                icon={<MonetizationOnIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/taxrates")}
                testID="MenuItem-TaxRates"
                title={t("taxRates")}
              />
            ) : null}

            {controlGrid.showPlugins ? (
              <MenuItemCard
                icon={<PowerIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/plugins")}
                testID="MenuItem-Plugins"
                title={t("plugins")}
              />
            ) : null}

            {controlGrid.showAutomatedMessaging ? (
              <MenuItemCard
                icon={<QuickreplyIcon style={iconStyle} />}
                onClick={() => navigate("/app/settings/automated-messaging")}
                testID="MenuItem-AutomatedMessaging"
                title={t("automatedMessaging")}
              />
            ) : null}
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

const iconStyle = {
  fontSize: "2.5rem",
} as const

export default SettingsHome
