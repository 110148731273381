import React, { useCallback, useState, useRef } from "react"
import {
  Column,
  DataSheetGrid,
  floatColumn,
  intColumn,
  keyColumn,
  textColumn,
} from "react-datasheet-grid"
import type { CellWithId } from "react-datasheet-grid/dist/types"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, gql } from "@apollo/client"
import { NavigateProps, useParams, useNavigate } from "react-router-dom"
import Collapse from "@mui/material/Collapse"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import FormControl from "@mui/material/FormControl"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useHotkeys } from "react-hotkeys-hook"

import { selectColumn } from "~/app/jobs/components/SelectColumn"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import HelicalPileInstallationWorksheetDiagramEditor from "~/components/helicalPileInstallationWorksheet/HelicalPileInstallationWorksheetDiagramEditor"
import RegularReferenceTable from "~/components/helicalPileInstallationWorksheet/RegularReferenceTable"
import MobileHomeReferenceTable from "~/components/helicalPileInstallationWorksheet/MobileHomeReferenceTable"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import {
  calculateAchievedCompressionCapacity,
  calculateAchievedTensionCapacity,
  calculateRequiredCompressionCapacity,
  calculateRequiredTorque,
  getKtFactor,
  PileTypeOptions,
} from "~/components/helicalPileInstallationWorksheet/util"
import { asInt, isBlank, isNumeric, parseGraphQLErrorCode, SETTINGS } from "~/util"
import { usePrompt } from "~/hooks/usePrompt"
import {
  HelicalPileInstallationWorksheetTemplate,
  HelicalPileInstallationWorksheetTemplateFormInput,
  HelicalPileInstallationWorksheetTemplateLineItem,
  HelicalPileInstallationWorksheetTemplateLineItemFormInput,
  HelicalPileInstallationWorksheetType,
  PileType,
  Snack,
} from "~/types"
import { HelicalPileInstallationWorksheetContextMenu } from "~/app/jobs/components/HelicalPileInstallationWorksheetContextMenu"
import { SectionHeader } from "~/components"
import ExpandMore from "~/components/ExpandMore"

const GET_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATE = gql`
  query GetHelicalPileInstallationWorksheetTemplateById($id: ID!) {
    getHelicalPileInstallationWorksheetTemplateById(id: $id) {
      id
      type
      name
      diagram
      lineItems {
        id
        pileUsed
        helixUsed
        number
        requiredCompressionCapacity
        requiredTorque
        totalPileLength
        pileInclination
        diagramId
        cutOffElevation
      }
    }
  }
`

const EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATE = gql`
  mutation EditHelicalPileInstallationWorksheetTemplate(
    $id: ID!
    $type: HelicalPileInstallationWorksheetType
    $name: String!
    $diagram: String
    $lineItems: [HelicalPileInstallationWorksheetTemplateLineItemInput!]
  ) {
    editHelicalPileInstallationWorksheetTemplate(
      input: { id: $id, type: $type, name: $name, diagram: $diagram, lineItems: $lineItems }
    ) {
      template {
        id
        type
        name
        diagram
        lineItems {
          id
          pileUsed
          helixUsed
          number
          requiredCompressionCapacity
          requiredTorque
          totalPileLength
          pileInclination
          diagramId
          cutOffElevation
        }
      }
    }
  }
`

const CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATE = gql`
  mutation CreateHelicalPileInstallationWorksheetTemplate(
    $name: String!
    $type: HelicalPileInstallationWorksheetType!
    $diagram: String
    $lineItems: [HelicalPileInstallationWorksheetTemplateLineItemInput!]
  ) {
    createHelicalPileInstallationWorksheetTemplate(
      input: { name: $name, type: $type, diagram: $diagram, lineItems: $lineItems }
    ) {
      template {
        id
        type
        name
        diagram
        lineItems {
          id
          pileUsed
          helixUsed
          number
          requiredCompressionCapacity
          requiredTorque
          totalPileLength
          pileInclination
          diagramId
          cutOffElevation
        }
      }
    }
  }
`

function renderReferenceTable(
  type: HelicalPileInstallationWorksheetType | null
): JSX.Element | null {
  if (type === HelicalPileInstallationWorksheetType.REGULAR) {
    return <RegularReferenceTable />
  } else if (type === HelicalPileInstallationWorksheetType.MOBILE_HOME) {
    return <MobileHomeReferenceTable />
  } else {
    return null
  }
}

function EditHelicalPileInstallationWorksheetTemplate() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const navigate = useNavigate()
  const [type, setType] = useState<HelicalPileInstallationWorksheetType | null>(() =>
    id ? null : HelicalPileInstallationWorksheetType.REGULAR
  )
  const [name, setName] = useState<string>("")
  const [template, setTemplate] = useState<HelicalPileInstallationWorksheetTemplateFormInput>({
    name: "",
    type: HelicalPileInstallationWorksheetType.REGULAR,
    lineItems: [] as HelicalPileInstallationWorksheetTemplateLineItemFormInput[],
  })
  const [activeColumn, setActiveColumn] = useState<CellWithId | null>(null)
  const [showPileSpecs, setShowPileSpecs] = useState<boolean>(true)
  const [snack, setSnack] = useState<Snack>()
  const [errors, setErrors] = useState<{ name: string | null }>(() => ({
    name: null,
  }))

  const editorRef = useRef()

  // setup a keyboard shortcut to save the HelicalPileInstallationWorksheetTemplate. ctrl+s or cmd+s.
  useHotkeys("mod+s", (e) => {
    e.preventDefault()
    handleSave(template)
  })

  const { loading: getHelicalPileInstallationWorksheetTemplateLoading } = useQuery(
    GET_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATE,
    {
      variables: { id },
      skip: !id,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted: (data) => {
        const templateData = data?.getHelicalPileInstallationWorksheetTemplateById
        if (templateData) {
          processHelicalPileInstallationWorksheetTemplateData(templateData)
        }
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [
    editHelicalPileInstallationWorksheetTemplate,
    { loading: editHelicalPileInstallationWorksheetTemplateLoading },
  ] = useMutation(EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATE, {
    onCompleted: (data) => {
      const templateData = data?.editHelicalPileInstallationWorksheetTemplate?.template
      if (templateData) {
        processHelicalPileInstallationWorksheetTemplateData(templateData)
      }
      setIsDirty(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [
    createHelicalPileInstallationWorksheetTemplate,
    { loading: createHelicalPileInstallationWorksheetTemplateLoading },
  ] = useMutation(CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATE, {
    onCompleted: (data) => {
      const templateData = data?.createHelicalPileInstallationWorksheetTemplate?.template
      if (templateData) {
        processHelicalPileInstallationWorksheetTemplateData(templateData)
      }
      setIsDirty(false)
      navigate(`/app/settings/templates/helicalPileInstallationWorksheet/edit/${templateData.id}`)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const loading =
    getHelicalPileInstallationWorksheetTemplateLoading ||
    editHelicalPileInstallationWorksheetTemplateLoading ||
    createHelicalPileInstallationWorksheetTemplateLoading

  function processHelicalPileInstallationWorksheetTemplateData(
    templateData: HelicalPileInstallationWorksheetTemplate
  ) {
    setType(templateData.type)
    const currentPiles = templateData.lineItems
      .map((li) => ({
        id: li.id,
        diagramId: li.diagramId,
        number: li.number,
        achievedCompressionCapacity: li.achievedCompressionCapacity,
        achievedTensionCapacity: li.achievedTensionCapacity,
        achievedTorque: li.achievedTorque,
        cutOffElevation: li.cutOffElevation,
        helixUsed: li.helixUsed,
        pileInclination: li.pileInclination,
        pileUsed: li.pileUsed,
        requiredCompressionCapacity: li.requiredCompressionCapacity,
        requiredTorque: li.requiredTorque,
        totalPileLength: li.totalPileLength,
      }))
      .sort(
        (
          a: HelicalPileInstallationWorksheetTemplateLineItem,
          b: HelicalPileInstallationWorksheetTemplateLineItem
        ) => (asInt(a.number) ?? 0) - (asInt(b.number) ?? 0)
      )

    setTemplate({
      id: templateData.id,
      name: templateData.name,
      type: templateData.type ?? HelicalPileInstallationWorksheetType.REGULAR,
      diagram: templateData.diagram,
      lineItems: currentPiles ?? [],
    })

    setType(templateData.type ?? HelicalPileInstallationWorksheetType.REGULAR)
    setName(templateData.name ?? "")

    editorRef.current?.fromJSON(templateData.diagram)
  }

  /**
   * Add a new pile (line item) to the template.
   * @param lineItem - an object with the following properties: number, diagramId
   *  These props are set by the caller, which is HelicalPileInstallationWorksheetDiagramEditor.
   * @param diagramJson
   */
  const handleAddPile = useCallback(
    (lineItem: HelicalPileInstallationWorksheetTemplateLineItemFormInput, diagramJson: any) => {
      const priorLineItem = template.lineItems?.[template.lineItems.length - 1]
      lineItem.pileUsed = (priorLineItem ? priorLineItem.pileUsed : "178") as PileType
      lineItem.helixUsed = priorLineItem ? priorLineItem.helixUsed : '9"'
      lineItem.safetyFactor = 2 // SF; kind of a "fudge" factor to account for uncertainty of soil conditions
      lineItem.requiredCompressionCapacity = priorLineItem
        ? priorLineItem.requiredCompressionCapacity
        : null
      lineItem.requiredTorque = priorLineItem ? priorLineItem.requiredTorque : null
      const lines = [...(template.lineItems ?? []), lineItem]

      setTemplate((prev) => ({
        ...prev,
        diagram: JSON.stringify(diagramJson),
        lineItems: lines as HelicalPileInstallationWorksheetTemplateLineItemFormInput[],
      }))
      setIsDirty(true)
    },
    [template.lineItems]
  )

  const handleRemovePiles = useCallback(
    (pileDiagramIds: string[]) => {
      let jsonString = ""
      pileDiagramIds.forEach((id) => {
        jsonString = editorRef.current?.removePile(id)
      })

      const newLineItems = (template.lineItems ?? [])
        .filter((li) => !pileDiagramIds.includes(li.diagramId))
        .sort((a, b) => (asInt(a.number) ?? 0) - (asInt(b.number) ?? 0))
        .map((li, idx) => ({
          ...li,
          number: idx + 1,
        }))

      setTemplate((prev) => {
        const c = {
          ...prev,
          lineItems: newLineItems,
          diagram: jsonString,
        }

        return c
      })

      setIsDirty(true)
    },
    [template.lineItems]
  )

  /**
   * The diagram was modified in some way that doesn't affect line item data
   * (although it might be that a line item diagram object was moved around)
   */
  const handleUpdateDiagram = useCallback((diagramJson: any) => {
    setTemplate((prev) => {
      return {
        ...prev,
        diagram: JSON.stringify(diagramJson),
      }
    })
    setIsDirty(true)
  }, [])

  /**
   * This function takes a template object as an argument rather than just
   * working on the `template` state variable because in some cases you need
   * to modify the template and immediately save. If you just update the template
   * state object via setTemplate and then immediately call this function expecting
   * the `template` state object to be up-to-date, you're gonna have a bad time.
   * There needs to be a re-render cycle after calling setTemplate before the template
   * state object reflects your changes.
   */
  function handleSave(
    updatedHelicalPileInstallationWorksheetTemplate: HelicalPileInstallationWorksheetTemplateFormInput
  ): void {
    const piles = updatedHelicalPileInstallationWorksheetTemplate.lineItems?.map(
      (pile: HelicalPileInstallationWorksheetTemplateLineItemFormInput) => {
        const requiredCompressionCapacity = pile.requiredCompressionCapacity
          ? asInt(pile.requiredCompressionCapacity)
          : null
        const requiredTorque = pile.requiredTorque ? asInt(pile.requiredTorque) : null
        const totalPileLength = parseFloat(pile.totalPileLength)
        const cutOffElevation = parseFloat(pile.cutOffElevation)
        const pileInclination = parseFloat(pile.pileInclination)
        const achievedTorque = asInt(pile.achievedTorque)

        return {
          diagramId: pile.diagramId,
          number: pile.number,
          requiredCompressionCapacity: requiredCompressionCapacity,
          achievedCompressionCapacity: pile.achievedCompressionCapacity,
          requiredTorque: requiredTorque,
          achievedTorque: achievedTorque ?? null,
          pileUsed: pile.pileUsed,
          helixUsed: pile.helixUsed,
          totalPileLength: totalPileLength ?? null,
          cutOffElevation: cutOffElevation ?? null,
          pileInclination: pileInclination ?? null,
          achievedTensionCapacity: pile.achievedTensionCapacity ?? null,
        }
      }
    )

    const variables = {
      ...updatedHelicalPileInstallationWorksheetTemplate,
      type,
      name,
      diagram: editorRef.current?.toJSON(),
      lineItems: piles,
    }

    setIsDirty(false)

    if (updatedHelicalPileInstallationWorksheetTemplate?.id) {
      editHelicalPileInstallationWorksheetTemplate({ variables })
    } else {
      createHelicalPileInstallationWorksheetTemplate({ variables })
    }
  }

  function renderSaveButton() {
    return (
      <Button
        color="primary"
        data-testid="saveHelicalPileInstallationWorksheetTemplateButton"
        disabled={
          !name ||
          editHelicalPileInstallationWorksheetTemplateLoading ||
          createHelicalPileInstallationWorksheetTemplateLoading
        }
        onClick={() => {
          handleSave(template)
        }}
        sx={{
          fontWeight: "bold",
          minWidth: 135,
        }}
        variant="contained"
      >
        {editHelicalPileInstallationWorksheetTemplateLoading ||
        createHelicalPileInstallationWorksheetTemplateLoading ? (
          <CircularProgress color="secondary" size={20} thickness={6.0} />
        ) : (
          <span>{t("saveTemplate")}</span>
        )}
      </Button>
    )
  }

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  const columns: Column<HelicalPileInstallationWorksheetTemplateLineItem>[] = [
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "pileUsed">(
        "pileUsed",
        selectColumn({ options: PileTypeOptions })
      ),
      title: t("helicalPileInstallationWorksheet.pileUsed"),
      deleteValue: ({ rowData }) => {
        return {
          ...rowData,
          pileUsed: "",
        }
      },
      copyValue: ({ rowData }) =>
        PileTypeOptions.find((option) => option.value === rowData.pileUsed)?.value ?? null,
      pasteValue: ({ rowData, value }) => {
        return {
          ...rowData,
          pileUsed: PileTypeOptions.find((option) => option.value === value)?.value ?? "",
        }
      },
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "helixUsed">(
        "helixUsed",
        textColumn
      ),
      title: t("helicalPileInstallationWorksheet.helixUsed"),
      deleteValue: ({ rowData }) => {
        return {
          ...rowData,
          helixUsed: "",
        }
      },
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "requiredCompressionCapacity">(
        "requiredCompressionCapacity",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.requiredCompressionCapacity"),
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "requiredTorque">(
        "requiredTorque",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.requiredTorque"),
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "totalPileLength">(
        "totalPileLength",
        floatColumn
      ),
      title: t("helicalPileInstallationWorksheet.totalPileLength"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "cutOffElevation">(
        "cutOffElevation",
        floatColumn
      ),
      title: t("helicalPileInstallationWorksheet.cutOffElevation"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "pileInclination">(
        "pileInclination",
        floatColumn
      ),
      title: t("helicalPileInstallationWorksheet.pileInclination"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "achievedTorque">(
        "achievedTorque",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.achievedTorque"),
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "achievedCompressionCapacity">(
        "achievedCompressionCapacity",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.achievedCompressionCapacity"),
      disabled: true,
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetTemplateLineItem, "achievedTensionCapacity">(
        "achievedTensionCapacity",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.achievedTensionCapacity"),
      disabled: true,
    },
  ]

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/templates", titleKey: "templates" },
              {
                to: "/app/settings/templates/helicalPileInstallationWorksheet",
                titleKey: "templates",
              },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey={id ? "edit" : "create"}
          />
          <Box sx={classes.contentContainer}>
            <Box sx={classes.headerControlsContainer}>
              <Box sx={classes.textFieldContainer}>
                <FielderTextField
                  error={!!errors.name}
                  fullWidth
                  label={t("templateName")}
                  name="name"
                  onBlur={() => {
                    if (isBlank(name)) {
                      setErrors((prev) => ({
                        ...prev,
                        name: "page.settings.templates.common.validation.name.required",
                      }))
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        name: null,
                      }))
                    }
                  }}
                  onChange={(e: any) => {
                    const val = e.target.value
                    if (isBlank(val)) {
                      setErrors((prev) => ({
                        ...prev,
                        name: "page.settings.templates.common.validation.name.required",
                      }))
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        name: null,
                      }))
                    }
                    setName(val)
                  }}
                  onFocus={(e) => e.target.select()}
                  required
                  style={{ marginTop: 0 }}
                  value={name}
                />
                {errors.name ? (
                  <FieldHelperText error message={t(errors.name)} />
                ) : (
                  <FieldHelperText message={t("page.settings.templates.common.helperText.name")} />
                )}
              </Box>
              <Box sx={[classes.buttonContainer, { marginTop: 0 }]}>{renderSaveButton()}</Box>
            </Box>
            <Box sx={classes.diagramContainer}>
              <HelicalPileInstallationWorksheetDiagramEditor
                loading={loading}
                onAddPile={handleAddPile}
                onRemovePiles={handleRemovePiles}
                onUpdateDiagram={handleUpdateDiagram}
                ref={editorRef}
              />
            </Box>
            <Paper sx={{ width: "100%", marginBottom: "1rem", paddingBottom: "1rem" }}>
              <SectionHeader>
                <Box
                  onClick={() => setShowPileSpecs((prev) => !prev)}
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box component="label" sx={{ cursor: "pointer" }}>
                    {t("helicalPileInstallationWorksheet.pileSpecs")}
                  </Box>
                  <ExpandMore
                    aria-expanded={showPileSpecs}
                    aria-label={t("helicalPileInstallationWorksheet.pileSpecs") as string}
                    expand={showPileSpecs}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>
              </SectionHeader>
              <Collapse in={showPileSpecs}>
                {template.lineItems && template.lineItems?.length > 0 ? (
                  <DataSheetGrid
                    addRowsComponent={false}
                    columns={columns}
                    contextMenuComponent={HelicalPileInstallationWorksheetContextMenu}
                    disableContextMenu={false}
                    headerRowHeight={60}
                    height={2000}
                    onActiveCellChange={(arg: { cell: CellWithId | null }) => {
                      setActiveColumn(arg.cell)
                    }}
                    onChange={(newValue, operations) => {
                      for (const operation of operations) {
                        switch (operation.type) {
                          case "UPDATE": {
                            const updatedRows = newValue.slice(
                              operation.fromRowIndex,
                              operation.toRowIndex
                            )
                            if (activeColumn?.colId === "pileUsed") {
                              for (const updatedRow of updatedRows) {
                                const pileType =
                                  PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                                  PileTypeOptions[0]

                                if (updatedRow.requiredCompressionCapacity) {
                                  updatedRow.requiredTorque = calculateRequiredTorque(
                                    pileType.value as PileType,
                                    updatedRow.requiredCompressionCapacity
                                  )
                                }

                                if (updatedRow.achievedTorque) {
                                  updatedRow.achievedCompressionCapacity =
                                    calculateAchievedCompressionCapacity(
                                      updatedRow.achievedTorque ?? 0,
                                      2,
                                      getKtFactor(updatedRow.pileUsed)
                                    )
                                }
                              }
                            } else if (activeColumn?.colId === "requiredCompressionCapacity") {
                              for (const updatedRow of updatedRows) {
                                const pileType =
                                  PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                                  PileTypeOptions[0]
                                const newRCC = updatedRow.requiredCompressionCapacity ?? 0
                                updatedRow.requiredTorque = calculateRequiredTorque(
                                  pileType.value as PileType,
                                  newRCC
                                )
                              }
                            } else if (activeColumn?.colId === "requiredTorque") {
                              for (const updatedRow of updatedRows) {
                                const pileType =
                                  PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                                  PileTypeOptions[0]
                                const newRequiredTorque = updatedRow.requiredTorque ?? 0
                                updatedRow.requiredCompressionCapacity =
                                  calculateRequiredCompressionCapacity(
                                    pileType.value as PileType,
                                    newRequiredTorque
                                  )
                              }
                            } else if (activeColumn?.colId === "totalPileLength") {
                              for (const updatedRow of updatedRows) {
                                if (isNumeric(updatedRow.achievedTorque)) {
                                  updatedRow.achievedCompressionCapacity =
                                    calculateAchievedCompressionCapacity(
                                      updatedRow.achievedTorque ?? 0,
                                      2,
                                      getKtFactor(updatedRow.pileUsed)
                                    )
                                  updatedRow.achievedTensionCapacity =
                                    calculateAchievedTensionCapacity(
                                      updatedRow.totalPileLength ?? 0,
                                      updatedRow.achievedCompressionCapacity
                                    )
                                }
                              }
                            } else if (activeColumn?.colId === "achievedTorque") {
                              for (const updatedRow of updatedRows) {
                                updatedRow.achievedCompressionCapacity =
                                  calculateAchievedCompressionCapacity(
                                    updatedRow.achievedTorque ?? 0,
                                    2,
                                    getKtFactor(updatedRow.pileUsed)
                                  )
                                updatedRow.achievedTensionCapacity =
                                  calculateAchievedTensionCapacity(
                                    updatedRow.totalPileLength ?? 0,
                                    updatedRow.achievedCompressionCapacity
                                  )
                              }
                            }
                            setTemplate((prev) => {
                              return {
                                ...prev,
                                lineItems: newValue,
                              }
                            })
                            break
                          }
                          case "DELETE": {
                            const deletedRows = (template.lineItems ?? []).slice(
                              operation.fromRowIndex,
                              operation.toRowIndex
                            )
                            const pileDiagramIds = deletedRows.map((row) => row.diagramId)
                            handleRemovePiles(pileDiagramIds)
                            break
                          }
                          default:
                            break
                        }
                      }
                    }}
                    onFocus={(arg: { cell: CellWithId }) => {
                      setActiveColumn(arg.cell)
                    }}
                    value={template.lineItems}
                  />
                ) : (
                  <Box
                    sx={{
                      color: (theme) => theme.fielderColors.mutedText,
                      fontSize: "1rem",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  >
                    {t("helicalPileInstallationWorksheet.pileSpecsEmpty")}
                  </Box>
                )}
              </Collapse>
            </Paper>
            <section style={{ marginTop: "0.5rem", width: "98%" }}>
              <FormControl component="fieldset">
                <legend style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {t("helicalPileInstallationWorksheet.selectReferenceChartForTemplate")}
                </legend>
                <RadioGroup
                  aria-label="reference chart type"
                  name="row-radio-buttons-group"
                  onChange={(e: any) => {
                    setIsDirty(true)
                    setType(e.target.value)
                  }}
                  row
                  value={type}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={t("helicalPileInstallationWorksheet.typeOptions.REGULAR") as string}
                    sx={classes.radioButtonLabel}
                    value={HelicalPileInstallationWorksheetType.REGULAR}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={t("helicalPileInstallationWorksheet.typeOptions.MOBILE_HOME") as string}
                    sx={classes.radioButtonLabel}
                    value={HelicalPileInstallationWorksheetType.MOBILE_HOME}
                  />
                </RadioGroup>
              </FormControl>
              {renderReferenceTable(type)}
            </section>
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  contentContainer: {
    paddingBottom: "25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "1161px",
    maxWidth: "1161px",
    margin: "0 auto",
  },
  diagramContainer: {
    width: "100%",
    height: "400px",
    backgroundColor: "#fefefe",
    marginBottom: "0.625rem",
  },
  headerControlsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "0.625rem",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "1.25rem",
  },
  textFieldContainer: {
    width: "50%",
    minWidth: "400px",
    maxWidth: "800px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
  },
  radioButtonLabel: {
    fontSize: "0.875rem",
  },
  table: {
    "& th": {
      lineHeight: "inherit",
      fontSize: "0.8rem",
      padding: "0.25rem 1rem",
    },
  },
  metaColumn: {
    backgroundColor: "#F8DBC0",
  },
}

export default EditHelicalPileInstallationWorksheetTemplate
