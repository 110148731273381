/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { alpha } from "@mui/material/styles"
import TextField, { type TextFieldProps } from "@mui/material/TextField"
import type { FilledInputProps } from "@mui/material/FilledInput"

const FielderTextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  return (
    <TextField
      InputProps={
        {
          ...props.InputProps,
          disableUnderline: true,
          inputRef: ref,
        } as Partial<FilledInputProps>
      }
      margin="normal"
      sx={(theme) => {
        const style = {
          marginTop: 0,
          marginBottom: 0,
          "& label.Mui-focused": {
            color: theme.fielderColors.black,
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff",
            border: "1px solid #e2e2e1",
            borderColor: theme.fielderColors.inputBorder,
            overflow: "hidden",
            borderRadius: "4px",
            transition: theme.transitions.create(["border-color", "box-shadow"]),
            "&:hover": {
              backgroundColor: "#fff",
            },
            "&.Mui-focused": {
              backgroundColor: "#fff",
              boxShadow: `${alpha(theme.fielderColors.inputBorderFocused, 0.25)} 0 0 0 2px`,
              borderColor: theme.fielderColors.inputBorderFocused,
            },
            "& .MuiInputAdornment-root": {
              marginRight: "4px",
            },
          },
          "& .Mui-error": {
            borderColor: theme.fielderColors.error,
          },
        }

        if (!props.label) {
          style["& .MuiInputBase-input"] = {
            padding: "0.5rem",
          }
        }

        return style
      }}
      variant="filled"
      {...props}
    />
  )
})

FielderTextField.displayName = "FielderTextField"

export default FielderTextField
