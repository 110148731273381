import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Navigate, useParams, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid2"
import Paper from "@mui/material/Paper"

import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import { CREATE_ORGANIZATION_ITEM } from "~/queries/createOrganizationItem"
import { INVENTORY, parseGraphQLErrorCode } from "~/util"
import { useAuth } from "~/context/AuthContext"
import type { Snack } from "~/types"
import { ItemType } from "~/types"
import ItemForm from "./components/ItemForm"

function CreateItem() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const routeParams = useParams()
  const itemType = routeParams?.itemType?.toUpperCase() as ItemType
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()

  const [createOrganizationItem, { loading }] = useMutation(CREATE_ORGANIZATION_ITEM, {
    onCompleted: (data) => {
      const id = data.createOrganizationItem.organizationItem.id
      setRedirectTo({ to: `/app/inventory/edit/${id}`, replace: false })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  function handleSave(payload: any) {
    createOrganizationItem({ variables: payload })
  }

  function handleCancel() {
    setRedirectTo({ to: "/app/inventory/list", replace: true })
  }

  function getPageTitle(): string {
    switch (itemType) {
      case ItemType.BUNDLE:
        return t("createBundle") as string
      case ItemType.PRODUCT:
        return t("createProduct") as string
      case ItemType.SERVICE:
        return t("createService") as string
      default:
        return "Invalid Item Type"
    }
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t(INVENTORY.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={INVENTORY}>
        <Box
          sx={(theme) => {
            return {
              margin: "0 0.5rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: INVENTORY.path, titleKey: INVENTORY.titleKey }]}
            icon={INVENTORY.icon}
            leafTitleKey={getPageTitle()}
          />
          <Grid container direction="row" justifyContent="flex-start">
            <Grid size={6}>
              <Paper sx={{ marginBottom: "5rem" }}>
                <SectionHeader>
                  <label>{t("basicInfo")}</label>
                </SectionHeader>
                <Divider />
                <SectionContent>
                  <ItemForm
                    currencyCode={user?.organization?.currencyCode ?? "USD"}
                    isLoading={loading}
                    item={{ type: itemType }}
                    onCancel={handleCancel}
                    onSave={handleSave}
                  />
                </SectionContent>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default CreateItem
