/* eslint-disable react/jsx-no-literals */
import React from "react"
import { useTranslation } from "react-i18next"

function RegularReferenceTable() {
  const { t } = useTranslation()

  return (
    <table css={classes.refTable}>
      <thead>
        <tr>
          <th colSpan="2" rowSpan="2" width="7%">
            {t("helicalPileInstallationWorksheet.appliedTorque")}
          </th>
          <th rowSpan="3" width="5%">
            {t("helicalPileInstallationWorksheet.suggestedPileBasedOnTubeConnectionFailure")}
          </th>
          <th rowSpan="3" width="5%">
            {t("helicalPileInstallationWorksheet.wallThickness")}
          </th>
          <th css={classes.highlightOrange} rowSpan="3" width="5%">
            {t("helicalPileInstallationWorksheet.tubeConnectionFailurePoint")}
          </th>
          <th css={classes.highlightYellow} rowSpan="3" width="5%">
            {t("helicalPileInstallationWorksheet.safeTorqueForTurning")} (SF=2)
          </th>
          <th rowSpan="3" width="4%">
            {t("helicalPileInstallationWorksheet.ktFactor")}
          </th>
          <th colSpan="6" width="5%">
            {t("helicalPileInstallationWorksheet.pileCapacitiesInSoil")} (SF=2) SLS
          </th>
          <th colSpan="6" width="5%">
            {t("helicalPileInstallationWorksheet.pileCapacitiesInSoil")} (SF=2) ULS
          </th>
        </tr>
        <tr>
          <th colSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.compression")}
          </th>
          <th colSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.tension")}{" "}
            <div>{t("helicalPileInstallationWorksheet.lessThan14FeetDepth")}</div>
          </th>
          <th colSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.tension")}{" "}
            <div>{t("helicalPileInstallationWorksheet.greaterThan14FeetDepth")}</div>
          </th>
          <th colSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.compression")}
          </th>
          <th colSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.tension")}{" "}
            <div>{t("helicalPileInstallationWorksheet.lessThan14FeetDepth")}</div>
          </th>
          <th colSpan="2" width="5%">
            {t("helicalPileInstallationWorksheet.tension")}{" "}
            <div>{t("helicalPileInstallationWorksheet.greaterThan14FeetDepth")}</div>
          </th>
        </tr>
        <tr>
          <th width="3%">lbf-ft</th>
          <th width="3%">Nm</th>
          <th>lb</th>
          <th>kN</th>
          <th>lb</th>
          <th>kN</th>
          <th>lb</th>
          <th>kN</th>
          <th>lb</th>
          <th>kN</th>
          <th>lb</th>
          <th>kN</th>
          <th>lb</th>
          <th>kN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>250</td>
          <td>339</td>
          <td>1⅞</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>1338</td>
          <td css={classes.highlightYellow}>956</td>
          <td>10</td>
          <td>1250</td>
          <td>6</td>
          <td>625</td>
          <td>3</td>
          <td>875</td>
          <td>4</td>
          <td>1750</td>
          <td>8</td>
          <td>875</td>
          <td>4</td>
          <td>1225</td>
          <td>5</td>
        </tr>
        <tr>
          <td>500</td>
          <td>678</td>
          <td>1⅞</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>1338</td>
          <td css={classes.highlightYellow}>956</td>
          <td>10</td>
          <td>2500</td>
          <td>11</td>
          <td>1250</td>
          <td>6</td>
          <td>1750</td>
          <td>8</td>
          <td>3500</td>
          <td>16</td>
          <td>1750</td>
          <td>8</td>
          <td>2450</td>
          <td>11</td>
        </tr>
        <tr>
          <td>750</td>
          <td>1017</td>
          <td>1⅞</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>1338</td>
          <td css={classes.highlightYellow}>956</td>
          <td>10</td>
          <td>3750</td>
          <td>17</td>
          <td>1875</td>
          <td>8</td>
          <td>2625</td>
          <td>12</td>
          <td>5250</td>
          <td>23</td>
          <td>2625</td>
          <td>12</td>
          <td>3675</td>
          <td>16</td>
        </tr>
        <tr>
          <td>1000</td>
          <td>1356</td>
          <td>1⅞</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>1338</td>
          <td css={classes.highlightYellow}>956</td>
          <td>10</td>
          <td>5000</td>
          <td>22</td>
          <td>2500</td>
          <td>11</td>
          <td>3500</td>
          <td>16</td>
          <td>7000</td>
          <td>31</td>
          <td>3500</td>
          <td>16</td>
          <td>4900</td>
          <td>22</td>
        </tr>
        <tr>
          <td>1250</td>
          <td>1695</td>
          <td>1⅞</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>1338</td>
          <td css={classes.highlightYellow}>956</td>
          <td>10</td>
          <td>6250</td>
          <td>28</td>
          <td>3125</td>
          <td>14</td>
          <td>4375</td>
          <td>19</td>
          <td>8750</td>
          <td>39</td>
          <td>4375</td>
          <td>19</td>
          <td>6125</td>
          <td>27</td>
        </tr>
        <tr css={classes.groupStart}>
          <td>1500</td>
          <td>2034</td>
          <td>2⅜</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>2263</td>
          <td css={classes.highlightYellow}>1617</td>
          <td>10</td>
          <td>7500</td>
          <td>33</td>
          <td>3750</td>
          <td>17</td>
          <td>5250</td>
          <td>23</td>
          <td>10500</td>
          <td>47</td>
          <td>5250</td>
          <td>23</td>
          <td>7350</td>
          <td>33</td>
        </tr>
        <tr>
          <td>1750</td>
          <td>2373</td>
          <td>2⅜</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>2263</td>
          <td css={classes.highlightYellow}>1617</td>
          <td>10</td>
          <td>8750</td>
          <td>39</td>
          <td>4375</td>
          <td>19</td>
          <td>6125</td>
          <td>27</td>
          <td>12250</td>
          <td>54</td>
          <td>6125</td>
          <td>27</td>
          <td>8575</td>
          <td>38</td>
        </tr>
        <tr>
          <td>2000</td>
          <td>2712</td>
          <td>2⅜</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>2263</td>
          <td css={classes.highlightYellow}>1617</td>
          <td>10</td>
          <td>10000</td>
          <td>44</td>
          <td>5000</td>
          <td>22</td>
          <td>7000</td>
          <td>31</td>
          <td>14000</td>
          <td>62</td>
          <td>7000</td>
          <td>31</td>
          <td>9800</td>
          <td>44</td>
        </tr>
        <tr>
          <td>2250</td>
          <td>3051</td>
          <td>2⅜</td>
          <td>0.154</td>
          <td css={classes.highlightOrange}>2263</td>
          <td css={classes.highlightYellow}>1617</td>
          <td>10</td>
          <td>11250</td>
          <td>50</td>
          <td>5625</td>
          <td>25</td>
          <td>7875</td>
          <td>35</td>
          <td>15750</td>
          <td>70</td>
          <td>7875</td>
          <td>35</td>
          <td>11025</td>
          <td>49</td>
        </tr>
        <tr css={classes.groupStart}>
          <td>2500</td>
          <td>3390</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>11250</td>
          <td>50</td>
          <td>5625</td>
          <td>25</td>
          <td>7875</td>
          <td>35</td>
          <td>15750</td>
          <td>70</td>
          <td>7875</td>
          <td>35</td>
          <td>11025</td>
          <td>49</td>
        </tr>
        <tr>
          <td>2750</td>
          <td>3729</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>12375</td>
          <td>55</td>
          <td>6188</td>
          <td>28</td>
          <td>8663</td>
          <td>39</td>
          <td>17325</td>
          <td>77</td>
          <td>8663</td>
          <td>39</td>
          <td>12128</td>
          <td>54</td>
        </tr>
        <tr>
          <td>3000</td>
          <td>4068</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>13500</td>
          <td>60</td>
          <td>6750</td>
          <td>30</td>
          <td>9450</td>
          <td>42</td>
          <td>18900</td>
          <td>84</td>
          <td>9450</td>
          <td>42</td>
          <td>13230</td>
          <td>59</td>
        </tr>
        <tr>
          <td>3250</td>
          <td>4407</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>14625</td>
          <td>65</td>
          <td>7313</td>
          <td>33</td>
          <td>10238</td>
          <td>46</td>
          <td>20475</td>
          <td>91</td>
          <td>10238</td>
          <td>46</td>
          <td>14333</td>
          <td>64</td>
        </tr>
        <tr>
          <td>3500</td>
          <td>4746</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>15750</td>
          <td>70</td>
          <td>7875</td>
          <td>35</td>
          <td>11025</td>
          <td>49</td>
          <td>22050</td>
          <td>98</td>
          <td>11025</td>
          <td>49</td>
          <td>15435</td>
          <td>69</td>
        </tr>
        <tr>
          <td>3750</td>
          <td>5085</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>16875</td>
          <td>75</td>
          <td>8438</td>
          <td>38</td>
          <td>11813</td>
          <td>53</td>
          <td>23625</td>
          <td>105</td>
          <td>11813</td>
          <td>53</td>
          <td>16538</td>
          <td>74</td>
        </tr>
        <tr>
          <td>4000</td>
          <td>5424</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>18000</td>
          <td>80</td>
          <td>9000</td>
          <td>40</td>
          <td>12600</td>
          <td>56</td>
          <td>25200</td>
          <td>112</td>
          <td>12600</td>
          <td>56</td>
          <td>17640</td>
          <td>78</td>
        </tr>
        <tr>
          <td>4250</td>
          <td>5763</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>19125</td>
          <td>85</td>
          <td>9563</td>
          <td>43</td>
          <td>13388</td>
          <td>60</td>
          <td>26775</td>
          <td>119</td>
          <td>13388</td>
          <td>60</td>
          <td>18743</td>
          <td>83</td>
        </tr>
        <tr>
          <td>4500</td>
          <td>6102</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>20250</td>
          <td>90</td>
          <td>10125</td>
          <td>45</td>
          <td>14175</td>
          <td>63</td>
          <td>28350</td>
          <td>126</td>
          <td>14175</td>
          <td>63</td>
          <td>19845</td>
          <td>88</td>
        </tr>
        <tr>
          <td>4750</td>
          <td>6441</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>21375</td>
          <td>95</td>
          <td>10688</td>
          <td>48</td>
          <td>14963</td>
          <td>67</td>
          <td>29925</td>
          <td>133</td>
          <td>14963</td>
          <td>67</td>
          <td>20948</td>
          <td>93</td>
        </tr>
        <tr>
          <td>5000</td>
          <td>6780</td>
          <td>2⅞</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>5032</td>
          <td css={classes.highlightYellow}>3594</td>
          <td>9</td>
          <td>22500</td>
          <td>100</td>
          <td>11250</td>
          <td>50</td>
          <td>15750</td>
          <td>70</td>
          <td>31500</td>
          <td>140</td>
          <td>15750</td>
          <td>70</td>
          <td>22050</td>
          <td>98</td>
        </tr>
        <tr css={classes.groupStart}>
          <td>5250</td>
          <td>7118</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>18375</td>
          <td>82</td>
          <td>9188</td>
          <td>41</td>
          <td>12863</td>
          <td>57</td>
          <td>25725</td>
          <td>114</td>
          <td>12863</td>
          <td>57</td>
          <td>18008</td>
          <td>80</td>
        </tr>
        <tr>
          <td>5500</td>
          <td>7457</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>19250</td>
          <td>86</td>
          <td>9625</td>
          <td>43</td>
          <td>13475</td>
          <td>60</td>
          <td>26950</td>
          <td>120</td>
          <td>13475</td>
          <td>60</td>
          <td>18865</td>
          <td>84</td>
        </tr>
        <tr>
          <td>5750</td>
          <td>7796</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>20125</td>
          <td>90</td>
          <td>10063</td>
          <td>45</td>
          <td>14088</td>
          <td>63</td>
          <td>28175</td>
          <td>125</td>
          <td>14088</td>
          <td>63</td>
          <td>19723</td>
          <td>88</td>
        </tr>
        <tr>
          <td>6000</td>
          <td>8135</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>21000</td>
          <td>93</td>
          <td>10500</td>
          <td>47</td>
          <td>14700</td>
          <td>65</td>
          <td>29400</td>
          <td>131</td>
          <td>14700</td>
          <td>65</td>
          <td>20580</td>
          <td>92</td>
        </tr>
        <tr>
          <td>6250</td>
          <td>8474</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>21875</td>
          <td>97</td>
          <td>10938</td>
          <td>49</td>
          <td>15313</td>
          <td>68</td>
          <td>30625</td>
          <td>136</td>
          <td>15313</td>
          <td>68</td>
          <td>21438</td>
          <td>95</td>
        </tr>
        <tr>
          <td>6500</td>
          <td>8813</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>22750</td>
          <td>101</td>
          <td>11375</td>
          <td>51</td>
          <td>15925</td>
          <td>71</td>
          <td>31850</td>
          <td>142</td>
          <td>15925</td>
          <td>71</td>
          <td>22295</td>
          <td>99</td>
        </tr>
        <tr>
          <td>6750</td>
          <td>9152</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>23625</td>
          <td>105</td>
          <td>11813</td>
          <td>53</td>
          <td>16538</td>
          <td>74</td>
          <td>33075</td>
          <td>147</td>
          <td>16538</td>
          <td>74</td>
          <td>23153</td>
          <td>103</td>
        </tr>
        <tr>
          <td>7000</td>
          <td>9491</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>24500</td>
          <td>109</td>
          <td>12250</td>
          <td>54</td>
          <td>17150</td>
          <td>76</td>
          <td>34300</td>
          <td>153</td>
          <td>17150</td>
          <td>76</td>
          <td>24010</td>
          <td>107</td>
        </tr>
        <tr>
          <td>7250</td>
          <td>9830</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>25375</td>
          <td>113</td>
          <td>12688</td>
          <td>56</td>
          <td>17763</td>
          <td>79</td>
          <td>35525</td>
          <td>158</td>
          <td>17763</td>
          <td>79</td>
          <td>24868</td>
          <td>111</td>
        </tr>
        <tr>
          <td>7500</td>
          <td>10169</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>26250</td>
          <td>117</td>
          <td>13125</td>
          <td>58</td>
          <td>18375</td>
          <td>82</td>
          <td>36750</td>
          <td>163</td>
          <td>18375</td>
          <td>82</td>
          <td>25725</td>
          <td>114</td>
        </tr>
        <tr>
          <td>7750</td>
          <td>10508</td>
          <td>3½</td>
          <td>0.250</td>
          <td css={classes.highlightOrange}>7820</td>
          <td css={classes.highlightYellow}>5586</td>
          <td>7</td>
          <td>27125</td>
          <td>121</td>
          <td>13563</td>
          <td>60</td>
          <td>18988</td>
          <td>84</td>
          <td>37975</td>
          <td>169</td>
          <td>18988</td>
          <td>84</td>
          <td>26583</td>
          <td>118</td>
        </tr>
      </tbody>
    </table>
  )
}

const classes = {
  refTable: {
    backgroundColor: "rgb(248, 219, 192)",
    fontSize: "0.75rem",
    borderCollapse: "collapse",
    width: "100%",

    "& thead > thead > tr:nth-of-type(even), & tbody > tr:nth-of-type(odd)": {
      backgroundColor: "rgb(242, 192, 146)",
    },

    "& th": {
      padding: "4px",
      border: "1px solid rgb(201, 124, 51)",
      textAlign: "center",
      verticalAlign: "text-top",
      fontWeight: 600,
    },

    "& td": {
      padding: "0",
      fontSize: "0.8125rem",
      border: "1px solid rgb(201, 124, 51)",
      textAlign: "center",
    },
  },
  highlightOrange: {
    backgroundColor: "rgb(255, 127, 7)",
  },
  highlightYellow: {
    backgroundColor: "rgb(255, 247, 7)",
  },
  groupStart: {
    borderTop: "2px solid rgb(0, 0, 0)",
  },
}

export default React.memo(RegularReferenceTable)
