import React, { useState, useRef, useEffect } from "react"
import * as Sentry from "@sentry/react"
import dayjs, { Dayjs } from "dayjs"
import { Link, Navigate, NavigateProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { gql, useQuery, useMutation, NetworkStatus } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import CircularProgress from "@mui/material/CircularProgress"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import WarningIcon from "@mui/icons-material/Warning"

import ConfirmationDialog from "~/components/ConfirmationDialog"
import EmptyState from "~/components/EmptyState"
import SearchField from "~/components/SearchField"
import SnackbarMessage from "~/components/SnackbarMessage"
import { ALL_JOB_WORKFLOWS } from "~/queries/allJobWorkflows"
import { EDIT_JOB } from "~/queries/editJob"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import { parseGraphQLErrorCode, MAX_INTEGER } from "~/util"
import useStore, { jobBoardWorkflowSelector, setJobBoardWorkflowSelector } from "~/store"
import { useAuth } from "~/context/AuthContext"
import CreateJobButton from "./CreateJobButton"
import JobBoard from "./JobBoard"
import { Job, JobWorkflow, Snack, DefaultPermission } from "~/types"
import EmailDialog, { EmailDialogMode } from "~/components/EmailDialog"
import { DateRangeFilter, calculateStartDate, calculateEndDate } from "./DateRangeFilter"
import { DateRangeShortcut } from "~/types/appTypes"
import { Detective } from "~/components/illustrations/Detective"

const GET_JOB_WORKFLOW_BY_ID = gql`
  query GetJobWorkflowById($id: ID!, $dateRangeStart: LocalDateTime, $dateRangeEnd: LocalDateTime) {
    getJobWorkflowById(id: $id) {
      id
      name
      isArchived
      steps {
        id
        jobStatus {
          id
          name
          lightColor
          mediumColor
          darkColor
        }
        destinationTransitions {
          id
          destination {
            id
          }
        }
        boardPosition
        jobs(
          jobFilter: {
            includeArchived: false
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
          }
        ) {
          id
          number
          description
          boardPosition
          address {
            addressString
          }
          assignments {
            id
            status
            startDate
            endDate
          }
          customer {
            id
            name
            email
            phoneNumber
            flags {
              id
              name
              colorCode
            }
          }
          siteContact {
            id
            firstName
            lastName
            email
            phoneNumber
          }
        }
      }
    }
  }
`

function JobBoardContainer() {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()
  const jobsScreenSettings = useStore((state) => state.jobsScreenSettings)
  const setJobsScreenSettings = useStore((state) => state.setJobsScreenSettings)
  const jobBoardWorkflow = useStore(jobBoardWorkflowSelector)
  const setJobBoardWorkflow = useStore(setJobBoardWorkflowSelector)
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [filter, setFilter] = useState<string>(jobsScreenSettings.searchTerm)
  const [columns, setColumns] = useState([])
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [workflow, setWorkflow] = useState<JobWorkflow | null>()
  const [workflowMenuAnchorEl, setWorkflowMenuAnchorEl] = useState(null)
  const [notifyCustomerPromptIsOpen, setNotifyCustomerPromptIsOpen] = useState<boolean>(false)
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false)
  const [dateRangeStart, setDateRangeStart] = useState<string>("")
  const [dateRangeEnd, setDateRangeEnd] = useState<string>("")
  const [editedJob, setEditedJob] = useState<Job>()
  const justDropped = useRef(false)

  useEffect(() => {
    if (filter !== jobsScreenSettings.searchTerm) {
      setJobsScreenSettings({
        ...jobsScreenSettings,
        searchTerm: filter,
      })
    }
    if (jobsScreenSettings.mode === "list") {
      setRedirectTo({ to: "/app/jobs/list", replace: false })
    }
  }, [filter, jobsScreenSettings, setJobsScreenSettings])

  useEffect(() => {
    if (jobsScreenSettings.dateRangeShortcut === DateRangeShortcut.Custom) {
      setDateRangeStart(
        jobsScreenSettings.dateRangeStart ??
          dayjs().subtract(30, "day").startOf("day").toISOString()
      )
      setDateRangeEnd(jobsScreenSettings.dateRangeEnd ?? dayjs().endOf("day").toISOString())
    } else if (jobsScreenSettings.dateRangeShortcut) {
      setDateRangeStart(calculateStartDate(jobsScreenSettings.dateRangeShortcut).toISOString())
      setDateRangeEnd(calculateEndDate(jobsScreenSettings.dateRangeShortcut).toISOString())
    } else {
      setDateRangeStart(dayjs().subtract(30, "day").startOf("day").toISOString())
      setDateRangeEnd(dayjs().endOf("day").toISOString())
      setJobsScreenSettings({
        ...jobsScreenSettings,
        dateRangeShortcut: DateRangeShortcut.Last30Days,
      })
    }
  }, [jobsScreenSettings, setJobsScreenSettings])

  const { data: allJobWorkflowsData } = useQuery(ALL_JOB_WORKFLOWS, {
    variables: {
      first: MAX_INTEGER,
      sortBy: "name",
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const allWorkflows = data?.allJobWorkflows
      const storedWorkflow = jobBoardWorkflow
      if (storedWorkflow?.id) {
        const match = allWorkflows.find((w: JobWorkflow) => w.id === storedWorkflow.id)
        if (match) {
          setWorkflow(match)
          return
        }
      }

      setJobBoardWorkflow(allWorkflows[0])
      setWorkflow(allWorkflows[0])
    },
  })

  const {
    data: workflowData,
    networkStatus: getJobWorkflowByIdNetworkStatus,
    refetch: refetchWorkflow,
    called: getJobWorkflowByIdCalled,
  } = useQuery(GET_JOB_WORKFLOW_BY_ID, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: jobBoardWorkflow?.id,
      dateRangeStart,
      dateRangeEnd,
    },
    ssr: false,
    skip: !jobBoardWorkflow?.id || !dateRangeStart || !dateRangeEnd,
    onError: (error) => {
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  useEffect(() => {
    if (workflowData && !justDropped.current) {
      const cols = workflowData?.getJobWorkflowById?.steps
        ?.map((s) => {
          return {
            id: s.id,
            boardPosition: s.boardPosition,
            jobStatus: s.jobStatus,
            jobs: s.jobs
              .map((j) => ({ ...j }))
              .sort((a: Job, b: Job) => (a.boardPosition ?? 0) - (b.boardPosition ?? 0)),
            destinationStepIds: s.destinationTransitions?.map((t) => t.destination.id) || [],
          }
        })
        ?.sort((a, b) => a.boardPosition - b.boardPosition)
      setColumns(cols)
    } else {
      justDropped.current = false
    }
  }, [workflowData])

  const [editJob] = useMutation(EDIT_JOB, {
    onCompleted: (data) => {
      const { editJob } = data
      setEditedJob(editJob?.job)
      if (editJob?.events?.map((e) => e.id)?.includes("PROMPT_CUSTOMER_NOTIFICATION")) {
        setNotifyCustomerPromptIsOpen(true)
      }
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
      refetchWorkflow()
    },
  })

  const [sendJobEmail, { loading: sendJobEmailLoading }] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setIsEmailDialogOpen(false)
      setSnack({ messageKey: "messages.messageSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({ messageKey: "messages.messageFailed", variant: "error" })
    },
  })

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const workflows = allJobWorkflowsData?.allJobWorkflows || []
  const loading =
    getJobWorkflowByIdNetworkStatus === NetworkStatus.loading || !getJobWorkflowByIdCalled
  const filteredColumns = filter
    ? columns.map((c) => {
        const lowerCaseSearchTerm = filter.toLowerCase()
        return {
          ...c,
          jobs: c.jobs.filter((j: Job) => {
            return (
              j.customer?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
              j.customer?.email?.toLowerCase().includes(lowerCaseSearchTerm) ||
              j.customer?.phoneNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
              `${j.siteContact?.firstName} ${j.siteContact?.lastName}`
                ?.toLowerCase()
                .includes(lowerCaseSearchTerm) ||
              j.siteContact?.email?.toLowerCase().includes(lowerCaseSearchTerm) ||
              j.siteContact?.phoneNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
              `${j.number}`.includes(lowerCaseSearchTerm) ||
              j.description?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
              j.address?.addressString?.toLowerCase().includes(lowerCaseSearchTerm)
            )
          }),
        }
      })
    : columns
  const filteredJobs = filteredColumns?.flatMap((c) => c.jobs) ?? []

  function renderZeroStateMessage() {
    if (!workflows || workflows?.length === 0) {
      return (
        <Box sx={{ paddingTop: "5rem", paddingBottom: "1.875rem" }}>
          <EmptyState title={t("page.jobBoard.noWorkflows.title")}>
            {hasPermissions?.([DefaultPermission.CreateJob]) ? (
              <Box>
                <Link style={{ color: "inherit" }} to="/app/settings/jobworkflows">
                  {t("page.jobBoard.noWorkflows.message")}
                </Link>
              </Box>
            ) : null}
          </EmptyState>
        </Box>
      )
    } else if (filteredJobs.length === 0) {
      return (
        <Box
          sx={{
            paddingTop: "5rem",
            paddingBottom: "1.875rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Detective size={100} />
          <EmptyState title={t("page.jobBoard.noMatchingResults.title")}>
            <Box sx={{ maxWidth: "32rem" }}>{t("page.jobBoard.noMatchingResults.message")}</Box>
          </EmptyState>
        </Box>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <ConfirmationDialog
        description={t("component.notifyCustomerDialog.message")}
        id="notify-customer-dialog"
        isLoading={false}
        onCancel={() => setNotifyCustomerPromptIsOpen(false)}
        onConfirm={() => {
          setIsEmailDialogOpen(true)
          setNotifyCustomerPromptIsOpen(false)
        }}
        open={notifyCustomerPromptIsOpen}
        title={t("component.notifyCustomerDialog.title")}
      />
      {isEmailDialogOpen ? (
        <EmailDialog
          isJobEditable={false}
          job={editedJob}
          mode={EmailDialogMode.COMPOSE_NEW}
          onCancel={() => setIsEmailDialogOpen(false)}
          onSend={(payload) => {
            sendJobEmail({ variables: payload })
          }}
          sending={sendJobEmailLoading}
        />
      ) : null}
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Box
        sx={(theme) => ({
          width: "100vw",
          px: "1.25rem",
          marginTop: 0,
          marginBottom: "0.875rem",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "0.5rem",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            alignItems: "center",
            width: "auto",
          },
        })}
      >
        <Box
          id="search-field-container"
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            gap: "0.5rem",
            [theme.breakpoints.up("sm")]: {
              flexDirection: "row",
              alignItems: "center",
              minWidth: "32rem",
              gap: "1rem",
            },
            [theme.breakpoints.up("md")]: {
              width: "auto",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              minWidth: "16rem",
              width: "100%",
              [theme.breakpoints.up("sm")]: {
                minWidth: "22rem",
                width: "auto",
              },
            })}
          >
            <SearchField
              onChange={(val) => {
                justDropped.current = false
                setFilter(val)
              }}
              placeholder={t("searchJobs")}
              term={filter}
            />
          </Box>
          <Box
            sx={(theme) => ({
              width: "100%",
              [theme.breakpoints.up("sm")]: {
                width: "auto",
              },
            })}
          >
            <DateRangeFilter
              currentEndDate={
                jobsScreenSettings.dateRangeEnd ? dayjs(jobsScreenSettings.dateRangeEnd) : null
              }
              currentStartDate={
                jobsScreenSettings.dateRangeStart
                  ? dayjs(jobsScreenSettings.dateRangeStart)
                  : dayjs().subtract(30, "day").startOf("day")
              }
              dateRangeShortcut={jobsScreenSettings.dateRangeShortcut ?? undefined}
              onApply={(
                dateRangeShortcut: DateRangeShortcut,
                startDate: Dayjs,
                endDate: Dayjs | null
              ) => {
                setJobsScreenSettings({
                  ...jobsScreenSettings,
                  dateRangeShortcut,
                  dateRangeStart:
                    dateRangeShortcut === DateRangeShortcut.Custom && startDate
                      ? startDate.toISOString()
                      : null,
                  dateRangeEnd:
                    dateRangeShortcut === DateRangeShortcut.Custom && endDate
                      ? endDate?.toISOString()
                      : null,
                })
              }}
            />
          </Box>
          <Box
            sx={{
              width: "1.5rem",
              minWidth: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!loading &&
            getJobWorkflowByIdNetworkStatus !== NetworkStatus.ready &&
            getJobWorkflowByIdNetworkStatus !== NetworkStatus.error ? (
              <CircularProgress color="secondary" size={20} />
            ) : null}
          </Box>
        </Box>
        {workflow?.isArchived ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(218, 21, 5, 0.1)",
              padding: "0.25rem 0.5rem",
              borderRadius: "4px",
              marginRight: "1.875rem",
            }}
          >
            <WarningIcon sx={{ fontSize: "1.125rem", color: "#DA1505", marginRight: "0.625rem" }} />
            <span>{t("page.jobWorkflow.workflow-is-archived")}</span>
          </Box>
        ) : null}
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            [theme.breakpoints.up("sm")]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              whiteSpace: "nowrap",
            },
            [theme.breakpoints.up("md")]: {
              gap: "1rem",
            },
          })}
        >
          <Box style={{ width: "100%" }}>
            {!workflows || workflows.length === 0 ? (
              <Button
                onClick={() => {
                  setJobsScreenSettings({
                    ...jobsScreenSettings,
                    mode: "list",
                  })
                }}
                style={{}}
              >
                {t("switchToListView")}
              </Button>
            ) : (
              <>
                <Button
                  aria-haspopup="true"
                  endIcon={<ArrowDropDownIcon />}
                  fullWidth
                  onClick={(event: any) => setWorkflowMenuAnchorEl(event.currentTarget)}
                  sx={(theme) => ({
                    color: theme.fielderColors.text,
                    borderColor: theme.fielderColors.mutedText,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    [theme.breakpoints.up("md")]: {
                      width: "auto",
                      whiteSpace: "nowrap",
                      border: "none",
                    },
                  })}
                  variant="text"
                >
                  {workflow?.name}
                </Button>
                <Menu
                  anchorEl={workflowMenuAnchorEl}
                  id="workflow-menu"
                  keepMounted
                  onClose={() => setWorkflowMenuAnchorEl(null)}
                  open={Boolean(workflowMenuAnchorEl)}
                >
                  <MenuItem
                    onClick={() =>
                      setJobsScreenSettings({
                        ...jobsScreenSettings,
                        mode: "list",
                      })
                    }
                  >
                    {t("switchToListView")}
                  </MenuItem>
                  {workflows.map((w: JobWorkflow) => (
                    <MenuItem
                      key={w.id}
                      onClick={() => {
                        justDropped.current = false
                        setJobBoardWorkflow(w) // saves selection to localStorage
                        setWorkflow(w)
                        setWorkflowMenuAnchorEl(null)
                      }}
                    >
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        {w.name}
                        {w.isArchived ? (
                          <Box style={{ fontSize: "0.6875rem", color: "#C70039" }}>
                            {t("archived")}
                          </Box>
                        ) : null}
                      </Box>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>
          {hasPermissions?.([DefaultPermission.CreateJob]) ? (
            <Box sx={{ width: "100%" }}>
              <CreateJobButton disabled={!workflow || workflow?.isArchived} />
            </Box>
          ) : null}
        </Box>
      </Box>
      {loading &&
      (!columns || columns.length === 0) &&
      allJobWorkflowsData?.allJobWorkflows?.length > 0 ? (
        <Box
          sx={{
            padding: "6.25rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" size={40} thickness={6.0} />
        </Box>
      ) : filteredColumns && filteredJobs.length > 0 ? (
        <JobBoard
          columns={filteredColumns}
          onJobDragEnd={(jobId, startStepId, endStepId, startIndex, endIndex) => {
            justDropped.current = true

            const startStep = filteredColumns.find((s) => s.id === startStepId)
            const endStep = filteredColumns.find((s) => s.id === endStepId)
            const job = startStep.jobs.find((j) => j.id === jobId)
            const startStepJobs = startStep?.jobs ? [...startStep.jobs] : []

            if (startStepId === endStepId) {
              // This callback doesn't seem to be invoked if the user drags a card around and drops it back where it was.
              // So I think we can assume that the position *has* changed.
              const topJob = startStepJobs[Math.max(endIndex - 1, 0)]
              const bottomJob = startStepJobs[Math.max(endIndex, 0)]
              const topPos = topJob?.boardPosition || 0
              const bottomPos = bottomJob?.boardPosition || 10000
              let boardPosition = 0

              if (endIndex === 0) {
                boardPosition = startStepJobs[0]?.boardPosition / 2 || 1
              } else {
                boardPosition =
                  (bottomPos - topPos) / 2 + (startIndex > endIndex ? topPos : bottomPos)
              }

              job.boardPosition = boardPosition

              startStepJobs.splice(startIndex, 1)
              startStepJobs.splice(endIndex, 0, job)

              const updatedStep = {
                ...startStep,
                jobs: startStepJobs,
              }

              const updatedColumns = filteredColumns.filter((c) => c.id !== startStep.id)
              updatedColumns.push(updatedStep)
              updatedColumns.sort((a, b) => a.boardPosition - b.boardPosition)

              setColumns(updatedColumns)

              editJob({
                variables: {
                  id: job.id,
                  boardPosition,
                },
              })
            } else {
              // move from one step to another
              const startJobIndex = startStepJobs.findIndex((j) => j.id === jobId)

              startStepJobs.splice(startJobIndex, 1)
              const updatedStartStep = {
                ...startStep,
                jobs: startStepJobs,
              }

              const endStepJobs = endStep?.jobs ? [...endStep.jobs] : []

              // figure out the new boardPosition for the job
              const topJob = endStepJobs[Math.max(endIndex - 1, 0)]
              const bottomJob = endStepJobs[Math.max(endIndex, 0)]
              const topPos = topJob?.boardPosition ?? 0
              const bottomPos = bottomJob?.boardPosition ?? 10000

              let boardPosition = 0
              if (endIndex === 0) {
                boardPosition = (endStepJobs[0]?.boardPosition ?? 1) / 2
              } else {
                boardPosition = (bottomPos - topPos) / 2 + topPos
              }

              endStepJobs.splice(endIndex, 0, job)
              const updatedEndStep = {
                ...endStep,
                jobs: endStepJobs,
              }

              const updatedColumns = filteredColumns.filter(
                (c) => c.id !== startStep.id && c.id !== endStep.id
              )
              updatedColumns.push(updatedStartStep)
              updatedColumns.push(updatedEndStep)
              updatedColumns.sort((a, b) => a.boardPosition - b.boardPosition)

              setColumns(updatedColumns)

              job.workflowStep = endStep
              job.boardPosition = boardPosition
              editJob({
                variables: {
                  id: job.id,
                  workflowStepId: endStep.id,
                  boardPosition,
                },
              })
            }
          }}
        />
      ) : (
        renderZeroStateMessage()
      )}
    </>
  )
}

export default JobBoardContainer
