/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import * as Sentry from "@sentry/react"
import isNil from "lodash/isNil"
import intersection from "lodash/intersection"
import { capitalize } from "lodash-es"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Checkbox from "@mui/material/Checkbox"
import TableContainer from "@mui/material/TableContainer"
import TablePagination from "@mui/material/TablePagination"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import SyncIcon from "@mui/icons-material/Sync"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"

import FielderIconButton from "~/components/FielderIconButton"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import EmptyState from "~/components/EmptyState"
import SortableTableHeader from "~/components/SortableTableHeader"
import TablePaginationActions from "~/components/TablePaginationActions"
import SearchField from "~/components/SearchField"
import NoResultsRow from "~/components/NoResultsRow"
import AddToCartDialog from "./components/AddToCartDialog"
import ItemLists from "./components/ItemLists"
import { ACTIVATE_ORGANIZATION_ITEMS } from "~/queries/activateOrganizationItems"
import { ADD_ITEMS_TO_CART } from "~/queries/addItemsToCart"
import { ADD_TO_CART } from "~/queries/addToCart"
import { ALL_ORGANIZATION_ITEMS } from "~/queries/allOrganizationItems"
import { DEACTIVATE_ORGANIZATION_ITEMS } from "~/queries/deactivateOrganizationItems"
import { SYNC_ORGANIZATION_ITEMS } from "~/queries/syncOrganizationItems"
import useStore, {
  OrganizationItemStatusFilter,
  inventoryListFiltersSelector,
  setInventoryListFiltersSelector,
} from "~/store"
import { isBlank, INVENTORY, NOT_SPECIFIED, parseGraphQLErrorCode, useDebounce } from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  Snack,
  DefaultPermission,
  OrganizationPlugin,
  PluginProviderCategory,
  OrganizationItem,
  StockType,
  OrganizationItemList,
  SortDirection as SortDirectionType,
  ItemType,
} from "~/types"
import FilterMenu, { FilterMenuOption } from "~/components/FilterMenu"
import CreateListDialog from "./components/CreateListDialog"

const NUM_COLUMNS = 7
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

// breakpoints for responsive layout
const BREAKPOINT_1 = 600

const GET_ORGANIZATION_INFO = gql`
  query GetOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      tradeName
      legalName
      currencyCode
      canSubmitProductOrders
      canCreateProductItems
      organizationItemLists {
        id
        name
        createdAt
      }
      plugins {
        id
        pluginProvider {
          id
          name
          category
        }
        tenantId
        supportsInventoryTracking
      }
    }
  }
`

const ADD_TO_ORGANIZATION_ITEM_LIST = gql`
  mutation AddToOrganizationItemList($id: ID!, $organizationItemIds: [ID!]!) {
    addToOrganizationItemList(input: { id: $id, organizationItemIds: $organizationItemIds }) {
      organizationItemList {
        id
        name
      }
    }
  }
`

const REMOVE_FROM_ORGANIZATION_ITEM_LIST = gql`
  mutation RemoveFromOrganizationItemList($id: ID!, $organizationItemIds: [ID!]!) {
    removeFromOrganizationItemList(input: { id: $id, organizationItemIds: $organizationItemIds }) {
      organizationItemList {
        id
        name
      }
    }
  }
`

function OrganizationItemListPage() {
  const { t } = useTranslation()
  const [createListDialogOpen, setCreateListDialogOpen] = useState<boolean>(false)
  const [openSecondaryMenu, setOpenSecondaryMenu] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<OrganizationItem | null>(null)
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<EventTarget & HTMLButtonElement>()
  const [bulkItemActionsMenuAnchorEl, setBulkItemActionsMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const [rowActionsMenuAnchorEl, setRowActionsMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const [quickFiltersMenuAnchorEl, setQuickFiltersMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const [statusFilterMenuAnchorEl, setStatusFilterMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const [itemTypesFilterMenuAnchorEl, setItemTypesFilterMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const [stockTypesFilterMenuAnchorEl, setStockTypesFilterMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showActionsMenu = Boolean(actionsMenuAnchorEl)
  const showBulkItemActionsMenu = Boolean(bulkItemActionsMenuAnchorEl)
  const showRowActionsMenu = Boolean(rowActionsMenuAnchorEl)
  const showQuickFiltersMenu = Boolean(quickFiltersMenuAnchorEl)
  const showStatusFiltersMenu = Boolean(statusFilterMenuAnchorEl)
  const showItemTypesFiltersMenu = Boolean(itemTypesFilterMenuAnchorEl)
  const showStockTypesFiltersMenu = Boolean(stockTypesFilterMenuAnchorEl)
  const inventoryListFilters = useStore(inventoryListFiltersSelector)
  const setInventoryListFilters = useStore(setInventoryListFiltersSelector)
  const inventoryListSettings = useStore((state) => state.inventoryListSettings)
  const setInventoryListSettings = useStore((state) => state.setInventoryListSettings)
  const location = useLocation()
  const { user, hasPermissions } = useAuth()
  const [addToCartItem, setAddToCartItem] = useState<OrganizationItem | null>(null)
  const [snack, setSnack] = useState<Snack | undefined>(() => {
    const { state } = location
    return state?.snack
  })
  const [after, setAfter] = useState<number | null>()
  const [before, setBefore] = useState<number | null>()
  const [first, setFirst] = useState<number | null>(inventoryListSettings.rowsPerPage)
  const [last, setLast] = useState<number | null>()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [selectedItems, setSelectedItems] = useState<OrganizationItem[]>([])
  const [filter, setFilter] = useState<string>(inventoryListSettings.searchTerm)
  const debouncedSearchTerm = useDebounce(filter, 500)

  useEffect(() => {
    if (debouncedSearchTerm !== inventoryListSettings.searchTerm) {
      setInventoryListSettings({
        ...inventoryListSettings,
        searchTerm: debouncedSearchTerm,
      })
      setAfter(null)
      setBefore(null)
      setFirst(inventoryListSettings.rowsPerPage)
      setLast(null)
    }
  }, [debouncedSearchTerm, inventoryListSettings, setInventoryListSettings])

  useEffect(() => {
    setAfter(null)
    setBefore(null)
    setFirst(inventoryListSettings.rowsPerPage)
    setLast(null)
    setSelectedItems([])
  }, [inventoryListFilters.selectedList, inventoryListSettings.rowsPerPage])

  const { loading, error, data, refetch } = useQuery(ALL_ORGANIZATION_ITEMS, {
    variables: {
      filter: debouncedSearchTerm,
      after,
      before,
      first,
      last,
      sortBy: inventoryListSettings.sortBy,
      sortDir: inventoryListSettings.sortDir,
      onlyInStockItems: Boolean(inventoryListFilters.quickFilters?.includes("IN_STOCK")),
      onlyAvailableItems: false,
      status: inventoryListFilters.status,
      itemTypes: inventoryListFilters.itemTypes,
      stockTypes: inventoryListFilters.stockTypes,
      organizationItemListId: inventoryListFilters.selectedList?.id,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: "ALL_ORGANIZATION_ITEMS",
      debounceTimeout: 50,
    },
  })

  const { data: orgData, refetch: refetchOrgInfo } = useQuery(GET_ORGANIZATION_INFO, {
    variables: {
      id: user?.organization?.id,
    },
    fetchPolicy: "cache-and-network",
  })

  const [syncOrganizationItems, { loading: syncLoading }] = useMutation(SYNC_ORGANIZATION_ITEMS, {
    onCompleted: () => {
      setSnack({
        messageKey: "page.inventoryList.sync.success",
        variant: "success",
      })
      refetch()
    },
    onError: (error) => {
      const errorCode = parseGraphQLErrorCode(error)

      if (errorCode.includes("organization-plugin.accounting-plugin")) {
        setSnack({ messageKey: errorCode, variant: "error" })
      } else {
        Sentry.captureException(error)
        setSnack({
          messageKey: "page.inventoryList.sync.error",
          variant: "error",
        })
      }
    },
  })

  const [addToCart, { loading: addToCartLoading }] = useMutation(ADD_TO_CART, {
    onCompleted: () => {
      setAddToCartItem(null)
      setSnack({
        messageKey: "messages.addToCart.success",
        variant: "success",
      })
      setActiveItem(null)
    },
    onError: (error) => {
      Sentry.captureException(error)
      setAddToCartItem(null)
      setSnack({
        messageKey: "messages.addToCart.error",
        variant: "error",
      })
    },
  })

  const [addItemsToCart] = useMutation(ADD_ITEMS_TO_CART, {
    onCompleted: () => {
      setAddToCartItem(null)
      const orderableItems = selectedItems?.filter((i) => isOrderable(i))
      if (orderableItems?.length > 0) {
        setSnack({
          messageKey: "messages.addItemsToCart.success",
          messageOptions: { count: orderableItems.length },
          variant: "success",
        })
      } else {
        setSnack({
          messageKey: "messages.addItemsToCart.noOrderableItems",
          variant: "info",
        })
      }

      setActiveItem(null)
    },
    onError: (error) => {
      Sentry.captureException(error)
      setAddToCartItem(null)
      setSnack({
        messageKey: "messages.addItemsToCart.error",
        variant: "error",
      })
    },
  })

  const [addToList] = useMutation(ADD_TO_ORGANIZATION_ITEM_LIST, {
    onCompleted: (data) => {
      const list = data.addToOrganizationItemList.organizationItemList
      setSnack({
        messageKey: "messages.addToList.success",
        messageOptions: {
          count: activeItem ? 1 : selectedItems?.length > 0 ? selectedItems.length : 0,
          listName: list?.name ?? "--",
        },
        variant: "success",
      })
      setActiveItem(null)
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({
        messageKey: "messages.addToList.error",
        variant: "error",
      })
    },
  })

  const [removeFromList] = useMutation(REMOVE_FROM_ORGANIZATION_ITEM_LIST, {
    onCompleted: (data) => {
      const list = data.removeFromOrganizationItemList.organizationItemList
      setSnack({
        messageKey: "messages.removeFromList.success",
        messageOptions: {
          count: activeItem ? 1 : selectedItems?.length > 0 ? selectedItems.length : 0,
          listName: list?.name ?? "--",
        },
        variant: "success",
      })
      setSelectedItems([]) // if the selected items were removed from the active list, we don't want the top list to be in an indeterminate state when there are potentially no rows selected.
      setActiveItem(null)
      refetch()
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({
        messageKey: "messages.removeFromList.error",
        variant: "error",
      })
    },
  })

  const [deactivateItems] = useMutation(DEACTIVATE_ORGANIZATION_ITEMS, {
    onCompleted: (data) => {
      const count = data.deactivateOrganizationItems.count
      setSnack({
        messageKey: "messages.deactivateItems.success",
        messageOptions: {
          count,
        },
        variant: "success",
      })
      setSelectedItems([])
      setActiveItem(null)
      refetch()
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({
        messageKey: "messages.deactivateItems.error",
        variant: "error",
      })
    },
  })

  const [activateItems] = useMutation(ACTIVATE_ORGANIZATION_ITEMS, {
    onCompleted: (data) => {
      const count = data.activateOrganizationItems.count
      setSnack({
        messageKey: "messages.activateItems.success",
        messageOptions: {
          count,
        },
        variant: "success",
      })
      setSelectedItems([])
      setActiveItem(null)
      refetch()
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({
        messageKey: "messages.activateItems.error",
        variant: "error",
      })
    },
  })

  function onGoToFirstPage() {
    setAfter(null)
    setBefore(null)
    setFirst(inventoryListSettings.rowsPerPage)
    setLast(null)
    setSelectedItems([])
  }

  function onGoToLastPage() {
    setAfter(null)
    setBefore(null)
    setFirst(null)
    setLast(inventoryListSettings.rowsPerPage)
    setSelectedItems([])
  }

  function onGoToPrevious() {
    setAfter(null)
    setBefore(pageInfo.startCursor)
    setFirst(null)
    setLast(inventoryListSettings.rowsPerPage)
    setSelectedItems([])
  }

  function onGoToNext() {
    setAfter(pageInfo.endCursor)
    setBefore(null)
    setFirst(inventoryListSettings.rowsPerPage)
    setLast(null)
    setSelectedItems([])
  }

  function handleChangeRowsPerPage(event: any) {
    const newPageSize = +event.target.value

    setInventoryListSettings({
      ...inventoryListSettings,
      rowsPerPage: newPageSize,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(newPageSize)
    setLast(null)
  }

  function sort(propertyName: string) {
    const newSortBy = propertyName
    const newSortDir =
      propertyName !== inventoryListSettings.sortBy
        ? SortDirectionType.ASC
        : inventoryListSettings.sortDir === SortDirectionType.ASC
          ? SortDirectionType.DESC
          : SortDirectionType.ASC

    setInventoryListSettings({
      ...inventoryListSettings,
      sortBy: newSortBy,
      sortDir: newSortDir,
      currentPage: 0,
    })

    setAfter(null)
    setBefore(null)
    setFirst(inventoryListSettings.rowsPerPage)
    setLast(null)
  }

  const handleSearchChange = (val: string) => {
    setFilter(val)
    setAfter(null)
    setBefore(null)
    setFirst(inventoryListSettings.rowsPerPage)
    setLast(null)
  }

  function handleRowClick(id: string) {
    setRedirectTo({ to: `/app/inventory/edit/${id}`, replace: false })
  }

  function isOrderable(organizationItem: OrganizationItem): boolean {
    return (
      Boolean(user?.organization?.canSubmitProductOrders) &&
      Boolean(organizationItem.isTopLevel) &&
      Boolean(!organizationItem.isArchived) &&
      Boolean(organizationItem.isAvailable) &&
      Boolean(hasPermissions?.([DefaultPermission.UpdateProductOrder]))
    )
  }

  function areFiltersApplied(): boolean {
    return (
      !isBlank(filter) ||
      inventoryListFilters.status !== "ALL" ||
      inventoryListFilters.itemTypes?.length > 0 ||
      inventoryListFilters.stockTypes?.length > 0 ||
      inventoryListFilters.quickFilters?.length > 0
    )
  }

  const pageInfo = data?.allOrganizationItems?.pageInfo || {}
  const organizationItems = data?.allOrganizationItems?.edges?.map((edge) => edge.node) || []
  const canCreateProductItems = Boolean(orgData?.getOrganizationById?.canCreateProductItems)
  const accountingPlugin = orgData?.getOrganizationById?.plugins?.find(
    (p: OrganizationPlugin) => p.pluginProvider.category === PluginProviderCategory.ACCOUNTING
  )
  const currencyCode =
    orgData?.getOrganizationById?.currencyCode ?? user?.organization?.currencyCode

  const itemLists: OrganizationItemList[] = [
    { id: "ALL", name: t("component.organizationItemList.allItems"), organizationItems: [] },
  ] as OrganizationItemList[]

  if (orgData?.getOrganizationById?.organizationItemLists?.length > 0) {
    const unsortedItemLists = [...orgData.getOrganizationById.organizationItemLists]
    unsortedItemLists.sort((a, b) => {
      const nameA = a.name.toUpperCase() // ignore upper and lowercase
      const nameB = b.name.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      // names must be equal
      return 0
    })
    itemLists.push(...unsortedItemLists)

    if (
      inventoryListFilters.selectedList &&
      !itemLists.find((l) => l.id === inventoryListFilters.selectedList?.id)
    ) {
      setInventoryListFilters({
        ...inventoryListFilters,
        selectedList: undefined,
      })
    }
  }

  const statusFilterOptions = [
    {
      value: "ALL",
      label: t(`organizationItemStatusFilterOption.ALL`),
    },
    {
      value: "ACTIVE",
      label: t(`organizationItemStatusFilterOption.ACTIVE`),
    },
    {
      value: "INACTIVE",
      label: t(`organizationItemStatusFilterOption.INACTIVE`),
    },
  ]

  if (redirectTo) {
    return <Navigate state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("sectionTitle.inventory")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={INVENTORY}>
        <Box
          sx={(theme) => {
            return {
              minWidth: "88rem",
              margin: "0 1.25rem",
              paddingBottom: "3rem",
              [theme.breakpoints.up("md")]: {
                margin: "0 1.25rem",
                // marginRight: "80px", // make sure the intercom chat bubble doesn't block the pagination controls
                paddingBottom: "12.5rem",
              },
            }
          }}
        >
          <PageHeader icon={INVENTORY.icon} leafTitleKey={INVENTORY.titleKey} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.625rem",
              marginRight: "1.25rem",
              marginTop: "-1.5rem",
            }}
          >
            <Box
              sx={{
                alignSelf: "flex-end",
                display: "flex",
                flexDirection: "row",
                gap: "0.25rem",
                alignItems: "center",
              }}
            >
              <a
                href={t("page.inventoryList.learnMoreUrl") as string}
                rel="noreferrer"
                style={{ color: "black" }}
                target="_blank"
              >
                {t("learnMore")}
              </a>
              <OpenInNewIcon sx={{ fontSize: "1rem" }} />
            </Box>
            <Paper
              sx={{
                minWidth: "86rem",
                overflowX: "auto",
                padding: "1rem",
                height: "calc(100vh - 200px)",
                display: "block",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1.25rem", height: "100%" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "0.625rem",
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      flex: 1,
                      maxWidth: "300px",
                      [theme.breakpoints.up(BREAKPOINT_1)]: {
                        marginTop: 0,
                      },
                    })}
                  >
                    <SearchField
                      onChange={handleSearchChange}
                      placeholder={t("search") as string}
                      term={filter}
                      testID="SearchField"
                    />
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    <FilterMenu
                      anchorEl={statusFilterMenuAnchorEl}
                      id="status-filters"
                      isOpen={showStatusFiltersMenu}
                      label={`${t("status")}: ${t(
                        "organizationItemStatusFilterOption." + inventoryListFilters.status
                      )}`}
                      mutuallyExclusive
                      onClickOption={(option: FilterMenuOption) => {
                        setInventoryListFilters({
                          ...inventoryListFilters,
                          status: option.value as OrganizationItemStatusFilter,
                        })
                      }}
                      onClose={() => setStatusFilterMenuAnchorEl(undefined)}
                      onOpen={(event) => {
                        setStatusFilterMenuAnchorEl(event.currentTarget)
                      }}
                      options={statusFilterOptions}
                      selectedOptions={[String(inventoryListFilters.status ?? "ACTIVE")]}
                    />
                    <FilterMenu
                      anchorEl={itemTypesFilterMenuAnchorEl}
                      id="item-type-filters"
                      isOpen={showItemTypesFiltersMenu}
                      label={t("itemType")}
                      mutuallyExclusive={false}
                      onClickOption={(option: FilterMenuOption) => {
                        const itemType = option.value
                        setInventoryListFilters({
                          ...inventoryListFilters,
                          itemTypes: inventoryListFilters.itemTypes?.includes(itemType)
                            ? inventoryListFilters.itemTypes.filter((t) => t !== itemType)
                            : [...(inventoryListFilters.itemTypes ?? []), itemType],
                        })
                      }}
                      onClose={() => setItemTypesFilterMenuAnchorEl(undefined)}
                      onOpen={(event) => {
                        setItemTypesFilterMenuAnchorEl(event.currentTarget)
                      }}
                      options={[
                        {
                          value: ItemType.PRODUCT,
                          label: t("itemTypeOptions.PRODUCT"),
                        },
                        {
                          value: ItemType.SERVICE,
                          label: t("itemTypeOptions.SERVICE"),
                        },
                        {
                          value: ItemType.BUNDLE,
                          label: t("itemTypeOptions.BUNDLE"),
                        },
                      ]}
                      selectedOptions={inventoryListFilters.itemTypes ?? []}
                    />
                    <FilterMenu
                      anchorEl={stockTypesFilterMenuAnchorEl}
                      id="stock-type-filters"
                      isOpen={showStockTypesFiltersMenu}
                      label={t("stockType")}
                      mutuallyExclusive={false}
                      onClickOption={(option: FilterMenuOption) => {
                        const stockType = option.value
                        setInventoryListFilters({
                          ...inventoryListFilters,
                          stockTypes: inventoryListFilters.stockTypes?.includes(stockType)
                            ? inventoryListFilters.stockTypes.filter((t) => t !== stockType)
                            : [...(inventoryListFilters.stockTypes ?? []), stockType],
                        })
                      }}
                      onClose={() => setStockTypesFilterMenuAnchorEl(undefined)}
                      onOpen={(event) => {
                        setStockTypesFilterMenuAnchorEl(event.currentTarget)
                      }}
                      options={[
                        {
                          value: StockType.STOCKED,
                          label: t("stockTypeOptions.STOCKED"),
                        },
                        {
                          value: StockType.NON_STOCKED,
                          label: t("stockTypeOptions.NON_STOCKED"),
                        },
                        {
                          value: StockType.SPECIAL_ORDER,
                          label: t("stockTypeOptions.SPECIAL_ORDER"),
                        },
                      ]}
                      selectedOptions={inventoryListFilters.stockTypes ?? []}
                    />
                    <FilterMenu
                      anchorEl={quickFiltersMenuAnchorEl}
                      id="quick-filters"
                      isOpen={showQuickFiltersMenu}
                      label={t("quickFilters")}
                      mutuallyExclusive={false}
                      onClickOption={(option: FilterMenuOption) => {
                        const quickFilter = option.value
                        setInventoryListFilters({
                          ...inventoryListFilters,
                          quickFilters: inventoryListFilters.quickFilters?.includes(quickFilter)
                            ? inventoryListFilters.quickFilters.filter((t) => t !== quickFilter)
                            : [...(inventoryListFilters.quickFilters ?? []), quickFilter],
                        })
                      }}
                      onClose={() => setQuickFiltersMenuAnchorEl(undefined)}
                      onOpen={(event) => {
                        setQuickFiltersMenuAnchorEl(event.currentTarget)
                      }}
                      options={[
                        {
                          value: "IN_STOCK",
                          label: t("onlyShowInStockItems") as string,
                        },
                      ]}
                      selectedOptions={inventoryListFilters.quickFilters ?? []}
                    />
                    {areFiltersApplied() ? (
                      <Button
                        onClick={() => {
                          setFilter("")
                          setInventoryListFilters({
                            ...inventoryListFilters,
                            status: "ALL",
                            itemTypes: [],
                            stockTypes: [],
                            quickFilters: [],
                          })
                        }}
                        sx={{ textDecoration: "underline" }}
                        variant="text"
                      >
                        {t("clearFilters")}
                      </Button>
                    ) : null}

                    <Box
                      sx={(theme) => ({
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        alignContent: "center",
                        gap: "2rem",
                        [theme.breakpoints.up("md")]: {
                          justifyContent: "flex-end",
                        },
                        [theme.breakpoints.up("lg")]: {
                          marginTop: 0,
                        },
                      })}
                    >
                      {selectedItems.length > 0 ? (
                        <Button
                          aria-controls={
                            showBulkItemActionsMenu ? "bulk-item-actions-menu" : undefined
                          }
                          aria-expanded={showBulkItemActionsMenu ? "true" : undefined}
                          aria-haspopup="true"
                          disableElevation
                          disabled={syncLoading}
                          endIcon={<KeyboardArrowDownIcon />}
                          id="actions-menu-button"
                          onClick={(event) => {
                            setOpenSecondaryMenu(false)
                            setBulkItemActionsMenuAnchorEl(event.currentTarget)
                          }}
                          variant="text"
                        >
                          {t("numItemsSelected", { count: selectedItems.length })}
                        </Button>
                      ) : null}
                      {hasPermissions?.([DefaultPermission.CreateOrganizationItem]) ? (
                        <Button
                          aria-controls={showActionsMenu ? "actions-menu" : undefined}
                          aria-expanded={showActionsMenu ? "true" : undefined}
                          aria-haspopup="true"
                          disableElevation
                          disabled={syncLoading}
                          endIcon={<KeyboardArrowDownIcon />}
                          id="actions-menu-button"
                          onClick={(event) => {
                            setActionsMenuAnchorEl(event.currentTarget)
                          }}
                          variant="outlined"
                        >
                          {t("actions")}
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    flex: 1,
                    overflow: "auto",
                  }}
                >
                  <ItemLists
                    itemLists={itemLists}
                    onListCreated={() => {
                      refetchOrgInfo()
                    }}
                    onListDeleted={() => {
                      refetchOrgInfo()
                      setInventoryListFilters({
                        ...inventoryListFilters,
                        selectedList: undefined,
                      })
                    }}
                    onListUpdated={() => {
                      refetchOrgInfo()
                    }}
                    onSelectList={(listId: string) => {
                      setInventoryListFilters({
                        ...inventoryListFilters,
                        selectedList:
                          listId !== "ALL" ? itemLists.find((l) => l.id == listId) : undefined,
                      })

                      setSelectedItems([])
                    }}
                    selectedList={inventoryListFilters.selectedList}
                  />
                  {!error && !loading && organizationItems?.length === 0 && (
                    <Box
                      sx={{
                        paddingTop: "1.875rem",
                        paddingBottom: "1.875rem",
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      {areFiltersApplied() ? (
                        <EmptyState
                          title={t("page.inventoryList.noMatchingResults.title") as string}
                        >
                          <Box>{t("page.inventoryList.noMatchingResults.message") as string}</Box>
                        </EmptyState>
                      ) : inventoryListFilters.selectedList?.id &&
                        inventoryListFilters.selectedList.id !== "ALL" ? (
                        <EmptyState title={t("page.inventoryList.emptyList.title") as string}>
                          {hasPermissions?.([DefaultPermission.UpdateOrganizationItemList]) ? (
                            <Box>{t("page.inventoryList.emptyList.message") as string}</Box>
                          ) : null}
                        </EmptyState>
                      ) : (
                        <EmptyState title={t("page.inventoryList.noOrgItems.title") as string}>
                          {!accountingPlugin &&
                          hasPermissions?.([DefaultPermission.CreateOrganizationItem]) ? (
                            <Box>{t("page.inventoryList.noOrgItems.message") as string}</Box>
                          ) : null}
                        </EmptyState>
                      )}
                    </Box>
                  )}
                  {!error && (loading || organizationItems?.length > 0) ? (
                    <Box
                      sx={{
                        flex: "4",
                        border: "1px solid #efefef",
                        borderRadius: "4px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TableContainer
                        sx={{
                          borderRadius: "4px",
                          flex: 1,
                          height: "100%",
                          maxHeight: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <Table
                          stickyHeader
                          sx={[
                            {
                              width: "100%",
                              minWidth: "1024px",
                            },
                            organizationItems?.length === 0 && { height: "100%" },
                          ]}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ ...classes.tableHead, padding: 0 }}>
                                <Checkbox
                                  checked={selectedItems.length > 0}
                                  indeterminate={Boolean(
                                    selectedItems.length > 0 &&
                                      intersection(selectedItems, organizationItems).length <
                                        organizationItems.length
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedItems(organizationItems)
                                    } else {
                                      setSelectedItems([])
                                    }
                                  }}
                                  size="small"
                                />
                              </TableCell>
                              <SortableTableHeader
                                isActiveSort={inventoryListSettings.sortBy === "code"}
                                label={t("itemCode") as string}
                                onClick={() => sort("code")}
                                sortDir={inventoryListSettings.sortDir}
                                sx={classes.tableHead}
                              />
                              <SortableTableHeader
                                isActiveSort={inventoryListSettings.sortBy === "name"}
                                label={t("name") as string}
                                onClick={() => sort("name")}
                                sortDir={inventoryListSettings.sortDir}
                                sx={classes.tableHead}
                                width="30%"
                              />
                              <SortableTableHeader
                                isActiveSort={inventoryListSettings.sortBy === "type"}
                                label={t("type") as string}
                                onClick={() => sort("type")}
                                sortDir={inventoryListSettings.sortDir}
                                sx={classes.tableHead}
                              />
                              <SortableTableHeader
                                isActiveSort={inventoryListSettings.sortBy === "category"}
                                label={t("category") as string}
                                onClick={() => sort("category")}
                                sortDir={inventoryListSettings.sortDir}
                                sx={classes.tableHead}
                              />
                              <SortableTableHeader
                                align="right"
                                isActiveSort={inventoryListSettings.sortBy === "qtyOnHand"}
                                label={t("qtyOnHand") as string}
                                onClick={() => sort("qtyOnHand")}
                                sortDir={inventoryListSettings.sortDir}
                                sx={classes.tableHead}
                              />
                              <SortableTableHeader
                                align="right"
                                isActiveSort={inventoryListSettings.sortBy === "reorderPoint"}
                                label={t("reorderPoint") as string}
                                onClick={() => sort("reorderPoint")}
                                sortDir={inventoryListSettings.sortDir}
                                sx={classes.tableHead}
                              />
                              <TableCell sx={classes.tableHead}> </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            style={organizationItems?.length === 0 ? { height: "100%" } : {}}
                          >
                            {!error && !loading && organizationItems?.length === 0 && (
                              <NoResultsRow colSpan={NUM_COLUMNS} />
                            )}
                            {!error && !loading
                              ? organizationItems?.map((node: OrganizationItem) => {
                                  const checked = Boolean(
                                    selectedItems.find((i) => i.id === node.id)
                                  )
                                  return (
                                    <TableRow
                                      key={node.id}
                                      onClick={() => {
                                        if (
                                          hasPermissions?.([DefaultPermission.ReadOrganizationItem])
                                        ) {
                                          handleRowClick(node.id)
                                        }
                                      }}
                                      sx={classes.dataRow}
                                    >
                                      <TableCell
                                        onClick={(e) => {
                                          e.preventDefault()
                                        }}
                                        sx={{ padding: 0 }}
                                      >
                                        <Checkbox
                                          checked={checked}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            if (checked) {
                                              setSelectedItems(
                                                selectedItems.filter((i) => i.id !== node.id)
                                              )
                                            } else {
                                              setSelectedItems([...selectedItems, node])
                                            }
                                          }}
                                          size="small"
                                        />
                                      </TableCell>
                                      <TableCell sx={{ paddingRight: "1rem" }}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            gap: "0.25rem",
                                          }}
                                        >
                                          <Box sx={{ maxWidth: "18rem" }}>
                                            {!isNil(node.code) ? node.code : NOT_SPECIFIED}
                                          </Box>
                                          {node.isArchived && node.isTopLevel ? (
                                            <Box
                                              sx={(theme) => {
                                                return {
                                                  color: theme.fielderColors.error,
                                                  fontSize: "0.625rem",
                                                  whiteSpace: "nowrap",
                                                }
                                              }}
                                            >
                                              <em>{t("itemDiscontinued")}</em>
                                            </Box>
                                          ) : null}
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        {!isNil(node.name) ? node.name : NOT_SPECIFIED}
                                      </TableCell>
                                      <TableCell>{capitalize(node.type)}</TableCell>
                                      <TableCell>
                                        {!isNil(node.category) ? node.category : NOT_SPECIFIED}
                                      </TableCell>
                                      <TableCell align="right">
                                        {!isNil(node.qtyOnHand) && node.type === "PRODUCT"
                                          ? node.qtyOnHand
                                          : NOT_SPECIFIED}
                                      </TableCell>
                                      <TableCell align="right">
                                        {!isNil(node.reorderPoint) && node.type === "PRODUCT"
                                          ? node.reorderPoint
                                          : NOT_SPECIFIED}
                                      </TableCell>
                                      <TableCell align="right" sx={{ paddingRight: "1rem" }}>
                                        <FielderIconButton
                                          aria-expanded={showRowActionsMenu ? "true" : undefined}
                                          aria-haspopup="true"
                                          aria-label="row actions menu button"
                                          onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            setActiveItem(node)
                                            setOpenSecondaryMenu(false)
                                            setRowActionsMenuAnchorEl(event.currentTarget)
                                          }}
                                        >
                                          <MoreVertIcon fontSize="inherit" />
                                        </FielderIconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                              : null}
                            {loading
                              ? [...Array(inventoryListSettings.rowsPerPage).keys()].map((i) => (
                                  <TableRow key={i} sx={classes.dataRow}>
                                    {[...Array(NUM_COLUMNS).keys()].map((j) => (
                                      <TableCell align={j > 2 ? "right" : "left"} key={j}>
                                        <Skeleton variant="text" />
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))
                              : null}
                            {error ? (
                              <TableRow>
                                <TableCell align="center" colSpan={NUM_COLUMNS}>
                                  <Box
                                    style={{
                                      color: "rgb(244, 67, 54)",
                                      fontWeight: 500,
                                      fontSize: "1.25rem",
                                      lineHeight: 1.6,
                                    }}
                                  >
                                    {t("errorLabel")}
                                    {": "}
                                    {error.message}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        ActionsComponent={() => (
                          <TablePaginationActions
                            hasNextPage={pageInfo.hasNextPage ?? false}
                            hasPreviousPage={pageInfo.hasPreviousPage ?? false}
                            onGoToFirstPage={onGoToFirstPage}
                            onGoToLastPage={onGoToLastPage}
                            onGoToNext={onGoToNext}
                            onGoToPrevious={onGoToPrevious}
                          />
                        )}
                        component="div"
                        count={-1}
                        labelDisplayedRows={() => ``}
                        labelRowsPerPage={t("rowsPerPage") as string}
                        onPageChange={() => {}}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        page={inventoryListSettings.currentPage}
                        rowsPerPage={inventoryListSettings.rowsPerPage}
                        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </MainLayout>

      {/* Row Actions menu */}
      {activeItem ? (
        <Menu
          anchorEl={rowActionsMenuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          elevation={1}
          onClose={() => {
            setRowActionsMenuAnchorEl(undefined)
          }}
          open={showRowActionsMenu}
          sx={{
            "& .MuiMenu-list": {
              minWidth: "200px",
            },
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ display: openSecondaryMenu ? "none" : "block" }}>
            {hasPermissions?.([DefaultPermission.UpdateOrganizationItem]) ? (
              <MenuItem
                aria-label={t(`editItem`) as string}
                onMouseDown={() => {
                  setRowActionsMenuAnchorEl(undefined)
                  handleRowClick(activeItem.id)
                }}
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                    fontSize: "0.875rem",
                    color: theme.fielderColors.text,
                    padding: "0.5rem 1rem",
                  }
                }}
              >
                <EditIcon sx={{ fontSize: "1rem" }} />
                <Box>{t(`editItem`)}</Box>
              </MenuItem>
            ) : null}
            {isOrderable(activeItem) ? (
              <MenuItem
                aria-label={t(`addToCart`) as string}
                onMouseDown={() => {
                  setRowActionsMenuAnchorEl(undefined)
                  setAddToCartItem(activeItem)
                }}
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                    fontSize: "0.875rem",
                    color: theme.fielderColors.text,
                  }
                }}
              >
                <AddShoppingCartIcon sx={{ fontSize: "1rem" }} />
                <Box>{t("addToCart") as string}</Box>
              </MenuItem>
            ) : null}
            {hasPermissions?.([DefaultPermission.UpdateOrganizationItemList]) ? (
              <MenuItem
                aria-label={t(`addToList`) as string}
                onMouseDown={() => {
                  setOpenSecondaryMenu(true)
                }}
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "0.875rem",
                    color: theme.fielderColors.text,
                    padding: "0.5rem 1rem",
                  }
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}
                >
                  <PlaylistAddIcon sx={{ fontSize: "1rem" }} />
                  <Box>{t(`addToList`)}</Box>
                </Box>
                <KeyboardArrowRightIcon sx={{ fontSize: "1rem" }} />
              </MenuItem>
            ) : null}
            {hasPermissions?.([DefaultPermission.UpdateOrganizationItemList]) &&
            inventoryListFilters.selectedList ? (
              <MenuItem
                aria-label={t(`removeFromList`) as string}
                onMouseDown={() => {
                  setRowActionsMenuAnchorEl(undefined)
                  removeFromList({
                    variables: {
                      id: inventoryListFilters.selectedList?.id,
                      organizationItemIds: [activeItem.id],
                    },
                  })
                }}
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                    fontSize: "0.875rem",
                    color: theme.fielderColors.text,
                    padding: "0.5rem 1rem",
                    minWidth: "200px",
                  }
                }}
              >
                <DeleteOutlinedIcon sx={{ fontSize: "1rem" }} />
                <Box>{t(`removeFromList`)}</Box>
              </MenuItem>
            ) : null}
            {accountingPlugin?.pluginProvider &&
            hasPermissions?.([DefaultPermission.SyncOrganizationItem]) ? (
              <MenuItem
                aria-label={t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`) as string}
                onMouseDown={() => {
                  setRowActionsMenuAnchorEl(undefined)
                  syncOrganizationItems()
                }}
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                    fontSize: "0.875rem",
                    color: theme.fielderColors.text,
                  }
                }}
              >
                <SyncIcon sx={{ fontSize: "1rem" }} />
                <Box>{t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`)}</Box>
              </MenuItem>
            ) : null}
            {hasPermissions?.([DefaultPermission.UpdateOrganizationItem]) ? (
              activeItem.isActive ? (
                <MenuItem
                  aria-label={t(`deactivate`) as string}
                  onMouseDown={() => {
                    setRowActionsMenuAnchorEl(undefined)
                    deactivateItems({
                      variables: {
                        organizationItemIds: [activeItem.id],
                      },
                    })
                  }}
                  sx={(theme) => {
                    return {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "1rem",
                      fontSize: "0.875rem",
                      color: theme.fielderColors.text,
                      padding: "0.5rem 1rem",
                    }
                  }}
                >
                  <DoDisturbAltOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Box>{t(`deactivate`)}</Box>
                </MenuItem>
              ) : (
                <MenuItem
                  aria-label={t(`activate`) as string}
                  onMouseDown={() => {
                    setRowActionsMenuAnchorEl(undefined)
                    activateItems({
                      variables: {
                        organizationItemIds: [activeItem.id],
                      },
                    })
                  }}
                  sx={(theme) => {
                    return {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "1rem",
                      fontSize: "0.875rem",
                      color: theme.fielderColors.text,
                      padding: "0.5rem 1rem",
                    }
                  }}
                >
                  <ElectricalServicesOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Box>{t(`activate`)}</Box>
                </MenuItem>
              )
            ) : null}
          </Box>
          <Box sx={{ display: openSecondaryMenu ? "block" : "none" }}>
            <Box
              sx={{
                padding: "0.25rem 0.5rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => setOpenSecondaryMenu(false)}
                sx={{
                  borderRadius: "4px",
                  border: "1px solid #efefef",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  padding: "0.25rem",
                }}
              >
                <KeyboardArrowLeftIcon />
              </Box>
              <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>{t("selectAList")}</Box>
            </Box>
            <Box
              sx={{
                maxHeight: "245px",
                overflowY: "scroll",
                marginTop: "0.5rem",
                borderTop: "1px solid #efefef",
                borderBottom: "1px solid #efefef",
              }}
            >
              {itemLists
                .filter(
                  (list) => list.id !== "ALL" && list.id !== inventoryListFilters.selectedList?.id
                )
                .map((list) => (
                  <MenuItem
                    aria-label={list.name}
                    key={list.id}
                    onMouseDown={() => {
                      setRowActionsMenuAnchorEl(undefined)
                      addToList({
                        variables: {
                          id: list.id,
                          organizationItemIds: [activeItem.id],
                        },
                      })
                    }}
                    sx={(theme) => {
                      return {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1rem",
                        fontSize: "0.875rem",
                        color: theme.fielderColors.text,
                        padding: "0.5rem 1rem",
                        minWidth: "200px",
                      }
                    }}
                  >
                    <Box>{list.name}</Box>
                  </MenuItem>
                ))}
            </Box>
            <MenuItem
              onMouseDown={() => {
                setRowActionsMenuAnchorEl(undefined)
                setCreateListDialogOpen(true)
              }}
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                  fontSize: "0.875rem",
                  color: theme.fielderColors.text,
                  padding: "0.5rem 1rem",
                }
              }}
            >
              <AddIcon sx={{ fontSize: "1rem" }} />
              <Box>{t("createList")}</Box>
            </MenuItem>
          </Box>
        </Menu>
      ) : null}
      {/* End Row actions menu */}

      {/* Bulk item actions menu */}
      <Menu
        anchorEl={bulkItemActionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={1}
        onClose={() => {
          setBulkItemActionsMenuAnchorEl(undefined)
        }}
        open={showBulkItemActionsMenu}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "200px",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: openSecondaryMenu ? "none" : "block" }}>
          {selectedItems.some((i) => isOrderable(i)) ? (
            <MenuItem
              aria-label={t(`addToCart`) as string}
              onMouseDown={() => {
                setBulkItemActionsMenuAnchorEl(undefined)
                addItemsToCart({
                  variables: {
                    organizationItemIds: selectedItems
                      .filter((i) => isOrderable(i))
                      .map((i) => i.id),
                  },
                })
              }}
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  fontSize: "0.875rem",
                  color: theme.fielderColors.text,
                }
              }}
            >
              <AddShoppingCartIcon sx={{ fontSize: "1rem" }} />
              <Box>{t("addToCart") as string}</Box>
            </MenuItem>
          ) : null}
          {hasPermissions?.([DefaultPermission.UpdateOrganizationItemList]) ? (
            <MenuItem
              aria-label={t(`addToList`) as string}
              onMouseDown={() => {
                setOpenSecondaryMenu(true)
              }}
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "0.875rem",
                  color: theme.fielderColors.text,
                  padding: "0.5rem 1rem",
                }
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}
              >
                <PlaylistAddIcon sx={{ fontSize: "1rem" }} />
                <Box>{t(`addToList`)}</Box>
              </Box>
              <KeyboardArrowRightIcon sx={{ fontSize: "1rem" }} />
            </MenuItem>
          ) : null}
          {hasPermissions?.([DefaultPermission.UpdateOrganizationItemList]) &&
          inventoryListFilters.selectedList?.id ? (
            <MenuItem
              aria-label={t(`removeFromList`) as string}
              onMouseDown={() => {
                setBulkItemActionsMenuAnchorEl(undefined)
                removeFromList({
                  variables: {
                    id: inventoryListFilters.selectedList?.id,
                    organizationItemIds: selectedItems.map((i) => i.id),
                  },
                })
              }}
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                  fontSize: "0.875rem",
                  color: theme.fielderColors.text,
                  padding: "0.5rem 1rem",
                  minWidth: "200px",
                }
              }}
            >
              <DeleteOutlinedIcon sx={{ fontSize: "1rem" }} />
              <Box>{t(`removeFromList`)}</Box>
            </MenuItem>
          ) : null}
          {hasPermissions?.([DefaultPermission.UpdateOrganizationItem]) ? (
            <Box>
              <MenuItem
                aria-label={t(`deactivate`) as string}
                onMouseDown={() => {
                  setBulkItemActionsMenuAnchorEl(undefined)
                  deactivateItems({
                    variables: {
                      organizationItemIds: selectedItems.map((i) => i.id),
                    },
                  })
                }}
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                    fontSize: "0.875rem",
                    color: theme.fielderColors.text,
                    padding: "0.5rem 1rem",
                  }
                }}
              >
                <DoDisturbAltOutlinedIcon sx={{ fontSize: "1rem" }} />
                <Box>{t(`deactivate`)}</Box>
              </MenuItem>
              <MenuItem
                aria-label={t(`activate`) as string}
                onMouseDown={() => {
                  setBulkItemActionsMenuAnchorEl(undefined)
                  activateItems({
                    variables: {
                      organizationItemIds: selectedItems.map((i) => i.id),
                    },
                  })
                }}
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                    fontSize: "0.875rem",
                    color: theme.fielderColors.text,
                    padding: "0.5rem 1rem",
                  }
                }}
              >
                <ElectricalServicesOutlinedIcon sx={{ fontSize: "1rem" }} />
                <Box>{t(`activate`)}</Box>
              </MenuItem>
            </Box>
          ) : null}
        </Box>
        <Box sx={{ display: openSecondaryMenu ? "block" : "none" }}>
          <Box
            sx={{
              padding: "0.25rem 0.5rem",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Box
              onClick={() => setOpenSecondaryMenu(false)}
              sx={{
                borderRadius: "4px",
                border: "1px solid #efefef",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                padding: "0.25rem",
              }}
            >
              <KeyboardArrowLeftIcon />
            </Box>
            <Box sx={{ fontSize: "0.875rem", fontWeight: "600" }}>{t("selectAList")}</Box>
          </Box>
          <Box
            sx={{
              maxHeight: "245px",
              overflowY: "scroll",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              // borderTop: "1px solid #efefef",
              // borderBottom: "1px solid #efefef",
            }}
          >
            {itemLists
              .filter(
                (list) => list.id !== "ALL" && list.id !== inventoryListFilters.selectedList?.id
              )
              .map((list) => (
                <MenuItem
                  aria-label={list.name}
                  key={list.id}
                  onMouseDown={() => {
                    setBulkItemActionsMenuAnchorEl(undefined)
                    addToList({
                      variables: {
                        id: list.id,
                        organizationItemIds: selectedItems.map((i) => i.id),
                      },
                    })
                  }}
                  sx={(theme) => {
                    return {
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      fontSize: "0.875rem",
                      color: theme.fielderColors.text,
                      padding: "0.875rem 1rem",
                      minWidth: "200px",
                    }
                  }}
                >
                  <Box>{list.name}</Box>
                </MenuItem>
              ))}
          </Box>
          <Divider />
          <MenuItem
            onMouseDown={() => {
              setBulkItemActionsMenuAnchorEl(undefined)
              setCreateListDialogOpen(true)
            }}
            sx={(theme) => {
              return {
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                fontSize: "0.875rem",
                color: theme.fielderColors.text,
                padding: "0.5rem 1rem",
              }
            }}
          >
            <AddIcon sx={{ fontSize: "1rem" }} />
            <Box>{t("createList")}</Box>
          </MenuItem>
        </Box>
      </Menu>
      {/* End of Bulk Item actions menu */}

      {/* Actions Menu */}
      <Menu
        anchorEl={actionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="actions-menu"
        keepMounted
        onClose={() => setActionsMenuAnchorEl(undefined)}
        open={showActionsMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {canCreateProductItems ? (
          <MenuItem
            onMouseDown={() => {
              setRedirectTo({ to: `/app/inventory/create/product` })
            }}
            sx={(theme) => {
              return {
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                fontSize: "0.875rem",
                color: theme.fielderColors.text,
                padding: "0.5rem 1rem",
              }
            }}
          >
            <AddIcon sx={{ fontSize: "1rem" }} />
            <Box>{t(`createProduct`)}</Box>
          </MenuItem>
        ) : null}
        <MenuItem
          onMouseDown={() => {
            setRedirectTo({ to: `/app/inventory/create/bundle` })
          }}
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              fontSize: "0.875rem",
              color: theme.fielderColors.text,
              padding: "0.5rem 1rem",
            }
          }}
        >
          <AddIcon sx={{ fontSize: "1rem" }} />
          <Box>{t(`createBundle`)}</Box>
        </MenuItem>
        <MenuItem
          onMouseDown={() => {
            setRedirectTo({ to: `/app/inventory/create/service` })
          }}
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              fontSize: "0.875rem",
              color: theme.fielderColors.text,
              padding: "0.5rem 1rem",
            }
          }}
        >
          <AddIcon sx={{ fontSize: "1rem" }} />
          <Box>{t(`createService`)}</Box>
        </MenuItem>
        {accountingPlugin?.pluginProvider &&
        hasPermissions?.([DefaultPermission.SyncOrganizationItem]) ? (
          <MenuItem
            aria-label={t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`) as string}
            onMouseDown={() => {
              setActionsMenuAnchorEl(undefined)
              syncOrganizationItems()
            }}
            sx={(theme) => {
              return {
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                fontSize: "0.875rem",
                color: theme.fielderColors.text,
                padding: "0.5rem 1rem",
              }
            }}
          >
            <SyncIcon sx={{ fontSize: "1rem" }} />
            <Box>{t(`syncWithPlugin.${accountingPlugin.pluginProvider.id}`)}</Box>
          </MenuItem>
        ) : null}
      </Menu>
      {/* End Actions Menu */}

      {addToCartItem ? (
        <AddToCartDialog
          currencyCode={currencyCode}
          loading={addToCartLoading}
          onClose={() => {
            setAddToCartItem(null)
          }}
          onSubmit={({ organizationItemId, quantity }) => {
            addToCart({
              variables: {
                organizationItemId,
                quantity,
              },
            })
          }}
          open={Boolean(addToCartItem)}
          organizationItem={addToCartItem}
        />
      ) : null}

      <CreateListDialog
        onCancel={() => setCreateListDialogOpen(false)}
        onClose={() => setCreateListDialogOpen(false)}
        onListCreated={(list: OrganizationItemList) => {
          setCreateListDialogOpen(false)
          refetchOrgInfo()
          addToList({
            variables: {
              id: list.id,
              organizationItemIds: activeItem ? [activeItem.id] : selectedItems.map((i) => i.id),
            },
          })
        }}
        open={createListDialogOpen}
      />
    </>
  )
}

const classes = {
  tableHead: {
    backgroundColor: "white",
    zIndex: 1000,
    position: "sticky",
    top: 0,
    whiteSpace: "nowrap",
    textWrap: "nowrap",
  },
  dataRow: {
    height: "3.75rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
  button: {
    marginLeft: "0.625rem",
    "& span": { whiteSpace: "nowrap" },
  },
} as const

export default OrganizationItemListPage
