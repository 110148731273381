import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Box from "@mui/material/Box"

interface ConfirmationDialogProps {
  readonly description: string
  readonly id?: string
  readonly isLoading?: boolean
  readonly negativeButtonTitle?: string | null
  readonly onCancel: (event: any) => void
  readonly onConfirm: (event: any) => void
  readonly open: boolean
  readonly positiveButtonTitle?: string | null
  readonly title?: string | null
  readonly titleBackgroundColor?: string
}

function ConfirmationDialog({
  description,
  id = "confirmation-dialog",
  isLoading = false,
  negativeButtonTitle,
  onCancel,
  onConfirm,
  open,
  positiveButtonTitle,
  title,
  titleBackgroundColor,
}: ConfirmationDialogProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Dialog
      aria-describedby={`${id}-description`}
      aria-labelledby={`${id}-title`}
      id={id}
      maxWidth="xs"
      onClose={onCancel}
      open={open}
    >
      {title ? (
        <DialogTitle
          id={`${id}-title`}
          sx={{
            py: "0.625rem",
            px: "1.5rem",
            backgroundColor: (theme) => titleBackgroundColor ?? theme.palette.primary.main,
          }}
        >
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent>
        <DialogContentText
          id={`${id}-description`}
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: "1.2rem",
            paddingTop: "1rem",
            px: "0.5rem",
          }}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <Button
          color="primary"
          disabled={isLoading}
          onClick={onCancel}
          sx={{ marginRight: "1.5rem" }}
          variant="outlined"
        >
          {negativeButtonTitle ?? t("no")}
        </Button>
        <Button
          autoFocus
          color="primary"
          disabled={isLoading}
          onClick={onConfirm}
          variant="contained"
        >
          {isLoading ? (
            <CircularProgress color="secondary" size={20} thickness={6.0} />
          ) : (
            <Box sx={{ fontWeight: "600" }}>{positiveButtonTitle ?? t("yes")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
