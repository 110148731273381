import React, { useCallback, useEffect, useMemo, useRef, forwardRef } from "react"
import isHotkey from "is-hotkey"
import isUrl from "is-url"
import { useTranslation } from "react-i18next"
import { Editable, withReact, Slate, useSelected, useFocused, ReactEditor } from "slate-react"
import { createEditor, Descendant, Editor, Transforms, Range } from "slate"
import { withHistory } from "slate-history"
import Paper from "@mui/material/Paper"
import Toolbar from "./Toolbar"
import FontOptions from "./FontOptions"
import { HOTKEYS, RichTextEditor } from "./RichTextEditor"
import { TemplateFieldOption, FontOption, TextSizeOption } from "../../types"
import Box from "@mui/material/Box"

const withInlines = (editor: Editor) => {
  const { isElementReadOnly, isInline, isSelectable, insertText, isVoid, addMark, removeMark } =
    editor

  editor.isElementReadOnly = (element) =>
    element.type === "templateField" || isElementReadOnly(element)

  editor.isSelectable = (element) => {
    if (element.type === "templateField") {
      return true
    }
    return isSelectable(element)
  }

  editor.addMark = (key, value) => {
    // Make sure to include TemplateFields
    const { selection } = editor
    if (selection) {
      Transforms.setNodes(
        editor,
        { [key]: value },
        { match: (n) => n.type === "templateField", split: false }
      )
    }

    // invoke the default behavior
    addMark(key, value)
  }

  editor.removeMark = (key, value) => {
    // Make sure to include TemplateFields
    const { selection } = editor
    if (selection) {
      Transforms.unsetNodes(editor, key, {
        match: (n) => n.type === "templateField",
        split: false,
      })
    }

    // invoke the default behavior
    removeMark(key, value)
  }

  editor.isInline = (element) => {
    return ["templateField", "link"].includes(element.type) ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === "templateField" ? true : isVoid(element)
  }

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      RichTextEditor.wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  return editor
}

/**
 * The `value` prop must be an object of the following minimal form:
 * [ { children: [{ text: "" }] } ]
 * Note that the `value` prop is really just an *initial* value for the Slate
 * Editor. The Slate editor manages its own internal state from there. So
 * if you keep passing in updated `value` props then the editor will not
 * be updated. That's why there is a useEffect block below.
 */
interface RichTextComposerProps {
  readonly autoFocus?: boolean
  readonly error?: boolean
  readonly hide?: boolean
  readonly value?: Descendant[]
  readonly onChange?: (value: Descendant[]) => void
  readonly onFocus?: () => void
  readonly placeholder?: string
  readonly renderRightToolbar?: () => React.ReactNode
  readonly templateFieldOptions?: TemplateFieldOption[]
}

export interface RichTextComposerRef {
  insertTemplateField: (templateField: TemplateFieldOption) => void
  editor: Editor
}

const DEFAULT_INITIAL_VALUE = [{ children: [{ text: "" }] }] as Descendant[]

const RichTextComposer = forwardRef<RichTextComposerRef, RichTextComposerProps>(
  (
    {
      autoFocus = true,
      error,
      hide,
      value = DEFAULT_INITIAL_VALUE,
      onChange,
      onFocus,
      placeholder,
      renderRightToolbar,
      templateFieldOptions,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const isFirstRender = useRef(true)
    const renderElement = useCallback((props) => <Element {...props} />, [])
    const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
    const editor = useMemo(() => withReact(withInlines(withHistory(createEditor()))), [])

    // Expose methods via ref
    React.useImperativeHandle(ref, () => ({
      insertTemplateField: (templateField: TemplateFieldOption) => {
        RichTextEditor.insertTemplateField(editor, templateField)
        Transforms.insertNodes(editor, { text: " " })
        Transforms.move(editor)
      },
      editor,
    }))

    /**
     * Replace the content of the editor when the `value` prop changes, unless
     * we're given the default minimum initial value mentioned above.
     */
    useEffect(() => {
      if (
        !isFirstRender.current &&
        !(
          value?.length === 1 &&
          value[0].children?.length === 1 &&
          value[0].children[0].text === ""
        )
      ) {
        Transforms.select(editor, [])
        Transforms.delete(editor)
        Transforms.insertNodes(editor, value, { at: [0, 0], mode: "highest" })
      } else {
        isFirstRender.current = false
      }
    }, [editor, value])

    const handleAddLink = useCallback(
      (event: any) => {
        event.preventDefault()
        const url = window.prompt(t("component.richTextEditor.addLinkPrompt") as string)
        if (!url) return
        RichTextEditor.insertLink(editor, url)
      },
      [editor, t]
    )

    const handleRemoveLink = useCallback(
      (event: any) => {
        event.preventDefault()
        if (RichTextEditor.isLinkActive(editor)) {
          RichTextEditor.unwrapLink(editor)
        }
      },
      [editor]
    )

    const handleAddTemplateField = useCallback(
      (event: any, templateField: TemplateFieldOption) => {
        event.preventDefault()
        RichTextEditor.insertTemplateField(editor, templateField)
      },
      [editor]
    )

    const handleChangeAlignment = useCallback(
      (event: any, alignment: string) => {
        event.preventDefault()
        RichTextEditor.setBlockAlignment(editor, alignment)
      },
      [editor]
    )

    const handleChangeFont = useCallback(
      (event: any, font: FontOption) => {
        event.preventDefault()
        RichTextEditor.setFont(editor, font)
      },
      [editor]
    )

    const handleChangeTextColor = useCallback(
      (textColor: string) => {
        RichTextEditor.setTextColor(editor, textColor)
      },
      [editor]
    )

    const handleChangeTextSize = useCallback(
      (event: any, textSize: TextSizeOption) => {
        event.preventDefault()
        RichTextEditor.setTextSize(editor, textSize)
      },
      [editor]
    )

    const handleToggleBlock = useCallback(
      (event: any, format: string) => {
        event.preventDefault()
        RichTextEditor.toggleBlock(editor, format)
      },
      [editor]
    )

    const handleToggleFormat = useCallback(
      (event: any, format: string) => {
        event.preventDefault()
        RichTextEditor.toggleMark(editor, format)
      },
      [editor]
    )

    const handleKeyDown = (event: { preventDefault: () => void }) => {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event as any)) {
          event.preventDefault()
          const mark = HOTKEYS[hotkey]
          RichTextEditor.toggleMark(editor, mark)
        }
      }
    }

    const handleFocus = useCallback(() => {
      // Place cursor at the end of the content
      const end = Editor.end(editor, [])
      Transforms.select(editor, end)
      onFocus?.()
    }, [editor, onFocus])

    return (
      <Slate editor={editor} initialValue={value} onChange={onChange}>
        <Paper
          sx={{
            display: hide ? "none" : "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            flexGrow: 1,
            border: (theme) => `1px solid ${error ? "#B31425" : theme.fielderColors.inputBorder}`,
            overflow: "hidden",
            "& .Mui-focusVisible": {
              color: "transparent",
            },
          }}
          variant="outlined"
        >
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Toolbar
              onAddLink={handleAddLink}
              onAddTemplateField={handleAddTemplateField}
              onChangeAlignment={handleChangeAlignment}
              onChangeFont={handleChangeFont}
              onChangeTextColor={handleChangeTextColor}
              onChangeTextSize={handleChangeTextSize}
              onRemoveLink={handleRemoveLink}
              onToggleBlock={handleToggleBlock}
              onToggleFormat={handleToggleFormat}
              renderRightToolbar={renderRightToolbar}
              templateFieldOptions={templateFieldOptions}
            />
          </Box>
          <Box
            onClick={() => {
              ReactEditor.focus(editor)
            }}
            sx={{
              padding: "1rem",
              flexGrow: 1,
              maxHeight: "100%",
              overflow: "scroll",
              "& p": {
                margin: "0px !important",
                padding: "0px !important",
              },
            }}
          >
            <Editable
              autoFocus={autoFocus}
              decorate={(entry) => {
                const [node, path] = entry
                const ranges = []
                const unresolvedPlaceholderRegex = /\[(.*)\.(.*)\]+/g
                if (node?.text?.match(unresolvedPlaceholderRegex)) {
                  const start = Editor.start(editor, path)
                  const end = Editor.end(editor, path)
                  ranges.push({
                    anchor: start,
                    focus: end,
                    error: true,
                  })
                }
                return ranges
              }}
              onCopy={(event) => {
                const { selection } = editor
                if (!selection) {
                  return
                }

                const [start, end] = Range.edges(selection)
                const text = Editor.string(editor, { anchor: start, focus: end })
                event.clipboardData.setData("text/plain", text)
                event.preventDefault()
              }}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder={
                placeholder ?? (t("component.richTextEditor.editorPlaceholder") as string)
              }
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              style={{
                flexGrow: 1,
                outline: "none",
              }}
            />
          </Box>
        </Paper>
      </Slate>
    )
  }
)

RichTextComposer.displayName = "RichTextComposer"

function TemplateField({ attributes, children, element }) {
  const { t } = useTranslation()
  const selected = useSelected()
  const focused = useFocused()

  const style = {
    borderRadius: "2px",
    padding: 0,
    margin: 0,
    backgroundColor: selected && focused ? "#B4D5FF" : "transparent",
  }

  return (
    <Leaf attributes={attributes} leaf={element}>
      <span
        contentEditable={false}
        data-cy={`templateField-${element.templateField.key}`}
        style={style}
      >
        [{t(`component.richTextEditor.templateFieldOptions.${element.templateField.key}`)}]
        {children}
      </span>
    </Leaf>
  )
}

function Element(props) {
  const { attributes, children, element } = props
  const style = {
    ...attributes.style,
    textAlign: element.alignment ?? "left",
    fontFamily: element.font ? element.font.fontFamily : FontOptions[0].fontFamily,
    color: element.textColor ?? "#000000",
  }

  switch (element.type) {
    case "templateField":
      return (
        <TemplateField attributes={attributes} element={element}>
          {children}
        </TemplateField>
      )
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case "paragraph":
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
    case "link":
      return <LinkComponent {...props} />
    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.font) {
    attributes.style = {
      ...attributes.style,
      fontFamily: leaf.font.fontFamily,
    }
  }

  if (leaf.textColor) {
    attributes.style = {
      ...attributes.style,
      color: leaf.textColor,
    }
  }

  if (leaf.textSize) {
    attributes.style = {
      ...attributes.style,
      fontSize: leaf.textSize.fontSize,
    }
  }

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.highlight) {
    attributes.style = {
      ...attributes.style,
      backgroundColor: "#ffeeba",
    }
  }

  if (leaf.error) {
    attributes.style = {
      ...attributes.style,
      backgroundColor: "#fee2e2",
      textDecoration: "underline",
      textDecorationStyle: "wavy",
      textDecorationColor: "#991b1b",
    }
  }

  return <span {...attributes}>{children}</span>
}

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
function InlineChromiumBugfix() {
  return (
    <span contentEditable={false} style={{ fontSize: 0 }}>
      ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  )
}

function LinkComponent({ attributes, children, element }) {
  const selected = useSelected()
  return (
    <a
      {...attributes}
      href={element.url}
      style={{
        boxShadow: selected ? "0 0 0 3px #ddd" : "",
      }}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}

export default RichTextComposer
