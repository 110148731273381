import React, { useMemo, forwardRef, useImperativeHandle, useCallback } from "react"
import { createEditor, Descendant, Editor, Transforms, Node } from "slate"
import { Slate, Editable, withReact, useFocused, useSelected } from "slate-react"
import { withHistory } from "slate-history"
import { alpha } from "@mui/material/styles"
import { Box } from "@mui/material"
import type { Theme } from "@mui/material/styles"
import { TemplateFieldOption } from "~/types"
import { useTranslation } from "react-i18next"

interface Props {
  readonly autoFocus?: boolean
  readonly error?: boolean
  readonly onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly onChange?: (value: Descendant[]) => void
  readonly onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly value?: Descendant[] | null
}

export interface PlainTextFieldRef {
  insertTemplateField: (templateField: TemplateFieldOption) => void
  editor: Editor
}

const withInlines = (editor: Editor) => {
  const { isElementReadOnly, isInline, isSelectable, insertText, isVoid } = editor

  editor.isElementReadOnly = (element: any) =>
    element.type === "templateField" || isElementReadOnly(element)

  editor.isSelectable = (element: any) => {
    if (element.type === "templateField") {
      return true
    }
    return isSelectable(element)
  }

  editor.isInline = (element: any) => {
    return ["templateField"].includes(element.type) ? true : isInline(element)
  }

  editor.isVoid = (element: any) => {
    return element.type === "templateField" ? true : isVoid(element)
  }

  return editor
}

const PlainTextField = forwardRef<PlainTextFieldRef, Props>(
  ({ autoFocus, error, onBlur, onChange, onFocus, value }, ref) => {
    const editor = useMemo(() => withReact(withInlines(withHistory(createEditor()))), [])
    const renderElement = useCallback((props) => <Element {...props} />, [])
    const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

    // Expose methods via ref
    useImperativeHandle(ref, () => ({
      insertTemplateField: (templateField: TemplateFieldOption) => {
        const templateFieldNode = {
          type: "templateField",
          templateField,
          children: [{ text: "" }],
        } as unknown as Node
        Transforms.insertNodes(editor, templateFieldNode)
        Transforms.move(editor)
        Transforms.insertNodes(editor, { text: " " })
        Transforms.move(editor)
      },
      editor,
    }))

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        // Place cursor at the end of the content
        const end = Editor.end(editor, [])
        Transforms.select(editor, end)
        onFocus?.(e)
      },
      [editor, onFocus]
    )

    return (
      <div style={{ marginTop: 0, marginBottom: 0 }}>
        <Slate editor={editor} initialValue={value ?? initialValue} onChange={onChange}>
          <Box
            sx={(theme: Theme) => ({
              width: "100%",
              backgroundColor: "#fff",
              border: `1px solid ${error ? "#B31425" : theme.fielderColors.inputBorder}`,
              borderRadius: "4px",
              padding: "0.5rem",
              transition: theme.transitions.create(["border-color", "box-shadow"]),
              "&:hover": {
                backgroundColor: "#fff",
              },
              "&:focus-within": {
                backgroundColor: "#fff",
                boxShadow: `${alpha(theme.fielderColors.inputBorderFocused, 0.25)} 0 0 0 2px`,
                borderColor: theme.fielderColors.inputBorderFocused,
                outline: "none",
              },
              "& [contenteditable]": {
                outline: "none",
              },
              "& [contenteditable]:focus": {
                outline: "none",
              },
            })}
          >
            <Editable
              autoFocus={autoFocus}
              decorate={(entry) => {
                const [node, path] = entry
                const ranges = []
                const unresolvedPlaceholderRegex = /\[(.*)\.(.*)\]+/g
                if (node?.text?.match(unresolvedPlaceholderRegex)) {
                  const start = Editor.start(editor, path)
                  const end = Editor.end(editor, path)
                  ranges.push({
                    anchor: start,
                    focus: end,
                    error: true,
                  })
                }
                return ranges
              }}
              onBlur={onBlur}
              onFocus={handleFocus}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
            />
          </Box>
        </Slate>
      </div>
    )
  }
)

PlainTextField.displayName = "PlainTextField"

const initialValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]

function TemplateField({ attributes, children, element }) {
  const { t } = useTranslation()
  const selected = useSelected()
  const focused = useFocused()

  const style = {
    borderRadius: "2px",
    padding: 0,
    margin: 0,
    backgroundColor: selected && focused ? "#B4D5FF" : "transparent",
  }

  return (
    <Leaf attributes={attributes} leaf={element}>
      <span
        contentEditable={false}
        data-cy={`templateField-${element.templateField.key}`}
        style={style}
      >
        [{t(`component.richTextEditor.templateFieldOptions.${element.templateField.key}`)}]
        {children}
      </span>
    </Leaf>
  )
}

function Element(props) {
  const { attributes, children, element } = props
  const style = {
    ...attributes.style,
  }

  switch (element.type) {
    case "templateField":
      return (
        <TemplateField attributes={attributes} element={element}>
          {children}
        </TemplateField>
      )

    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.error) {
    attributes.style = {
      ...attributes.style,
      backgroundColor: "#fee2e2",
      textDecoration: "underline",
      textDecorationStyle: "wavy",
      textDecorationColor: "#991b1b",
    }
  }

  return <span {...attributes}>{children}</span>
}

export { PlainTextField }
