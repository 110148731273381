export function formatTimeOfDay(timeOfDay: number, hour12 = true): string {
    const hours = Math.floor(timeOfDay / 60)
    const minutes = timeOfDay % 60

    return new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: hour12,
    }).format(new Date(0, 0, 0, hours, minutes))
}

/**
 * Convert a time string to the number of minutes since midnight
 * @param timeString - The time string to convert
 * @returns A number in the range [0, 1440)
 */
export function timeToMinutes(timeString: string): number {
    if (!timeString) {
        return 0
    }

    const [hours, minutes] = timeString.split(":").map(Number)

    return hours * 60 + minutes
}