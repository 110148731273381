import gql from "graphql-tag"

export const CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET_LINE_ITEM_NOTE = gql`
  mutation CreateHelicalPileInstallationWorksheetLineItemNote(
    $lineItemId: ID!
    $text: String!
  ) {
    createHelicalPileInstallationWorksheetLineItemNote(
      input: { lineItemId: $lineItemId, text: $text }
    ) {
      note {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
