import { HelicalPileInstallationWorksheetLineItem, PileType } from "~/types"

const PileTypeOptions = [
  { value: "178", label: '1⅞"' },
  { value: "238", label: '2⅜"' },
  { value: "278", label: '2⅞"' },
  { value: "312", label: '3½"' },
  { value: "412", label: '4½"' },
  { value: "5916", label: '5⁹⁄₁₆"' },
  { value: "658", label: '6⅝"' },
  { value: "858", label: '8⅝"' },
  { value: "1034", label: '10¾"' },
  { value: "1234", label: '12¾"' },
]

function getKtFactor(pileUsed?: string | null | undefined): number {
  switch (pileUsed) {
    case "178":
    case "238":
      return 10
    case "278":
      return 9
    case "312":
      return 7
    case "412":
      return 5.6
    case "5916":
      return 4.5
    case "658":
      return 4
    case "858":
      return 3
    case "1034":
      return 2.5
    case "1234":
      return 2
    default:
      return 2
  }
}

function calculateRequiredTorque(
  pileType: PileType,
  requiredCompressionCapacity: number,
  safetyFactor?: number
): number {
  const Q = requiredCompressionCapacity ?? 0
  const SF = safetyFactor ?? 2
  const Kt = getKtFactor(pileType)
  return Math.max(Math.round((Q * SF) / Kt), 500)
}

function calculateRequiredCompressionCapacity(
  pileType: PileType,
  requiredTorque: number,
  safetyFactor = 2
): number {
  const T = requiredTorque ?? 0
  const SF = safetyFactor ?? 2
  const Kt = getKtFactor(pileType)
  return Math.round((T * Kt) / SF)
}

function calculateAchievedCompressionCapacity(T: number, SF: number, Kt: number): number {
  return Math.round((T * Kt) / SF)
}

function calculateAchievedTensionCapacity(pileLength: number, compressionCapacity: number): number {
  let result = Math.round(compressionCapacity / 2)

  if (pileLength > 14) {
    result = Math.round(result * 1.4)
  }

  return result
}

function setAchievedCompAndTension(pile: HelicalPileInstallationWorksheetLineItem) {
  pile.achievedCompressionCapacity = calculateAchievedCompressionCapacity(
    pile.achievedTorque,
    pile.safetyFactor,
    getKtFactor(pile.pileUsed)
  )
  // See the chart on the bottom of the old Numbers-based worksheet
  // note - "achieved tension capacity" represents the amount of uplift resistance of the pile,
  // whereas compression capacity represents the amount of downward resistance.
  const pileLength = pile.totalPileLength ?? 0
  pile.achievedTensionCapacity = calculateAchievedTensionCapacity(pileLength, pile.achievedCompressionCapacity)
}

export {
  PileTypeOptions,
  calculateRequiredCompressionCapacity,
  calculateRequiredTorque,
  setAchievedCompAndTension,
  calculateAchievedTensionCapacity,
  calculateAchievedCompressionCapacity,
  getKtFactor
}
