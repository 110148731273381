import React, { useState, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import WarningIcon from "@mui/icons-material/WarningAmberOutlined"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined"
import { useQuery } from "@apollo/client"
import { Editor } from "slate"

import {
  AllTemplateFieldsResponse,
  MessageChannel,
  ReminderScheduleUnit,
  ReminderSeriesCategory,
  ReminderSeriesEvent,
  TemplateFieldOption,
} from "~/types"
import FielderTextField from "~/components/FielderTextField"
import { formatTimeOfDay, timeToMinutes } from "~/util/timeUtils"
import { Descendant } from "slate"
import RichTextComposer, { RichTextComposerRef } from "~/components/RichTextEditor/RichTextComposer"
import { ConfirmationDialog, FieldHelperText, PositiveIntegerInput } from "~/components"
import { isNumeric } from "~/util/stringUtils"
import Menu from "@mui/material/Menu"
import { ALL_TEMPLATE_FIELDS } from "~/queries/allTemplateFields"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import { resolveTemplate, SAMPLE_DATA, toSimpleHtml } from "~/util/richTextTemplateUtils"
import { PlainTextField, PlainTextFieldRef } from "~/components/RichTextEditor/PlainTextField"
import CircularProgress from "@mui/material/CircularProgress"

const DEFAULT_INITIAL_RTE_VALUE: Descendant[] = [{ children: [{ text: "" }] }] as Descendant[]

interface Props {
  readonly isSaving: boolean
  readonly onCancel: () => void
  readonly onDelete: () => void
  readonly onSave: (reminderSeriesEvent: ReminderSeriesEvent) => void
  readonly reminderSeriesEvent: ReminderSeriesEvent
}

type FocusedField = "subject" | "message" | null

export function EditReminderSeriesEventDialog({
  isSaving,
  onCancel,
  onDelete,
  onSave,
  reminderSeriesEvent,
}: Props) {
  const { t } = useTranslation()
  const richTextComposerRef = useRef<RichTextComposerRef>(null)
  const subjectInputRef = useRef<PlainTextFieldRef>(null)
  const [lastFocusedField, setLastFocusedField] = useState<FocusedField>(null)
  const [subject, setSubject] = useState<Descendant[] | null>(() => {
    if (reminderSeriesEvent.id && reminderSeriesEvent.subject) {
      try {
        return JSON.parse(reminderSeriesEvent.subject)
      } catch (error) {
        console.error("Error parsing reminder series event subject: ", error)
        return [{ children: [{ text: reminderSeriesEvent.subject }] }]
      }
    }
    return DEFAULT_INITIAL_RTE_VALUE
  })
  const initialBody = useMemo(() => {
    if (reminderSeriesEvent.id) {
      try {
        return JSON.parse(reminderSeriesEvent.body)
      } catch (error) {
        console.error("Error parsing reminder series event body: ", error)
        return [{ children: [{ text: reminderSeriesEvent.body }] }]
      }
    }
    return DEFAULT_INITIAL_RTE_VALUE
  }, [reminderSeriesEvent.body, reminderSeriesEvent.id])
  const [body, setBody] = useState<Descendant[] | null>(() => {
    if (reminderSeriesEvent.body) {
      try {
        return JSON.parse(reminderSeriesEvent.body)
      } catch (error) {
        console.error("Error parsing reminder series event body: ", error)
        return [{ children: [{ text: reminderSeriesEvent.body }] }]
      }
    }
    return DEFAULT_INITIAL_RTE_VALUE
  })
  const [scheduleOffset, setScheduleOffset] = useState<number>(
    isNumeric(reminderSeriesEvent.scheduleOffset) ? Math.abs(reminderSeriesEvent.scheduleOffset) : 1
  )
  const [scheduleUnit, setScheduleUnit] = useState<ReminderScheduleUnit>(
    reminderSeriesEvent.scheduleUnit ?? ReminderScheduleUnit.DAYS
  )
  const [channel, setChannel] = useState<MessageChannel>(
    reminderSeriesEvent.channel ?? MessageChannel.EMAIL
  )
  const [timeOfDay, setTimeOfDay] = useState<string>(
    reminderSeriesEvent.timeOfDay
      ? formatTimeOfDay(reminderSeriesEvent.timeOfDay ?? 0, false)
      : "10:00"
  )
  const [allTemplateFieldOptions, setAllTemplateFieldOptions] = useState<TemplateFieldOption[]>([])
  const [errors, setErrors] = useState(() => ({
    general: null,
    scheduleOffset: null,
    scheduleUnit: null,
    channel: null,
    timeOfDay: null,
    subject: null,
    body: null,
  }))
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState<boolean>(false)
  const [inPreviewMode, setInPreviewMode] = useState<boolean>(false)
  const [showCharacterCountWarning, setShowCharacterCountWarning] = useState<boolean>(false)
  const [insertPlaceholderAnchorEl, setInsertPlaceholderAnchorEl] = useState<null | HTMLElement>(
    null
  )
  const insertPlaceholderMenuOpen = Boolean(insertPlaceholderAnchorEl)

  useQuery<AllTemplateFieldsResponse>(ALL_TEMPLATE_FIELDS, {
    onCompleted: (data) => {
      const sortedOptions = data?.allTemplateFields
        ?.map((p) => ({
          id: p.id,
          key: p.key,
          format: p.format,
          displayName: t(`component.richTextEditor.templateFieldOptions.${p.key}`),
        }))
        ?.sort((a, body) => a.displayName.localeCompare(body.displayName))
      setAllTemplateFieldOptions(sortedOptions)
    },
  })

  function handleClosePlaceholderMenu() {
    setInsertPlaceholderAnchorEl(null)
  }

  function getTemplateFieldOptions(): TemplateFieldOption[] {
    if (reminderSeriesEvent.category === ReminderSeriesCategory.JOB_ASSIGNMENT) {
      return allTemplateFieldOptions.filter(
        (f) => !f.key.startsWith("estimate.") && !f.key.startsWith("invoice.")
      )
    } else if (reminderSeriesEvent.category === ReminderSeriesCategory.ESTIMATE_FOLLOW_UP) {
      return allTemplateFieldOptions.filter((f) => !f.key.startsWith("invoice."))
    } else if (reminderSeriesEvent.category === ReminderSeriesCategory.INVOICE_FOLLOW_UP) {
      return allTemplateFieldOptions.filter((f) => !f.key.startsWith("estimate."))
    } else {
      return []
    }
  }

  const handleSubjectFocus = () => {
    setLastFocusedField("subject")
  }

  const handleMessageFocus = () => {
    setLastFocusedField("message")
  }

  const handleAddTemplateField = (templateField: TemplateFieldOption) => {
    if (lastFocusedField === "message" && richTextComposerRef.current) {
      richTextComposerRef.current.insertTemplateField(templateField)
    } else if (lastFocusedField === "subject" && subjectInputRef.current) {
      subjectInputRef.current.insertTemplateField(templateField)
    }

    handleClosePlaceholderMenu()
  }

  function getSubjectText(): string {
    return (
      subject
        ?.map((node) => node.children?.[0].text)
        ?.join("")
        ?.trim() ?? ""
    )
  }

  function getBodyText(): string {
    return (
      body
        ?.map((node) => node.children?.[0].text)
        ?.join("")
        ?.trim() ?? ""
    )
  }

  function validateSubject(): boolean {
    if (channel === MessageChannel.SMS) {
      return true
    }

    const subjectText = getSubjectText()
    if (!subject || subject.length === 0 || subjectText === "") {
      setErrors((prev) => ({
        ...prev,
        subject: t(
          "page.manageAutomatedMessaging.editReminderSeriesEventDialog.validation.subject.required"
        ),
      }))
      return false
    } else {
      setErrors((prev) => ({
        ...prev,
        subject: null,
      }))
    }
    return true
  }

  function validateBody(): boolean {
    const bodyText = getBodyText()
    if (!body || body.length === 0 || bodyText === "") {
      setErrors({
        ...errors,
        body: t(
          "page.manageAutomatedMessaging.editReminderSeriesEventDialog.validation.body.required"
        ),
      })
      return false
    }
    return true
  }

  function validate() {
    return validateSubject() && validateBody()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onCancel?.()
        }
      }}
      open
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          px: 3,
          py: 2,
        }}
      >
        {t("page.manageAutomatedMessaging.editReminderSeriesEventDialog.title")}
        <IconButton onClick={onCancel} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "1.5rem", display: "flex", flexDirection: "column", gap: "1.5rem" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
          }}
        >
          <Box
            id="schedule-sentence-form-container"
            sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <Box component="span" sx={{ fontSize: "0.875rem" }}>
              {t("page.manageAutomatedMessaging.editReminderSeriesEventDialog.sendReminder")}
            </Box>
            <PositiveIntegerInput
              max={scheduleUnit === ReminderScheduleUnit.HOURS ? 23 : 90}
              name="scheduleOffset"
              onBlur={(e) => {
                const value = Number(e.target.value)
                if (value > (scheduleUnit === ReminderScheduleUnit.HOURS ? 23 : 90)) {
                  setScheduleOffset(scheduleUnit === ReminderScheduleUnit.HOURS ? 23 : 90)
                }
              }}
              onChange={(e) => setScheduleOffset(Number(e.target.value))}
              style={{
                maxWidth: "4rem",
              }}
              value={scheduleOffset ?? ""}
            />
            <FormControl size="small" sx={{ minWidth: 96 }}>
              <Select
                onChange={(e) => setScheduleUnit(e.target.value as ReminderScheduleUnit)}
                value={scheduleUnit}
              >
                <MenuItem value={ReminderScheduleUnit.DAYS}>
                  {t(
                    `page.manageAutomatedMessaging.scheduleUnit.DAYS.${Math.abs(scheduleOffset ?? 0) === 1 ? "singular" : "plural"}`
                  ).toLowerCase()}
                </MenuItem>
                <MenuItem value={ReminderScheduleUnit.HOURS}>
                  {t(
                    `page.manageAutomatedMessaging.scheduleUnit.HOURS.${Math.abs(scheduleOffset ?? 0) === 1 ? "singular" : "plural"}`
                  ).toLowerCase()}
                </MenuItem>
              </Select>
            </FormControl>
            <Box component="span" sx={{ fontSize: "0.875rem" }}>
              {reminderSeriesEvent.category === ReminderSeriesCategory.JOB_ASSIGNMENT
                ? t(
                    "page.manageAutomatedMessaging.editReminderSeriesEventDialog.beforeTheAssignmentBy"
                  )
                : reminderSeriesEvent.category === ReminderSeriesCategory.ESTIMATE_FOLLOW_UP
                  ? t(
                      "page.manageAutomatedMessaging.editReminderSeriesEventDialog.afterEstimateSent"
                    )
                  : t(
                      "page.manageAutomatedMessaging.editReminderSeriesEventDialog.afterInvoiceDueDate"
                    )}
            </Box>
            <FormControl size="small">
              <Select
                onChange={(e) => setChannel(e.target.value as MessageChannel)}
                value={channel}
              >
                <MenuItem value={MessageChannel.EMAIL}>
                  {t("page.manageAutomatedMessaging.email").toLowerCase()}
                </MenuItem>
                <MenuItem value={MessageChannel.SMS}>
                  {t("page.manageAutomatedMessaging.textMessage").toLowerCase()}
                </MenuItem>
              </Select>
            </FormControl>
            {scheduleUnit === ReminderScheduleUnit.DAYS ? (
              <>
                <Box component="span" sx={{ fontSize: "0.875rem" }}>
                  at
                </Box>
                <FielderTextField
                  onChange={(e) => setTimeOfDay(e.target.value)}
                  type="time"
                  value={timeOfDay}
                />
              </>
            ) : null}
          </Box>
          <Box id="insert-placeholder">
            <Button
              aria-controls={insertPlaceholderMenuOpen ? "insert-placeholder-menu" : undefined}
              aria-expanded={insertPlaceholderMenuOpen ? "true" : undefined}
              aria-haspopup="true"
              id="insert-placeholder-button"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setInsertPlaceholderAnchorEl(event.currentTarget)
              }}
            >
              {t("page.manageAutomatedMessaging.editReminderSeriesEventDialog.insertPlaceholder")}
              <ExpandMoreIcon />
            </Button>
            <Menu
              MenuListProps={{
                "aria-labelledby": "insert-placeholder-button",
              }}
              anchorEl={insertPlaceholderAnchorEl}
              id="insert-placeholder-menu"
              onClose={handleClosePlaceholderMenu}
              open={insertPlaceholderMenuOpen}
            >
              {getTemplateFieldOptions().map((templateFieldOption) => (
                <MenuItem
                  key={templateFieldOption.id}
                  onMouseDown={() => {
                    handleAddTemplateField(templateFieldOption)
                  }}
                >
                  <span>{templateFieldOption.displayName}</span>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {channel === MessageChannel.EMAIL ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <Box component="span" sx={{ fontWeight: "500", fontSize: "0.875rem" }}>
                  {t("page.manageAutomatedMessaging.subject")}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0rem" }}>
                  <PlainTextField
                    error={Boolean(errors.subject)}
                    onChange={(value: Descendant[]) => {
                      setSubject(value)
                      validateSubject()
                    }}
                    onFocus={handleSubjectFocus}
                    ref={subjectInputRef}
                    value={subject}
                  />
                  <FieldHelperText
                    error={Boolean(errors.subject)}
                    message={errors.subject ? errors.subject : ""}
                  />
                </Box>
              </Box>
            ) : null}
            <Box
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                minHeight: "18rem",
                gap: "0.5rem",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  height: "100%",
                  minHeight: "16rem",
                  width: "100%",
                }}
              >
                <Box component="span" sx={{ fontWeight: "500", fontSize: "0.875rem" }}>
                  {t("page.manageAutomatedMessaging.message")}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0rem", flexGrow: 1 }}>
                  <RichTextComposer
                    error={!!errors.body}
                    onChange={(value: Descendant[]) => {
                      setErrors({ ...errors, body: null })
                      setBody(value)
                      if (channel === MessageChannel.SMS && richTextComposerRef.current) {
                        const count = getCharacterCount(richTextComposerRef.current.editor)
                        setShowCharacterCountWarning(count > 160)
                      }
                    }}
                    onFocus={handleMessageFocus}
                    ref={richTextComposerRef}
                    value={initialBody}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 0.5rem",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
                      {!errors.body &&
                      channel === MessageChannel.SMS &&
                      richTextComposerRef.current ? (
                        <CharacterCounter
                          characterCount={getCharacterCount(richTextComposerRef.current.editor)}
                          showCharacterCountWarning={showCharacterCountWarning}
                        />
                      ) : null}
                      <FieldHelperText
                        error={Boolean(errors.body)}
                        message={errors.body ? errors.body : ""}
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={inPreviewMode}
                            // disabled={!!loadError}
                            onChange={() => {
                              setInPreviewMode(!inPreviewMode)
                            }}
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "green",
                                "& + .MuiSwitch-track": {
                                  backgroundColor: "green",
                                },
                              },
                            }}
                          />
                        }
                        label={
                          t(
                            "page.manageAutomatedMessaging.editReminderSeriesEventDialog.preview"
                          ) as string
                        }
                        labelPlacement="start"
                        style={{ alignSelf: "flex-end" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Preview
            body={body}
            isVisible={inPreviewMode}
            subject={channel === MessageChannel.EMAIL ? subject : null}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "1.5rem", display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: "2rem" }}>
          <Button color="inherit" disabled={isSaving} onClick={onCancel} variant="outlined">
            {t("cancel")}
          </Button>
          {reminderSeriesEvent.id ? (
            <Button
              disabled={isSaving}
              onClick={() => {
                setDeleteConfirmationDialogOpen(true)
              }}
              sx={{ color: (theme) => theme.fielderColors.error }}
              variant="text"
            >
              {t("delete")}
            </Button>
          ) : null}
        </Box>
        <Button
          color="primary"
          disabled={isSaving}
          onClick={() => {
            if (!validate()) {
              return
            }

            const updatedEvent = {
              ...reminderSeriesEvent,
              channel,
              category: reminderSeriesEvent.id ? undefined : reminderSeriesEvent.category,
              subject: subject ? JSON.stringify(subject) : undefined,
              body: JSON.stringify(body),
              scheduleOffset:
                reminderSeriesEvent.category === ReminderSeriesCategory.JOB_ASSIGNMENT
                  ? scheduleOffset * -1
                  : scheduleOffset,
              scheduleUnit,
              timeOfDay: timeToMinutes(timeOfDay),
            } as ReminderSeriesEvent
            onSave(updatedEvent)
          }}
          variant="contained"
        >
          {isSaving && !deleteConfirmationDialogOpen ? (
            <CircularProgress color="secondary" size={20} thickness={6.0} />
          ) : (
            t("save")
          )}
        </Button>
      </DialogActions>
      <ConfirmationDialog
        description={t(
          "page.manageAutomatedMessaging.editReminderSeriesEventDialog.deleteConfirmation.description"
        )}
        isLoading={isSaving}
        onCancel={() => setDeleteConfirmationDialogOpen(false)}
        onConfirm={onDelete}
        open={deleteConfirmationDialogOpen}
        positiveButtonTitle={t("yes")}
        title={t(
          "page.manageAutomatedMessaging.editReminderSeriesEventDialog.deleteConfirmation.title"
        )}
        titleBackgroundColor="#ffffff"
      />
    </Dialog>
  )
}

function CharacterCounter({
  characterCount,
  showCharacterCountWarning,
}: {
  readonly characterCount: number
  readonly showCharacterCountWarning: boolean
}) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <Box
        component="span"
        sx={{
          color: "text.secondary",
          fontSize: "0.875rem",
        }}
      >
        {characterCount} / 160
      </Box>
      {showCharacterCountWarning ? (
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: "0.5rem",
            alignItems: "center",
            fontSize: "0.875rem",
            color: (theme) => theme.fielderColors.warningText,
            padding: "0 0.5rem",
            borderRadius: "0.25rem",
          }}
        >
          <WarningIcon sx={{ fontSize: "1rem" }} />
          <span>
            {t(
              "page.manageAutomatedMessaging.editReminderSeriesEventDialog.textMessageLengthWarning"
            )}
          </span>
        </Box>
      ) : null}
    </Box>
  )
}

function Preview({
  isVisible,
  subject,
  body,
}: {
  readonly isVisible: boolean
  readonly subject: Descendant[] | null
  readonly body: Descendant[] | null
}) {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        border: "1px solid #fff8e6",
        backgroundColor: "#fffcf2",
        borderRadius: "4px",
        padding: "1rem",
        display: isVisible ? "flex" : "none",
        flexDirection: "column",
        gap: "1rem",
        fontSize: "0.875rem",
      }}
    >
      {subject ? (
        <Box
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: toSimpleHtml(
              resolveTemplate(JSON.parse(JSON.stringify(subject)), SAMPLE_DATA, t)
            ),
          }}
          sx={{ fontWeight: "600" }}
        />
      ) : null}
      <Box
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: toSimpleHtml(resolveTemplate(JSON.parse(JSON.stringify(body)), SAMPLE_DATA, t)),
        }}
      />
    </Box>
  )
}

function getCharacterCount(editor: Editor) {
  if (!editor) return 0
  return Editor.string(editor, [])?.trim().length
}
