import React from "react"
import Box from "@mui/material/Box"

export function WarningMessage({ message }: { readonly message: string }) {
  return (
    <Box
      sx={(theme) => ({
        color: theme.fielderColors.warningText,
        borderRadius: "4px",
        padding: "0.625rem",
        backgroundColor: theme.fielderColors.warningLight,
      })}
    >
      {message}
    </Box>
  )
}
