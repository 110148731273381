import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"

import SelectorField from "../SelectorField"
import { WarningMessage } from "../WarningMessage"
import { HelicalPileInstallationWorksheetTemplate } from "~/types"

const ALL_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATES = gql`
  query AllHelicalPileInstallationWorksheetTemplates {
    allHelicalPileInstallationWorksheetTemplates {
      id
      type
      name
      diagram
      lineItems {
        id
        diagramId
        number
        requiredCompressionCapacity
        requiredTorque
        pileUsed
        helixUsed
        totalPileLength
        cutOffElevation
        pileInclination
      }
    }
  }
`

interface Props {
  readonly onCancel: () => void
  readonly onSelectTemplate: (template: HelicalPileInstallationWorksheetTemplate) => void
}

function LoadFromTemplate({ onCancel, onSelectTemplate }: Props) {
  const { t } = useTranslation()
  const [selectedTemplate, setSelectedTemplate] =
    useState<HelicalPileInstallationWorksheetTemplate | null>(null)

  const { data, loading } = useQuery(ALL_HELICAL_PILE_INSTALLATION_WORKSHEET_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  })

  const templates = data?.allHelicalPileInstallationWorksheetTemplates || []

  function handleSelect() {
    selectedTemplate && onSelectTemplate?.(selectedTemplate)
  }

  return (
    <Dialog
      aria-labelledby="load-from-template-dialog"
      data-testid="LoadFromTemplate"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {t("helicalPileInstallationWorksheet.loadFromTemplateDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "0.5rem",
          }}
        >
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginTop: "0.625rem",
              marginBottom: "0.625rem",
            }}
          >
            {t("helicalPileInstallationWorksheet.loadFromTemplateDialog.description")}
          </Box>
          <Box
            sx={{
              marginTop: "1.25rem",
            }}
          >
            {templates.length === 0 ? (
              <WarningMessage
                message={t("helicalPileInstallationWorksheet.loadFromTemplateDialog.noTemplates")}
              />
            ) : (
              <SelectorField
                label={t("selectTemplate") as string}
                name="template"
                onChange={(selection) => {
                  const template = templates?.find((t) => t.id === selection.id)
                  setSelectedTemplate(template)
                }}
                options={templates}
                value={selectedTemplate?.id}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button color="secondary" disabled={loading} onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="loadButton"
          disabled={loading || !selectedTemplate}
          onClick={handleSelect}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <Box>{t("loadFromTemplate")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoadFromTemplate
