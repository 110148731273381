import {
  Address,
  HelicalPileInstallationWorksheetType,
  TemplateField,
  Job,
  JobAssignment,
  LanguageCode,
  Organization,
  OrganizationItem,
  ProductOrderLineItem,
  SearchResultType,
  TaxRateGroup,
  UserStatus,
  User,
  ProductOrderStatus,
  Item,
  OrganizationDefaultEstimateSettings,
  OrganizationDefaultInvoiceSettings,
  PricingStrategy,
  Discount,
  Contact,
} from "./apiTypes"
import { DefaultPermission } from "./permissions"

export enum FeatureFlag {
  AutomatedMessaging = "AUTOMATED_MESSAGING",
  FranchisorProductOrder = "FRANCHISOR_PRODUCT_ORDER",
  HelicalPileInstallationWorksheet = "HELICAL_PILE_INSTALLATION_WORKSHEET",
}

export enum DateRangeShortcut {
  Last7Days = "last7days",
  Last14Days = "last14days",
  Last30Days = "last30days",
  Last60Days = "last60days",
  Last90Days = "last90days",
  ThisMonth = "thisMonth",
  LastMonth = "lastMonth",
  Last3Months = "last3months",
  Last6Months = "last6months",
  Last12Months = "last12months",
  ThisQuarter = "thisQuarter",
  LastQuarter = "lastQuarter",
  ThisYear = "thisYear",
  LastYear = "lastYear",
  Custom = "custom",
}

export interface AppFeature {
  name: string
  requiredPermissions: DefaultPermission[]
}

export interface BadgeData {
  unreadInboxConversationCount: number
  estimateRequestCount: number
}

export interface AppSection {
  name: string
  position: number
  titleKey: string
  path: string
  icon: React.ReactNode
  requiredOrgLevel: number
  orgLevels: number[]
  features: AppFeature[]
  getBadgeContent?: (data: BadgeData) => number
}

export interface DispatchResource {
  readonly id: string
  readonly name: string
  readonly user: User
  readonly position: number
}

export interface DispatchDailySort {
  readonly date: string
  readonly resources: DispatchResource[]
}

export interface CreateCustomerInput {
  readonly tradeName?: string
  readonly legalName?: string
  readonly email?: string
  readonly phoneNumber?: string
  readonly address?: Address
  readonly languageCode?: LanguageCode
}

export interface EditCustomerInput extends CreateCustomerInput {
  readonly id: string
}

export interface Snack {
  messageKey: string
  messageOptions?: any
  variant: "success" | "warning" | "error" | "info"
}

export interface BillingPeriodOption {
  value: string
  titleKey: string
  subTitleKey: string
}

export interface MyProfileFormFields {
  firstName?: string
  lastName?: string
  mobilePhoneNumber?: string
  languageCode?: LanguageCode | null
}

export interface UserFormInput {
  id?: string
  email: string
  firstName?: string
  lastName?: string
  jobTitle?: string
  mobilePhoneNumber?: string
  roleIds: string[]
  organizationId?: string
  status?: keyof typeof UserStatus
  languageCode?: LanguageCode | null
}

export interface OrganizationFormInput {
  id: string
  tradeName?: string
  legalName?: string
  ownerUserId?: string
  timeZone?: string
  currencyCode?: string
  email?: string
  websiteURL?: string
  phoneNumber?: string
  address?: Address
  languageCode?: LanguageCode
  allowBundleModsOnTxns?: boolean
  allowJobAssignmentOverlap?: boolean
  bccJobEmailToOrganizationEmail?: boolean
  defaultEstimateSettings?: OrganizationDefaultEstimateSettings
  defaultInvoiceSettings?: OrganizationDefaultInvoiceSettings
  defaultPricingStrategySettings?: {
    defaultPricingStrategy: PricingStrategy
    defaultUnitSalePriceMarkup?: number | null
    applyImmediately?: boolean
  }
}

export interface TaxRateFormInput {
  id?: string | null | undefined
  key: string
  name: string
  rate: string
  errors: {
    name?: string | null | undefined
    rate?: string | null | undefined
  }
}

export interface HelicalPileInstallationWorksheetTemplateFormInput {
  id?: string
  name: string
  type: HelicalPileInstallationWorksheetType
  diagram?: string
  lineItems?: HelicalPileInstallationWorksheetTemplateLineItemFormInput[]
}

export type PileType = "178" | "238" | "278" | "312"

export interface HelicalPileInstallationWorksheetTemplateLineItemFormInput {
  id?: string
  diagramId?: string
  number: number
  safetyFactor?: number
  requiredCompressionCapacity?: string
  achievedCompressionCapacity?: string
  requiredTorque?: string
  achievedTorque?: string
  pileUsed?: PileType
  helixUsed?: string
  totalPileLength?: string
  cutOffElevation?: string
  pileInclination?: string
  achievedTensionCapacity?: string
}

export interface TextSizeOption {
  id: string
  fontSize: string
}

export interface FontOption {
  id: string
  fontFamily: string
}

export interface TemplateFieldOption extends TemplateField {
  displayName: string
}

/**
 * The values of the MapMarkerType enum
 * should match up with the name of an image
 * in the static/icons folder with the prefix of "mapMarker_"
 */
export enum MapMarkerType {
  USER = "user",
  JOB = "job",
  HQ = "hq",
  ESTIMATE_COMPLETED = "estimate_completed",
  ESTIMATE_CONFIRMED = "estimate_confirmed",
  ESTIMATE_TENTATIVE = "estimate_tentative",
}

export interface MapMarkerIcon {
  path: string
  fillColor?: string
  fillOpacity?: string
  anchor?: {
    width: number
    height: number
  }
  strokeWeight?: number
  strokeColor?: string
  scale?: number
}

export interface Coordinate {
  lat: number
  lng: number
}

/**
 * See https://developers.google.com/maps/documentation/javascript/reference/marker
 */
export interface MapMarker extends Coordinate {
  markerType: MapMarkerType
  icon: string | MapMarkerIcon
  infoWindow?: {
    title?: string
    content?: string
  }
  dataObject?: Job | JobAssignment | Organization
}

export interface ProductOrderStatusColumnOption {
  id: string
  name: string
  backgroundColor: string
  foregroundColor: string
  position: number
}

export interface ProductOrderStatusColumnData extends ProductOrderStatusColumnOption {
  productOrderIds: string[]
}

export interface SelectorOption {
  id: string
  name: string
}

export interface FranchisorOrderCount {
  id: ProductOrderStatus,
  name: string,
  color: string,
  count: number
}

export type ItemOrderCount = {
  count: number;
  item: Item;
};

export type SalesTotalCount = {
  organization: Organization;
  total: number;
};

export type ProductOrderCount = {
  count: number;
  status: ProductOrderStatus;
};

export interface DashboardWidgetMetricGroup {
  label: string
  data: DashboardWidgetMetric[]
}

export interface DashboardWidgetMetric {
  label: string
  value: number
}

export enum TimeFrameOption {
  DAY = "DAY",
  WEEK = "WEEK",
  MAP = "MAP",
}

export interface JobAssignmentFrame {
  left: number
  originalLeft: number
  top: number
  width: number
  minWidth: number
  maxWidth: number
  height: number
  minHeight: number
  maxHeight: number
}

export interface JobAssignmentUserBlock {
  key: string
  assignment: JobAssignment
  assignee: User // only used for DAY timeframe so we know which resource row this block applies to
  frame: JobAssignmentFrame
}

export interface JobAssignmentBlockDimensions {
  x: number
  y: number
  width: number
  height: number
  isDragging: boolean
}

export interface JobAssignmentDragDeltas {
  x: number
  y: number
  width: number
  height: number
}

export interface PermissionOption {
  id: string
  permissions?: PermissionOption[]
  requiredPermissionOptions?: PermissionOption[]
}

export interface SuggestedFilterOption {
  id: SearchResultType
  label: string
  description: string
}

export type TemplateData = { [x: string]: any }

export interface WorkOrderFormInput {
  id?: string
  number?: string
  jobAddress?: string
  notes?: string
  currencyCode?: string
  name?: string
  description?: string
  lineItems?: TransactionLineItemFormInput[]
  subTotal?: number
  total?: number
  job?: Job
  discount?: Discount
  billingContact?: Contact
}

export interface TransactionLineItemDetailFormInput {
  id?: string
  organizationItem?: Partial<OrganizationItem>
  organizationItemId?: string
  quantity?: string
  number?: number
  unitPrice?: string
}

export interface TransactionLineItemFormInput {
  id?: string
  number: number
  key: string
  organizationItem?: Partial<OrganizationItem>
  organizationItemId: string
  description?: string
  quantity?: string
  unitPrice?: string
  taxRateGroup?: TaxRateGroup
  subTotal?: number
  total?: number
  showDetails?: boolean
  lineItemDetails?: TransactionLineItemDetailFormInput[]
  errors?: {
    organizationItemId?: string | null
    quantity?: string | null
    taxRateGroup?: string | null
    showErrors: boolean
    unitPrice?: string | null
  }
  isLoading?: boolean
}

export interface ProductOrderDeliveryLineItemForm {
  lineItem: ProductOrderLineItem
  quantityReceived?: string
}
