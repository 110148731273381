import React from "react"
import { Trans, useTranslation } from "react-i18next"
import Box from "@mui/material/Box"

import TopLevelProductDetailsStatic from "./TopLevelProductDetailsStatic"
import { formatMoney } from "~/util"
import StaticField from "~/components/StaticField"
import { OrganizationItem } from "~/types"
import HelperTextContainer from "./HelperTextContainer"

interface ProductDetailsStackProps {
  readonly currencyCode: string
  readonly item: OrganizationItem
}

function ProductDetailsStatic({ currencyCode, item }: ProductDetailsStackProps) {
  const { t } = useTranslation()

  return (
    <>
      <TopLevelProductDetailsStatic currencyCode={currencyCode} item={item} showBottomDivider />
      <Box>
        <StaticField
          label={t("status")}
          renderHelpText={() => {
            return (
              <HelperTextContainer>
                <Box>{t(`itemStatus.helperText`)}</Box>
              </HelperTextContainer>
            )
          }}
          showBottomDivider
          value={t(`itemStatus.label.${item.isActive ? "ACTIVE" : "INACTIVE"}`)}
        />
      </Box>
      <Box>
        <StaticField
          label={t("unitSalePrice")}
          renderHelpText={() => {
            return (
              <HelperTextContainer>
                <Box>
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey={`unitSalePriceTypeLabel.${item.pricingStrategy}`}
                    values={{ markup: (item.unitSalePriceMarkup ?? 0.0) * 100.0 }}
                  />
                </Box>
              </HelperTextContainer>
            )
          }}
          showBottomDivider
          value={formatMoney(currencyCode, item.unitSalePrice)}
        />
      </Box>
      {item.isTracked ? (
        <>
          <StaticField label={t("quantityOnHand")} showBottomDivider value={item.qtyOnHand} />
          <StaticField label={t("reorderPoint")} showBottomDivider value={item.reorderPoint} />
        </>
      ) : null}
      <StaticField label={t("reorderQty")} showBottomDivider value={item.reorderQty} />
      {item.isTopLevel ? (
        <StaticField label={t("quantityOnOrder")} showBottomDivider value={item.orderedQty} />
      ) : null}
      <StaticField
        label={t("quantityCommitted")}
        renderHelpText={() => {
          return (
            <HelperTextContainer>
              <Box>{t("quantityCommittedHelperText")}</Box>
            </HelperTextContainer>
          )
        }}
        showBottomDivider={false}
        value={item.committedQty}
      />
    </>
  )
}

export default ProductDetailsStatic
