import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import PositiveIntegerInput from "../../../components/PositiveIntegerInput"
import FieldHelperText from "../../../components/FieldHelperText"
import { formatMoney, isBlank, NOT_SPECIFIED } from "../../../util"
import { OrganizationItem } from "../../../types"

interface Props {
  readonly currencyCode: string
  readonly organizationItem: OrganizationItem
  readonly loading?: boolean
  readonly open?: boolean
  readonly onClose: () => void
  readonly onSubmit: (value: { organizationItemId: string; quantity: number }) => void
}

function AddToCartDialog({
  currencyCode,
  organizationItem,
  loading,
  open = false,
  onClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState<string>(() => {
    let qty = Number(organizationItem.reorderQty ?? 1)

    if (organizationItem.packSize && organizationItem.packSize > 1) {
      // round up qty to the nearest multiple of packSize
      qty = Math.ceil(qty / organizationItem.packSize) * organizationItem.packSize
    }

    return String(qty)
  })

  return (
    <Dialog
      aria-labelledby="add-to-cart-dialog"
      data-testid="AddToCartDialog"
      disableEscapeKeyDown={false}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        id="add-to-cart-dialog"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {t("addToCart")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: "1rem",
            paddingTop: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.875rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <label style={{ fontWeight: "bold" }}>{organizationItem.code}</label>
              <span>{organizationItem.name}</span>
            </Box>
            <Box
              sx={{
                padding: "1rem",
                borderRadius: "4px",
                border: "1px solid #f5f5f5",
                backgroundColor: "#f5f5f5",
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}
            >
              <FieldContainer>
                <FieldLabel>{t("unitPrice")}</FieldLabel>
                <FieldValue>
                  {organizationItem.wholesalePrice
                    ? formatMoney(currencyCode, organizationItem.wholesalePrice)
                    : NOT_SPECIFIED}
                </FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>{t("quantityOnHand")}</FieldLabel>
                <FieldValue>{organizationItem.qtyOnHand}</FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>{t("reorderPoint")}</FieldLabel>
                <FieldValue>{organizationItem.reorderPoint ?? NOT_SPECIFIED}</FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>{t("reorderQty")}</FieldLabel>
                <FieldValue>{organizationItem.reorderQty ?? NOT_SPECIFIED}</FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>{t("palletQty")}</FieldLabel>
                <FieldValue>{organizationItem.palletQty ?? NOT_SPECIFIED}</FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>{t("packSize")}</FieldLabel>
                <FieldValue>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0rem",
                      alignItems: "flex-end",
                    }}
                  >
                    <Box>{organizationItem.packSize ?? NOT_SPECIFIED}</Box>
                    {Number(organizationItem.packSize) > 1 && !organizationItem.isPackBreakable ? (
                      <Box sx={{ fontSize: "0.75rem", fontStyle: "italic" }}>
                        {t("notBreakable")}
                      </Box>
                    ) : null}
                  </Box>
                </FieldValue>
              </FieldContainer>
            </Box>
            {organizationItem.isArchived ? (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "hsla(33, 88%, 95%, 1)",
                  color: theme.fielderColors.text,
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  padding: "1rem 2rem",
                  border: `1px solid ${theme.fielderColors.warningHeavy}`,
                  borderRadius: "4px",
                  marginTop: "1rem",
                  marginBottom: "0",
                })}
              >
                {t("itemNotOrderable")}
              </Box>
            ) : (
              <>
                <Box
                  sx={[{ marginTop: "1rem", flexDirection: "column", backgroundColor: "white" }]}
                >
                  <PositiveIntegerInput
                    autoFocus
                    data-testid="quantity-Field"
                    fullWidth
                    id="quantity"
                    label={t("quantity")}
                    max={999999}
                    min={1}
                    name="quantity"
                    onBlur={() => {
                      const qty = Number(quantity)
                      const packSize = Number(organizationItem.packSize ?? 1)
                      if (
                        packSize > 1 &&
                        !organizationItem.isPackBreakable &&
                        qty % packSize !== 0
                      ) {
                        // round up qty to the nearest multiple of packSize
                        setQuantity(String(Math.ceil(qty / packSize) * packSize))
                      }
                    }}
                    onChange={(e) => {
                      setQuantity(e.target.value)
                    }}
                    required
                    value={quantity}
                  />
                  <FieldHelperText
                    message={t("component.addToCartDialog.helperText.quantity") as string}
                  />
                </Box>
                <Box
                  sx={{
                    padding: "1rem 0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.25rem",
                  }}
                >
                  <FieldContainer>
                    <FieldLabel sx={{ fontSize: "1rem" }}>{t("subTotal")}</FieldLabel>
                    <FieldValue sx={{ fontSize: "1rem" }}>
                      {organizationItem.wholesalePrice
                        ? formatMoney(
                            currencyCode,
                            parseInt(quantity, 10) * organizationItem.wholesalePrice
                          )
                        : NOT_SPECIFIED}
                    </FieldValue>
                  </FieldContainer>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "0.75rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onClose}
          sx={(theme) => ({
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          })}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="submitButton"
          disabled={loading || isBlank(quantity) || parseInt(quantity, 10) < 1}
          onClick={() => {
            onSubmit({ organizationItemId: organizationItem.id, quantity: parseInt(quantity, 10) })
          }}
          sx={(theme) => ({
            fontWeight: "bold",
            [theme.breakpoints.down("xs")]: {
              marginTop: "0.625rem",
              width: "100%",
            },
          })}
          variant="contained"
        >
          {loading ? (
            <CircularProgress color="secondary" size={20} thickness={6.0} />
          ) : (
            <Box>{t("addToCart")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface FieldElementProps {
  readonly children: React.ReactNode
  readonly sx?: any
}

function FieldContainer({ children, sx }: FieldElementProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        justifyContent: "space-between",
        ...(sx ?? {}),
      }}
    >
      {children}
    </Box>
  )
}

function FieldLabel({ children, sx }: FieldElementProps) {
  return (
    <Box
      component="label"
      sx={{
        fontWeight: "600",
        fontSize: "0.875rem",
        color: (theme) => theme.fielderColors.mutedText,
        ...(sx ?? {}),
      }}
    >
      {children}
    </Box>
  )
}

function FieldValue({ children, sx }: FieldElementProps) {
  return (
    <Box
      component="span"
      sx={{
        fontSize: "0.875rem",
        color: (theme) => theme.fielderColors.mutedText,
        ...(sx ?? {}),
      }}
    >
      {children}
    </Box>
  )
}

export default AddToCartDialog
