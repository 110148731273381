import React, { useState } from "react"
import { Navigate, useLocation, NavigateProps } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { gql, useQuery, useMutation, ApolloError } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/EditOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import CircularProgress from "@mui/material/CircularProgress"

import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import { SETTINGS } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { DefaultPermission, EmailTemplate, Snack } from "~/types"

const ALL_EMAIL_TEMPLATES = gql`
  query GetOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      emailTemplates {
        id
        name
      }
    }
  }
`

const DELETE_EMAIL_TEMPLATE = gql`
  mutation DeleteEmailTemplate($id: ID!) {
    deleteEmailTemplate(id: $id)
  }
`

function EmailTemplates() {
  const { t } = useTranslation()
  const location = useLocation()
  const [snack, setSnack] = useState<Snack | undefined>(() => location?.state?.snack)
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>()
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const { hasPermissions, user } = useAuth()

  const { loading, data, refetch } = useQuery(ALL_EMAIL_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: user?.organization?.id,
    },
  })

  const [deleteEmailTemplate, { loading: deleteLoading }] = useMutation(DELETE_EMAIL_TEMPLATE, {
    onCompleted: () => {
      setSnack({
        messageKey: "page.settings.templates.common.delete.success",
        variant: "success",
      })
      setSelectedTemplate(null)
      setIsConfirmDeleteDialogOpen(false)
      refetch()
    },
    onError: (error: ApolloError) => {
      Sentry.captureException(error)
      setSnack({
        messageKey: "page.settings.templates.common.delete.error",
        variant: "error",
      })
    },
  })

  const templates = (data?.getOrganizationById?.emailTemplates || [])
    .slice()
    .sort((a: EmailTemplate, b: EmailTemplate) => a.name.localeCompare(b.name)) as EmailTemplate[]

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[
              { to: SETTINGS.path, titleKey: SETTINGS.titleKey },
              { to: "/app/settings/templates", titleKey: "templates" },
            ]}
            icon={SETTINGS.icon}
            leafTitleKey="emailTemplates"
          />
          <Paper sx={classes.widgetContainer}>
            <SectionHeader>
              <label>{t("emailTemplates")}</label>
              {hasPermissions?.([DefaultPermission.CreateEmailTemplate]) ? (
                <Button
                  aria-label="create"
                  color="primary"
                  disabled={loading}
                  onClick={() =>
                    setRedirectTo({ to: "/app/settings/templates/email/create", replace: false })
                  }
                  sx={classes.primaryActionButton}
                  variant="contained"
                >
                  {loading ? (
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  ) : (
                    <>
                      <AddIcon />
                      <Box>{t(`add`)}</Box>
                    </>
                  )}
                </Button>
              ) : null}
            </SectionHeader>
            <Divider />
            <SectionContent>
              {loading ? (
                <Box sx={classes.spinnerContainer}>
                  <CircularProgress color="secondary" size={20} thickness={6.0} />
                </Box>
              ) : null}
              {!loading && (
                <TableContainer>
                  <Table aria-label={t("templates")} stickyHeader>
                    <TableBody>
                      {templates.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={2} sx={{ borderBottom: "none" }}>
                            <Box
                              sx={{
                                color: (theme) => theme.fielderColors.mutedText,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                fontSize: "1.125rem",
                                weight: "bold",
                                padding: "2.5rem",
                              }}
                            >
                              {t("page.settings.templates.email.zeroState")}
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      {templates?.map((t: EmailTemplate) => {
                        return (
                          <TableRow key={t.id}>
                            <TableCell width="95%">{t.name}</TableCell>
                            <TableCell align="right" sx={{ paddingRight: "0.5rem" }}>
                              {hasPermissions?.([DefaultPermission.UpdateEmailTemplate]) ? (
                                <Box sx={classes.rowButtonContainer}>
                                  <IconButton
                                    aria-label="edit"
                                    data-testid="editBtn"
                                    onClick={() => {
                                      setSelectedTemplate(t)
                                      setRedirectTo({
                                        to: `/app/settings/templates/email/edit/${t.id}`,
                                        replace: false,
                                      })
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    data-testid="deleteBtn"
                                    onClick={() => {
                                      setSelectedTemplate(t)
                                      setIsConfirmDeleteDialogOpen(true)
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </SectionContent>
          </Paper>
        </Box>
      </MainLayout>
      {isConfirmDeleteDialogOpen && selectedTemplate ? (
        <Dialog
          aria-describedby="delete-dialog-description"
          aria-labelledby="delete-dialog-title"
          onClose={() => setIsConfirmDeleteDialogOpen(false)}
          open={isConfirmDeleteDialogOpen}
        >
          <DialogTitle id="delete-dialog-title">
            {t("page.settings.templates.common.deleteConfirmationPromptTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-contact-dialog-description">
              {t("page.settings.templates.common.deleteConfirmationPromptMsg", {
                name: selectedTemplate.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={deleteLoading}
              onClick={() => setIsConfirmDeleteDialogOpen(false)}
            >
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={deleteLoading}
              onClick={() => {
                deleteEmailTemplate({
                  variables: {
                    id: selectedTemplate.id,
                  },
                })
              }}
            >
              {deleteLoading ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                <Box>{t("yes")}</Box>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
  widgetContainer: {
    maxWidth: "700px",
    marginBottom: "1.25rem",
    marginTop: "3.75rem",
  },
  tableHeaderCell: {
    backgroundColor: "#fff",
  },
  rowButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
}

export default EmailTemplates
