import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import SaveButton from "./SaveButton"
import Divider from "@mui/material/Divider"
import { TextField } from "@mui/material"
import { OrganizationPlugin, ProductOrder, ProductOrderDeliveryLineItemForm } from "../types"
import PositiveIntegerInput from "./PositiveIntegerInput"
import { CREATE_PRODUCT_ORDER_DELIVERY } from "../queries/createProductOrderDelivery"
import { asInt, isNumeric, parseGraphQLErrorCode } from "../util"
import ErrorMessage from "./ErrorMessage"

const dialogBackgroundColor = "#FFFFFF"

function ReceiveShipmentDialog({
  accountingPlugin,
  productOrder,
  onClose,
}: {
  readonly accountingPlugin?: OrganizationPlugin
  readonly productOrder: ProductOrder
  readonly onClose: () => void
}) {
  const { t } = useTranslation()

  const [lineItemForms, setLineItemForms] = useState<Array<ProductOrderDeliveryLineItemForm>>(
    () => {
      return productOrder.lineItems?.map((li) => {
        return {
          lineItem: li,
          quantity: "",
        }
      })
    }
  )

  const [
    createProductOrderDelivery,
    { loading: createProductOrderDeliveryLoading, error: createProductOrderDeliveryError },
  ] = useMutation(CREATE_PRODUCT_ORDER_DELIVERY, {
    onCompleted: () => {
      onClose?.()
    },
    onError: (error) => {
      Sentry.captureException(error)
      console.error(error)
    },
    refetchQueries: ["GetProductOrderById"],
  })

  const errorCode = createProductOrderDeliveryError
    ? parseGraphQLErrorCode(createProductOrderDeliveryError)
    : undefined

  function updateLineItemForm(lineItemForm: ProductOrderDeliveryLineItemForm) {
    const idx = lineItemForms.findIndex((lif) => lif.lineItem.id == lineItemForm.lineItem.id)
    lineItemForms.splice(idx, 1, lineItemForm)
    setLineItemForms(lineItemForms.concat([]))
  }

  function handleSave() {
    const variables = {
      productOrderId: productOrder.id,
      lineItems: lineItemForms.map((lif) => ({
        productOrderLineItemId: lif.lineItem.id,
        quantity: isNumeric(lif.quantityReceived) ? asInt(lif.quantityReceived, true) : 0,
      })),
    }
    createProductOrderDelivery({ variables })
  }

  return (
    <Dialog
      aria-labelledby="receive-shipment-dialog"
      data-testid="ReceiveShipmentDialog"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {t("component.receiveShipmentDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: dialogBackgroundColor,
            paddingBottom: 0,
            paddingTop: "1rem",
            px: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          {errorCode ? (
            <Box>
              <ErrorMessage data-testid="error-message" message={t(errorCode)} title="" />
            </Box>
          ) : (
            <Box sx={{ fontSize: "0.75rem", color: (theme) => theme.fielderColors.mutedText }}>
              {t(
                `component.receiveShipmentDialog.instructions.${
                  accountingPlugin?.pluginProvider?.id?.toUpperCase() ?? "FIELDER"
                }`
              )}
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
            <Header />
            <Divider />
          </Box>
          <Box sx={{ margin: "0.5rem 0", display: "flex", flexDirection: "column", gap: "1rem" }}>
            {lineItemForms.map((lif) => {
              return (
                <ShipmentLineItem
                  key={lif.lineItem.id}
                  lineItemForm={lif}
                  onChange={updateLineItemForm}
                />
              )
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: dialogBackgroundColor,
          borderTop: "1px solid #cdcdcd",
          padding: "1.5rem",
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <Button
          color="secondary"
          data-testid="receiveShipmentDialogField_cancelButton"
          disabled={createProductOrderDeliveryLoading}
          onClick={onClose}
          variant="outlined"
        >
          {t("cancel")}
        </Button>

        <SaveButton loading={createProductOrderDeliveryLoading} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

function Header() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.5rem",
        fontWeight: "600",
        fontSize: "0.875rem",
      }}
    >
      <Box>{t("item")}</Box>
      <Box>{t("quantityReceived")}</Box>
    </Box>
  )
}

function ShipmentLineItem({
  lineItemForm,
  onChange,
}: {
  readonly lineItemForm: ProductOrderDeliveryLineItemForm
  readonly onChange: (lineItemForm: ProductOrderDeliveryLineItemForm) => void
}) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ fontSize: "0.875rem", flex: "2" }}>
        <Box sx={{ fontWeight: "600" }}>{lineItemForm.lineItem.organizationItem.code}</Box>
        <Box>{lineItemForm.lineItem.organizationItem.name}</Box>
      </Box>
      <Box>
        <PositiveIntegerInput
          aria-label={t("quantityReceived") as string}
          customInput={TextField}
          inputProps={{ min: 0, maxLength: 12, style: { maxWidth: "60px", textAlign: "right" } }}
          name="quantityReceived"
          onChange={(e) => {
            lineItemForm.quantityReceived = e.target.value
            onChange(lineItemForm)
          }}
          required
          size="small"
          value={lineItemForm.quantityReceived}
          variant="outlined"
        />
      </Box>
    </Box>
  )
}

export default ReceiveShipmentDialog
