import React from "react"
import difference from "lodash/difference"
import intersection from "lodash/intersection"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"

import { PermissionOption } from "~/types"
import PermissionToggle from "./PermissionToggle"
import { flattenPermissions } from "../utils/helpers"

interface PermissionGroupProps {
  readonly disabled?: boolean
  readonly permissionOptionGroupId: string
  readonly enabledPermissionOptionIds: string[]
  readonly permissionOptions?: PermissionOption[]
  readonly onChange: (id: string, checked: boolean) => void
}

function PermissionGroup({
  disabled,
  enabledPermissionOptionIds,
  permissionOptionGroupId,
  permissionOptions,
  onChange,
}: PermissionGroupProps) {
  const { t } = useTranslation()

  const isChecked = () => {
    if (!permissionOptions) {
      return enabledPermissionOptionIds.includes(permissionOptionGroupId)
    } else {
      const descendentPermIds = flattenPermissions({
        id: permissionOptionGroupId,
        permissions: permissionOptions,
      })

      return difference(descendentPermIds, enabledPermissionOptionIds).length === 0
    }
  }

  const isIndeterminate = () => {
    if (!permissionOptions) {
      return false
    } else {
      const descendentPermIds = flattenPermissions({
        id: permissionOptionGroupId,
        permissions: permissionOptions,
      })

      const inter = intersection(descendentPermIds, enabledPermissionOptionIds)
      return inter.length > 0 && inter.length < descendentPermIds.length
    }
  }

  return (
    <Box>
      <Box sx={classes.toggleWrapper}>
        <PermissionToggle
          checked={isChecked()}
          description={
            permissionOptions
              ? undefined
              : (t(`roleManagement.${permissionOptionGroupId}.description`) as string)
          }
          disabled={disabled}
          indeterminate={isIndeterminate()}
          onChange={(id: string, checked: boolean) => {
            onChange(permissionOptionGroupId, checked)
          }}
          permissionOptionId={permissionOptionGroupId}
          title={t(`roleManagement.${permissionOptionGroupId}.title`)}
        />
      </Box>
      {permissionOptions ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "0.89rem",
            borderLeft: "1px solid #ededed",
            paddingLeft: "1.5rem",
            marginTop: "-0.5rem",
            marginBottom: "0.5rem",
          }}
        >
          {permissionOptions.map((p) => {
            if (p.permissions) {
              return (
                <PermissionGroup
                  disabled={disabled}
                  enabledPermissionOptionIds={enabledPermissionOptionIds}
                  key={p.id}
                  onChange={(id: string, checked: boolean) => {
                    onChange(id, checked)
                  }}
                  permissionOptionGroupId={p.id}
                  permissionOptions={p.permissions as PermissionOption[]}
                />
              )
            } else {
              return (
                <Box key={p.id} sx={classes.toggleWrapper}>
                  <PermissionToggle
                    checked={enabledPermissionOptionIds.includes(p.id)}
                    description={
                      p.permissions
                        ? undefined
                        : (t(`roleManagement.${p.id}.description`) as string)
                    }
                    disabled={disabled}
                    onChange={(id: string, checked: boolean) => {
                      onChange(id, checked)
                    }}
                    permissionOptionId={p.id}
                    title={t(`roleManagement.${p.id}.title`)}
                  />
                </Box>
              )
            }
          })}
        </Box>
      ) : null}
    </Box>
  )
}

const classes = {
  toggleWrapper: {
    marginBottom: "0.95rem",
  },
} as const

export default PermissionGroup
