import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { formatAddress, formatPersonName, formatPhoneNumber, NOT_SPECIFIED } from "~/util"
import { Organization } from "~/types"
import StaticField from "~/components/StaticField"
import FieldHelperText from "~/components/FieldHelperText"

interface Props {
  readonly organization: Organization
}

function OrganizationBasicInfoStatic({ organization }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <StaticField
        label={t("tradeName") as string}
        renderHelpText={() => (
          <FieldHelperText
            message={t("page.organizationDetails.helperText.tradeName")}
            style={{ marginLeft: 0 }}
          />
        )}
        showBottomDivider
        value={organization.tradeName}
      />
      <StaticField
        label={t("legalName") as string}
        renderHelpText={() => (
          <FieldHelperText
            message={t("page.organizationDetails.helperText.legalName")}
            style={{ marginLeft: 0 }}
          />
        )}
        showBottomDivider
        value={organization.legalName}
      />
      {organization.level >= 2 ? (
        <>
          <StaticField
            label={t("owner") as string}
            showBottomDivider
            value={formatPersonName(organization.owner)}
          />
          {!organization.isStandalone ? (
            <StaticField
              label={t("accountManager") as string}
              showBottomDivider
              value={
                organization.accountManager ? (
                  formatPersonName(organization.accountManager)
                ) : (
                  <Box component="span" sx={{ fontSize: "0.875rem", fontStyle: "italic" }}>
                    {t("page.organizationDetails.helperText.accountManagerNotAssigned")}
                  </Box>
                )
              }
            />
          ) : null}
        </>
      ) : null}
      <StaticField
        label={t("address") as string}
        showBottomDivider
        value={
          organization.address?.addressString
            ? formatAddress(organization.address.addressString)
            : NOT_SPECIFIED
        }
      />
      <StaticField
        label={t("email") as string}
        showBottomDivider
        value={organization.email ?? NOT_SPECIFIED}
      />
      <StaticField
        label={t("phoneNumber") as string}
        showBottomDivider
        value={
          organization.phoneNumber
            ? formatPhoneNumber(organization.phoneNumber, true)
            : NOT_SPECIFIED
        }
      />
      <StaticField
        label={t("websiteAddress") as string}
        showBottomDivider
        value={organization.websiteURL ?? NOT_SPECIFIED}
      />
      <StaticField
        label={t("currency") as string}
        showBottomDivider
        value={organization.currencyCode ?? NOT_SPECIFIED}
      />
      <StaticField
        label={t("timeZone") as string}
        showBottomDivider={false}
        value={organization.timeZone ?? NOT_SPECIFIED}
      />
    </>
  )
}

export default OrganizationBasicInfoStatic
