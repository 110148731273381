import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { useQuery } from "@apollo/client"
import { GET_LEVEL1_ORGANIZATION_DASHBOARD_METRICS } from "../queries/getLevel1OrgDashboardMetrics"
import { GET_LEVEL2_ORGANIZATION_DASHBOARD_METRICS } from "../queries/getLevel2OrgDashboardMetrics"
import dayjs from "dayjs"
import numeral from "numeral"

import { DASHBOARD, formatMoney, getStatusForegroundColor } from "../util"
import { useAuth } from "../context/AuthContext"
import { MainLayout, PageHeader, Seo } from "../components"
import SnackbarMessage from "../components/SnackbarMessage"
import MetricWidget from "../components/reports/MetricWidget"
import { DefaultPermission, FeatureFlag, ProductOrderStatus, Snack } from "../types"
import type {
  FranchisorOrderCount,
  ItemOrderCount,
  SalesTotalCount,
  ProductOrderCount,
  DashboardWidgetMetricGroup,
  DashboardWidgetMetric,
} from "../types"
import GettingStartedGuide from "../components/GettingStartedGuide"
import { FranchisorOrderCounts } from "../components/DashboardWidgets/FranchisorOrderCounts"
import { FranchisorTopItemsOrdered } from "../components/DashboardWidgets/FranchisorTopItemsOrdered"
import { FranchisorTopFranchiseesBySales } from "../components/DashboardWidgets/FranchisorTopFranchiseesBySales"
import { isFeatureFlagEnabled } from "~/util/featureFlag"
import useStore from "~/store"

const currentMonth = dayjs().month() + 1
const currentYear = dayjs().year()
const currentYearString = `${currentYear}`
const lastYear = `${currentYear - 1}`

function calculateSummary(dataset: DashboardWidgetMetricGroup[]) {
  const lastYearData = dataset.find((d) => d.label === lastYear) || { data: [] }
  const currentYearData = dataset.find((d) => d.label === currentYearString) || { data: [] }
  const lastYearTotal = lastYearData?.data
    ?.filter((d) => parseInt(d.label, 10) <= currentMonth)
    ?.reduce((acc, curr) => acc + curr.value, 0)
  const currentYearTotal = currentYearData?.data
    ?.filter((d) => parseInt(d.label, 10) <= currentMonth)
    ?.reduce((acc, curr) => acc + curr.value, 0)

  return {
    percentChange:
      lastYearTotal > 0 ? (currentYearTotal - lastYearTotal) / lastYearTotal : undefined,
    currentYearTotal,
    lastYearData: lastYearData?.data || [],
    currentYearData: currentYearData?.data || [],
  }
}

function translateData(dataset) {
  const defaults = []
  for (let i = 1; i <= 12; i++) {
    defaults.push({
      label: dayjs(`${i}`, "M").format("MMM"),
      value: dataset.find((d) => d.label === `${i}`)?.value || 0,
    })
  }
  return defaults
}

function WidgetContainer({ children }: { readonly children: React.ReactNode }) {
  return <Box>{children}</Box>
}

interface DashboardProps {
  readonly location?: {
    state?: {
      snack?: Snack
    }
  }
}

function DashboardPage({ location }: DashboardProps) {
  const { t } = useTranslation()
  const { user, hasPermissions } = useAuth()
  const { featureFlags } = useStore()
  const [snack, setSnack] = useState<Snack | null>(() => location?.state?.snack ?? null)
  const [salesPercentChange, setSalesPercentChange] = useState<number>(0) // Year over Year
  const [jobsPercentChange, setJobsPercentChange] = useState<number>(0) // Year over Year
  const [jobsTotal, setJobsTotal] = useState<number>(0) // Year-to-date
  const [salesTotal, setSalesTotal] = useState<number>(0) // Year-to-date
  const [salesData, setSalesData] = useState<DashboardWidgetMetric[]>([])
  const [jobsData, setJobsData] = useState<DashboardWidgetMetric[]>([])
  const [productOrderCountsData, setProductOrderCountsData] = useState<FranchisorOrderCount[]>([])
  const [itemOrderCountsData, setItemOrderCountsData] = useState<ItemOrderCount[]>([])
  const [salesTotalCountsData, setSalesTotalCountsData] = useState<SalesTotalCount[]>([])
  // const [avgJobValueData, setAvgJobValueData] = useState<DashboardWidgetMetric[]>([])
  // const [avgJobValueTotal, setAvgJobValueTotal] = useState<number[]>(0)
  // const [estimateWinRateData, setEstimateWinRateData] = useState<DashboardWidgetMetric[]>([])
  // const [estimateWinRateTotal, setEstimateWinRateTotal] = useState<number>(0)
  // const [returnCustomerRateData, setReturnCustomerRateData] = useState<DashboardWidgetMetric[]>(
  //   []
  // )
  // const [returnCustomerRateTotal, setReturnCustomerRateTotal] = useState<number>(0)
  // const [firstTimeFixRateData, setFirstTimeFixRateData] = useState<DashboardWidgetMetric[]>([])
  // const [firstTimeFixRateTotal, setFirstTimeFixRateTotal] = useState<number>(0)

  const metricsQuery =
    user?.organization?.level === 1
      ? GET_LEVEL1_ORGANIZATION_DASHBOARD_METRICS
      : GET_LEVEL2_ORGANIZATION_DASHBOARD_METRICS
  const metricsDateFormat = "YYYY-MM-DD"
  const metricsStart =
    user?.organization?.level === 1
      ? dayjs().subtract(1, "month").startOf("month")
      : dayjs().subtract(1, "year").startOf("year")
  const metricsEnd = dayjs().endOf("day")

  const { loading, error } = useQuery(metricsQuery, {
    variables: {
      id: user?.organization?.id,
      skip: !(user?.organization?.id && hasPermissions?.([DefaultPermission.ReadOrganization])),
      start: metricsStart.format(metricsDateFormat),
      end: metricsEnd.add(1, "day").startOf("day").format(metricsDateFormat),
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (user?.organization?.level === 1) {
        const productOrderCounts = data.getOrganizationById.productOrderCounts
        const orderCounts = [
          {
            id: ProductOrderStatus.SUBMITTED,
            name: t("productOrderStatus.SUBMITTED"),
            color: getStatusForegroundColor(ProductOrderStatus.SUBMITTED),
            count:
              productOrderCounts.find(
                (poc: ProductOrderCount) => poc.status === ProductOrderStatus.SUBMITTED
              )?.count ?? 0,
          },
          {
            id: ProductOrderStatus.IN_PROGRESS,
            name: t("productOrderStatus.IN_PROGRESS"),
            color: getStatusForegroundColor(ProductOrderStatus.IN_PROGRESS),
            count:
              productOrderCounts.find(
                (poc: ProductOrderCount) => poc.status === ProductOrderStatus.IN_PROGRESS
              )?.count ?? 0,
          },
          {
            id: ProductOrderStatus.SHIPPING,
            name: t("productOrderStatus.SHIPPING"),
            color: getStatusForegroundColor(ProductOrderStatus.SHIPPING),
            count:
              productOrderCounts.find(
                (poc: ProductOrderCount) => poc.status === ProductOrderStatus.SHIPPING
              )?.count ?? 0,
          },
          {
            id: ProductOrderStatus.INVOICING,
            name: t("productOrderStatus.INVOICING"),
            color: getStatusForegroundColor(ProductOrderStatus.INVOICING),
            count:
              productOrderCounts.find(
                (poc: ProductOrderCount) => poc.status === ProductOrderStatus.INVOICING
              )?.count ?? 0,
          },
          {
            id: ProductOrderStatus.COMPLETED,
            name: t("productOrderStatus.COMPLETED"),
            color: getStatusForegroundColor(ProductOrderStatus.COMPLETED),
            count:
              productOrderCounts.find(
                (poc: ProductOrderCount) => poc.status === ProductOrderStatus.COMPLETED
              )?.count ?? 0,
          },
        ]

        setProductOrderCountsData(orderCounts)
        setItemOrderCountsData(data.getOrganizationById.itemOrderCounts)
        setSalesTotalCountsData(data.getOrganizationById.salesTotalCounts)
      } else if (user?.organization?.level === 2) {
        const jobCounts = data.getOrganizationById.jobCounts
        const sales = data.getOrganizationById.sales

        const jobSummary = calculateSummary(jobCounts)
        const salesSummary = calculateSummary(sales)

        setJobsPercentChange(jobSummary.percentChange)
        setJobsTotal(jobSummary.currentYearTotal)
        setJobsData(translateData(jobSummary.currentYearData))
        setSalesPercentChange(salesSummary.percentChange)
        setSalesTotal(salesSummary.currentYearTotal)
        setSalesData(translateData(salesSummary.currentYearData))
      }
    },
  })

  return (
    <>
      <Seo title={t(DASHBOARD.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={DASHBOARD}>
        <Box sx={{ margin: "1.25rem", marginTop: "0" }}>
          <PageHeader icon={DASHBOARD.icon} leafTitleKey={DASHBOARD.titleKey} />
          <Box sx={{ marginBottom: "2rem" }}>
            {user?.organization?.level == 2 ? (
              <Box
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    gap: "1.5rem",
                    [theme.breakpoints.up("sm")]: {
                      flex: 1,
                      flexDirection: "row",
                    },
                  }
                }}
              >
                <Box
                  sx={(theme) => {
                    return {
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
                      gridAutoFlow: "dense",
                      gridAutoRows: "min-content",
                      gap: "1.5rem",
                      [theme.breakpoints.up("sm")]: {
                        flex: 1,
                      },
                    }
                  }}
                >
                  <WidgetContainer>
                    <MetricWidget
                      chartColor="#35A8FE"
                      data={salesData}
                      helpText={t("component.annualSalesWidget.helpText")}
                      id="salesWidget"
                      percentChange={salesPercentChange}
                      showGraph={salesData?.some((d) => d.value !== 0)}
                      summaryFigure={formatMoney(
                        user?.organization?.currencyCode,
                        salesTotal,
                        t("format:currency.short") as string
                      )}
                      title={t("component.annualSalesWidget.title")}
                    />
                  </WidgetContainer>
                  <WidgetContainer>
                    <MetricWidget
                      data={jobsData}
                      helpText={t("component.jobsCreatedWidget.helpText")}
                      id="jobCountWidget"
                      percentChange={jobsPercentChange}
                      showGraph={jobsData?.some((d) => d.value !== 0)}
                      summaryFigure={numeral(jobsTotal).format(t("0,0") as string)}
                      title={t("component.jobsCreatedWidget.title")}
                    />
                  </WidgetContainer>
                </Box>
                {user.roles?.map((r) => r.name)?.includes("ADMIN_LEVEL_2") ? (
                  <GettingStartedGuide sx={{ flex: "2", maxWidth: "600px" }} />
                ) : null}
              </Box>
            ) : (
              <Box
                sx={(theme) => {
                  return {
                    mt: "2rem",
                    display: "grid",
                    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                    gap: "1rem",
                    [theme.breakpoints.up("sm")]: {
                      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                    },
                    [theme.breakpoints.up("xl")]: {
                      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                    },
                  }
                }}
              >
                {isFeatureFlagEnabled(FeatureFlag.FranchisorProductOrder, featureFlags) ? (
                  <>
                    <FranchisorOrderCounts
                      counts={productOrderCountsData}
                      endDate={metricsEnd}
                      startDate={metricsStart}
                    />
                    <FranchisorTopItemsOrdered
                      counts={itemOrderCountsData}
                      endDate={metricsEnd}
                      startDate={metricsStart}
                    />
                  </>
                ) : null}
                <FranchisorTopFranchiseesBySales
                  counts={salesTotalCountsData}
                  endDate={metricsEnd}
                  startDate={metricsStart}
                />
              </Box>
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}

export default DashboardPage
