/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from "react"
import Downshift from "downshift"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { styled } from "@mui/material/styles"
import SearchIcon from "@mui/icons-material/Search"
import CircularProgress from "@mui/material/CircularProgress"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Box from "@mui/material/Box"
import Popper from "@mui/material/Popper"
import SearchResults from "./SearchResults"
import { SEARCH } from "../../queries/search"
import { SearchResultType, SuggestedFilterOption } from "../../types"

const breakpoints = {
  hideInput: 380,
  hideHelpAndNotifications: 620,
  minimum: 600,
}

const SearchField = styled("input")(({ theme }) => ({
  flexGrow: 1,
  border: "none",
  fontSize: "0.875rem",
  color: "#737373",
  fontWeight: 500,
  maxHeight: 48,
  [theme.breakpoints.down(breakpoints.hideInput)]: {
    display: "none",
  },
  "&::placeholder": {
    fontWeight: 400,
    color: "#bdbdbd",
  },
  "&:focus": {
    outline: "none",
  },
}))

function SearchTypeahead() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const anchorEl = useRef(null)
  const searchInput = useRef(null)
  const [keyword, setKeyword] = useState<string>("")
  const [searchType, setSearchType] = useState<string>("")
  const [searchResults, setSearchResults] = useState([])

  const { loading } = useQuery(SEARCH, {
    variables: {
      keyword,
      types: searchType?.length > 0 ? [searchType] : undefined,
      first: 10,
    },
    context: {
      debounceKey: "SearchTypeahead",
      debounceTimeout: 500,
    },
    fetchPolicy: "cache-and-network",
    skip: keyword.length === 0,
    onCompleted: (data) => {
      setSearchResults(data?.search?.records ?? [])
    },
  })

  return (
    <Downshift
      itemToString={(item) => (item ? String(item) : "")}
      onInputValueChange={(inputValue) => {
        if (inputValue.includes(":")) {
          const [type, ...term] = inputValue.split(":")
          setKeyword(term.join(":").trim())
          if (searchType?.length === 0) {
            setSearchType(type.toUpperCase())
          }
        } else {
          setKeyword(inputValue)
          setSearchType("")
        }

        if (inputValue.length === 0) {
          setSearchResults([])
        }
      }}
      onSelect={(selectedItem, stateAndHelpers) => {
        // get a ref to the item before clearing everything
        if (selectedItem) {
          const item = selectedItem

          switch (item.type) {
            case SearchResultType.JOB:
              navigate(`/app/jobs/edit/${item.id}`)
              break
            case SearchResultType.CUSTOMER:
              navigate(`/app/customers/edit/${item.id}`)
              break
            case SearchResultType.TASK:
              navigate(`/app/tasks/${item.id}`)
              break
            case SearchResultType.ESTIMATE:
              navigate(`/app/jobs/edit/${item.record.job.id}/estimates`)
              break
            case SearchResultType.INVOICE:
              navigate(`/app/jobs/edit/${item.record.job.id}/invoices`)
              break
            case SearchResultType.PRODUCT_ORDER:
              navigate(`/app/orders/edit/${item.id}`)
              break
            case SearchResultType.ITEM:
              navigate(`/app/inventory/edit/${item.id}`)
              break
            default:
              return
          }

          setKeyword("")
          setSearchType("")
          stateAndHelpers.clearSelection()
          stateAndHelpers.reset()
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getRootProps,
        inputValue = "",
        isOpen,
        closeMenu,
        openMenu,
        setState,
      }) => {
        return (
          <Box
            {...getRootProps()}
            sx={{ flex: 1, display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <Box
              ref={anchorEl}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Box sx={{ width: "1rem", display: "flex", alignItems: "center" }}>
                {loading ? (
                  <CircularProgress color="secondary" size={12} thickness={6.0} />
                ) : (
                  <SearchIcon
                    sx={{ color: (theme) => theme.fielderColors.mutedText, fontSize: "1rem" }}
                  />
                )}
              </Box>
              <SearchField
                {...getInputProps({
                  onFocus: () => {
                    if (!isOpen) {
                      openMenu()
                      setKeyword("")
                      setSearchType("")
                    }
                  },
                })}
                placeholder={t("component.uberSearchBar.placeholder") as string}
                ref={searchInput}
                value={inputValue}
              />
            </Box>
            <Popper
              anchorEl={anchorEl.current}
              disablePortal
              id="popper"
              open={isOpen}
              placement="bottom-start"
              sx={{
                zIndex: 10001,
                "& .MuiPaper-root": {
                  marginTop: "1rem",
                },
              }}
            >
              <ClickAwayListener
                onClickAway={(event) => {
                  if (anchorEl?.current?.contains?.(event.target)) {
                    return
                  } else {
                    closeMenu()
                    setKeyword("")
                    setSearchType("")
                  }
                }}
              >
                <div>
                  <SearchResults
                    getItemProps={getItemProps}
                    loading={loading}
                    onClickSuggestedFilter={(filter: SuggestedFilterOption) => {
                      setKeyword("")
                      setState({ inputValue: filter.label })
                      setSearchType(filter.id)
                      searchInput?.current?.focus()
                    }}
                    searchResults={searchResults}
                    searchTerm={inputValue ?? ""}
                  />
                </div>
              </ClickAwayListener>
            </Popper>
          </Box>
        )
      }}
    </Downshift>
  )
}

export default React.memo(SearchTypeahead)
