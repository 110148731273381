import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, gql } from "@apollo/client"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

import { AddReminderSeriesEventButton } from "./components/AddReminderSeriesEventButton"
import { ReminderSeriesEventCard } from "./components/ReminderSeriesEventCard"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import { parseGraphQLErrorCode, SETTINGS } from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  ReminderSeries,
  ReminderSeriesCategory,
  Snack,
  ReminderSeriesEvent,
  ReminderScheduleUnit,
} from "~/types"
import { SeriesEventConnectingLine } from "./components/SeriesEventConnectingLine"
import { EditReminderSeriesEventDialog } from "./components/EditReminderSeriesEventDialog"

const GET_ALL_REMINDER_SERIES = gql`
  query GetAllReminderSeries($organizationId: ID!) {
    getOrganizationById(id: $organizationId) {
      id
      reminderSeries {
        id
        category
        events {
          id
          channel
          scheduleUnit
          scheduleOffset
          timeOfDay
          subject
          body
        }
      }
    }
  }
`

const CREATE_REMINDER_SERIES_EVENT = gql`
  mutation CreateReminderSeriesEvent(
    $category: ReminderSeriesCategory!
    $channel: MessageChannel!
    $scheduleUnit: ReminderScheduleUnit!
    $scheduleOffset: Int!
    $timeOfDay: Int
    $subject: String
    $body: String!
  ) {
    createReminderSeriesEvent(
      input: {
        category: $category
        channel: $channel
        scheduleUnit: $scheduleUnit
        scheduleOffset: $scheduleOffset
        timeOfDay: $timeOfDay
        subject: $subject
        body: $body
      }
    ) {
      reminderSeriesEvent {
        id
        channel
        scheduleUnit
        scheduleOffset
        timeOfDay
        subject
        body
      }
    }
  }
`

const EDIT_REMINDER_SERIES_EVENT = gql`
  mutation EditReminderSeriesEvent(
    $id: ID!
    $channel: MessageChannel!
    $scheduleUnit: ReminderScheduleUnit!
    $scheduleOffset: Int!
    $timeOfDay: Int
    $subject: String
    $body: String!
  ) {
    editReminderSeriesEvent(
      input: {
        id: $id
        channel: $channel
        scheduleUnit: $scheduleUnit
        scheduleOffset: $scheduleOffset
        timeOfDay: $timeOfDay
        subject: $subject
        body: $body
      }
    ) {
      reminderSeriesEvent {
        id
        channel
        scheduleUnit
        scheduleOffset
        timeOfDay
        subject
        body
      }
    }
  }
`

const DELETE_REMINDER_SERIES_EVENT = gql`
  mutation DeleteReminderSeriesEvent($id: ID!) {
    deleteReminderSeriesEvent(id: $id) {
      reminderSeriesEvent {
        id
      }
    }
  }
`

export default function ManageAutomatedMessaging() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [snack, setSnack] = useState<Snack>()
  const [eventToEdit, setEventToEdit] = useState<ReminderSeriesEvent | null>(null)
  const {
    loading: queryLoading,
    data,
    refetch,
  } = useQuery(GET_ALL_REMINDER_SERIES, {
    variables: {
      organizationId: user?.organization?.id,
    },
    fetchPolicy: "cache-and-network",
  })

  const [createReminderSeriesEvent, { loading: createReminderSeriesEventLoading }] = useMutation(
    CREATE_REMINDER_SERIES_EVENT,
    {
      onCompleted: () => {
        refetch()
        setEventToEdit(null)
        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      },
      onError: (error) => {
        const errorCode = parseGraphQLErrorCode(error)
        let reportToSentry = true

        if (errorCode.includes("reminder-series-event")) {
          reportToSentry = false
        }

        if (reportToSentry) {
          Sentry.captureException(error)
        }

        setSnack({
          messageKey: errorCode,
          variant: "error",
        })
      },
    }
  )

  const [editReminderSeriesEvent, { loading: editReminderSeriesEventLoading }] = useMutation(
    EDIT_REMINDER_SERIES_EVENT,
    {
      onCompleted: () => {
        refetch()
        setEventToEdit(null)
        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      },
      onError: (error) => {
        const errorCode = parseGraphQLErrorCode(error)
        let reportToSentry = true

        if (errorCode.includes("reminder-series-event")) {
          reportToSentry = false
        }

        if (reportToSentry) {
          Sentry.captureException(error)
        }

        setSnack({
          messageKey: errorCode,
          variant: "error",
        })
      },
    }
  )

  const [deleteReminderSeriesEvent, { loading: deleteReminderSeriesEventLoading }] = useMutation(
    DELETE_REMINDER_SERIES_EVENT,
    {
      onCompleted: () => {
        refetch()
        setEventToEdit(null)
        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      },
      onError: (error) => {
        Sentry.captureException(error)
        setSnack({
          messageKey: "page.manageAutomatedMessaging.reminderSeries.error",
          variant: "error",
        })
      },
    }
  )

  const isSaving =
    createReminderSeriesEventLoading ||
    editReminderSeriesEventLoading ||
    deleteReminderSeriesEventLoading
  const reminderSeries = data?.getOrganizationById?.reminderSeries
  const jobAssignmentReminderSeries = reminderSeries?.find(
    (reminderSeries: ReminderSeries) =>
      reminderSeries.category === ReminderSeriesCategory.JOB_ASSIGNMENT
  )
  const jobAssignmentReminderSeriesEvents = jobAssignmentReminderSeries?.events
    ?.map((event: ReminderSeriesEvent) => ({
      ...event,
      category: ReminderSeriesCategory.JOB_ASSIGNMENT,
      sortIndex:
        Math.abs(event.scheduleOffset) *
        (event.scheduleUnit === ReminderScheduleUnit.DAYS ? 24 : 1),
    }))
    .sort((a: ReminderSeriesEvent, b: ReminderSeriesEvent) => b.sortIndex - a.sortIndex)

  const estimateFollowUpSeries = reminderSeries?.find(
    (reminderSeries: ReminderSeries) =>
      reminderSeries.category === ReminderSeriesCategory.ESTIMATE_FOLLOW_UP
  )
  const estimateFollowUpSeriesEvents = estimateFollowUpSeries?.events
    ?.map((event: ReminderSeriesEvent) => ({
      ...event,
      category: ReminderSeriesCategory.ESTIMATE_FOLLOW_UP,
      sortIndex:
        Math.abs(event.scheduleOffset) *
        (event.scheduleUnit === ReminderScheduleUnit.DAYS ? 24 : 1),
    }))
    .sort((a: ReminderSeriesEvent, b: ReminderSeriesEvent) => a.sortIndex - b.sortIndex)

  const invoiceFollowUpSeries = reminderSeries?.find(
    (reminderSeries: ReminderSeries) =>
      reminderSeries.category === ReminderSeriesCategory.INVOICE_FOLLOW_UP
  )
  const invoiceFollowUpSeriesEvents = invoiceFollowUpSeries?.events
    ?.map((event: ReminderSeriesEvent) => ({
      ...event,
      category: ReminderSeriesCategory.INVOICE_FOLLOW_UP,
      sortIndex:
        Math.abs(event.scheduleOffset) *
        (event.scheduleUnit === ReminderScheduleUnit.DAYS ? 24 : 1),
    }))
    .sort((a: ReminderSeriesEvent, b: ReminderSeriesEvent) => a.sortIndex - b.sortIndex)

  return (
    <>
      <Seo title={t("sectionTitle.settings")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={SETTINGS}>
        <Box sx={classes.root}>
          <PageHeader
            breadcrumbs={[{ to: SETTINGS.path, titleKey: SETTINGS.titleKey }]}
            icon={SETTINGS.icon}
            leafTitleKey="page.manageAutomatedMessaging.title"
          />
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "2rem", marginBottom: "10rem" }}
          >
            <Paper sx={classes.seriesContainer}>
              <SectionHeader>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "0.25rem",
                    flex: 1,
                    paddingTop: "1rem",
                    paddingRight: "0.25rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.25rem",
                    }}
                  >
                    <Box sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
                      {t(
                        "page.manageAutomatedMessaging.reminderSeries.jobAssignmentReminders.title"
                      )}
                    </Box>
                    <Box
                      sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}
                    >
                      {t(
                        "page.manageAutomatedMessaging.reminderSeries.jobAssignmentReminders.description"
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.25rem",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://help.fielderapp.com/en/articles/11004105-using-automated-messages"
                      rel="noreferrer"
                      style={{ color: "black" }}
                      target="_blank"
                    >
                      {t("learnMore")}
                    </a>
                    <OpenInNewIcon sx={{ fontSize: "1rem" }} />
                  </Box>
                </Box>
              </SectionHeader>
              <SectionContent sx={{ maxHeight: 600, overflowY: "scroll" }}>
                {queryLoading ? (
                  <Box sx={classes.spinnerContainer}>
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  </Box>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2.5rem",
                    margin: "1rem",
                    position: "relative",
                    width: "fit-content",
                  }}
                >
                  <SeriesEventConnectingLine />
                  {jobAssignmentReminderSeriesEvents?.map((event: ReminderSeriesEvent) => (
                    <ReminderSeriesEventCard
                      event={event}
                      key={event.id}
                      onClick={() => {
                        setEventToEdit(event)
                      }}
                    />
                  ))}
                  {jobAssignmentReminderSeriesEvents?.length < 5 ? (
                    <AddReminderSeriesEventButton
                      onClick={() => {
                        setEventToEdit({
                          category: ReminderSeriesCategory.JOB_ASSIGNMENT,
                        } as ReminderSeriesEvent)
                      }}
                    />
                  ) : null}
                </Box>
              </SectionContent>
            </Paper>
            <Paper sx={classes.seriesContainer}>
              <SectionHeader>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "0.25rem",
                    flex: 1,
                    paddingTop: "1rem",
                    paddingRight: "0.25rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.25rem",
                    }}
                  >
                    <Box sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
                      {t("page.manageAutomatedMessaging.reminderSeries.estimateFollowUps.title")}
                    </Box>
                    <Box
                      sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}
                    >
                      {t(
                        "page.manageAutomatedMessaging.reminderSeries.estimateFollowUps.description"
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.25rem",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://help.fielderapp.com/en/articles/11004105-using-automated-messages"
                      rel="noreferrer"
                      style={{ color: "black" }}
                      target="_blank"
                    >
                      {t("learnMore")}
                    </a>
                    <OpenInNewIcon sx={{ fontSize: "1rem" }} />
                  </Box>
                </Box>
              </SectionHeader>
              <SectionContent sx={{ maxHeight: 600, overflowY: "scroll" }}>
                {queryLoading ? (
                  <Box sx={classes.spinnerContainer}>
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  </Box>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2.5rem",
                    margin: "1rem",
                    position: "relative",
                    width: "fit-content",
                  }}
                >
                  <SeriesEventConnectingLine />
                  {estimateFollowUpSeriesEvents?.map((event: ReminderSeriesEvent) => (
                    <ReminderSeriesEventCard
                      event={event}
                      key={event.id}
                      onClick={() => {
                        setEventToEdit(event)
                      }}
                    />
                  ))}
                  {estimateFollowUpSeriesEvents?.length < 5 ? (
                    <AddReminderSeriesEventButton
                      onClick={() => {
                        setEventToEdit({
                          category: ReminderSeriesCategory.ESTIMATE_FOLLOW_UP,
                        } as ReminderSeriesEvent)
                      }}
                    />
                  ) : null}
                </Box>
              </SectionContent>
            </Paper>
            <Paper sx={classes.seriesContainer}>
              <SectionHeader>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "0.25rem",
                    flex: 1,
                    paddingTop: "1rem",
                    paddingRight: "0.25rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.25rem",
                    }}
                  >
                    <Box sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
                      {t("page.manageAutomatedMessaging.reminderSeries.invoiceFollowUps.title")}
                    </Box>
                    <Box
                      sx={{ fontSize: "0.875rem", color: (theme) => theme.fielderColors.mutedText }}
                    >
                      {t(
                        "page.manageAutomatedMessaging.reminderSeries.invoiceFollowUps.description"
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.25rem",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://help.fielderapp.com/en/articles/11004105-using-automated-messages"
                      rel="noreferrer"
                      style={{ color: "black" }}
                      target="_blank"
                    >
                      {t("learnMore")}
                    </a>
                    <OpenInNewIcon sx={{ fontSize: "1rem" }} />
                  </Box>
                </Box>
              </SectionHeader>
              <SectionContent sx={{ maxHeight: 600, overflowY: "scroll" }}>
                {queryLoading ? (
                  <Box sx={classes.spinnerContainer}>
                    <CircularProgress color="secondary" size={20} thickness={6.0} />
                  </Box>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2.5rem",
                    margin: "1rem",
                    position: "relative",
                    width: "fit-content",
                  }}
                >
                  <SeriesEventConnectingLine />
                  {invoiceFollowUpSeriesEvents?.map((event: ReminderSeriesEvent) => (
                    <ReminderSeriesEventCard
                      event={event}
                      key={event.id}
                      onClick={() => {
                        setEventToEdit(event)
                      }}
                    />
                  ))}
                  {invoiceFollowUpSeriesEvents?.length < 5 ? (
                    <AddReminderSeriesEventButton
                      onClick={() => {
                        setEventToEdit({
                          category: ReminderSeriesCategory.INVOICE_FOLLOW_UP,
                        } as ReminderSeriesEvent)
                      }}
                    />
                  ) : null}
                </Box>
              </SectionContent>
            </Paper>
          </Box>
        </Box>
      </MainLayout>
      {eventToEdit ? (
        <EditReminderSeriesEventDialog
          isSaving={isSaving}
          onCancel={() => setEventToEdit(null)}
          onDelete={() => {
            deleteReminderSeriesEvent({ variables: { id: eventToEdit.id } })
          }}
          onSave={(reminderSeriesEvent: ReminderSeriesEvent) => {
            if (reminderSeriesEvent.id) {
              editReminderSeriesEvent({
                variables: {
                  ...reminderSeriesEvent,
                },
              })
            } else {
              createReminderSeriesEvent({ variables: { ...reminderSeriesEvent } })
            }
          }}
          reminderSeriesEvent={eventToEdit}
        />
      ) : null}
    </>
  )
}

const classes = {
  root: {
    margin: "0 1.25rem",
  },
  spinnerContainer: {
    padding: "6.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  linkButton: {
    textDecoration: "none",
  },
  primaryActionButton: {
    fontWeight: "bold",
    "& svg": {
      fontSize: "1.0rem",
    },
    "& div": {
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
    },
  },
  seriesContainer: {},
  rowButtonContainer: {
    display: "flex",
  },
} as const
