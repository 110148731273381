/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useCallback } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import dayjs, { type Dayjs } from "dayjs"
import {
  Column,
  DataSheetGrid,
  floatColumn,
  intColumn,
  keyColumn,
  textColumn,
} from "react-datasheet-grid"
import { useMutation, useQuery, gql, NetworkStatus } from "@apollo/client"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined"
import FormControl from "@mui/material/FormControl"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Grow from "@mui/material/Grow"
import Collapse from "@mui/material/Collapse"
import Popper from "@mui/material/Popper"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import "react-datasheet-grid/dist/style.css"
import type { CellWithId } from "react-datasheet-grid/dist/types"

import { CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET } from "~/queries/createHelicalPileInstallationWorksheet"
import { CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET_AND_LOAD_PDF } from "~/queries/createHelicalPileInstallationWorksheetAndLoadPdf"
import { CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET_LINE_ITEM_NOTE } from "~/queries/createHelicalPileInstallationWorksheetLineItemNote"
import { EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET } from "~/queries/editHelicalPileInstallationWorksheet"
import { EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET_AND_LOAD_PDF } from "~/queries/editHelicalPileInstallationWorksheetAndLoadPdf"
import FullLineItem from "~/queries/fragments/helicalPileInstallationWorksheetLineItem"
import FielderTextField from "~/components/FielderTextField"
import ConfirmationDialog from "~/components/ConfirmationDialog"
import SnackbarMessage from "~/components/SnackbarMessage"
import MultiUserSelect from "~/components/MultiUserSelect"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import HelicalPileInstallationWorksheetDiagramEditor from "~/components/helicalPileInstallationWorksheet/HelicalPileInstallationWorksheetDiagramEditor"
import RegularReferenceTable from "~/components/helicalPileInstallationWorksheet/RegularReferenceTable"
import MobileHomeReferenceTable from "~/components/helicalPileInstallationWorksheet/MobileHomeReferenceTable"
import SaveAsTemplateDialog from "~/components/helicalPileInstallationWorksheet/SaveAsTemplateDialog"
import LoadFromTemplateDialog from "~/components/helicalPileInstallationWorksheet/LoadFromTemplateDialog"
import {
  PileTypeOptions,
  calculateAchievedCompressionCapacity,
  calculateAchievedTensionCapacity,
  calculateRequiredCompressionCapacity,
  calculateRequiredTorque,
  getKtFactor,
} from "~/components/helicalPileInstallationWorksheet/util"
import { asInt, isNumeric, parseGraphQLErrorCode } from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  HelicalPileInstallationWorksheet,
  HelicalPileInstallationWorksheetLineItem,
  HelicalPileInstallationWorksheetLineItemNote,
  HelicalPileInstallationWorksheetTemplateLineItem,
  HelicalPileInstallationWorksheetTemplateLineItemFormInput,
  HelicalPileInstallationWorksheetType,
  Job,
  PileType,
  Snack,
  User,
} from "~/types"
import { usePrompt } from "~/hooks/usePrompt"
import HelicalPileInstallationWorksheetLineNotes from "~/components/helicalPileInstallationWorksheet/HelicalPileInstallationWorksheetLineNotes"
import { selectColumn } from "./SelectColumn"
import { HelicalPileInstallationWorksheetContextMenu } from "./HelicalPileInstallationWorksheetContextMenu"
import "./helical-pile-installation-worksheet-datagrid.css"
import { useHotkeys } from "react-hotkeys-hook"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import ExpandMore from "~/components/ExpandMore"
import EmailDialog, { EmailDialogMode } from "~/components/EmailDialog"

const GET_HELICAL_PILE_INSTALLATION_WORKSHEET = gql`
  query GetHelicalPileInstallationWorksheet($jobId: ID!) {
    getJobById(id: $jobId) {
      id
      helicalPileInstallationWorksheet {
        id
        type
        diagram
        projectDescription
        installers {
          id
          firstName
          lastName
        }
        installationEquipment
        installationDate
        lineItems {
          ...FullLineItem
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${FullLineItem}
`

const DELETE_HELICAL_PILE_INSTALLATION_WORKSHEET_LINE_ITEM_NOTE = gql`
  mutation DeleteHelicalPileInstallationWorksheetLineItemNote($id: ID!) {
    deleteHelicalPileInstallationWorksheetLineItemNote(id: $id) {
      note {
        id
      }
    }
  }
`

const EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET_LINE_ITEM_NOTE = gql`
  mutation EditHelicalPileInstallationWorksheetLineItemNote($id: ID!, $text: String!) {
    editHelicalPileInstallationWorksheetLineItemNote(input: { id: $id, text: $text }) {
      note {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`

function openPdf(pdfString: string) {
  // window.open(url, "_blank", "width=1200,height=900")
  if (!pdfString) {
    return
  }
  const byteCharacters = atob(pdfString)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: "application/pdf;base64" })
  // eslint-disable-next-line compat/compat
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

function renderReferenceTable(type: HelicalPileInstallationWorksheetType): JSX.Element | null {
  if (type === HelicalPileInstallationWorksheetType.REGULAR) {
    return <RegularReferenceTable />
  } else if (type === HelicalPileInstallationWorksheetType.MOBILE_HOME) {
    return <MobileHomeReferenceTable />
  } else {
    return null
  }
}

interface HelicalPileInstallationWorksheetTabProps {
  readonly job: Job
}

function HelicalPileInstallationWorksheetTab({ job }: HelicalPileInstallationWorksheetTabProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [
    showClearHelicalPileInstallationWorksheetConfirmationDialog,
    setShowClearHelicalPileInstallationWorksheetConfirmationDialog,
  ] = useState<boolean>(false)
  const [type, setType] = useState<HelicalPileInstallationWorksheetType>(
    HelicalPileInstallationWorksheetType.REGULAR
  )
  const [installationDate, setInstallationDate] = useState<Dayjs | null>(null)
  const [installers, setInstallers] = useState<User[]>([])
  const [projectDescription, setProjectDescription] = useState<string>("")
  const [installationEquipment, setInstallationEquipment] = useState<string>("")
  const [worksheet, setWorksheet] = useState<HelicalPileInstallationWorksheet>({})
  const [snack, setSnack] = useState<Snack>()
  const [noteLineItem, setNoteLineItem] =
    useState<HelicalPileInstallationWorksheetLineItem | null>() // store a temporary reference to the pile that a note is being added to
  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false)
  const [saveAsTemplateDialogOpen, setSaveAsTemplateDialogOpen] = useState<boolean>(false)
  const [loadFromTemplateDialogOpen, setLoadFromTemplateDialogOpen] = useState<boolean>(false)
  const [deleteLineItemNoteLoadingIds, setDeleteLineItemNoteLoadingIds] = useState<string[]>([])
  const [editLineItemNoteLoadingIds, setEditLineItemNoteLoadingIds] = useState<string[]>([])
  const [activeColumn, setActiveColumn] = useState<CellWithId | null>(null)
  const [showPileNotes, setShowPileNotes] = useState<boolean>(true)
  const [showPileSpecs, setShowPileSpecs] = useState<boolean>(true)
  const [confirmDeleteNoteId, setConfirmDeleteNoteId] = useState<string | null>(null)
  const editorRef = useRef()
  const actionMenuAnchorRef = useRef(null)

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  // setup a keyboard shortcut to save the helicalPileInstallationWorksheet. ctrl+s or cmd+s.
  useHotkeys("mod+s", (e) => {
    e.preventDefault()
    handleSave(worksheet)
  })

  const {
    loading: getHelicalPileInstallationWorksheetLoading,
    networkStatus: getHelicalPileInstallationWorksheetNetworkStatus,
  } = useQuery(GET_HELICAL_PILE_INSTALLATION_WORKSHEET, {
    variables: {
      jobId: job?.id,
    },
    skip: !job?.id,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const helicalPileInstallationWorksheetData =
        data?.getJobById?.helicalPileInstallationWorksheet
      if (helicalPileInstallationWorksheetData) {
        processHelicalPileInstallationWorksheetData(helicalPileInstallationWorksheetData)
      } else {
        setProjectDescription(job?.description ?? "")
      }
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [
    createHelicalPileInstallationWorksheet,
    { loading: createHelicalPileInstallationWorksheetLoading },
  ] = useMutation(CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET, {
    onCompleted: (data) => {
      const helicalPileInstallationWorksheetData =
        data?.createHelicalPileInstallationWorksheet?.worksheet
      if (helicalPileInstallationWorksheetData) {
        processHelicalPileInstallationWorksheetData(helicalPileInstallationWorksheetData)
      }
      setIsDirty(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [
    createHelicalPileInstallationWorksheetAndLoadPdf,
    { loading: createHelicalPileInstallationWorksheetAndLoadPdfLoading },
  ] = useMutation(CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET_AND_LOAD_PDF, {
    onCompleted: (data) => {
      const helicalPileInstallationWorksheetData =
        data?.createHelicalPileInstallationWorksheet?.worksheet
      if (helicalPileInstallationWorksheetData) {
        processHelicalPileInstallationWorksheetData(helicalPileInstallationWorksheetData)
        openPdf(helicalPileInstallationWorksheetData.pdfFile)
      }
      setIsDirty(false)

      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [
    editHelicalPileInstallationWorksheet,
    { loading: editHelicalPileInstallationWorksheetLoading },
  ] = useMutation(EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET, {
    onCompleted: (data) => {
      const helicalPileInstallationWorksheetData =
        data?.editHelicalPileInstallationWorksheet?.worksheet
      if (helicalPileInstallationWorksheetData) {
        processHelicalPileInstallationWorksheetData(helicalPileInstallationWorksheetData)
        setIsDirty(false)
      }
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [
    editHelicalPileInstallationWorksheetAndLoadPdf,
    { loading: editHelicalPileInstallationWorksheetAndLoadPdfLoading },
  ] = useMutation(EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET_AND_LOAD_PDF, {
    onCompleted: (data) => {
      const helicalPileInstallationWorksheetData =
        data?.editHelicalPileInstallationWorksheet?.worksheet
      if (helicalPileInstallationWorksheetData) {
        processHelicalPileInstallationWorksheetData(helicalPileInstallationWorksheetData)
        setIsDirty(false)
        openPdf(helicalPileInstallationWorksheetData.pdfFile)
      }
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [createHelicalPileInstallationWorksheetLineItemNote, { loading: addNoteLoading }] =
    useMutation(CREATE_HELICAL_PILE_INSTALLATION_WORKSHEET_LINE_ITEM_NOTE, {
      onCompleted: (data) => {
        const noteData = data?.createHelicalPileInstallationWorksheetLineItemNote?.note
        const newLineItems = [
          ...(worksheet.lineItems ?? []),
        ] as HelicalPileInstallationWorksheetLineItem[]
        const index = newLineItems.findIndex((li) => li.id === noteLineItem?.id)
        const lineItem = newLineItems[index]

        if (lineItem) {
          const updatedNotes = [
            {
              id: noteData.id,
              text: noteData.text,
              createdBy: noteData.createdBy,
              createdAt: noteData.createdAt,
            },
            ...(lineItem.notes ?? []),
          ] as HelicalPileInstallationWorksheetLineItemNote[]
          newLineItems[index] = {
            ...lineItem,
            notes: updatedNotes,
          }
          setWorksheet((prev) => ({
            ...prev,
            lineItems: newLineItems,
          }))
        }
        setNoteLineItem(null)
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    })

  const [deleteHelicalPileInstallationWorksheetLineItemNote] = useMutation(
    DELETE_HELICAL_PILE_INSTALLATION_WORKSHEET_LINE_ITEM_NOTE,
    {
      onCompleted: (data) => {
        const deletedNoteId = data.deleteHelicalPileInstallationWorksheetLineItemNote.note.id
        const lineItems = worksheet.lineItems ?? []
        const updatedLineItems: HelicalPileInstallationWorksheetLineItem[] = lineItems.map(
          (li: HelicalPileInstallationWorksheetLineItem) => {
            if (li.notes?.some((n) => n.id === deletedNoteId)) {
              const updatedNotes = li.notes?.filter(
                (n: HelicalPileInstallationWorksheetLineItemNote) => n.id !== deletedNoteId
              )
              return {
                ...li,
                notes: updatedNotes,
              }
            } else {
              return li
            }
          }
        )
        setWorksheet((prev) => ({
          ...prev,
          lineItems: updatedLineItems,
        }))
        setTimeout(
          () => setDeleteLineItemNoteLoadingIds((ids) => ids.filter((id) => id !== deletedNoteId)),
          100
        )
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
      refetchQueries: () => {
        return ["GetHelicalPileInstallationWorksheet"]
      },
    }
  )

  const [editHelicalPileInstallationWorksheetLineItemNote] = useMutation(
    EDIT_HELICAL_PILE_INSTALLATION_WORKSHEET_LINE_ITEM_NOTE,
    {
      onCompleted: (data) => {
        const editedNote = data.editHelicalPileInstallationWorksheetLineItemNote.note
        setTimeout(
          () => setEditLineItemNoteLoadingIds((ids) => ids.filter((id) => id !== editedNote.id)),
          100
        )
      },
      onError: (error) => {
        console.error("error: ", error)
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
      refetchQueries: () => {
        return ["GetHelicalPileInstallationWorksheet"]
      },
    }
  )

  const [
    sendHelicalPileInstallationWorksheet,
    { loading: sendHelicalPileInstallationWorksheetLoading },
  ] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setEmailDialogOpen(false)
      setSnack({ messageKey: "messages.messageSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: () => {
      return ["AllEstimatesForJob"]
    },
  })

  const loading =
    getHelicalPileInstallationWorksheetLoading ||
    createHelicalPileInstallationWorksheetLoading ||
    editHelicalPileInstallationWorksheetLoading ||
    createHelicalPileInstallationWorksheetAndLoadPdfLoading ||
    editHelicalPileInstallationWorksheetAndLoadPdfLoading

  function processHelicalPileInstallationWorksheetData(worksheetData: any) {
    setInstallationDate(
      worksheetData.installationDate
        ? dayjs(worksheetData.installationDate).tz(user?.organization?.timeZone)
        : null
    )
    setInstallers(worksheetData.installers ?? [])
    setType(worksheetData.type)
    setProjectDescription(worksheetData.projectDescription ?? job.description ?? "")
    setInstallationEquipment(worksheetData.installationEquipment ?? "")
    const currentPiles = worksheetData.lineItems
      .map((li: HelicalPileInstallationWorksheetLineItem) => ({
        id: li.id,
        diagramId: li.diagramId,
        number: li.number,
        achievedCompressionCapacity: li.achievedCompressionCapacity,
        achievedTensionCapacity: li.achievedTensionCapacity,
        achievedTorque: li.achievedTorque,
        cutOffElevation: li.cutOffElevation,
        helixUsed: li.helixUsed,
        pileInclination: li.pileInclination,
        pileUsed: li.pileUsed,
        requiredCompressionCapacity: li.requiredCompressionCapacity,
        requiredTorque: li.requiredTorque,
        totalPileLength: li.totalPileLength,
        notes: li.notes
          ?.map((n: HelicalPileInstallationWorksheetLineItemNote) => ({
            id: n.id,
            createdAt: n.createdAt,
            createdBy: { ...n.createdBy },
            text: n.text,
          }))
          .sort((a: any, b: any) => Date.parse(b.createdAt) - Date.parse(a.createdAt)),
      }))
      .sort(
        (
          a: HelicalPileInstallationWorksheetLineItem,
          b: HelicalPileInstallationWorksheetLineItem
        ) => (asInt(a.number) ?? 0) - (asInt(b.number) ?? 0)
      )

    setWorksheet({
      id: worksheetData.id,
      diagram: worksheetData.diagram,
      lineItems: currentPiles,
    })

    editorRef.current?.fromJSON(worksheetData.diagram)
  }

  /**
   * Add a new pile (line item) to the helicalPileInstallationWorksheet.
   * @param lineItem - an object with the following properties: number, diagramId
   *  These props are set by the caller, which is HelicalPileInstallationWorksheetDiagramEditor.
   * @param diagramJson
   */
  const handleAddPile = useCallback(
    (lineItem: HelicalPileInstallationWorksheetTemplateLineItemFormInput, diagramJson: any) => {
      const priorLineItem = worksheet.lineItems?.[worksheet.lineItems.length - 1]
      lineItem.pileUsed = (priorLineItem ? priorLineItem.pileUsed : "178") as PileType
      lineItem.helixUsed = priorLineItem ? priorLineItem.helixUsed : '9"'
      lineItem.safetyFactor = 2 // SF; kind of a "fudge" factor to account for uncertainty of soil conditions
      lineItem.requiredCompressionCapacity = priorLineItem
        ? priorLineItem.requiredCompressionCapacity
        : null
      lineItem.requiredTorque = priorLineItem ? priorLineItem.requiredTorque : null
      const lines = [...(worksheet.lineItems ?? []), lineItem]

      setWorksheet((prev) => ({
        ...prev,
        diagram: JSON.stringify(diagramJson),
        lineItems: lines as HelicalPileInstallationWorksheetLineItem[],
      }))
      setIsDirty(true)
    },
    [worksheet.lineItems]
  )

  const handleRemovePiles = useCallback(
    (pileDiagramIds: string[]) => {
      let jsonString = ""
      pileDiagramIds.forEach((id) => {
        jsonString = editorRef.current?.removePile(id)
      })

      const newLineItems = (worksheet.lineItems ?? [])
        .filter((li) => !pileDiagramIds.includes(li.diagramId))
        .sort((a, b) => (asInt(a.number) ?? 0) - (asInt(b.number) ?? 0))
        .map((li, idx) => ({
          ...li,
          number: idx + 1,
        }))

      setWorksheet((prev) => {
        const c = {
          ...prev,
          lineItems: newLineItems,
          diagram: jsonString,
        }

        return c
      })

      setIsDirty(true)
    },
    [worksheet.lineItems]
  )

  const handleAddNote = (pile: HelicalPileInstallationWorksheetLineItem, note: string) => {
    const lineItem = worksheet.lineItems?.find((li) => {
      if (pile.id) {
        return li.id === pile.id
      } else {
        return li.number === pile.number
      }
    })

    if (lineItem?.id) {
      setNoteLineItem(pile)
      setTimeout(() => {
        createHelicalPileInstallationWorksheetLineItemNote({
          variables: {
            lineItemId: lineItem.id,
            text: note,
          },
        })
      }, 100)
    } else if (lineItem) {
      // the user hasn't saved the line item yet, so they can only add a single note.
      // update the line item in the helicalPileInstallationWorksheet state, and then save the entire helicalPileInstallationWorksheet
      const updatedHelicalPileInstallationWorksheet = {
        ...worksheet,
        lineItems: (worksheet.lineItems ?? []).map((li) =>
          li.number === lineItem.number ? { ...lineItem, note } : li
        ),
      }
      setWorksheet(updatedHelicalPileInstallationWorksheet)
      handleSave(updatedHelicalPileInstallationWorksheet)
    }
  }

  const handleEditNote = (noteId: string, updatedText: string) => {
    setEditLineItemNoteLoadingIds([...editLineItemNoteLoadingIds, noteId])
    editHelicalPileInstallationWorksheetLineItemNote({
      variables: { id: noteId, text: updatedText },
    })
  }

  /**
   * The diagram was modified in some way that doesn't affect line item data
   * (although it might be that a line item diagram object was moved around)
   */
  const handleUpdateDiagram = useCallback((diagramJson: any) => {
    setWorksheet((prev) => {
      return {
        ...prev,
        diagram: JSON.stringify(diagramJson),
      }
    })
    setIsDirty(true)
  }, [])

  /**
   * This function takes a helicalPileInstallationWorksheet object as an argument rather than just
   * working on the `helicalPileInstallationWorksheet` state variable because in some cases you need
   * to modify the helicalPileInstallationWorksheet and immediately save. If you just update the helicalPileInstallationWorksheet
   * state object via setWorksheet and then immediately call this function expecting
   * the `helicalPileInstallationWorksheet` state object to be up-to-date, you're gonna have a bad time.
   * There needs to be a re-render cycle after calling setWorksheet before the helicalPileInstallationWorksheet
   * state object reflects your changes.
   * However, this function can be called with no argument. If the updatedHelicalPileInstallationWorksheet argument
   * is not provided, then it will use the `helicalPileInstallationWorksheet` state variable as-is.
   */
  function handleSave(
    updatedHelicalPileInstallationWorksheet?: HelicalPileInstallationWorksheet,
    generatePDF = false
  ) {
    const updated = updatedHelicalPileInstallationWorksheet
      ? updatedHelicalPileInstallationWorksheet
      : worksheet

    const piles = updated.lineItems?.map((pile: HelicalPileInstallationWorksheetLineItem) => {
      const requiredCompressionCapacity = pile.requiredCompressionCapacity
        ? asInt(pile.requiredCompressionCapacity)
        : null
      const requiredTorque = pile.requiredTorque ? asInt(pile.requiredTorque) : null
      const totalPileLength = parseFloat(pile.totalPileLength)
      const cutOffElevation = parseFloat(pile.cutOffElevation)
      const pileInclination = parseFloat(pile.pileInclination)
      const achievedTorque = asInt(pile.achievedTorque)

      return {
        diagramId: pile.diagramId,
        number: pile.number,
        requiredCompressionCapacity: requiredCompressionCapacity,
        achievedCompressionCapacity: pile.achievedCompressionCapacity ?? null,
        requiredTorque: requiredTorque,
        achievedTorque: achievedTorque ?? null,
        pileUsed: pile.pileUsed,
        helixUsed: pile.helixUsed,
        totalPileLength: totalPileLength ?? null,
        cutOffElevation: cutOffElevation ?? null,
        pileInclination: pileInclination ?? null,
        achievedTensionCapacity: pile.achievedTensionCapacity ?? null,
        note: pile.note,
      }
    })

    const variables = {
      ...updated,
      jobId: job.id,
      type,
      projectDescription,
      installationDate: installationDate
        ? dayjs(installationDate).startOf("day").toISOString()
        : undefined,
      installerUserIds: installers?.map((i) => i.id) || undefined,
      installationEquipment,
      diagram: editorRef.current?.toJSON(),
      lineItems: piles,
    }

    if (updated?.id) {
      generatePDF
        ? editHelicalPileInstallationWorksheetAndLoadPdf({ variables })
        : editHelicalPileInstallationWorksheet({ variables })
    } else {
      generatePDF
        ? createHelicalPileInstallationWorksheetAndLoadPdf({ variables })
        : createHelicalPileInstallationWorksheet({ variables })
    }
  }

  function renderSaveButton(style: React.CSSProperties | undefined) {
    return (
      <Button
        color="primary"
        data-testid="saveHelicalPileInstallationWorksheetButton"
        disabled={loading}
        onClick={() => handleSave(worksheet)}
        style={{
          fontWeight: "bold",
          minWidth: 135,
          ...style,
        }}
        variant="contained"
      >
        {createHelicalPileInstallationWorksheetLoading ||
        createHelicalPileInstallationWorksheetAndLoadPdfLoading ||
        editHelicalPileInstallationWorksheetLoading ||
        editHelicalPileInstallationWorksheetAndLoadPdfLoading ||
        getHelicalPileInstallationWorksheetNetworkStatus === NetworkStatus.refetch ? (
          <CircularProgress color="secondary" size={20} thickness={6.0} />
        ) : (
          <span>{t("helicalPileInstallationWorksheet.saveWorksheet")}</span>
        )}
      </Button>
    )
  }

  const columns: Column<HelicalPileInstallationWorksheetLineItem>[] = [
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "pileUsed">(
        "pileUsed",
        selectColumn({ options: PileTypeOptions })
      ),
      title: t("helicalPileInstallationWorksheet.pileUsed"),
      deleteValue: ({ rowData }) => {
        return {
          ...rowData,
          pileUsed: "",
        }
      },
      copyValue: ({ rowData }) =>
        PileTypeOptions.find((option) => option.value === rowData.pileUsed)?.value ?? null,
      pasteValue: ({ rowData, value }) => {
        return {
          ...rowData,
          pileUsed: PileTypeOptions.find((option) => option.value === value)?.value ?? "",
        }
      },
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "helixUsed">("helixUsed", textColumn),
      title: t("helicalPileInstallationWorksheet.helixUsed"),
      deleteValue: ({ rowData }) => {
        return {
          ...rowData,
          helixUsed: "",
        }
      },
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "requiredCompressionCapacity">(
        "requiredCompressionCapacity",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.requiredCompressionCapacity"),
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "requiredTorque">(
        "requiredTorque",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.requiredTorque"),
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "totalPileLength">(
        "totalPileLength",
        floatColumn
      ),
      title: t("helicalPileInstallationWorksheet.totalPileLength"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "cutOffElevation">(
        "cutOffElevation",
        floatColumn
      ),
      title: t("helicalPileInstallationWorksheet.cutOffElevation"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "pileInclination">(
        "pileInclination",
        floatColumn
      ),
      title: t("helicalPileInstallationWorksheet.pileInclination"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "achievedTorque">(
        "achievedTorque",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.achievedTorque"),
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "achievedCompressionCapacity">(
        "achievedCompressionCapacity",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.achievedCompressionCapacity"),
      disabled: true,
    },
    {
      ...keyColumn<HelicalPileInstallationWorksheetLineItem, "achievedTensionCapacity">(
        "achievedTensionCapacity",
        intColumn
      ),
      title: t("helicalPileInstallationWorksheet.achievedTensionCapacity"),
      disabled: true,
    },
  ]

  return (
    <Box
      sx={{
        paddingBottom: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 auto",
        width: "1161px",
        maxwidth: "1161px",
        minWidth: "1161px",
      }}
    >
      <ConfirmationDialog
        description={t("helicalPileInstallationWorksheet.clearDialog.confirmationPrompt")}
        onCancel={() => setShowClearHelicalPileInstallationWorksheetConfirmationDialog(false)}
        onConfirm={() => {
          setShowClearHelicalPileInstallationWorksheetConfirmationDialog(false)
          const emptyHelicalPileInstallationWorksheet: HelicalPileInstallationWorksheet = {
            id: worksheet.id,
            projectDescription: "",
            installers: [],
            installationEquipment: "",
            installationDate: "",
            lineItems: [],
          }
          setActionMenuOpen(false)
          editorRef.current?.clear()
          handleSave(emptyHelicalPileInstallationWorksheet)
        }}
        open={showClearHelicalPileInstallationWorksheetConfirmationDialog}
      />
      <ConfirmationDialog
        description={t("component.deleteNoteConfirmation.message")}
        id="delete-note-confirmation"
        negativeButtonTitle={t("no")}
        onCancel={() => setConfirmDeleteNoteId(null)}
        onConfirm={() => {
          setConfirmDeleteNoteId(null)
          // optimistic update: find the note in jobNotes and remove it
          if (confirmDeleteNoteId) {
            setDeleteLineItemNoteLoadingIds([...deleteLineItemNoteLoadingIds, confirmDeleteNoteId])
            deleteHelicalPileInstallationWorksheetLineItemNote({
              variables: { id: confirmDeleteNoteId },
            })
          }
        }}
        open={Boolean(confirmDeleteNoteId)}
        positiveButtonTitle={t("yes")}
      />
      {saveAsTemplateDialogOpen ? (
        <SaveAsTemplateDialog
          onCancel={() => {
            setSaveAsTemplateDialogOpen(false)
          }}
          onSave={(success, messageKey, messageOptions) => {
            if (success) {
              setSnack({ messageKey, messageOptions, variant: "success" })
            } else {
              setSnack({ messageKey, messageOptions, variant: "error" })
            }
            setSaveAsTemplateDialogOpen(false)
          }}
          worksheet={worksheet}
        />
      ) : null}
      {loadFromTemplateDialogOpen ? (
        <LoadFromTemplateDialog
          onCancel={() => {
            setLoadFromTemplateDialogOpen(false)
          }}
          onSelectTemplate={(selectedTemplate) => {
            const sanitized = {
              ...selectedTemplate,
              id: worksheet?.id,
              projectDescription,
              installationDate,
              installationEquipment,
              installers,
              lineItems: selectedTemplate.lineItems.map(
                (li: HelicalPileInstallationWorksheetTemplateLineItem) => {
                  // eslint-disable-next-line no-unused-vars
                  const { id, __typename, ...rest } = li
                  return rest
                }
              ),
            }
            setIsDirty(true)
            processHelicalPileInstallationWorksheetData(sanitized)
            setLoadFromTemplateDialogOpen(false)
          }}
        />
      ) : null}
      {emailDialogOpen && worksheet ? (
        <EmailDialog
          helicalPileInstallationWorksheet={worksheet}
          isJobEditable={false}
          job={job}
          mode={EmailDialogMode.COMPOSE_NEW}
          onCancel={() => {
            setEmailDialogOpen(false)
          }}
          onSend={(payload) => {
            sendHelicalPileInstallationWorksheet({ variables: payload })
          }}
          sending={sendHelicalPileInstallationWorksheetLoading}
        />
      ) : null}
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Paper
        sx={{
          width: "100%",
          marginBottom: "1rem",
          maxWidth: "1161px",
        }}
      >
        <SectionHeader>
          <label>{t("basicInfo")}</label>
          <ButtonGroup
            aria-label="split button"
            color="primary"
            ref={actionMenuAnchorRef}
            variant="contained"
          >
            {renderSaveButton({
              borderRadius: "4px 0 0 4px",
              boxShadow: "none",
            })}
            <Button
              aria-controls={actionMenuOpen ? "split-button-menu" : undefined}
              aria-expanded={actionMenuOpen ? "true" : undefined}
              aria-haspopup="menu"
              aria-label="select an option"
              color="primary"
              disabled={loading}
              onClick={() => setActionMenuOpen((prevOpen) => !prevOpen)}
              size="small"
              style={{
                borderRadius: "0 4px 4px 0",
              }}
              variant="contained"
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            anchorEl={actionMenuAnchorRef.current}
            disablePortal
            open={actionMenuOpen}
            placement="bottom"
            role={undefined}
            style={{
              opacity: 1,
              zIndex: 100,
              minWidth: 178,
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener
                    onClickAway={(event) => {
                      if (actionMenuAnchorRef.current?.contains(event.target)) {
                        return
                      }
                      setActionMenuOpen(false)
                    }}
                  >
                    <MenuList id="split-button-menu">
                      <MenuItem
                        disabled={false}
                        key="generatePDF"
                        onClick={() => {
                          setActionMenuOpen(false)
                          handleSave(worksheet, true)
                        }}
                      >
                        {t("generatePDF")}
                      </MenuItem>
                      <MenuItem
                        disabled={!worksheet.id}
                        key="sendWorksheet"
                        onClick={() => {
                          setActionMenuOpen(false)
                          setEmailDialogOpen(true)
                        }}
                      >
                        {t("helicalPileInstallationWorksheet.sendWorksheet")}
                      </MenuItem>
                      <MenuItem
                        disabled={false}
                        key="saveAsTemplate"
                        onClick={() => {
                          setActionMenuOpen(false)
                          setSaveAsTemplateDialogOpen(true)
                        }}
                      >
                        {t("saveAsTemplate")}
                      </MenuItem>
                      <MenuItem
                        disabled={false}
                        key="loadFromTemplate"
                        onClick={() => {
                          setActionMenuOpen(false)
                          setLoadFromTemplateDialogOpen(true)
                        }}
                      >
                        {t("loadFromTemplate")}
                      </MenuItem>
                      <MenuItem
                        disabled={false}
                        key="clearWorksheet"
                        onClick={() =>
                          setShowClearHelicalPileInstallationWorksheetConfirmationDialog(true)
                        }
                      >
                        {t("helicalPileInstallationWorksheet.clearWorksheet")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </SectionHeader>
        <Divider />
        <SectionContent sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <FormControl fullWidth sx={classes.formControl}>
            <DatePicker
              aria-label={t("installationDate")}
              format={t("format:dateFormat.short") as string}
              label={t("installationDate")}
              onChange={(val) => {
                setIsDirty(true)
                setInstallationDate(val)
              }}
              slotProps={{
                textField: {
                  inputProps: {
                    style: {
                      height: "31px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      transform: "translate(12px, 20px) scale(1)", // Default position
                      "&.MuiInputLabel-shrink": {
                        transform: "translate(12px, 6px) scale(0.75)", // Adjusted for shrink state
                      },
                    },
                  },
                  error: false,
                  fullWidth: true,
                  required: false,
                },
              }}
              slots={{
                textField: FielderTextField,
              }}
              value={installationDate}
            />
          </FormControl>
          <FormControl fullWidth sx={classes.formControl}>
            <MultiUserSelect
              aria-label={t("installers")}
              id="installers"
              label={t("installers")}
              name="installers"
              onChange={(users) => {
                setIsDirty(true)
                setInstallers(users ?? ([] as User[]))
              }}
              roleNames={["FIELD_TECH"]}
              selectedUsers={installers}
            />
          </FormControl>
          <FormControl fullWidth sx={classes.formControl}>
            <FielderTextField
              data-testid="projectDescription-Field"
              fullWidth
              id="projectDescription"
              inputProps={{
                maxLength: 1000,
              }}
              label={t("projectDescription")}
              maxRows="3"
              minRows="3"
              multiline
              name="projectDescription"
              onChange={(e) => {
                setIsDirty(true)
                setProjectDescription(e.target.value)
              }}
              value={projectDescription}
            />
          </FormControl>
          <FormControl fullWidth sx={classes.formControl}>
            <FielderTextField
              data-testid="installationEquipment-Field"
              fullWidth
              id="installationEquipment"
              inputProps={{
                maxLength: 1000,
              }}
              label={t("installationEquipment")}
              maxRows="3"
              minRows="3"
              multiline
              name="installationEquipment"
              onChange={(e) => {
                setIsDirty(true)
                setInstallationEquipment(e.target.value)
              }}
              value={installationEquipment}
            />
          </FormControl>
        </SectionContent>
      </Paper>
      <Paper
        sx={{
          width: "100%",
          maxWidth: "1161px",
          backgroundColor: "#fefefe",
          marginBottom: "1rem",
          paddingBottom: "0.125rem",
        }}
      >
        <SectionHeader>
          <label>{t("helicalPileInstallationWorksheet.diagram")}</label>
        </SectionHeader>
        <Divider sx={{ slize: "#212121" }} />
        <HelicalPileInstallationWorksheetDiagramEditor
          loading={loading}
          onAddPile={handleAddPile}
          onRemovePiles={handleRemovePiles}
          onUpdateDiagram={handleUpdateDiagram}
          ref={editorRef}
        />
      </Paper>
      <Paper sx={{ width: "100%", marginBottom: "1rem", paddingBottom: "1rem" }}>
        <SectionHeader>
          <Box
            onClick={() => setShowPileSpecs((prev) => !prev)}
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Box component="label" sx={{ cursor: "pointer" }}>
              {t("helicalPileInstallationWorksheet.pileSpecs")}
            </Box>
            <ExpandMore
              aria-expanded={showPileSpecs}
              aria-label={t("helicalPileInstallationWorksheet.pileSpecs") as string}
              expand={showPileSpecs}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </SectionHeader>
        <Collapse in={showPileSpecs}>
          {worksheet.lineItems && worksheet.lineItems.length > 0 ? (
            <DataSheetGrid
              addRowsComponent={false}
              columns={columns}
              contextMenuComponent={HelicalPileInstallationWorksheetContextMenu}
              disableContextMenu={false}
              headerRowHeight={60}
              height={2000}
              onActiveCellChange={(arg: { cell: CellWithId | null }) => {
                setActiveColumn(arg.cell)
              }}
              onChange={(newValue, operations) => {
                for (const operation of operations) {
                  switch (operation.type) {
                    case "UPDATE": {
                      const updatedRows = newValue.slice(
                        operation.fromRowIndex,
                        operation.toRowIndex
                      )
                      if (activeColumn?.colId === "pileUsed") {
                        for (const updatedRow of updatedRows) {
                          const pileType =
                            PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                            PileTypeOptions[0]

                          if (updatedRow.requiredCompressionCapacity) {
                            updatedRow.requiredTorque = calculateRequiredTorque(
                              pileType.value as PileType,
                              updatedRow.requiredCompressionCapacity
                            )
                          }

                          if (updatedRow.achievedTorque) {
                            updatedRow.achievedCompressionCapacity =
                              calculateAchievedCompressionCapacity(
                                updatedRow.achievedTorque ?? 0,
                                2,
                                getKtFactor(updatedRow.pileUsed)
                              )
                          }
                        }
                      } else if (activeColumn?.colId === "requiredCompressionCapacity") {
                        for (const updatedRow of updatedRows) {
                          const pileType =
                            PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                            PileTypeOptions[0]
                          const newRCC = updatedRow.requiredCompressionCapacity ?? 0
                          updatedRow.requiredTorque = calculateRequiredTorque(
                            pileType.value as PileType,
                            newRCC
                          )
                        }
                      } else if (activeColumn?.colId === "requiredTorque") {
                        for (const updatedRow of updatedRows) {
                          const pileType =
                            PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                            PileTypeOptions[0]
                          const newRequiredTorque = updatedRow.requiredTorque ?? 0
                          updatedRow.requiredCompressionCapacity =
                            calculateRequiredCompressionCapacity(
                              pileType.value as PileType,
                              newRequiredTorque
                            )
                        }
                      } else if (activeColumn?.colId === "totalPileLength") {
                        for (const updatedRow of updatedRows) {
                          if (isNumeric(updatedRow.achievedTorque)) {
                            updatedRow.achievedCompressionCapacity =
                              calculateAchievedCompressionCapacity(
                                updatedRow.achievedTorque ?? 0,
                                2,
                                getKtFactor(updatedRow.pileUsed)
                              )
                            updatedRow.achievedTensionCapacity = calculateAchievedTensionCapacity(
                              updatedRow.totalPileLength ?? 0,
                              updatedRow.achievedCompressionCapacity
                            )
                          }
                        }
                      } else if (activeColumn?.colId === "achievedTorque") {
                        for (const updatedRow of updatedRows) {
                          updatedRow.achievedCompressionCapacity =
                            calculateAchievedCompressionCapacity(
                              updatedRow.achievedTorque ?? 0,
                              2,
                              getKtFactor(updatedRow.pileUsed)
                            )
                          updatedRow.achievedTensionCapacity = calculateAchievedTensionCapacity(
                            updatedRow.totalPileLength ?? 0,
                            updatedRow.achievedCompressionCapacity
                          )
                        }
                      }
                      setWorksheet((prev) => {
                        return {
                          ...prev,
                          lineItems: newValue,
                        }
                      })
                      break
                    }
                    case "DELETE": {
                      const deletedRows = (worksheet.lineItems ?? []).slice(
                        operation.fromRowIndex,
                        operation.toRowIndex
                      )
                      const pileDiagramIds = deletedRows.map((row) => row.diagramId)
                      handleRemovePiles(pileDiagramIds)
                      break
                    }
                    default:
                      break
                  }
                }
              }}
              onFocus={(arg: { cell: CellWithId }) => {
                setActiveColumn(arg.cell)
              }}
              value={worksheet.lineItems}
            />
          ) : (
            <Box
              sx={{
                color: (theme) => theme.fielderColors.mutedText,
                fontSize: "1rem",
                padding: "1rem",
                textAlign: "center",
              }}
            >
              {t("helicalPileInstallationWorksheet.pileSpecsEmpty")}
            </Box>
          )}
        </Collapse>
      </Paper>
      <Paper sx={{ width: "100%", marginBottom: "3rem", paddingBottom: "0.125rem" }}>
        <SectionHeader>
          <Box
            onClick={() => setShowPileNotes((prev) => !prev)}
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Box component="label" sx={{ cursor: "pointer" }}>
              {t("helicalPileInstallationWorksheet.pileNotes")}
            </Box>
            <ExpandMore
              aria-expanded={showPileNotes}
              aria-label={t("helicalPileInstallationWorksheet.pileNotes") as string}
              expand={showPileNotes}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </SectionHeader>
        <Collapse in={showPileNotes}>
          <SectionContent>
            {worksheet.lineItems && worksheet.lineItems.length > 0 ? (
              worksheet.lineItems.map((pile) => {
                return (
                  <Accordion
                    disableGutters={false}
                    key={`${pile.diagramId}+${pile.number}`}
                    sx={{ backgroundColor: "#ffffff" }}
                    variant="outlined"
                  >
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        {t("helicalPileInstallationWorksheet.notesForPileNumber", {
                          pileNumber: pile.number,
                        })}
                        {(pile.notes ?? []).length > 0 ? (
                          <ChatOutlinedIcon
                            fontSize="small"
                            sx={{
                              fontSize: "0.875rem",
                              color: (theme) => theme.fielderColors.mutedText,
                            }}
                          />
                        ) : null}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <HelicalPileInstallationWorksheetLineNotes
                        addNoteLoading={addNoteLoading ? noteLineItem?.id === pile.id : false}
                        deleteNoteLoadingIds={deleteLineItemNoteLoadingIds}
                        editNoteLoadingIds={editLineItemNoteLoadingIds}
                        notes={pile.notes ?? []}
                        onAddNote={(note) => handleAddNote(pile, note)}
                        onDeleteNote={setConfirmDeleteNoteId}
                        onEditNote={(noteId: string, updatedText: string) => {
                          handleEditNote?.(noteId, updatedText)
                        }}
                        user={user}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              })
            ) : (
              <Box
                sx={{
                  color: (theme) => theme.fielderColors.mutedText,
                  fontSize: "1rem",
                  padding: "1rem",
                  textAlign: "center",
                }}
              >
                {t("helicalPileInstallationWorksheet.pileNotesEmpty")}
              </Box>
            )}
          </SectionContent>
        </Collapse>
      </Paper>
      <section style={{ marginTop: "0.5rem", width: "98%" }}>
        <FormControl component="fieldset">
          <legend style={{ fontSize: "1rem", fontWeight: "bold" }}>
            {t("helicalPileInstallationWorksheet.selectReferenceChart")}
          </legend>
          <RadioGroup
            aria-label="reference chart type"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setIsDirty(true)
              setType(e.target.value)
            }}
            row
            value={type}
          >
            <FormControlLabel
              control={<Radio />}
              label={t("helicalPileInstallationWorksheet.typeOptions.REGULAR") as string}
              value={HelicalPileInstallationWorksheetType.REGULAR}
            />
            <FormControlLabel
              control={<Radio />}
              label={t("helicalPileInstallationWorksheet.typeOptions.MOBILE_HOME") as string}
              value={HelicalPileInstallationWorksheetType.MOBILE_HOME}
            />
          </RadioGroup>
        </FormControl>
        {renderReferenceTable(type)}
      </section>
    </Box>
  )
}

const classes = {
  sectionColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    padding: "0.625rem",
  },
  metaColumn: {
    backgroundColor: "#F8DBC0",
  },
}

export default HelicalPileInstallationWorksheetTab
