import React, { useState, useEffect, FC } from "react"
import { Helmet } from "react-helmet"
import * as Sentry from "@sentry/react"
import { useLocation } from "react-router-dom"
import Cookies from "js-cookie"
import { useMutation } from "@apollo/client"
import GlobalStyles from "@mui/material/GlobalStyles"
import Box from "@mui/material/Box"

import Header from "./Header"
import ResponsiveDrawer from "./ResponsiveDrawer"
import ErrorFallback from "./ErrorFallback"
import { formatPersonName } from "~/util"
import { LOGOUT } from "~/queries/logout"
import { useAuth } from "~/context/AuthContext"
import { AppSection } from "~/types"

interface MainLayoutProps {
  readonly activeSection: AppSection
}

const MainLayout: FC<MainLayoutProps> = ({ activeSection, children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { user, logout: logoutAction, isImpersonating } = useAuth()
  const location = useLocation()

  useEffect(() => {
    if (typeof window !== "undefined" && typeof window.Intercom !== "undefined") {
      window.Intercom("update")
    }
  }, [location])

  const [logout, { client }] = useMutation(LOGOUT, {
    onCompleted: () => {
      client.clearStore()
    },
  })

  function handleDrawerToggle() {
    setIsDrawerOpen(!isDrawerOpen)
  }

  function handleLogout() {
    const authToken = Cookies.get("authToken")
    if (authToken) {
      logout({ variables: { authToken } })
    }
    logoutAction?.()
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        width: "100%",
      }}
    >
      <Helmet>
        <script>
          {`
            if (typeof window !== 'undefined') {
              // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/tvfgvffm'
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/tvfgvffm';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            
              window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "${process.env.GATSBY_INTERCOM_APP_ID}",
                name: "${formatPersonName(user)}", // Full name
                email: "${user?.email}", // Email address
                user_id: "${user?.id}", // User ID
                user_hash: "${user?.userHash}", // HMAC using SHA-256 hash of user_id
                created_at: "${user?.createdAt}" // Signup date as a Unix timestamp
              });
            }
            `}
        </script>
      </Helmet>
      <Header handleDrawerToggle={handleDrawerToggle} onLogout={handleLogout} />
      <ResponsiveDrawer
        activeSection={activeSection}
        handleDrawerToggle={handleDrawerToggle}
        isOpen={isDrawerOpen}
      />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: isImpersonating ? "5rem" : "3rem", //this is intended to match the height of the header; the AppBar is set up as static, and it's Toolbar is the "dense" variant
          width: (theme) => `calc(100% - ${theme.fielderComponents.drawer.width}px)`,
          minWidth: "320px",
        }}
      >
        <Sentry.ErrorBoundary
          dialogOptions={{
            user: {
              email: user?.email,
              name: user ? formatPersonName(user) : "unknown",
            },
          }}
          fallback={ErrorFallback}
          showDialog
        >
          <Box
            sx={{
              width: "100%",
              margin: 0,
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GlobalStyles styles={{ ".pac-container": { zIndex: 3000 } }} />
            {children}
          </Box>
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  )
}

export default MainLayout
