import React from "react"
// import { useDraggable } from "@dnd-kit/core"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

import { DAY_MODE_ROW_HEIGHT } from "./Constants"
import { DispatchResource } from "~/types/appTypes"

interface Props {
  readonly children: React.ReactNode
  readonly resource: DispatchResource
  readonly style: React.CSSProperties
}

export default function StaffCell({ children, resource, style }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: resource.id,
    data: {
      resource,
    },
  })

  const styleOverride = {
    ...style,

    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#f1f8ff" : "#fff",
    zIndex: isDragging ? 111 : 100,

    height: DAY_MODE_ROW_HEIGHT,
    minHeight: DAY_MODE_ROW_HEIGHT,
    maxHeight: DAY_MODE_ROW_HEIGHT,

    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={styleOverride} {...attributes} {...listeners}>
      {children}
    </div>
  )
}
