import gql from "graphql-tag"
import { useQuery } from "@apollo/client"

import { useAuth } from "~/context/AuthContext"
import useStore from "~/store"

export const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags($organizationId: ID!) {
    getOrganizationById(id: $organizationId) {
      id
      featureFlags {
        id
        enabled
      }
    }
  }
`

export default function useGetFeatureFlags(): number {
  const { user } = useAuth()
  const { setFeatureFlags } = useStore()
  const { data } = useQuery(GET_FEATURE_FLAGS, {
    variables: {
      organizationId: user?.organization?.id,
    },
    skip: !user?.organization?.id, // Do not make the network request if the user does not have the required permissions! Otherwise they may not be able to do anything in the app.
    pollInterval: 1000 * 60 * 5, // Poll every 5 minutes
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setFeatureFlags(data?.getOrganizationById?.featureFlags)
    },
  })
  return data?.getOrganizationById?.featureFlags ?? {}
}
