/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Navigate, useParams, NavigateProps } from "react-router-dom"
import numeral from "numeral"
import { loadStripe } from "@stripe/stripe-js"
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import FormControlLabel from "@mui/material/FormControlLabel"
import LockIcon from "@mui/icons-material/Lock"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { FielderTextField, Seo, GreenCheckbox } from "../components"
import { formatAddress, parseGraphQLErrorCode, BILLING_PERIOD_OPTIONS } from "../util"
import { useAuth } from "../context/AuthContext"
import { ALL_SUBSCRIPTION_OPTIONS } from "../queries/allSubscriptionOptions"
import { CREATE_SUBSCRIPTION } from "../queries/createSubscription"
import { SYNC_SUBSCRIPTION } from "../queries/syncSubscription"
import { GET_ORGANIZATION_SUBSCRIPTION } from "../queries/getOrganizationSubscription"
import { useQuery, useMutation } from "@apollo/client"
import {
  AllSubscriptionOptionsData,
  BillingPeriodOption,
  FielderSubscriptionProduct,
  FielderSubscriptionPricePoint,
  FielderSubscriptionPricePointInterval,
  FielderSubscriptionStatus,
  OrganizationStatus,
} from "../types"
import PublicAppBar from "../components/PublicAppBar"

const KNOWN_PAYMENT_ERROR_CODES = [
  "expired_card",
  "generic_decline",
  "incorrect_cvc",
  "incorrect_number",
  "incorrect_zip",
  "insufficient_funds",
  "invalid_expiry_year",
  "processing_error",
]

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeKey = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY
const stripePromise = "undefined" !== typeof window && stripeKey ? loadStripe(stripeKey) : null

function SubscriptionPaymentForm() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { planId, priceId } = useParams()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const [setupFee, setSetupFee] = useState<FielderSubscriptionPricePoint>()
  const [subscriptionOptions, setSubscriptionOptions] = useState<FielderSubscriptionProduct[]>()

  const { loading: allSubscriptionOptionsLoading, error } = useQuery<AllSubscriptionOptionsData>(
    ALL_SUBSCRIPTION_OPTIONS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setSetupFee(
          data?.allSubscriptionOptions?.find((o) => o.nameKey === "setup")?.pricePoints?.[0]
        )
        const sortedPlans = data?.allSubscriptionOptions
          ?.filter((o) => o.nameKey !== "setup")
          ?.sort((a, b) => {
            const monthlyPriceA = a.pricePoints.find(
              (pricePoint) => pricePoint.interval === FielderSubscriptionPricePointInterval.MONTH
            )
            const monthlyPriceB = b.pricePoints.find(
              (pricePoint) => pricePoint.interval === FielderSubscriptionPricePointInterval.MONTH
            )

            if (!monthlyPriceA || !monthlyPriceB) {
              return 0
            }

            if (monthlyPriceA.unitAmount < monthlyPriceB.unitAmount) return -1
            if (monthlyPriceA.unitAmount > monthlyPriceB.unitAmount) return 1
            return 0
          })
        setSubscriptionOptions(sortedPlans)
      },
    }
  )

  useQuery(GET_ORGANIZATION_SUBSCRIPTION, {
    variables: {
      id: user?.organization?.id,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const subscription = data?.getOrganizationById?.fielderSubscription
      if (
        subscription &&
        !["INCOMPLETE", "INCOMPLETE_EXPIRED", "CANCELED"].includes(subscription.status)
      ) {
        // user's Organization already has a subscription, so they shouldn't be coming here.
        // This page is only for setting up new subscriptions - not for adjusting existing subscriptions.
        // Since the user is already logged in, let's just send them to the dashboard
        setRedirectTo({ to: "/app/dashboard", replace: true })
      }
    },
  })

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  const selectedPlan = subscriptionOptions?.find((p) => p.id === planId)
  const selectedPrice = selectedPlan?.pricePoints?.find((p) => p.id === priceId)

  const loading = allSubscriptionOptionsLoading

  return (
    <>
      <Seo title={t("page.franchiseePricing.title")} />
      <PublicAppBar />
      <Box
        component="main"
        sx={{
          margin: "0 auto",
          minWidth: "300px",
          width: "80%",
          maxWidth: 1000,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "1rem",
          paddingTop: "0.625rem",
          "& h1": {
            textAlign: "center",
            fontSize: "2.2rem",
            marginBottom: "1.25rem",
          },
        }}
      >
        <h1>{t("page.franchiseePricing.checkout")}</h1>
        {loading ? (
          <Box sx={{ marginTop: "6.25rem" }}>
            <CircularProgress />
          </Box>
        ) : null}
        {!loading && !error && selectedPlan && selectedPrice && subscriptionOptions && user ? (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              plan={selectedPlan}
              price={selectedPrice}
              setupFee={setupFee}
              subscriptionOptions={subscriptionOptions}
              user={user}
            />
          </Elements>
        ) : (
          <Box>Oops! An error occurred. Please try again later.</Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          color: "#212121",
          textAlign: "center",
          marginTop: "6.25rem",
          paddingBottom: "1.875rem",
        }}
      >
        <Box sx={styles.poweredBy}>{t("poweredBy")} Fielder &#8482;</Box>
        <Box>www.fielderapp.com</Box>
      </Box>
    </>
  )
}

interface CheckoutFormProps {
  readonly plan: FielderSubscriptionProduct
  readonly price: FielderSubscriptionPricePoint
  readonly setupFee?: FielderSubscriptionPricePoint
  readonly subscriptionOptions: FielderSubscriptionProduct[]
}

function CheckoutForm({ plan, price, setupFee, subscriptionOptions }: CheckoutFormProps) {
  const { t } = useTranslation()
  const { user, setUser } = useAuth()
  const [redirectTo, setRedirectTo] = useState<NavigateProps>()
  const stripe = useStripe()
  const elements = useElements()
  const [errorCode, setErrorCode] = useState<string | null>()
  const [selectedPlan, setSelectedPlan] = useState<FielderSubscriptionProduct>(plan)
  const [selectedPrice, setSelectedPrice] = useState<FielderSubscriptionPricePoint>(price)
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriodOption>(() => {
    if (price.interval === FielderSubscriptionPricePointInterval.YEAR) {
      return BILLING_PERIOD_OPTIONS.ANNUALLY
    } else {
      return BILLING_PERIOD_OPTIONS.MONTHLY
    }
  })
  const [cardFocused, setCardFocused] = useState<boolean>(false)
  const [paymentMethodId, setPaymentMethodId] = useState<string | undefined>()
  const [legalConsentGiven, setLegalConsentGiven] = useState<boolean>(false)
  const [planMenuAnchorEl, setPlanMenuAnchorEl] = useState(null)
  const [priceMenuAnchorEl, setPriceMenuAnchorEl] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const p = selectedPlan.pricePoints.find((p) => p.interval === billingPeriod.value)
    if (p) {
      setSelectedPrice(p)
    } else {
      throw new Error(
        `No price point found in the selected plan for billing period ${billingPeriod.value}`
      )
    }
  }, [billingPeriod, selectedPlan])

  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION, {
    onCompleted: (data) => {
      // The subscription was created, but payment might not have succeeded.
      // See if we got a "requires_action" or 'requires_confirmation' or "requires_payment_method"  response.
      // With the former two, we'll just call the more-advanced `confirmCardPayment` method
      // which may do some fancy shit with 3D cards and stuff. If it's "requires_payment_method",
      // for now let's just assume the card payment failed and ignore any subscriptions or invoices
      // that Stripe may have created. They'll expire anyway. Make the user try again, hopefully with a valid card.
      // I think it's just easier & more reliable this way, as opposed to trying to pull up and edit
      // existing subscriptions/invoices as per the Stripe example app(s). Our user may have changed their
      // tier and/or price point too. So the only thing to deal with is 'requires_action' or 'requires_confirmation'.
      // If that succeeds, then as far as Stripe is concerned the customer is all paid up. But we don't know that. So make a
      // call to a 'updateSubscription' or something (although, we could/should have a Stripe Webhook setup as well).
      const subscription = data.createSubscription.fielderSubscription
      if (subscription.status !== FielderSubscriptionStatus.ACTIVE) {
        const paymentIntent = subscription.latestInvoice.paymentIntent
        if (
          (paymentIntent.status === "requires_action" ||
            paymentIntent.status === "requires_confirmation") &&
          paymentMethodId
        ) {
          stripe
            ?.confirmCardPayment(paymentIntent.clientSecret, {
              payment_method: paymentMethodId,
            })
            .then((result) => {
              if (result.error) {
                // start code flow to handle updating the payment details
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
                setErrorCode("general_decline")
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  // There's a risk of the customer closing the window before callback
                  // execution. To handle this case, we have a webhook endpoint
                  // listening to invoice.payment_succeeded that will try to set the Org's status to ACTIVE.
                  syncSubscription({
                    variables: {
                      subscriptionId: subscription.id,
                    },
                  })
                }
              }
            })
        }
      } else {
        setUser({
          ...user,
          organization: {
            ...user?.organization,
            status: OrganizationStatus.ACTIVE,
          },
        })
        setRedirectTo({
          to: "/app/dashboard",
          state: {
            snack: {
              messageKey: "messages.subscription.success",
              variant: "success",
            },
          },
          replace: true,
        })
      }
    },
    onError: (error) => {
      setLoading(false)
      const errorCode = parseGraphQLErrorCode(error)
      if (KNOWN_PAYMENT_ERROR_CODES.includes(errorCode)) {
        setErrorCode(errorCode)
      } else {
        setErrorCode("generic_decline")
      }
    },
  })

  const [syncSubscription] = useMutation(SYNC_SUBSCRIPTION, {
    onCompleted: (data) => {
      const subscription = data.syncSubscription.fielderSubscription
      if (subscription.status === FielderSubscriptionStatus.ACTIVE) {
        setUser({
          ...user,
          organization: {
            ...user?.organization,
            status: OrganizationStatus.ACTIVE,
          },
        })
        setRedirectTo({
          to: "/app/dashboard",
          state: {
            snack: {
              messageKey: "messages.subscription.success",
              variant: "success",
            },
          },
          replace: true,
        })
      } else {
        setErrorCode("generic_decline")
      }
    },
    onError: () => {
      setErrorCode("generic_decline")
    },
  })

  function handleOpenPlanMenu(event: any) {
    setPlanMenuAnchorEl(event.currentTarget)
  }

  function handleClosePlanMenu() {
    setPlanMenuAnchorEl(null)
  }

  function handleOpenPriceMenu(event: any) {
    setPriceMenuAnchorEl(event.currentTarget)
  }

  function handleClosePriceMenu() {
    setPriceMenuAnchorEl(null)
  }

  function calculateTotalDue() {
    return selectedPrice.unitAmount + (setupFee?.unitAmount ?? 0)
  }

  async function handleSubmit(event: any) {
    // Block native form submission.
    event.preventDefault()

    setErrorCode(null)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how to find your
    // CardElement because there can only ever be one of each type of element.
    const cardElement = elements.getElement(CardElement)
    if (!cardElement) {
      console.error("Could not find CardElement")
      return
    }
    // cardElement.update({ value: { postalCode: user.organization?.address?.postalCode ?? null } })

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    })

    if (error) {
      setErrorCode(error?.code)
    } else {
      setPaymentMethodId(paymentMethod?.id)
      const paymentMethodId = paymentMethod?.id
      setLoading(true)
      createSubscription({
        variables: {
          paymentMethodId,
          priceId: selectedPrice.id,
        },
      })
    }
  }

  if (redirectTo) {
    return <Navigate replace={redirectTo.replace} state={redirectTo.state} to={redirectTo.to} />
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper
            sx={{
              padding: "1.25rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: 600,
              }}
            >
              <span>{t("page.franchiseePricing.paymentMethod")}</span>
              <span style={{ display: "flex" }}>
                <LockIcon fontSize="small" style={{ marginRight: "0.5rem" }} />
                {t("page.franchiseePricing.secureForm")}*
              </span>
            </Box>
            <Box
              sx={{
                margin: "0.625rem",
                fontSize: "0.85rem",
                "& label": {
                  fontWeight: "bold",
                },
              }}
            >
              <label>{t("page.franchiseePricing.billTo")}:</label>
              <Box>{user.organization.tradeName}</Box>
              <Box>{formatAddress(user.organization?.address?.addressString)}</Box>
            </Box>
            <Box
              sx={{
                marginTop: "1.875rem",
              }}
            >
              <Box
                sx={{
                  marginBottom: "0.9375rem",
                }}
              >
                <FielderTextField
                  data-testid="cardholderName"
                  fullWidth
                  id="cardholderName"
                  inputProps={{ maxLength: 255 }}
                  label={t("page.franchiseePricing.cardholderName")}
                  name="cardholderName"
                  required
                />
              </Box>
              <Box
                sx={{
                  marginBottom: "0.9375rem",
                }}
              >
                <Box
                  sx={[
                    {
                      border: "1px solid #e2e2e1",
                      borderRadius: "4px !important",
                      padding: "18.5px 14px",
                    },
                    cardFocused && {
                      border: "2px solid rgba(0, 0, 0, 0.8)",
                      borderColor: (theme) => theme.palette.primary.main,
                    },
                  ]}
                >
                  <CardElement
                    onBlur={() => setCardFocused(false)}
                    onChange={() => setErrorCode(null)}
                    onFocus={() => setCardFocused(true)}
                    options={{
                      hidePostalCode: false,
                      style: {
                        base: {
                          fontSize: "1rem",
                          color: "#32325d",
                          fontFamily: "Source Sans Pro, sans-serif",
                          "::placeholder": {
                            color: "#a0aec0",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                </Box>
                {errorCode ? (
                  <Box
                    sx={{
                      marginTop: "0.625rem",
                    }}
                  >
                    <Alert severity="error">
                      {t(`page.franchiseePricing.paymentError.${errorCode}`)}
                    </Alert>
                  </Box>
                ) : null}
              </Box>
              <Box sx={styles.legalConsentContainer}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={legalConsentGiven}
                      name="legalConsentCheckbox"
                      onChange={() => setLegalConsentGiven(!legalConsentGiven)}
                    />
                  }
                  label={
                    <Box>
                      {t("page.franchiseePricing.legalConsent")}{" "}
                      <a href="/terms" target="_blank">
                        {t("termsOfService")}
                      </a>{" "}
                      {t("and")}{" "}
                      <a href="/privacy" target="_blank">
                        {t("privacyPolicy")}
                      </a>
                    </Box>
                  }
                />
              </Box>
              <Button
                disabled={loading || !legalConsentGiven}
                onClick={handleSubmit}
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  "&.MuiButton-contained.MuiButton-root": {
                    padding: "0.75rem",
                    "&:enabled": {
                      backgroundColor: "#055707",
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#0C8B13",
                      boxShadow: "none",
                    },
                  },
                }}
                variant="contained"
              >
                {loading ? <CircularProgress /> : t("page.franchiseePricing.placeOrder")}
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper
            sx={{
              flexGrow: 1,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                padding: "1.25rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Box
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {t("page.franchiseePricing.selectedPlan")}
                </Box>
                <div>
                  <Button
                    aria-controls="plan-menu"
                    aria-haspopup="true"
                    onClick={handleOpenPlanMenu}
                  >
                    {t(`page.franchiseePricing.products.${selectedPlan.nameKey}.title`)}{" "}
                    <ExpandMoreIcon />
                  </Button>
                  <Menu
                    anchorEl={planMenuAnchorEl}
                    id="plan-menu"
                    keepMounted
                    onClose={handleClosePlanMenu}
                    open={Boolean(planMenuAnchorEl)}
                  >
                    {subscriptionOptions.map((p) => (
                      <MenuItem
                        key={p.id}
                        onClick={() => {
                          setSelectedPlan(p)
                          handleClosePlanMenu()
                        }}
                        sx={styles.listItem}
                      >
                        {t(`page.franchiseePricing.products.${p.nameKey}.title`)}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
              <Box>
                <Box
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {t("page.franchiseePricing.billingPeriod")}
                </Box>
                <Box>
                  <Button
                    aria-controls="price-menu"
                    aria-haspopup="true"
                    onClick={handleOpenPriceMenu}
                  >
                    {t(billingPeriod.titleKey)} <ExpandMoreIcon />
                  </Button>
                  <Menu
                    anchorEl={priceMenuAnchorEl}
                    id="price-menu"
                    keepMounted
                    onClose={handleClosePriceMenu}
                    open={Boolean(priceMenuAnchorEl)}
                    sx={{
                      "&.MuiList-root": {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setBillingPeriod(BILLING_PERIOD_OPTIONS.ANNUALLY)
                        handleClosePriceMenu()
                      }}
                      sx={styles.listItem}
                    >
                      {t(BILLING_PERIOD_OPTIONS.ANNUALLY.titleKey)}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setBillingPeriod(BILLING_PERIOD_OPTIONS.MONTHLY)
                        handleClosePriceMenu()
                      }}
                      sx={styles.listItem}
                    >
                      {t(BILLING_PERIOD_OPTIONS.MONTHLY.titleKey)}
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                padding: "1.25rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {billingPeriod === BILLING_PERIOD_OPTIONS.ANNUALLY && (
                <Box sx={styles.lineItem}>
                  <Box>
                    <Box>{t("page.franchiseePricing.annualSubscription")}</Box>
                    <Box sx={styles.extraLineItemDetail}>
                      {numeral(selectedPrice.effectiveMonthlyRate).format("$0,0")}/mo * 1 year
                    </Box>
                  </Box>
                  <Box>{numeral(selectedPrice.unitAmount).format("$0,0")} USD</Box>
                </Box>
              )}
              {billingPeriod === BILLING_PERIOD_OPTIONS.MONTHLY && (
                <Box sx={styles.lineItem}>
                  <Box>
                    <Box>{t("page.franchiseePricing.monthlySubscription")}</Box>
                    <Box sx={styles.extraLineItemDetail}>
                      {numeral(selectedPrice.unitAmount).format("$0,0")}/mo
                    </Box>
                  </Box>
                  <Box>{numeral(selectedPrice.unitAmount).format("$0,0")} USD</Box>
                </Box>
              )}
              {setupFee ? (
                <Box sx={styles.lineItem}>
                  <Box>{t("page.franchiseePricing.products.setup.title")}</Box>
                  <Box>{numeral(setupFee?.unitAmount ?? 0).format("$0,0")} USD</Box>
                </Box>
              ) : null}
              <Box sx={styles.dueTodayLineItem}>
                <Box>{t("page.franchiseePricing.dueToday")}</Box>
                <Box>{numeral(calculateTotalDue()).format("$0,0")} USD</Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={styles.disclosureContainer}>
        <Box sx={styles.disclosure}>{t("page.franchiseePricing.disclosure.jobOverageCharge")}</Box>
        <Box sx={styles.disclosure}>* {t("page.franchiseePricing.disclosure.secureForm")}</Box>
      </Box>
    </>
  )
}

const styles = {
  lineItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px dashed #979797",
    py: "1.25rem",
    "&:last-of-type": {
      borderBottom: "1px solid #000",
    },
  },
  legalConsentContainer: {
    marginTop: "0.625rem",
    marginBottom: "1rem",
    "& label > span": {
      fontSize: "0.9375rem",
    },
  },
  submitButton: {
    width: "100%",
    fontWeight: "bold",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    color: "#fff",
    backgroundColor: "#055707",
    "&:hover": {
      backgroundColor: "#0C8B13",
      boxShadow: "none",
    },
  },
  extraLineItemDetail: {
    fontSize: "0.75rem",
  },
  dueTodayLineItem: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: "0.625rem",
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  disclosureContainer: {
    marginTop: "1.25rem",
    fontSize: "0.9rem",
  },
  disclosure: {
    marginTop: "1.25rem",
  },
  poweredBy: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  listItem: {
    "&.MuiMenuItem-root": {
      py: "0.5rem",
      px: "1rem",
      display: "block",
    },
  },
} as const

export default SubscriptionPaymentForm
