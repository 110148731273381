/**
 * GoliathTech theme
 */

import { createTheme } from "@mui/material/styles"
import { FielderColors, FielderScreenThemes, FielderComponentThemes } from "~/themes/themeTypes"

const fielderColors: FielderColors = {
  brandPrimary: "#FFC601",
  brandPrimaryFaded: "rgb(255, 198, 1, 0.25)",
  brandPrimaryExtraFaded: "#fffdf3",
  listItemActive: "rgb(255, 198, 1, 0.25)",
  white: "#ffffff",
  black: "#0F0708",
  fadedBlack: "#0F07087F",
  lightGrey: "#efefef",
  mutedText: "#747474",
  text: "#0F0708",
  error: "#B31425",
  success: "#208C27",
  successFaded: "#BBD9AD",
  inputBorder: "#E2E2E1",
  inputBorderFocused: "#FFC601",
  warningHeavy: "hsla(46, 100%, 50%, 1)", // "#FFC300"
  warningLight: "hsla(46, 100%, 50%, 0.3)", // "rgba(255, 195, 0, 0.3)"
  warningText: "hsl(33.34 98.24% 30.67%)"
}

const fielderScreens: FielderScreenThemes = {
  franchiseePricing: {
    headerBackground: fielderColors.black,
    color: fielderColors.mutedText,
  },
  login: {
    backgroundColor: fielderColors.black,
  },
  estimateRequest: {
    headerBackground: fielderColors.black,
    color: fielderColors.mutedText,
  },
  common: {
    backgroundColor: fielderColors.lightGrey,
  },
}

const fielderComponents: FielderComponentThemes = {
  header: {
    menuIcon: fielderColors.black,
    height: "3rem",
  },
  drawer: {
    backgroundColor: "#2F2F2F",
    color: fielderColors.white,
    activeBackgroundColor: fielderColors.fadedBlack,
    activeColor: fielderColors.brandPrimary,
    activeIndicatorColor: fielderColors.brandPrimary,
    hoverBackgroundColor: "rgb(30 30 30)",
    width: 240,
  },
  notification: {
    badgeBackground: fielderColors.brandPrimary,
    badgeForeground: fielderColors.text,
  },
}

const theme = createTheme({
  fielderColors,
  fielderScreens,
  fielderComponents,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          lineHeight: 1.43,
          // fontSize: "0.875rem",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0.375rem 2.5rem 0.375rem 1rem",
          fontSize: "0.8rem",
          wordWrap: "break-word",
        },
        head: {
          fontWeight: 600,
          paddingTop: "0.875rem",
          paddingBottom: "0.875rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.55)",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: "100%",
        },
        indicator: {
          backgroundColor: fielderColors.brandPrimary,
          zIndex: 1000,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: "2rem",
          paddingRight: "2rem",
          textTransform: "none",
          color: "rgba(0, 0, 0, 0.8)",
          fontSize: "0.875rem",
          fontWeight: 600,
          fontFamily: "Source Sans Pro",
          minHeight: 53,
          minWidth: 0,
          "&:focus": {
            opacity: 1,
          },
          "&$selected": {
            backgroundColor: fielderColors.brandPrimaryFaded,
            "& *": {},
          },
        },
        textColorInherit: {
          opacity: 0.6,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: fielderColors.white,
          "&:hover": {
            backgroundColor: fielderColors.white,
          },
          "&$focused": {
            backgroundColor: fielderColors.white,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&$focused": {
            color: fielderColors.black,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: fielderColors.white,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        textPrimary: {
          color: fielderColors.black,
        },
        outlined: {
          color: fielderColors.black,
          borderColor: fielderColors.fadedBlack,
          "&:hover": {
            borderColor: fielderColors.black,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: fielderColors.black,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: fielderColors.black,
          },
          "&.MuiCheckbox-indeterminate": {
            color: fielderColors.black,
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#efefef",
    },
    error: {
      main: fielderColors.error,
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: fielderColors.brandPrimary,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: fielderColors.black,
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
  },
  typography: {
    htmlFontSize: 16, //using the 16px simplification for using rem units
    fontFamily: [
      "Source Sans Pro",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
})

export default theme