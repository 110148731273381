import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import capitalize from "capitalize"

import { ItemFormContext } from "./ItemFormContext"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"

function BasicInfoForm() {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(ItemFormContext)

  return (
    <>
      <Box sx={classes.formFieldContainer}>
        <FielderTextField
          data-testid="code-Field"
          error={!!state.code.error}
          fullWidth
          id="code"
          inputProps={{
            maxLength: 30,
          }}
          label={t("itemCode")}
          name="code"
          onChange={(e) => {
            dispatch({ type: "SET_FIELD", payload: { fieldName: "code", value: e.target.value } })
          }}
          onFocus={(e) => e.target.select()}
          required
          value={state.code.value}
        />
        {!(state.code.error && state.code.touched) ? (
          <FieldHelperText message={t("component.itemBasicInfoForm.helperText.code")} />
        ) : (
          <FieldHelperText error message={t(state.code.error)} />
        )}
      </Box>
      <Box sx={classes.formFieldContainer}>
        <FielderTextField
          data-testid="name-Field"
          error={!!state.name.error}
          fullWidth
          id="name"
          inputProps={{
            maxLength: 255,
          }}
          label={t("name")}
          name="name"
          onBlur={() => {
            if (state.name.value) {
              dispatch({
                type: "SET_FIELD",
                payload: { fieldName: "name", value: capitalize.words(state.name.value, true) },
              })
            }
          }}
          onChange={(e) => {
            dispatch({
              type: "SET_FIELD",
              payload: { fieldName: "name", value: e.target.value },
            })
          }}
          onFocus={(e) => e.target.select()}
          required
          value={state.name.value}
        />
        {!(state.name.error && state.name.touched) ? (
          <FieldHelperText message={t("component.itemBasicInfoForm.helperText.name") as string} />
        ) : (
          <FieldHelperText error message={t(state.name.error)} />
        )}
      </Box>
      <Box sx={classes.formFieldContainer}>
        <FielderTextField
          data-testid="description-Field"
          error={!!state.description.error}
          fullWidth
          id="description"
          inputProps={{
            maxLength: 1000,
          }}
          label={t("description")}
          maxRows="2"
          minRows="2"
          multiline
          name="description"
          onChange={(e) => {
            dispatch({
              type: "SET_FIELD",
              payload: { fieldName: "description", value: e.target.value },
            })
          }}
          value={state.description.value}
        />
        {!(state.description.error && state.description.touched) ? (
          <FieldHelperText message={t("component.itemBasicInfoForm.helperText.description")} />
        ) : (
          <FieldHelperText error message={t(state.description.error)} />
        )}
      </Box>
      <Box sx={classes.formFieldContainer}>
        <FielderTextField
          data-testid="category-Field"
          error={!!state.category.error}
          fullWidth
          id="category"
          inputProps={{
            maxLength: 255,
          }}
          label={t("category")}
          name="category"
          onBlur={() => {
            if (state.category.value) {
              dispatch({
                type: "SET_FIELD",
                payload: {
                  fieldName: "category",
                  value: capitalize.words(state.category.value, true),
                },
              })
            }
          }}
          onChange={(e) => {
            dispatch({
              type: "SET_FIELD",
              payload: { fieldName: "category", value: e.target.value },
            })
          }}
          onFocus={(e) => e.target.select()}
          value={state.category.value}
        />
        {!(state.category.error && state.category.touched) ? (
          <FieldHelperText message={t("component.itemBasicInfoForm.helperText.category")} />
        ) : (
          <FieldHelperText error message={t(state.category.error)} />
        )}
      </Box>
    </>
  )
}

const classes = {
  formFieldContainer: {
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
    marginBottom: "0.75rem",
  },
}

export default BasicInfoForm
