import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useAuth } from "../context/AuthContext"
import { DefaultPermission } from "../types"
import useSessionChecker from "~/hooks/useSessionChecker"

interface RequireAuthProps {
  readonly requiredPermissions: DefaultPermission[]
  readonly children: JSX.Element
}

function RequireAuth({ children, requiredPermissions }: RequireAuthProps): JSX.Element {
  const { user, isAuthenticated, hasPermissions } = useAuth()
  const location = useLocation()
  useSessionChecker()

  const org = user?.organization

  if (!isAuthenticated()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate replace state={{ from: location }} to="/app/login" />
  } else if (org?.status === "SUSPENDED") {
    return <Navigate state={{ from: location }} to="/app/org-account-locked" />
  } else if (org?.status === "PENDING") {
    if (user?.isOrganizationOwner) {
      return location.pathname.includes("/app/pricing") ||
        location.pathname.includes("/app/payment") ? (
        children
      ) : (
        <Navigate state={{ from: location }} to="/app/pricing" />
      )
    } else {
      return <Navigate state={{ from: location }} to="/app/org-account-pending" />
    }
  } else if (!hasPermissions?.(requiredPermissions)) {
    return <Navigate state={{ from: location }} to="/app/unauthorized" />
  } else {
    return children
  }
}

export default RequireAuth
