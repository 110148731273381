import React from "react"

function HowDoIGetStarted() {
  return (
    <div>
      <h3>How do I get started?</h3>
      <p>
        After you’ve logged in and activated your organization’s subscription, you should head over
        to the Settings section to make sure certain things are set up. If you want to connect your
        QuickBooks or Xero account to Fielder, see How do I connect my Quickbooks Online (or Xero)
        account with Fielder? If you specify that you want to use your QuickBooks or Xero account
        for invoicing, then Fielder will grab all your tax rate data from that other system.
        Otherwise, you will want to set up some Tax Rate Groups in Fielder yourself.
      </p>
      <p>
        You may also want to set up some Job Categories by clicking the “Job Category Options”
        option in Settings. A Job Category is basically just a label you can apply to a Job. We
        provide a default set of Job Category options, but you can add, remove, and edit the set of
        options however you like.
      </p>
      <p>
        Next you may want to create accounts for your team. Check out the Users and Security Roles
        sections in Settings. A Security Role is a named grouping of permissions. A “permission” is
        essentially an action you can take in Fielder. We provide a few default roles but you can
        create your own however you like, specifying the permissions that the role has at a fairly
        fine-grained level. You would then create a User account and assign it the appropriate
        roles. A user may have multiple security roles. In that case, they will be granted all
        permissions from all of their roles.
      </p>
      <p>
        Once you’ve got your basic settings the way you like, you might create a Job. Go to the Jobs
        section via the navigation menu on the left. Then click the “+ Create Job” button. You’ll
        see a form that will ask you to specify the Customer and the Job Address, at minimum. Enter
        the required info and then click Save. If all goes well, you’ll enter “edit” mode where you
        can then attach files to the job, create Estimates, draw up an Installation Worksheet, send
        an email to your customer, and more.
      </p>
    </div>
  )
}

export default HowDoIGetStarted
