import React from "react"
import Box from "@mui/material/Box"

import ItemImageManager from "~/components/ItemImageManager"
import ItemInfoAlert from "./ItemInfoAlert"
import { OrganizationItem } from "~/types"

interface SidebarProps {
  readonly item?: OrganizationItem
  readonly onImageUpload?: () => void
}

function Sidebar({ item, onImageUpload }: SidebarProps) {
  return (
    <Box
      sx={(theme) => {
        return {
          display:
            item?.isTopLevel && item?.attachments?.length && item.attachments.length > 0
              ? "flex"
              : "none",
          margin: 0,
          padding: 0,
          fontSize: "0.875rem",
          [theme.breakpoints.up("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "200px",
          },
        }
      }}
    >
      <ItemImageManager disabled={item?.isTopLevel} item={item} onUploadComplete={onImageUpload} />
      {item?.isTopLevel ? <ItemInfoAlert item={item} /> : null}
    </Box>
  )
}

export default Sidebar
