import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"

interface CreateJobButtonProps {
  readonly disabled?: boolean
  readonly onClick?: () => void
}

function CreateJobButton({ disabled = false, onClick }: CreateJobButtonProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Button
      aria-label={t("createJob") as string}
      color="primary"
      data-testid="createJobButton"
      disabled={disabled}
      fullWidth
      onClick={() => {
        if (onClick) {
          onClick()
        } else {
          navigate("/app/jobs/create")
        }
      }}
      startIcon={<AddIcon fontSize="small" />}
      sx={{
        fontWeight: "bold",
        "& svg": {
          fontSize: "1.0rem",
        },
      }}
      variant="contained"
    >
      {t("createJob")}
    </Button>
  )
}

export default CreateJobButton
