import React from "react"
import Box from "@mui/material/Box"

export function SeriesEventConnectingLine() {
  return (
    <Box
      sx={{
        height: "1px",
        width: "100%",
        backgroundColor: (theme) => theme.fielderColors.inputBorder,
        position: "absolute",
        top: "50%",
        left: 0,
        zIndex: 1,
      }}
    />
  )
}
