import React from "react"
import Box from "@mui/material/Box"
import { Divider } from "@mui/material"

import Note from "~/components/Note"
import NoteForm from "~/components/NoteForm"
import { HelicalPileInstallationWorksheetLineItemNote, User } from "~/types"

interface Props {
  readonly addNoteLoading?: boolean
  readonly deleteNoteLoadingIds?: string[]
  readonly editNoteLoadingIds?: string[]
  readonly notes?: HelicalPileInstallationWorksheetLineItemNote[]
  readonly onAddNote: (note: string) => void
  readonly onEditNote?: (id: string, text: string) => void
  readonly onDeleteNote?: (id: string) => void
  readonly user: Partial<User>
}

function HelicalPileInstallationWorksheetLineNotes({
  addNoteLoading = false,
  deleteNoteLoadingIds,
  editNoteLoadingIds,
  notes,
  onAddNote,
  onEditNote,
  onDeleteNote,
  user,
}: Props) {
  return (
    <Box sx={classes.notesRootContainer}>
      <Box sx={classes.notesContentContainer}>
        <NoteForm addNoteLoading={addNoteLoading} onAddNote={onAddNote} />
        <Box sx={classes.noteThreadContainer}>
          {notes?.map((n: HelicalPileInstallationWorksheetLineItemNote, idx: number) => (
            <Box key={n.id}>
              <Note
                isAuthor={n.createdBy.id === user.id}
                isDeleting={deleteNoteLoadingIds?.includes(n.id) ?? false}
                isUpdating={editNoteLoadingIds?.includes(n.id) ?? false}
                key={n.id}
                note={n}
                onDelete={(id) => onDeleteNote?.(id)}
                onEdit={(id, text) => onEditNote?.(id, text)}
                timeZone={user.organization?.timeZone}
              />
              {idx !== notes.length - 1 && <Divider />}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

const classes = {
  notesRootContainer: {
    backgroundColor: "rgb(255, 200, 100, 0.10)",
    padding: "2.5rem",
    paddingTop: "1.5rem",
    paddingBottom: "0.625rem",
  },
  notesContentContainer: {
    width: "60%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
  },
  notesSectionHeader: {
    fontWeight: 500,
    fontSize: "0.9rem",
    color: "#747474",
    marginBottom: "0.625rem",
  },
  noteThreadContainer: {
    marginTop: "1rem",
  },
} as const

export default HelicalPileInstallationWorksheetLineNotes
