import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { nanoid } from "nanoid"
import numeral from "numeral"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { asFloat, isBlank } from "../../../../util"
import TaxRateForm from "./TaxRateForm"
import { TaxRate, TaxRateFormInput, TaxRateGroup } from "../../../../types"
import FielderTextField from "../../../../components/FielderTextField"
import FieldHelperText from "../../../../components/FieldHelperText"
import SaveButton from "../../../../components/SaveButton"

interface TaxRateGroupDialog {
  taxRateGroup?: TaxRateGroup | null | undefined
  loading?: boolean
  onCancel: () => void
  onSave: (taxRateGroup: Partial<TaxRateGroup>) => void
  open?: boolean
}

function TaxRateGroupDialog({
  taxRateGroup,
  loading = false,
  onCancel,
  onSave,
  open = false,
}: TaxRateGroupDialog) {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(taxRateGroup?.name ?? "")
  const [errors, setErrors] = useState<{
    name: string | null
    taxRates: { [key: string]: string | null } | null
  }>(() => ({
    name: null,
    taxRates: null,
  }))
  const [taxRateFormInputs, setTaxRateFormInputs] = useState<TaxRateFormInput[]>(() =>
    taxRateGroup
      ? taxRateGroup.taxRates.map((tr: TaxRate) => ({
          id: tr.id,
          name: tr.name,
          key: nanoid(),
          rate: String(tr.rate * 100),
          errors: {
            name: null,
            rate: null,
          },
        }))
      : [
          {
            id: "",
            name: "",
            key: nanoid(),
            rate: "",
            errors: {},
          },
        ]
  )

  function updateTaxRate(taxRate: TaxRateFormInput) {
    const idx = taxRateFormInputs.findIndex((tr) => tr.key == taxRate.key)
    taxRateFormInputs.splice(idx, 1, taxRate)
    setTaxRateFormInputs(taxRateFormInputs.concat([]))
  }

  function removeTaxRate(taxRate: TaxRateFormInput) {
    const updatedTaxRates = taxRateFormInputs.filter((tr) => tr.key !== taxRate.key)
    setTaxRateFormInputs(updatedTaxRates)
  }

  /**
   * Validate the form
   * A return value of TRUE means everthing looks good.
   */
  function validate() {
    const hasInvalidTaxRate = taxRateFormInputs.some((tr) => {
      return isBlank(tr.name) || tr.rate === null || typeof tr.rate === "undefined"
    })

    if (hasInvalidTaxRate) {
      const validatedTaxRates = taxRateFormInputs.map((tr) => {
        return {
          ...tr,
          errors: {
            name: isBlank(tr.name) ? "required" : null,
            rate: !tr.rate ? "required" : null,
          },
        }
      })

      setTaxRateFormInputs(validatedTaxRates)
    }

    return !hasInvalidTaxRate
  }

  function handleSave() {
    if (!taxRateFormInputs || taxRateFormInputs.length === 0) {
      setErrors((prev) => ({
        ...prev,
        taxRates: {
          title: t("component.taxRateGroupDialog.taxRates.required.title"),
          message: t("component.taxRateGroupDialog.taxRates.required.message"),
        },
      }))
      return
    }

    if (!validate()) {
      setErrors((prev) => ({
        ...prev,
        taxRates: {
          title: t("component.taxRateGroupDialog.taxRates.invalid.title"),
          message: t("component.taxRateGroupDialog.taxRates.invalid.message"),
        },
      }))
      return
    }

    setErrors({
      name: null,
      taxRates: null,
    })

    const sanitizedTaxRates = taxRateFormInputs.map((tr) => {
      const taxRate = {
        name: tr.name,
        rate: asFloat(tr.rate) / 100,
      } as Partial<TaxRate>

      if (tr.id) {
        taxRate.id = tr.id
      }
      return taxRate
    })

    const group = {
      id: taxRateGroup?.id,
      name,
      taxRates: sanitizedTaxRates,
    } as Partial<TaxRateGroup>

    onSave(group)
  }

  function validateTaxRateGroupName(value: string): void {
    if (isBlank(value)) {
      setErrors((prev) => ({
        ...prev,
        name: t("component.taxRateGroupDialog.validation.name.required"),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        name: null,
      }))
    }
  }

  return (
    <Dialog
      aria-labelledby="edit-taxrategroup-dialog-title"
      data-testid="TaxRateGroupDialog"
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle
        id="edit-taxrategroup-dialog-title"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {taxRateGroup?.id ? t("editTaxRateGroup") : t("createTaxRateGroup")}
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.dialogContent}>
          <FielderTextField
            data-testid="name-Field"
            error={!!errors?.name}
            fullWidth
            id="taxRateGroupName"
            inputProps={{ maxLength: 50 }}
            label={t("component.taxRateGroupDialog.label.taxRateGroupName")}
            name="name"
            onBlur={() => {
              validateTaxRateGroupName(name)
            }}
            onChange={(e: any) => {
              setName(e.target.value)
              validateTaxRateGroupName(e.target.value)
            }}
            onFocus={(e: any) => e.target.select()}
            required
            value={name}
          />
          {!errors?.name ? (
            <FieldHelperText message={" "} />
          ) : (
            <FieldHelperText error message={errors.name} />
          )}
          <Box sx={classes.taxRatesSectionLabel}>{t("taxRates")}</Box>
          <Paper variant="outlined">
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={classes.tableHeaderCell} width="60%">
                    {t("name")}
                  </TableCell>
                  <TableCell sx={classes.tableHeaderCell} width="30%">
                    {t("rate")}
                  </TableCell>
                  <TableCell sx={classes.tableHeaderCell} width="10%" />
                </TableRow>
              </TableHead>
              <TableBody>
                {taxRateFormInputs.map((taxRateFormInput) => (
                  <TaxRateForm
                    key={taxRateFormInput.key}
                    onChange={updateTaxRate}
                    onDelete={removeTaxRate}
                    taxRateFormInput={taxRateFormInput}
                  />
                ))}
              </TableBody>
            </Table>
            <Box sx={classes.summaryContainer}>
              <Button
                aria-label={t("addTaxRate")}
                color="secondary"
                data-testid="addTaxRateButton"
                onClick={() => {
                  setTaxRateFormInputs([
                    ...taxRateFormInputs,
                    { key: nanoid(), name: "", rate: "", errors: {} },
                  ])
                }}
                variant="outlined"
              >
                <Box>{t("addTaxRate")}</Box>
              </Button>
              <Box sx={classes.totalTaxRateContainer}>
                <label>{t("totalTaxRate")}</label>
                <span>
                  {numeral(
                    taxRateFormInputs.reduce((acc, curr) => acc + asFloat(curr.rate), 0) / 100
                  ).format("0.[0000]%")}
                </span>
              </Box>
            </Box>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          color="secondary"
          disabled={loading}
          onClick={onCancel}
          style={{ border: "1px solid #ccc", minWidth: "80px" }}
        >
          {t("cancel")}
        </Button>
        <SaveButton disabled={loading || isBlank(name)} loading={loading} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  dialogContent: {
    backgroundColor: "#FFFFFF",
    paddingBottom: "1rem",
    paddingTop: "1rem",
  },
  dialogActions: {
    px: "1.5rem",
    paddingBottom: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  taxRatesSectionLabel: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginTop: "0.75rem",
    marginBottom: "0.5rem",
    marginLeft: "0.5rem",
  },
  summaryContainer: {
    padding: "0.5rem",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeaderCell: {
    borderBottom: "none",
    backgroundColor: "transparent",
    padding: "0.25rem 1rem",
  },
  totalTaxRateContainer: {
    fontWeight: "bold",
    fontSize: "1rem",
    marginRight: "1rem",
    "& label": {
      marginRight: "1.25rem",
    },
  },
} as const

export default TaxRateGroupDialog
