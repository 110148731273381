import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import DateRangeIcon from "@mui/icons-material/DateRange"
import Popper from "@mui/material/Popper"
import Button from "@mui/material/Button"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import { Divider } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

import FielderTextField from "~/components/FielderTextField"
import { DateRangeShortcut } from "~/types/appTypes"

function ShortcutButton({
  label,
  onClick,
}: {
  readonly label: string
  readonly onClick: () => void
}) {
  return (
    <Button
      fullWidth
      onClick={onClick}
      sx={{
        px: "2rem",
        justifyContent: "flex-start",
        paddingLeft: "1rem",
        whiteSpace: "nowrap",
        "&:hover": {
          backgroundColor: (theme) => `${theme.fielderColors.brandPrimary}55`,
        },
      }}
    >
      {label}
    </Button>
  )
}

export function calculateStartDate(dateRangeShortcut?: DateRangeShortcut | null): dayjs.Dayjs {
  switch (dateRangeShortcut) {
    case DateRangeShortcut.Last7Days:
      return dayjs().subtract(7, "day")
    case DateRangeShortcut.Last14Days:
      return dayjs().subtract(14, "day")
    case DateRangeShortcut.Last30Days:
      return dayjs().subtract(30, "day")
    case DateRangeShortcut.Last60Days:
      return dayjs().subtract(60, "day")
    case DateRangeShortcut.Last90Days:
      return dayjs().subtract(90, "day")
    case DateRangeShortcut.ThisMonth:
      return dayjs().startOf("month")
    case DateRangeShortcut.LastMonth:
      return dayjs().subtract(1, "month").startOf("month")
    case DateRangeShortcut.Last3Months:
      return dayjs().subtract(3, "month").startOf("month")
    case DateRangeShortcut.Last6Months:
      return dayjs().subtract(6, "month").startOf("month")
    case DateRangeShortcut.Last12Months:
      return dayjs().subtract(12, "month").startOf("month")
    case DateRangeShortcut.ThisQuarter:
      return dayjs().startOf("quarter")
    case DateRangeShortcut.LastQuarter:
      return dayjs().subtract(1, "quarter").startOf("quarter")
    case DateRangeShortcut.ThisYear:
      return dayjs().startOf("year")
    case DateRangeShortcut.LastYear:
      return dayjs().subtract(1, "year").startOf("year")
    default:
      return dayjs().subtract(30, "day")
  }
}

export function calculateEndDate(dateRangeShortcut?: DateRangeShortcut | null): dayjs.Dayjs {
  switch (dateRangeShortcut) {
    case DateRangeShortcut.Last7Days:
    case DateRangeShortcut.Last14Days:
    case DateRangeShortcut.Last30Days:
    case DateRangeShortcut.Last60Days:
    case DateRangeShortcut.Last90Days:
    case DateRangeShortcut.ThisMonth:
    case DateRangeShortcut.Last3Months:
    case DateRangeShortcut.Last6Months:
    case DateRangeShortcut.Last12Months:
    case DateRangeShortcut.ThisQuarter:
    case DateRangeShortcut.ThisYear:
      return dayjs().endOf("day")
    case DateRangeShortcut.LastMonth:
      return dayjs().subtract(1, "month").endOf("month")
    case DateRangeShortcut.LastQuarter:
      return dayjs().subtract(1, "quarter").endOf("quarter")
    case DateRangeShortcut.LastYear:
      return dayjs().subtract(1, "year").endOf("year")
    default:
      return dayjs().endOf("day")
  }
}

interface Props {
  readonly onApply: (
    shortcut: DateRangeShortcut,
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs | null
  ) => void
  readonly dateRangeShortcut?: DateRangeShortcut
  readonly currentStartDate?: dayjs.Dayjs | null
  readonly currentEndDate?: dayjs.Dayjs | null
}

export function DateRangeFilter({
  onApply,
  dateRangeShortcut,
  currentStartDate,
  currentEndDate,
}: Props) {
  const { t } = useTranslation("common")
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(() => {
    if (dateRangeShortcut === DateRangeShortcut.Custom) {
      return currentStartDate ?? dayjs().subtract(30, "day")
    } else {
      return calculateStartDate(dateRangeShortcut ?? DateRangeShortcut.Last30Days)
    }
  })
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(() => {
    if (dateRangeShortcut === DateRangeShortcut.Custom) {
      return currentEndDate ?? null
    } else {
      return calculateEndDate(dateRangeShortcut ?? DateRangeShortcut.Last30Days)
    }
  })
  const [errors, setErrors] = useState(() => ({
    startDate: null,
    endDate: null,
  }))

  const isOpen = Boolean(anchorEl)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (dateRangeShortcut === DateRangeShortcut.Custom) {
        setStartDate(currentStartDate ?? dayjs().subtract(30, "day"))
        setEndDate(currentEndDate ?? null)
      } else {
        setStartDate(calculateStartDate(dateRangeShortcut ?? DateRangeShortcut.Last30Days))
        setEndDate(calculateEndDate(dateRangeShortcut ?? DateRangeShortcut.Last30Days))
      }
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [currentEndDate, currentStartDate, dateRangeShortcut])

  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleClickShortcut(shortcut: DateRangeShortcut) {
    setAnchorEl(null)

    if (shortcut === DateRangeShortcut.Custom) {
      const start = startDate ?? dayjs().subtract(30, "day")
      const end = endDate ?? null
      onApply(shortcut, start, end)
    } else {
      onApply(shortcut, calculateStartDate(shortcut), calculateEndDate(shortcut))
    }
  }

  function getButtonLabel(): string {
    if (dateRangeShortcut && dateRangeShortcut !== DateRangeShortcut.Custom) {
      return t(`component.dateRangeFilter.shortcuts.${dateRangeShortcut}`)
    } else if (currentStartDate && currentEndDate) {
      return `${currentStartDate?.format("ll")} - ${currentEndDate?.format("ll")}`
    } else if (currentStartDate && !currentEndDate) {
      return `${currentStartDate?.format("ll")} - ${dayjs().format("ll")}`
    } else {
      return t("component.dateRangeFilter.label")
    }
  }

  return (
    <>
      <Button
        fullWidth
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        startIcon={<DateRangeIcon sx={{ fontSize: "1rem" }} />}
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          padding: "0.5rem 1rem",
          color: theme.fielderColors.text,
          borderColor: theme.fielderColors.mutedText,
          border: "1px solid #ccc",
          borderRadius: "4px",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "#ffffff65",
            borderColor: "#aaa",
          },
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
        })}
        variant="text"
      >
        {getButtonLabel()}
      </Button>
      <Popper
        anchorEl={anchorEl}
        id="date-range-filter-popper"
        open={isOpen}
        placement="bottom-start"
        sx={{ zIndex: 1200, fontSize: "1rem" }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={150}>
              <Paper
                sx={{
                  border: "none",
                  borderRadius: "4px",
                  bgcolor: "background.paper",
                  maxWidth: "34rem",
                  mx: "0.25rem",
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ fontWeight: "600", mb: "1rem" }}>
                  {t("component.dateRangeFilter.label")}
                </Box>
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <Box
                    id="date-range-filter-shortcuts"
                    sx={(theme) => ({
                      display: "none",
                      [theme.breakpoints.up(375)]: {
                        flex: "0 0 auto",
                        display: "flex",
                        flexDirection: "column",
                        py: "1rem",
                        pr: "0.5rem",
                        maxHeight: "20.75rem",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        borderRight: "1px solid rgba(0,0,0,0.12)",
                      },
                    })}
                  >
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last7days")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last7Days)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last14days")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last14Days)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last30days")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last30Days)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last60days")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last60Days)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last90days")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last90Days)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.thisMonth")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.ThisMonth)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.lastMonth")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.LastMonth)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last3months")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last3Months)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last6months")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last6Months)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.last12months")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.Last12Months)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.thisQuarter")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.ThisQuarter)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.lastQuarter")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.LastQuarter)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.thisYear")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.ThisYear)}
                    />
                    <ShortcutButton
                      label={t("component.dateRangeFilter.shortcuts.lastYear")}
                      onClick={() => handleClickShortcut(DateRangeShortcut.LastYear)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                      padding: "1rem",
                      paddingRight: "2rem",
                      paddingTop: "1.5rem",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      "& .MuiInputAdornment-root button.Mui-focusVisible": {
                        backgroundColor: (theme) => theme.fielderColors.brandPrimaryFaded,
                        borderRadius: "50%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        color: (theme) => theme.fielderColors.mutedText,
                        fontSize: "0.875rem",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {t("component.dateRangeFilter.instructions")}
                    </Box>
                    <DatePicker
                      format="L"
                      label={t("startDate") as string}
                      maxDate={dayjs().endOf("day")}
                      onChange={(val) => {
                        setStartDate(val)
                        if (val && dayjs(val).isValid()) {
                          setErrors({
                            ...errors,
                            startDate: null,
                          })
                        }
                      }}
                      slotProps={{
                        field: {
                          clearable: true,
                        },
                        textField: {
                          InputProps: {
                            disableUnderline: true,
                          },
                          error: !!errors.startDate,
                          fullWidth: true,
                          required: false,
                          helperText: errors.startDate ? errors.startDate : null,
                        },
                      }}
                      slots={{
                        textField: FielderTextField,
                      }}
                      sx={{
                        flex: 1,
                      }}
                      value={startDate}
                    />
                    <DatePicker
                      format="L"
                      label={t("endDate")}
                      maxDate={
                        startDate
                          ? dayjs(startDate).add(1, "year").endOf("day")
                          : dayjs().endOf("day")
                      }
                      minDate={startDate ? dayjs(startDate).add(1, "day") : undefined}
                      onChange={setEndDate}
                      openTo="day"
                      slotProps={{
                        field: {
                          clearable: true,
                        },
                        textField: {
                          InputProps: {
                            disableUnderline: true,
                          },
                          error: !!errors.endDate,
                          fullWidth: true,
                          required: false,
                          helperText: errors.endDate ? errors.endDate : null,
                        },
                      }}
                      slots={{
                        textField: FielderTextField,
                      }}
                      value={endDate}
                    />
                  </Box>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    pt: "0.875rem",
                  }}
                >
                  <Button onClick={() => setAnchorEl(null)} variant="outlined">
                    {t("cancel")}
                  </Button>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                    <Button
                      onClick={() => {
                        setStartDate(null)
                        setEndDate(null)
                      }}
                      variant="text"
                    >
                      {t("clear")}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        setAnchorEl(null)
                        const start = startDate ?? dayjs().subtract(30, "day")
                        let end = endDate ?? dayjs().endOf("day")

                        if (end.isBefore(start)) {
                          end = start.add(7, "day")
                        } else if (end.diff(start, "day") > 365) {
                          end = start.add(1, "year")
                        }

                        onApply(DateRangeShortcut.Custom, start, end)
                      }}
                      variant="contained"
                    >
                      {t("apply")}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
