import pick from "lodash/pick"
import { Address } from "../types"

function snakeToCamel(str: string): string {
  return str.replace(/([-_][a-z0-9])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  )
}

/**
 * Accepts a GeocoderResult object as provided by the 
 * Google Geocoding Service:
 * https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingResults
 * 
 * The object should have the following shape: 
    {
      types[]: string,
      formatted_address: string,
      address_components[]: {
        short_name: string,
        long_name: string,
        postcode_localities[]: string,
        types[]: string
      },
      partial_match: boolean,
      place_id: string,
      postcode_localities[]: string,
      geometry: {
        location: LatLng,
        location_type: GeocoderLocationType
        viewport: LatLngBounds,
        bounds: LatLngBounds
      }
    }
 */
interface LatLng {
  lat(): number
  lng(): number
}
enum GeocoderLocationType {
  APPROXIMATE = "APPROXIMATE",
  GEOMETRIC_CENTER = "GEOMETRIC_CENTER",
  RANGE_INTERPOLATED = "RANGE_INTERPOLATED",
  ROOFTOP = "ROOFTOP",
}
interface GeocoderGeometry {
  location: LatLng
  location_type: GeocoderLocationType
  // viewport: LatLngBounds
  // bounds: LatLngBounds
}
interface GeocoderAddress {
  short_name: string
  long_name: string
  postcode_localities: string[]
  types: string[]
}
interface GeocoderResult {
  types: string[]
  formatted_address: string
  address_components: GeocoderAddress[]
  partial_match: boolean
  place_id: string
  postcode_localities: string[]
  geometry: GeocoderGeometry
  name?: string
}
function convertPlaceToAddress(place: GeocoderResult): Address | null {
  if (place?.address_components) {
    const address = place.address_components?.reduce((acc: { [key: string]: any }, cur) => {
      acc[snakeToCamel(cur["types"][0])] = cur.long_name
      return acc
    }, {}) as Address

    if (!address) {
      return {}
    }

    address.addressString = place.formatted_address
    address.latitude = place.geometry?.location?.lat()
    address.longitude = place.geometry?.location?.lng()
    return pick(address, [
      "addressString",
      "streetNumber",
      "route",
      "locality",
      "administrativeAreaLevel1",
      "administrativeAreaLevel2",
      "postalCode",
      "country",
      "latitude",
      "longitude",
    ])
  } else if (place?.name) {
    return {
      addressString: place.name,
    }
  } else {
    return null
  }
}

export default convertPlaceToAddress
